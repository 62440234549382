<div id="footer-container" fxLayout="row" fxLayout.md="row" fxLayout.xs="column" fxLayoutAlign.gt-lg="space-between none" fxLayoutAlign.gt-md="none none" fxLayoutGap="1em">
    <div id="socials-footer-mobile" fxLayout.lt-md="row" fxLayoutAlign="start center stretch" fxLayoutGap.lt-md="20px">
        <a href="{{facebook}}" target="_blank"><img src="/assets/images/fb-logo.svg" width="33px" alt=""></a>
        <a href="{{instagram}}" target="_blank"><img src="/assets/images/ins-logo.svg" width="33px" alt=""></a>
        <a href="{{twitter}}" target="_blank"><img src="/assets/images/tw-logo.svg" width="33px" alt=""></a>
        <a href="{{linkedin}}" target="_blank"><img src="/assets/images/lin-logo.svg" width="33px" alt=""></a>
        <a href="{{ticktok}}" target="_blank"><img src="/assets/images/tik-logo.svg" width="33px" alt=""></a>
    </div>
    <div id="links-footer-container" fxFlex.gt-lg="59%" fxFlex="80%" fxLayout="row" fxLayoutAlign="start start" fxLayout.md="column" fxLayout.lt-sm="column" >
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="items-footer" fxLayoutGap.md="50px">
            <p class="p pointer" style="padding-top: 15px; width: max-content;" fxFlex routerLink="/about-us">Quienes somos</p>
            <p class="p pointer" style="padding-top: 15px; width: max-content;" fxFlex routerLink="/business-info">Sogcial Business</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="items-footer" fxLayoutGap.md="50px">
            <p class="p pointer" style="padding-top: 15px; width: max-content;" routerLink="/first-steps" fxFlex>Primeros Pasos</p>
            <p id="ensena-en-sogcial-title" style="padding-top: 15px; width: max-content;" class="p pointer" fxFlex routerLink="/teacher-info" >Ensena en Sogcial</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="items-footer" fxLayoutGap.md="50px">
            <p class="p pointer" style="padding-top: 15px; width: max-content;" fxFlex routerLink="/first-steps">Ayuda y Asistencia</p>
            <p class="p pointer" style="padding-top: 15px; width: max-content;" fxFlex routerLink="/first-steps">Planes</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="items-footer">
            <p class="p pointer" style="padding-top: 15px;  width: max-content;" fxFlex routerLink="/contact" >Contactanos</p>
            <p class="p pointer" style="padding-top: 15px;" fxFlex></p>
        </div>
    </div>
    <div fxFlex="40%" fxLayout="column" fxLayoutAlign="center end stretch" >
        <div id="pay-methods-container" style="margin-right: 1em;"  fxLayoutAlign="center start stretch" fxLayout="row" fxLayout.xs="row" fxLayout.md="row" fxLayoutGap.gt-sm="20px" fxLayoutGap.xs="1em">
            <img src="/assets/images/icon5.png" width="120px" alt="">
            <img src="/assets/images/paypal.png" width="120px" alt="">
            <img src="/assets/images/icon7.png" width="33px" alt="">
        
            <button  mat-button class="example-icon style-button favorite-icon style-login" >
                CO <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
            <button  mat-button class="example-icon style-button favorite-icon style-login" >
                ES <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
        </div>
        <div fxLayout="row" style="margin-right: 1em;" fxLayoutAlign.xs="center center" fxLayoutGap.xs="1em" fxLayoutAlign="end end stretch"  fxLayoutGap.gt-sm="20px">
            <p class="links-footer pointer" routerLink="/term-condition">Condiciones de uso  </p>
            <p class="links-footer pointer"> | </p>
            <p class="links-footer pointer" routerLink="/privacy-policies"> Politicas de Privacidad  </p>
            <p class="links-footer pointer"> | </p>
            <p class="links-footer pointer" routerLink="/privacy-policies"> Politica de Cookies  </p>

        </div>
    </div>
</div>



<div class="footer-background" fxLayout="row" fxLayoutAlign="space-between none" fxLayoutGap="36px" fxLayoutGap.xs="0px" >
    <div fxFlex="33%" fxLayout="column" fxFlex.md="50%" fxLayoutAlign="space-between stretch">

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center stretch" fxLayoutGap.gt-sm="20px">
            <p class="footer-title"><strong >Sogcial</strong> 2020</p>
        </div>
    </div>
    <div fxFlex="66%" fxLayout="column" fxLayoutAlign="end end">
        <div class="policies" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center stretch" fxLayoutGap.gt-sm="20px" style="margin-bottom: 17px;">
            <a href="{{facebook}}" target="_blank"><img src="/assets/images/fb-logo.svg" width="33px" alt=""></a>
            <a href="{{instagram}}" target="_blank"><img src="/assets/images/ins-logo.svg" width="33px" alt=""></a>
            <a href="{{twitter}}" target="_blank"><img src="/assets/images/tw-logo.svg" width="33px" alt=""></a>
            <a href="{{linkedin}}" target="_blank"><img src="/assets/images/lin-logo.svg" width="33px" alt=""></a>
            <a href="{{ticktok}}" target="_blank"><img src="/assets/images/tik-logo.svg" width="33px" alt=""></a>
        </div>
    </div>
</div>
