<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
      <app-header></app-header>
        <app-sub-header [screen]="'Quienes Somos'" ></app-sub-header>
        
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px"
        style="padding: 5em;" class="background">
            <div fxFlex="50%" fxLayout="column" fxLayoutAlign="end end stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="height: 12em; padding: 5em;">
                    <mat-icon>remove_from_queue</mat-icon>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p class="title">Un sitio para ti</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p class="rigth">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="height: 12em; padding: 5em;">
                    <mat-icon>remove_from_queue</mat-icon>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p class="title">Un sitio para ti</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p class="rigth">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                </div>
            </div>
            <div fxFlex="50%" fxLayout="column" fxLayoutAlign="center start center">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="height: 12em; padding-bottom: 5em; padding-top: 4em;">
                    <mat-icon>remove_from_queue</mat-icon>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p class="title">Un sitio para ti</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p class="left">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                </div>
            </div>
        </div>
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
        <div class="row">
            <div class="col-md-12" style="padding: 7em;">
                <app-front-sliders [images]="images"></app-front-sliders>
            </div>
        </div>
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px"
    style="padding: 50px; background: #f3f3f3;">
        <div fxFlex="33%" fxLayout="column" fxLayoutAlign="end end stretch">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="height: 6em; padding-right: 3em;">
              <mat-icon>people_alt</mat-icon>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                <p class="title-2">Usuario</p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                <p class="rigth-2">Si eres Usuario, sogcial será tu mejor aliado para llevar el control de los diversos programas, eventos y cursos en los que estés participando. Podrás inscribir a tus hijos y seres queridos, conocer y organizar los horarios y eventos de todos, desde una misma plataforma, de forma práctica y sencilla.</p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                <button  mat-button class="example-icon favorite-icon style-login ingresar" >
                    Ingresar
                </button>
            </div>
        </div>
        <div fxFlex="33%" fxLayout="column" fxLayoutAlign="end end stretch" >
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="height: 6em;padding-right: 3em;">
              <mat-icon>people_alt</mat-icon>
                
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                <p class="title-2">Teacher</p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                <p class="rigth-2">Al suscribirte como Teacher, podrás dar a conocer y ofrecer la enseñanza de tus conocimientos académicos, empíricos y mejores habilidades, para así participar en los cursos, programas y eventos que tenemos disponibles según tu perfil y metas profesionales.</p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                <button  mat-button class="example-icon favorite-icon style-login ingresar" >
                    Ingresar
                </button>
            </div>
        </div>
        <div fxFlex="34%" fxLayout="column" fxLayoutAlign="end end stretch">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="height: 6em; padding-right: 3em;">
              <mat-icon>people_alt</mat-icon>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                <p class="title-2">Business</p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                <p class="rigth-2">Con tu suscripción de Business, sogcial te ofrece una plataforma confiable y eficaz para lograr impulsar tu negocio, ofrecer la prestación de tus servicios y alcanzar un gran número de clientes. Sogcial será tu mejor socio para fijar tu negocio en el e-commerce y las nuevas tecnologías, el futuro de tu visión empresarial es ahora.</p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                <button  mat-button class="example-icon favorite-icon style-login ingresar" >
                    Ingresar
                </button>
            </div>
        </div>
    </div>
        <app-footer></app-footer>  
    </div>
</mat-drawer-container>