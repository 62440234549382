import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsersGuard implements CanActivate {


  constructor(private router:Router){}


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean {

   const user=JSON.parse(localStorage.getItem('userData'))
   const rol = user?.rol_id
   console.log(rol)
   if(rol===2){
     this.router.navigateByUrl('/dashboard/teacher')
     return false
   }
    return true;
  }

}
