import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { async } from '@angular/core/testing';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BussinesOwnerServiceService {
    public baseurl: string;
    temp: string;
  
   constructor( private http: HttpClient) {
      this.baseurl = environment.url_server;  
   }

   errorHandl
   getUser(id) : Observable<any>{
    const urlService = `${this.baseurl}users/${id}`
    return  this.http.get(urlService)    .pipe( retry(1),
    catchError(this.errorHandl)
     );
   }

   getBusinessInfo(id) : Observable<any>{
    const urlService = `${this.baseurl}businessInfo/${id}`
    
    return  this.http.get(urlService)    .pipe( retry(1),
    catchError(this.errorHandl)
     );
   }




   

}


