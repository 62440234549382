import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent} from './header/header.component';
import { FooterComponent} from './footer/footer.component';
import { SidebarComponent} from './sidebar/sidebar.component';
import { MaterialModule } from 'src/app/material/material.module';
import { MessageComponent } from './message/message.component';
import { TableComponent } from './table/table.component';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { environment } from "../../../environments/environment";

/* ngx-translate */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

  const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    observer: true,
    direction: 'horizontal',
    threshold: 50,
    spaceBetween: 5,
    slidesPerView: 1,
    centeredSlides: true
  };

import { AgmCoreModule } from '@agm/core';
import { CarouselComponent } from './carousel/carousel.component';
import { UploadComponent } from './upload/upload.component';
import { EditorComponent } from './editor/editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { ModalDeleteHourComponent } from './calendar/modal-delete-hour/modal-delete-hour.component';
// import { MyOfertListComponent } from './my-ofert-list/my-ofert-list.component';

// import { CalendarComponent } from './calendar/calendar.component';




@NgModule({
  declarations: [
    HeaderComponent,
  	FooterComponent,
  	SidebarComponent,
  	MessageComponent,
    TableComponent,
    CarouselComponent,
    UploadComponent,
    EditorComponent,
    GoogleMapsComponent,
    ModalDeleteHourComponent,
    // MyOfertListComponent,
    // CalendarComponent,
  	],
  imports: [
    CommonModule,
    MaterialModule,
    SwiperModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SwiperModule,
    HttpClientModule,
    AngularEditorModule,
    AgmCoreModule.forRoot({
      apiKey: environment.key_google_maps
    })
  ],
  exports: [
  	HeaderComponent,
  	FooterComponent,
    SidebarComponent,
    MessageComponent,
    TableComponent,
    CarouselComponent,
    UploadComponent,
    GoogleMapsComponent
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class ComponentsModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/language/', '.json');
}
