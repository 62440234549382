import { Component, OnInit } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface,SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { GeneralService } from "../../../services/general.service";

@Component({
  selector: 'app-carousel-website',
  templateUrl: './carousel-website.component.html',
  styleUrls: ['./carousel-website.component.scss']
})

export class CarouselWebsiteComponent implements OnInit {
  images:string[]=['audiovisual.jfif','arte.jfif','fisico.jfif', 'derecho.jfif','idiomas.jfif'];
  public config: SwiperConfigInterface = {
    a11y: false,
    loop:true,
    direction: 'horizontal',
    keyboard: true,
    autoplay:false,
    
    spaceBetween:25,
    navigation: true,
    speed:1000,
   
     breakpoints: {
            // when window width is <= 1024px
            1024: {
                slidesPerView: 4,
                initialSlide:1,

            }
        }
    // mousewheel: true,
    // scrollbar: false,
    // navigation: true,
  };
  sliders=[]
  constructor(
    private GeneralService:GeneralService
  ) { }
  // get_numberCourseByCategory
  ngOnInit(): void {
    this.GeneralService.get_numberCourseByCategory().subscribe(resp=>{
      if(resp.message_return.ERROR==false){
        this.sliders=resp.result;
        console.log(this.sliders)
      }
    })
  }

}
