import { Component, OnInit } from '@angular/core';
import { NotificationsService } from "../../../services/notifications.service";
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import { GeneralService } from "../../../services/general.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications
  // =[
  //   {
  //     id:1,
  //     image:'apps',
  //     description:"Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:2,
  //     image:'account_box',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:3,
  //     image:'import_contacts',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:4,
  //     image:'account_box',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:1,
  //     image:'apps',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:2,
  //     image:'account_box',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:3,
  //     image:'import_contacts',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:4,
  //     image:'account_box',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:2,
  //     image:'account_box',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
  //   {
  //     id:3,
  //     image:'import_contacts',
  //     description:"Lorem ipsum dolor sit amet, consectetur...",
  //     time:"1m"
  //   },
    
    
  // ]
  total=0
  constructor(
    private NotificationsService:NotificationsService,
    private translate: TranslateService,
    private GeneralService:GeneralService
    
  ) { 
    this.translate.get('notifications.time').subscribe( (text: string) => {
    console.log(text,'xx')
    moment.locale("en");
    // this.msg = text;
    });

  }

  ngOnInit(): void {
    let datas=JSON.parse(localStorage.getItem('userData'))
      console.log(datas)
      let data={
        user_id:datas.id,
        limit:0
      }
      let aux=[];
      let text
      let text2
      console.log("hola")
      // this.NotificationsService.noti$.subscribe
      this.NotificationsService.post_notificationsgetNotificationsByUserID(data).subscribe(resp=>{
        console.log(resp,"mmmmm")
        if(resp.message_return.ERROR==false){
          this.total=resp.notifications.length
          this.notifications=resp.notifications
          this.notifications.filter(re=>{
            let time=moment(re.date).fromNow(true);
            console.log(time,"moment(re.date).fromNow(true)");
            let format=this.GeneralService.formatDate(time);
            aux.push({
              image:re.notification_type,
              title:re.title,
              time:format,
              status:re.Status
            })
          })
          this.notifications=aux
          console.log(this.notifications)
        }
      })
  }
  

}
