import { data } from 'jquery';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SendProposalComponent } from '../../events/create/step-teacher/send-proposal/send-proposal.component';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Params, ActivatedRoute, RouterLink, Router } from '@angular/router';
import { EventsService } from '../../../../services/events.service';
import { GeneralService } from '../../../../services/general.service';
import { ProposalService } from '../../../../services/proposal.service';

@Component({
  selector: 'app-list-teacher',
  templateUrl: './list-teacher.component.html',
  styleUrls: ['./list-teacher.component.scss'],
})
export class ListTeacherComponent implements OnInit, OnDestroy,AfterViewInit {
  @ViewChild('paginator') paginator: MatPaginator;


  hide = true;
  dataSource;
  displayedColumns: string[] = ['image', 'body', 'invite'];
  teacher = [];
  idProposal;
  user;
  idcureven;

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  countrySelect = 0;
  rankSelect = 0;
  skillSelect = '';
titles=[];
city=[];
  country = [];
  skills = [];
states=[];
  skillsTeacher;
  loadTeachers = false;
  rank = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' },
  ];
  proposal = null;

  constructor(
    private EventsService: EventsService,
    private GeneralService: GeneralService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _router: Router
  ) {}

  ngAfterViewInit() {
   // this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('userData'));

    this.msgSuscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });

    this.GeneralService.get_countries().subscribe(
      (res) => {
        console.log('res teacher-get_countries:', res);
        res.countries.forEach((element) => {
          this.country.push({
            name: element.nicename,
            id: element.id,
          });
        });
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
    this.EventsService.get_tags().subscribe(
      (res) => {
        console.log('res:teacher get_tags:', res);
        this.skills = res.tags;
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
this.listTeachers();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  listTeachers() {
    let data = [];
    data.push({
      state_id: 71, // this.user.country_id  (71 es el q segun tienen mas teachers) el valor a setear es this.user.country_id para que quede correcto
    });
    console.log('data: servicio', data);
    this.EventsService.post_teacher_search('').subscribe(
      (res) => {
        this.loadTeachers=true;
        console.log('res post_teacher_search:', res);
        this.teacher = res.result;
        this.titles= this.teacher.map((t)=>t.title).filter((t)=>t!==null)
        this.titles=[...new Set(this.titles)]
        console.log(this.titles)
        this.city= this.teacher.map((c)=>c.CityName).filter((c)=>c!==null)
        this.city=[...new Set(this.city)]
        this.states= this.teacher.map((s)=>s.StateName).filter((s)=>s!==null)
        this.states=[...new Set(this.states)]
        this.teacher.forEach((element) => {
          element.skills =
            element.skills != null ? element.skills?.map((sk)=>sk.name) : '';
          element.Ubication =
            element.Ubication != null ? element.Ubication.split(',') : '';


        });
        console.log(this.proposal);
        console.log(this.teacher);

        console.log('teacher:::', this.teacher);
        this.teacher.filter((resp) => {
          resp.hidden = false;
        });
        this.dataSource = new MatTableDataSource(this.teacher);

        console.log('teacher:', this.teacher);
        this.dataSource.paginator = this.paginator;
        // console.log("datasource:",this.dataSource);
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
  }

  onFilter($event) {
    let data = [];
    data.push({
      skill: this.skillSelect,
      city_id: this.rankSelect,
      country: this.countrySelect,
    });
    console.log('data:', data);
    this.EventsService.post_teacher_search(data).subscribe(
      (res) => {
        console.log('res post_teacher_search-onfilter:', res);
        this.teacher = res.result;
        this.teacher.filter((resp) => {
          resp.hidden = false;
        });
        this.teacher.forEach((element) => {
          element.skills =
            element.skills != null ? element.skills.split(',') : '';
        });
        console.log('teacher:::', this.teacher);
        this.dataSource = new MatTableDataSource(this.teacher);
        this.dataSource.paginator = this.paginator;
        console.log('datasource-onfiletr:', this.dataSource);
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
  }


  onImgError(event) {
    event.target.src = './assets/images/person-default.svg';
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSuscription;
    this.typeMsgSuscription;
  }

  filterTitle(select){
    const teacher= this.teacher;
const data= teacher.filter((t)=>t.title===select.value)
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;

    console.log(select.value)
  }

  filterLocation(select){
    const teacher= this.teacher;
const data= teacher.filter((l)=>l.CityName===select.value)
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;

    console.log(select.value)
  }

  filterState(select){
    const teacher= this.teacher;
    const data= teacher.filter((s)=>s.StateName===select.value)
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;

    console.log(select.value)
  }

  cleartitle(title){
    title.value='';

    this.dataSource = new MatTableDataSource(this.teacher);
    this.dataSource.paginator = this.paginator;


  }

  clearcity(city){
    city.value='';

    this.dataSource = new MatTableDataSource(this.teacher);
    this.dataSource.paginator = this.paginator;
  }

  clearstate(state){
    state.value='';

    this.dataSource = new MatTableDataSource(this.teacher);
    this.dataSource.paginator = this.paginator;
  }
}
