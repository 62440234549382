import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'auth-app',

  template: `   
   <app-header-mobile *ngIf="isMobile"></app-header-mobile>
        <div class="container" >
            <mat-card id="mat-auth"><router-outlet></router-outlet></mat-card>
        </div>`,
  })
export class AuthComponent {
  isMobile:boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {}
  ngOnInit(): void {
    const CustomBreakpoints = {
      Handset: '(max-width: 599px)', // Mobile devices up to 599px
    };
    this.breakpointObserver.observe([CustomBreakpoints.Handset])
    .subscribe(result => this.isMobile = result.matches);
}
}
