<!-- <mat-horizontal-stepper linear #stepper> esta linea para version web y final -->
<mat-horizontal-stepper #stepper linear>
  <mat-step [completed]="controlStepper.step1" [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup" fxLayout="column" fxLayoutGap="36px" style="margin-top: 24px;">
      <ng-template matStepLabel>{{'ev-create.basic' | translate}}</ng-template>

      <div fxLayout="column" fxLayoutAlign="center stretch">
        <app-message *ngIf="msg!=null" [msg]="msg" [typeMsg]="typeMsg"></app-message>
      </div>


      <div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">
        <mat-card fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="60" fxLayoutGap="30pxpx" class="card">
          <div div fxLayout="row" fxLayoutAlign="space-between center">
            <p class="title-card">{{'ev-create.basic' | translate}}</p>
            <p class="bg-orange text-required">* Campos requeridos</p>
          </div>
          <mat-form-field>
            <mat-label class="label-input" translate>ev-create.nameEvent <span class="bg-orange">*</span></mat-label>
            <input matInput placeholder="Programacion Basica" formControlName="nameEvent">
            <mat-error *ngIf="invalidName" class="error" translate>errRequired</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label class="label-input" translate>ev-create.category <span class="bg-orange">*</span></mat-label>
            <mat-select formControlName="category">
              <mat-option *ngFor="let option of categories" [value]="option.id"> {{option.name}} </mat-option>
            </mat-select>
            <mat-error *ngIf="invalidCategory" class="error" translate>errRequired</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label class="label-input" translate>ev-create.privacity <span class="bg-orange">*</span></mat-label>
            <mat-select formControlName="state">
              <mat-option value=0>{{'ev-create.public' | translate}}</mat-option>
              <mat-option value=1>{{'ev-create.private' | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="invalidState" class="error" translate>errRequired</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label class="label-input" translate>ev-create.description <span class="bg-orange">*</span></mat-label>
            <textarea matInput cdkTextareaAutosize rows="30" #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5" formControlName="description"></textarea>
            <mat-error *ngIf="invalidDescription" class="error" translate>errRequired</mat-error>
          </mat-form-field>
        </mat-card>

        <mat-card fxFlex="35" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="36px" class="card">
          <img *ngIf="src_img!=''" [src]="src_img" style="width:100%; border-radius: 5px;">

          <mat-card *ngIf="src_img==''" fxLayoutAlign="center center" style="min-height: 210px;">
            <span class="material-icons icon-large">perm_media</span>
          </mat-card>
          <span *ngIf="showErrorImg" class="camporequerido">* La imagen es obligatoria</span>
          <app-upload (fileEvent)="receiveFile($event)" (srcImgEvent)="receiveSrcImg($event)"></app-upload>

          <mat-form-field>
            <mat-chip-list #chipList aria-label="Tag selection">
              <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                (removed)="remove(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input (click)="add($event)" (focus)="add($event)" placeholder="Tags" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                {{tag}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="chipList.errorState" class="error" translate>errRequired
            </mat-error>

          </mat-form-field>
        </mat-card>
      </div>


      <div *ngIf="load" fxLayoutAlign="center center" fxflex>
        <mat-spinner  strokeWidth="10" [diameter]="24"></mat-spinner>
      </div>
      <div *ngIf="!load" fxLayoutAlign="center center" fxFlex="30">
        <button (click)="saveEvent()" class="btn-orange-auto btn">{{'save' | translate }}</button>
      </div>
    </form>
  </mat-step>

  <!-- segundo paso -->
  <mat-step  [completed]="controlStepper.step2">
    <ng-template matStepLabel>{{'ev-create.places' | translate}}</ng-template>
    <div fxLayout="column" fxLayoutGap="36px" style="margin-top: 24px;">
      <app-step-places (nextStep)="nextStep2($event)" [createPage]="true"></app-step-places>
    </div>
  </mat-step>

  <!-- tercer paso -->
  <mat-step [completed]="controlStepper.step3">
    <ng-template matStepLabel>{{'ev-create.proposal' | translate}}</ng-template>
    <div fxLayout="column" fxLayoutGap="36px" style="margin-top: 24px;">
      <app-step-proposal></app-step-proposal>
    </div>
  </mat-step>

</mat-horizontal-stepper>
