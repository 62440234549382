import { Component, OnInit,Input,SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {
  @Input() text: string;
  show:boolean=false;
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes.text.currentValue==""){
      this.show = false;
    }else{
      this.show = true;
       
    }
  }

}
