
<form [formGroup]="firstFormGroup">
  <app-message
    *ngIf="msg != null"
    [msg]="msg"
    [typeMsg]="typeMsg"
  ></app-message>
  <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="30px">
    <mat-card fxFlex="70" style="padding: 46px 45px 55px 33px">
      <p class="raleway-23-bold">
        {{ "step-proposal.job-proposal" | translate }}
      </p>
      <mat-tab-group mat-align-tabs="center" style="margin-top: 55px">
        <mat-tab
          class="raleway-14-bold"
          label=" {{ 'step-proposal.data-basic' | translate }}"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="starten stretch"
            fxFlex
            fxLayoutGap="28px"
            style="overflow: hidden"
          >
            <div class="titulo" fxLayout="column" fxLayoutAlign="start stretch" fxflex>


              <mat-form-field fxFlex>
                <mat-label class="oxigen-14">{{
                  "step-proposal.title" | translate
                }}</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="title"
                  placeholder="Descripcion corta de la propuesta"
                />
                <mat-error *ngIf="validTitle">*Campo obligatorio</mat-error>
              </mat-form-field>


            </div>

            <div fxLayout="row" fxLayoutGap="37px" fxFlex>
              <div fxLayout="column" fxFlex>
                <mat-form-field fxFlex>
                  <mat-label class="oxigen-14">{{
                    "step-proposal.visibility" | translate
                  }}</mat-label>
                  <mat-select formControlName="visibility">
                    <mat-option value="public">{{
                      "step-proposal.public" | translate
                    }}</mat-option>
                    <mat-option value="private">{{
                      "step-proposal.private" | translate
                    }}</mat-option>
                    <mat-option value="both">{{
                      "step-proposal.both" | translate
                    }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="validVisibility"
                    >*Campo obligatorio</mat-error
                  >
                </mat-form-field>
              </div>

              <div fxLayout="column" fxFlex>
                <mat-form-field fxFlex>
                  <mat-label class="oxigen-14"
                    >{{ "step-proposal.type-contract" | translate }}
                  </mat-label>
                  <mat-select formControlName="type">
                    <mat-option value="fixed price"
                      >{{ "step-proposal.fixed-price" | translate }}
                    </mat-option>
                    <mat-option value="hourly">{{
                      "step-proposal.hourly" | translate
                    }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="validType">*Campo obligatorio</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxLayoutGap="37px" fxFlex>
              <div fxLayout="column" fxFlex>
                <mat-form-field fxFlex>
                  <mat-label class="oxigen-14">{{
                    "step-proposal.currency" | translate
                  }}</mat-label>
                  <mat-select formControlName="currency">
                    <mat-option *ngFor="let item of currency" [value]="item.id"
                      >{{ item.title }} ({{ item.code }})</mat-option
                    >
                  </mat-select>

                  <mat-error *ngIf="validCurrency"
                    >*Campo obligatorio</mat-error
                  >
                </mat-form-field>
              </div>

              <div fxLayout="column" fxFlex>
                <mat-form-field fxFlex>
                  <mat-label class="oxigen-14">{{
                    "step-proposal.price" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="price"
                    placeholder="0000"
                  />
                  <mat-error *ngIf="validPrice">*Campo obligatorio</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="column" fxFlex>
              <mat-form-field fxFlex>
                <mat-label class="oxigen-14">{{
                  "step-proposal.level" | translate
                }}</mat-label>
                <mat-select formControlName="level">
                  <mat-option value="Beginner">{{
                    "step-proposal.basic" | translate
                  }}</mat-option>
                  <mat-option value="Intermediate"
                    >{{ "step-proposal.intermediate" | translate }}
                  </mat-option>
                  <mat-option value="Expert">{{
                    "step-proposal.expert" | translate
                  }}</mat-option>
                </mat-select>
                <mat-error *ngIf="validLevel">*Campo obligatorio</mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxFlex fxLayoutGap="24px">
              <div fxLayout="column" fxFlex="20">
                <mat-form-field fxFlex>
                  <mat-label class="oxigen-14">{{
                    "step-proposal.time" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="time"
                    placeholder="0000"
                  />
                  <mat-error *ngIf="validTime">*Campo obligatorio</mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="column" fxFlex>
                <mat-form-field fxFlex="80">
                  <mat-label class="oxigen-14">{{
                    "step-proposal.hour/day" | translate
                  }}</mat-label>
                  <mat-select formControlName="timeType">
                    <mat-option value="hour">{{
                      "step-proposal.hour" | translate
                    }}</mat-option>
                    <mat-option value="day">{{
                      "step-proposal.day" | translate
                    }}</mat-option>
                    <mat-option value="week">{{
                      "step-proposal.week" | translate
                    }}</mat-option>
                    <mat-option value="year">{{
                      "step-proposal.year" | translate
                    }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="validTimeType"
                    >*Campo obligatorio</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab
          class="raleway-14-bold"
          label="{{ 'step-proposal.description-question' | translate }}"
        >
          <div fxLayout="column" style="margin-top: 40px" fxFlex>
            <div fxLayout="column" fxFlex>
              <mat-form-field fxFlex>
                <mat-label class="oxigen-14">{{
                  "step-proposal.description" | translate
                }}</mat-label>
                <textarea
                  matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="5"
                  formControlName="description"
                ></textarea>
                <mat-error *ngIf="validDescription"
                  >*Campo obligatorio</mat-error
                >
              </mat-form-field>
            </div>

            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              style="margin-top: 40px"
            >
              <div fxLayout="row" fxLayoutGap="300px" style="margin-top: 45px">
                <p class="raleway-23-bold">
                  {{ "step-proposal.quest" | translate }}
                </p>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start stretch"
                  (click)="openDialogCreateQuestion()"
                >
                  <mat-icon class="btn-add pointer">question_answer</mat-icon>
                  <button class="add-event pointer">
                    {{ "step-proposal.create-question" | translate }}
                  </button>
                </div>
              </div>
              <p
                class="oxigen-13"
                style="margin-top: 20px"
                *ngIf="countQuestions == 0"
              >
                {{ "step-proposal.no-quest" | translate }}
              </p>
              <div *ngFor="let item of questions">
                <p class="oxigen-13" style="margin-top: 20px">
                  {{ item.title }}
                </p>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>

    <mat-card fxFlex="30" style="padding: 46px 32px 30px 32px" *ngIf="flagStar">
      <div fxLayout="column" fxLayoutGap="24px">
        <p class="raleway-23-bold">{{ "step-proposal.detail" | translate }}</p>
        <p class="raleway-14-bold" style="padding-top: 28px">
          {{ "step-proposal.event" | translate }}
        </p>
        <p class="oxigen-13">{{ nameCourse }}</p>
        <p class="raleway-14-bold">Rol: {{ user.rol }}</p>
        <p class="oxigen-13" style="padding-top: 6px">
          {{ user.name }} {{ user.last_name }}
        </p>
        <div fxLayout="row">
          <mat-icon
            *ngFor="
              let item of [].constructor(teacherStar.teacher_average_stars)
            "
            >star</mat-icon
          >
        </div>
        <!-- <p class="oxigen-13" style="margin-top: 12px;">{{date | date:'yyyy LLLL'}}</p>
                 -->
        <p class="oxigen-13">
          Miembro desde {{ date[0] }} {{ "month." + date[1] | translate }}
        </p>
        <p class="oxigen-13">
          {{ teacherStar.publish_work.length - 1 }} Trabajos Publicados
        </p>

        <p class="raleway-14-bold" style="padding-top: 6px">
          {{ "step-proposal.place" | translate }}
        </p>
        <p class="oxigen-13" *ngIf="placeEvent == null">Sin place asignado</p>
        <div *ngIf="placeEvent != null">
          <p class="oxigen-13">{{ placeEvent.Name }}</p>
          <p class="oxigen-13">{{ placeEvent.Ubication }}</p>
          <p class="oxigen-13">{{ placeEvent.address }}</p>
        </div>
      </div>
    </mat-card>
  </div>
</form>
<div *ngIf="load" fxLayoutAlign="center center" fxflex style="margin-top: 35px">
  <mat-spinner strokeWidth="10" [diameter]="24"></mat-spinner>
</div>
<div
  *ngIf="!load"
  fxLayout="row"
  fxLayoutAlign="center center"
  style="margin-top: 35px"
  fxLayoutGap="20px"
>
  <button (click)="onSave()" class="btn-orange btn">
    {{ "ev-create.acept" | translate }}
  </button>
  <div
    class="btn-border-orange pointer"
    style="height: 40px !important; width: 150px !important"
    fxLayoutAlign="center center"
    routerLink="/dashboard/courses"
  >
    <p class="font">Saltar Propuesta</p>
  </div>
</div>

<!-- boton omitir
    <div style="margin-top: 40px;" fxLayoutAlign="center center" style="margin-top: 55px;">
                    <div class="btn-border pointer" fxLayoutAlign="center center">
                        <p class="font">{{'step-proposal.skip' | translate}}</p>
                    </div>
                </div> -->
