<div class="content-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="auto">
    <div class="swiper-container" [disabled]="disabled"
        [swiper]="config" (indexChange)="onIndexChange($event)"
        (swiperTransitionStart)="onSwiperEvent('transitionStart')"
        (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"
    >
        <div class="swiper-wrapper">
            <div *ngFor="let slide of images" class="swiper-slide">
                <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                    <img [src]="slide" alt="image" class="slide" style="width: 100%; max-width: 100%; height: auto; max-height: 100%;">
                </div>
            </div>
        </div>

        <div class="swiper-scrollbar" [hidden]="!config.scrollbar"></div>
        <div class="swiper-pagination" [hidden]="!config.pagination"></div>

        <div class="swiper-button-prev" [hidden]="!config.navigation"></div>
        <div class="swiper-button-next" [hidden]="!config.navigation"></div>
    </div>
</div>
