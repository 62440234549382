import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import { environment } from 'src/environments/environment';
import { CourseDashboardService } from '../../../../services/course-dashboard.service';
import { EventsService } from '../../../../services/events.service';

@Component({
  selector: 'app-detail-course',
  templateUrl: './detail-course.component.html',
  styleUrls: ['./detail-course.component.scss'],
})
export class DetailCourseComponent implements OnInit {

  baseurl = "";

  constructor(
    private CourseDashboardService: CourseDashboardService,
    private route: ActivatedRoute
  ) {
    this.baseurl = environment.url_server;
  }

  idCourse;
  detail = {
    main_image: '',
    title: '',
    category: '',
    place: '',
    capacity: 0,
    availability: 0,
    description: '',
    end_date: '2021-01-01',
    price: 0.01,
    start_date: '2021-01-01',
    tags: '',
    urls: '',
    id_event: -1,
    city: '',
    country: '',
    state: '',
    age: '',
    approved: -1,
    level: '',
    content: '',
    currency: '',
    visibility: 3,
    requirements: '',
    learn: '',
    aimed_to: '',
    quantitative: '',
  };
  images;
  /**
   * url youtube
   */
  youtube='';
  /**
   * id del lugar de curso
   */
  place_id = null;
  event_schedule = [];
  teacher = [];
  event_content = [];
  displayedColumns = ['name', 'identity', 'age', 'email'];
  dataSource;
  jobs = false;
  user;
  id;
  chips: string[];

  url: string = '';
  urls: string = '';
  @Input() SendUrl: string = '';
  @Input() data;
  TODAY_STR = new Date().toISOString().replace(/T.*$/, '');
  INITIAL_EVENTS: EventInput[];

  ngOnInit(): void {

    let URLactual = window.location.href;

    this.route.params.subscribe((params: Params) => {
      this.id = params.id;

      // this.EventsService.get_adminEvents(this.id).subscribe(res => {
      this.CourseDashboardService.get_coursesById(this.id).subscribe(
        (res) => {
          this.place_id = res.course[0].place_id;

          const images = JSON.parse(res.course[0].images);
          const nuevos = images?.map(
            (img) => {
              img = this.baseurl.replace('api/','') + img;
              console.log(img)
              return img;
            }
          );

          this.user = JSON.parse(localStorage.getItem('userData'));


          this.youtube=res.course[0].url;
           this.urls = this.youtube;
          this.images = nuevos;
          this.detail.main_image = res.course[0].main_image;
          this.detail.title = res.course[0].course_name;
          this.detail.category = res.course[0].course_category;
          this.detail.place = res.course[0].place;
          this.detail.capacity = res.course[0].enrollment;
          this.detail.availability = res.course[0].availability;
          this.detail.description = res.course[0].description;
          this.detail.end_date = res.course[0].end_date;
          this.detail.price = res.course[0].price;
          this.detail.start_date = res.course[0].start_date;
          this.detail.tags = res.course[0].tags;
          this.detail.urls = res.course[0].url;
          this.detail.id_event = res.course[0].course_id;
          this.detail.city = res.course[0].city;
          this.detail.country = res.course[0].country;
          this.detail.state = res.course[0].state;
          this.detail.age = res.course[0].age;
          this.detail.requirements = res.course[0].requirements;
          this.detail.learn = res.course[0].learn;
          // this.detail.approved = res.event.approved;
          this.detail.level = res.course[0].level;
           this.detail.content = res.course[0].content;
          this.detail.currency = res.course[0].currency;
          this.detail.aimed_to = res.course[0].aimed_to;
          this.detail.quantitative = res.course[0].quantitative;

          // this.detail.visibility = res.event.visibility;//falta visibilidad en EP

          // // console.log('res(detalle) guardado:', this.detail);
          // for (let i = 0; i < res.event_content.length; i++) {
          //   this.event_content.push({
          //     objetive: res.event_content[i].objective,
          //     content: res.event_content[i].content
          //   })
          // }
          // console.log("event_content:", this.event_content);

          if (res.course_teacher != null) {
            res.course_teacher.forEach((element) => {
              this.teacher.push({
                avatar: element.avatar,
                last_name: element.last_name,
                name: element.name,
                skill: element.tags?.split(','),
                title: element.title,
              });
            });
          }
          console.log('teacher', this.teacher);
          if (this.detail.tags != null) {
            this.chips = this.detail.tags.split(',');
          } else {
            this.chips = null;
          }
          this.chips == null && this.detail.tags != null
            ? this.chips.push(this.detail.tags)
            : null;
          console.log('chips', this.chips);
        },
        (err) => {
          console.log('ERRor ', err);
        }
      );
    });
    if (URLactual.indexOf('/jobs/') != -1) {
      this.get_data_students();
      this.jobs = true;
    }
  }

  get_data_students() {
    let ELEMENT_DATA = [];
    this.CourseDashboardService.getAllUsersByCourse(this.id).subscribe(
      (res) => {
        res.listStudent.forEach((element) => {
          ELEMENT_DATA.push({
            name: element.User,
            identity: element.dni,
            age: element.age,
            email: element.email,
          });
        });
        this.dataSource = ELEMENT_DATA;
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
  }

  get_data_ratings() {}

  onKey($event) {
    this.urls = $event.target.value;
    console.log('onKey', this.urls);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.data.currentValue, 'desc');
    if (changes.data.currentValue != null) {
      this.url = changes.data.currentValue.result.youtube_url;
    }
  }

  errorHandler(event) {
    console.debug(event);
    event.target.src = "./assets/images/person-default.svg";
 }
}

const week = [
  { num: 0, name: 'Domingo' },
  { num: 1, name: 'Lunes' },
  { num: 2, name: 'Martes' },
  { num: 3, name: 'Miercoles' },
  { num: 4, name: 'Jueves' },
  { num: 5, name: 'Viernes' },
  { num: 6, name: 'Sabado' },
];
