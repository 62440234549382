<div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="18px" style="width: 500px; height: 180px;">
    <div  fxLayout="row" fxLayoutAlign="space-between start">
        <p class="raleway-23-g"><b>Remover horar</b></p>
        <button [mat-dialog-close]="null" class="btn-close">
            <mat-icon aria-hidden="false">clear</mat-icon>
        </button>
    </div>
    <p class="oxigen-16-b" >¿Estas seguro de que quieres remover este grupo de horas?</p>
    <div fxLayout="row" fxLayoutAlign="end end"  fxLayoutGap="20px" style="padding-top: 40px;">
        <div class="btn-border-orange pointer" [mat-dialog-close]="null" fxLayoutAlign="center center">
            <p class="font">{{'send-proposal.cancel' | translate}}</p>
        </div>
        <div fxLayoutAlign="center center" [mat-dialog-close]="true">
            <button class="btn-orange btn pointer">{{'send-proposal.accept' | translate}}</button>
        </div>
    </div>
</div>