import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Components */
import { DashboardComponent } from './dashboard.component';
import { DashboardCenterComponent } from './views/dashboard-center/dashboard-center.component';
import { HomeComponent } from './views/events/home/home.component';
import { CreateComponent } from './views/events/create/create.component';
import { DetailComponent } from './views/events/detail/detail.component';


import { AddEditComponent } from './views/places/add-edit/add-edit.component';
import { ListsComponent } from './views/places/lists/lists.component';
import { ShowComponent } from './views/places/show/show.component';
import { CoursesListComponent } from './views/courses/courses-list/courses-list.component';
import { CoursesAddComponent } from './views/courses/courses-add/courses-add.component';
import { EditComponent } from './views/events/edit/edit.component';
import { PaymentComponent } from "./views/payment/payment.component";
import { BasicComponent } from './views/profile/basic/basic.component';
import { ConfigComponent } from './views/profile/config/config.component';
import { CommentComponent } from './views/profile/comment/comment.component';
import { HelpComponent } from './views/profile/help/help.component';
import { ChatComponent } from "./views/chat/chat.component";
import { EditorComponent } from './components/editor/editor.component';
import { NotificationsComponent } from './views/notifications/notifications.component';
import { BudgetComponent } from './views/budget/budget.component';
import { BudgetSentReceivedComponent } from './views/budget-sent-received/budget-sent-received.component';
import { OfertComponent } from './views/ofert/ofert.component';
import { OfertListComponent } from './components/ofert-list/ofert-list.component';
import { ExapmplesComponent } from './views/exapmples/exapmples.component';
import { PublishComponent } from './views/events/publish/publish.component';
import { TeacherComponent } from './views/events/create/step-teacher/teacher.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { StepPlacesComponent } from './views/events/create/step-places/step-places.component';
import { AffiliatesComponent } from './views/affiliates/affiliates.component';
import { CreateCourseComponent } from './views/courses/create-course/create-course.component';
import { ListTeacherCourseComponent } from './views/courses/list-teacher-course/list-teacher-course.component';
import { DetailCourseComponent } from './views/courses/detail-course/detail-course.component';
import { EditCourseComponent } from './views/courses/edit-course/edit-course.component';
import { HomeCourseComponent } from './views/courses/home-course/home-course.component';
import { StepPlaceCourseComponent } from './views/courses/create-course/step-place-course/step-place-course.component';
import { PublishCourseComponent } from './views/courses/publish-course/publish-course.component';
import { MyOfertListComponent } from './components/my-ofert-list/my-ofert-list.component';
import { ProposalDetailsComponent } from './views/proposal-details/proposal-details.component';
import { JobsComponent } from './views/jobs/jobs.component';
import { ProfileTeacherComponent } from './views/teachers/profile/profile.component';
import { ListTeacherComponent } from './views/teachers/list-teacher/list-teacher.component';
import {UsersGuard } from './../guards/users.guard'
import { DashboardTeacherComponent } from './views/teachers/dashboard/dashboard.component';
import { DetailProposalComponent } from './views/teachers/detail-proposal/detail-proposal.component';
import { PostulationTeacherComponent} from './views/teachers/postulacion-teacher/postulation-teacher.component';
const routes: Routes = [
  {path: 'dashboard', component: DashboardComponent,
    children: [
      { path: '', component: DashboardCenterComponent,canActivate:[UsersGuard] },
      { path: 'teacher', component: DashboardTeacherComponent },
      { path: 'events', component:  HomeComponent},
      { path: 'events/list-teachers/:id', component:  TeacherComponent},
      { path: 'courses/list-teachers/:id', component:  ListTeacherCourseComponent},
      { path: 'payments', component:  PaymentComponent},
      { path: 'events/create', component:  CreateComponent},
      { path: 'courses/create', component: CreateCourseComponent },
      { path: 'events/:id', component:  DetailComponent},
      { path: 'courses/:id', component:  DetailCourseComponent},
      { path: 'events/edit/:id', component:  EditComponent},
      { path: 'courses/edit/:id', component:  EditCourseComponent},
      { path: 'places', component: ListsComponent },
      { path: 'ofert', component: OfertListComponent },
      { path: 'places/create', component: AddEditComponent },
      { path: 'places/edit/:id', component: AddEditComponent },
      { path: 'places/:id', component: ShowComponent },
      { path: 'courses', component: HomeCourseComponent },
      { path: 'chat', component: ChatComponent },
      { path: 'test', component: EditorComponent },
      { path: 'basic', component: BasicComponent },
      { path: 'config', component: ConfigComponent },
      { path: 'comment', component: CommentComponent },
      { path: 'help', component: HelpComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'proposal-details/:id', component: BudgetComponent },
      { path: 'proposal', component: BudgetSentReceivedComponent },
      { path: 'ofert-create', component: OfertComponent },
      { path: 'ofert-create/:id', component: OfertComponent },
      { path: 'example', component: ExapmplesComponent },
      { path: 'events/publish/:id', component:  PublishComponent},
      { path: 'courses/publish/:id', component:  PublishCourseComponent},
      { path: 'test2', component:  ExapmplesComponent},
      { path: 'calendar', component:  CalendarComponent},
      { path: 'proposalPlace', component:  StepPlacesComponent},
      { path: 'affiliates', component:  AffiliatesComponent},
      { path: 'proposalPlaceCourse', component:  StepPlaceCourseComponent},
      { path: 'proposals', component:  MyOfertListComponent },
      { path: 'jobs', component: JobsComponent},
      { path: 'jobs/courses/:id', component:  DetailCourseComponent},
      { path: 'jobs/courses/edit/:id', component:  EditCourseComponent},
      //{ path: 'proposal-details/:id', component: ProposalDetailsComponent }
      { path: 'ofert-details/:id', component: BudgetComponent },
      { path: 'teacher/:id', component: ProfileTeacherComponent },
      { path: 'teachers', component: ListTeacherComponent },
      { path: 'teacher/detail-proposal/:id', component: DetailProposalComponent },
      {path: 'teacher/teacher-postulation/:id', component: PostulationTeacherComponent},
      {path: 'teacher/detail-postulation/:id', component: PostulationTeacherComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
