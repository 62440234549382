import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, last, retry } from 'rxjs/operators';
import { EventsService } from '../../../../services/events.service';
import { GeneralService } from '../../../../services/general.service';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { CourseDashboardService } from '../../../../services/course-dashboard.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  // providers: [{
  //   provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  // }]
})
export class CreateComponent implements OnInit, OnDestroy {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl(null,[Validators.required]);
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  src_img: string = '';
  user;
  event_id: Number;
  formData;
  file;

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;


  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('chipList') chipList;
  showErrorImg=false
  controlStepper = {
    step1: false,
    step2: false,
    step3: false,
  };
  minDate = new Date;
  firstFormGroup: FormGroup;



  places = [];
  categories = [];
  searchTags = [];

  load = false;
  selectedStepIndex = 0;
  constructor(private _formBuilder: FormBuilder,
    private dialogRef: MatDialog,
    private fb: FormBuilder,
    private EventsService: EventsService,
    private CourseDashboardService:CourseDashboardService,
    private GeneralService: GeneralService) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));

  }

  ngOnInit() {
    this.serviceGetEventsCreate();
    this.user = JSON.parse(localStorage.getItem("userData"));

    this.firstFormGroup = this._formBuilder.group({
      nameEvent: ['', Validators.required],
      category: ['', Validators.required],
      description: ['', Validators.required],
      state: ['', Validators.required],


    });

    this.msgSuscription = this.GeneralService.msg$.subscribe(data => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe(data => {
      this.typeMsg = data;
    });

    setTimeout(() => {
      this.checkStep()
    }, 100);
  }

  get invalidName() {
    return this.firstFormGroup.get('nameEvent').invalid && this.firstFormGroup.get('nameEvent').touched
  }

  get invalidCategory() {
    return this.firstFormGroup.get('category').invalid && this.firstFormGroup.get('category').touched
  }

  get invalidState() {
    return this.firstFormGroup.get('state').invalid && this.firstFormGroup.get('state').touched
  }

  get invalidDescription() {
    return this.firstFormGroup.get('description').invalid && this.firstFormGroup.get('description').touched
  }

  receiveSrcImg(event) {
    this.src_img = event;
  }

  receiveFile(file) {
    this.file = file;
    this.showErrorImg=false;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    console.log("add -----------> ", event.value)

    if ((value || '').trim()) { this.tags.push(value.trim()); }// Add our tag

    if (input) { input.value = ''; }// Reset the input value

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
  nextStep2(valid) {
    this.controlStepper.step2 = true;

    setTimeout(() => {
      this.stepper.next();
      this.dialogRef.closeAll();
    }, 100);

    // this.stepper.next();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log("event.option.viewValue", event.option.viewValue)
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.chipList.errorState = false;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }
  getStep(step) {
    console.log(step);
    console.log(this.controlStepper);
    if (!(step.selectedIndex === 1 && this.controlStepper.step1)) {
      console.log('no pasa');
      this.selectedStepIndex = 0;
    }
  }
  saveEvent() {
    console.log("tags:", this.tags);
    let data;
    let array_tags = [];
    let item = '';
    let postImage;
console.log(this.firstFormGroup.status)
    if (this.firstFormGroup.status == "VALID" && this.tags.length > 0 && this.src_img !=='') {
      this.tags.forEach(element => {
        for (let i = 0; i < this.searchTags.length; i++) {
          if (element == this.searchTags[i].name) {
            // item += this.searchTags.length ? `{ ${i} : ${this.searchTags[i].id}}`: `{ ${i} : ${this.searchTags[i].id}},`;
            array_tags.push(this.searchTags[i].id);
          }
        }
      });
      this.load=true;

      data = {
        "tags": array_tags,
        "name": this.firstFormGroup.get("nameEvent").value,
        "user_id": this.user.id,
        "states": this.firstFormGroup.get("state").value,
        "description": this.firstFormGroup.get("description").value,
        "category_id": this.firstFormGroup.get("category").value,
      };

      console.log("data para servicio:",data);
      this.EventsService.post_events(data).subscribe(res => {
        console.log("RES create-post_events::", res);
        this.EventsService.dataBasic$.emit(this.firstFormGroup.get("nameEvent").value);
        this.EventsService.set_eventId(res.event_id);
        this.CourseDashboardService.set_courseId(-1);
        postImage = this.GeneralService.onUploadOneImage(this.file, res.event_id).pipe(retry(3)).subscribe();
        this.controlStepper.step1 = true;
        this.stepper.next();
      }, err => {
        console.log("ERRor create-post_events incorrecto", err);
        this.GeneralService.typeMsg$.emit("error");
        this.GeneralService.msg$.emit(err.name);
        this.load=false;
      });
      // if
    } else {
      this.GeneralService.typeMsg$.emit("error");
      this.GeneralService.msg$.emit("Formulario incompleto");
      this.load=false;
      Object.keys(this.firstFormGroup.controls).forEach((key) => {
        this.firstFormGroup.get(key).markAsDirty();
        this.firstFormGroup.get(key).markAsTouched();
      });
      if (this.tags.length === 0) {
        this.chipList.errorState = true;
      }
    if(this.src_img===''){  this.showErrorImg=true;}
    }
  }

  serviceGetEventsCreate() {
    this.EventsService.get_event_create().subscribe(res => {
      console.log("res create-get_event_create", res);
      if (!res?.message_return?.ERROR) {
        this.categories = res.event_categories.original.event_categories;
        res.tags.original.tags.forEach(element => {
          this.allTags.push(element.name);
          this.searchTags.push(element);
        });
        // this.load = false;
      } else {
        this.GeneralService.typeMsg$.emit("error");
        this.GeneralService.msg$.emit(res.message_return.ERROR_MENSAGGE);
      }
    }, err => {
      console.log("ERRor create-get_event_create", err);
      this.GeneralService.typeMsg$.emit("error");
      this.GeneralService.msg$.emit(err.statusText);
    });
  }

  checkStep() {
    const eventincomplete = localStorage.getItem('eventincomplete');
    if (
      eventincomplete &&
      eventincomplete !== '' &&
      eventincomplete === 'step2'
    ) {
      const idevent = localStorage.getItem('idevent');
      this.CourseDashboardService.set_courseId(-1);
      this.EventsService.set_eventId(idevent);

      this.firstFormGroup.get('nameEvent').setValue(' ');
      this.firstFormGroup.get('category').setValue(' ');
      this.firstFormGroup.get('description').setValue(' ');
      this.firstFormGroup.get('state').setValue(' ');




      this.controlStepper.step1 = true;
      this.stepper.next();

      console.log('vamos al paso 2');
    } else if (
      eventincomplete &&
      eventincomplete !== '' &&
      eventincomplete === 'step3'
    ) {
      console.log('vamos al paso 3');
      const idEvent = localStorage.getItem('idevent');
      this.CourseDashboardService.set_courseId(-1);
      this.EventsService.set_eventId(idEvent);
      this.firstFormGroup.get('nameEvent').setValue(' ');
      this.firstFormGroup.get('category').setValue(' ');
      this.firstFormGroup.get('description').setValue(' ');
      this.firstFormGroup.get('state').setValue(' ');

      this.controlStepper.step1 = true;
      this.stepper.next();
      this.controlStepper.step2 = true;

      setTimeout(() => {
        this.stepper.next();
      }, 0);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSuscription;
    this.typeMsgSuscription
  }

}
