import { Injectable } from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, retry} from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  public baseurl: string;
  temp: string;
  public message;
  headers = new Headers();

  constructor(private __http: HttpClient,  private http: HttpClient) {
    this.baseurl = environment.url_server;
    this.headers.set("Content-Type", "multipart/form-data");
    this.headers.set("Authorization", 'Bearer ' + JSON.parse(localStorage.getItem('access_token')));
  }


  getMessage(){
    return this.message;
  }

  setMessage(msg){
    this.message = msg;
  }

  get_admin_affiliates_create(): Observable<any> {
    this.temp = this.baseurl + "admin/affiliates/create";
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  get_admin_useraffiliates(id): Observable<any> {
    this.temp = this.baseurl + "admin/userAffiliates/" + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  post_admin_affiliates(data): Observable<any> {
    this.temp = this.baseurl + 'admin/affiliates';
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_admin_affiliates(id): Observable<any> {
    this.temp = this.baseurl + "admin/affiliates/" + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  put_admin_affiliates(id, data): Observable<any> {
    this.temp = this.baseurl + 'admin/affiliates/' + id;

    // https://jose.franceloop.com/api/admin/events/1
    return this.__http.put(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  delete_admin_affiliates(id): Observable<any>{
    this.temp = this.baseurl + `admin/affiliates/${id}` ;
    // console.log("--------------data:", data);
    return this.__http.delete( this.temp)
      .pipe( retry(1),
        catchError(this.errorHandl)
      );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }

}
