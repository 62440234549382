<swiper [config]="config" >
    <div *ngFor="let slide of sliders; let i=index"  class="swiper-slide">
       <mat-card style="background:chocolate;  height: 50em;">
            <div class="row" style="height: 74%;">
                <div class="col-md-12">
                    <p class="title-slider">{{slide.title}}</p>
                </div>
                <div class="col-md-12">
                    <p class="title-slider-2">{{slide.descripcion}}</p>
                </div>
            </div>
       </mat-card>
    </div>
</swiper>