<h1 mat-dialog-title>Llena</h1>
<div mat-dialog-content>
  My favorite animal is:
  <ul>
    <li>{{data.city_id}}</li>
    <li>{{data.name}}</li>
    <li>{{data.country_id}}</li>
    <li>{{data.county_id}}</li>
    <li>{{data.state_id}}</li>
    <li>{{data.parish_id}}</li>
    <li>{{data.email}}</li>
    <li>{{data.youtube_url}}</li>
    <li>{{data.place_category}}</li>
  </ul>
</div>