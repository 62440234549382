<mat-spinner *ngIf="!load" strokeWidth="10" [diameter]="24"></mat-spinner>
<div class="mat-elevation-z8" *ngIf="load">
  <table mat-table [dataSource]="dataSource">

    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{"method_payment.address.address" | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef> {{"method_payment.address.to" | translate}} </th>
      <td mat-cell *matCellDef="let element">  <mat-chip-list aria-label="Fish selection"><mat-chip color="{{element.category==1?'primary':'accent'}}" selected>{{element.category==1?'Facturacion':'Envio'}}</mat-chip></mat-chip-list> </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef> {{"method_payment.address.action" | translate}} </th>
      <td mat-cell class="pointer" *matCellDef="let element"> <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="edit(element.address_id)">
          <mat-icon>edit</mat-icon>
          <span>{{"method_payment.address.edit" | translate}}</span>
        </button>
        <button mat-menu-item (click)="deleted(element.address_id)">
          <mat-icon>cancel</mat-icon>
          <span>{{"method_payment.address.delete" | translate}}</span>
        </button>
      </mat-menu>
    </td>
    </ng-container>
    
    
    <!-- Symbol Column -->
    <!-- <ng-container matColumnDef="copy">
      <th mat-header-cell *matHeaderCellDef> Borrar </th>
      <td mat-cell class="pointer" *matCellDef="let element" (click)="deleted(element.address_id)"> <mat-icon>{{element.copy}}</mat-icon> </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>