<mat-divider></mat-divider>
<footer fxLayout="row" fxLayoutAlign="space-between center" >
	<p><b>Sogcial</b> © {{this.year}}</p>
	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
		<img class="icons" src="./assets/images/fb-logo.svg">
		<img class="icons" src="./assets/images/tw-logo.svg">
		<img class="icons" src="./assets/images/ins-logo.svg">
		<img class="icons" src="./assets/images/lin-logo.svg">
		<img class="icons" src="./assets/images/tik-logo.svg">
	</div>
</footer>
