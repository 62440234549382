import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ListsComponent } from '../lists/lists.component';
import { PlaceService } from '../../../../services/place.service';
import { GeneralService } from '../../../../services/general.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

interface Place {
  Category: string;
  Country: string;
  Place: string;
  State: string;
  User: string;
  address: string;
  city: string;
  created_at: string;
  description: string;
  email: string;
  end_time_week: string;
  end_time_weekend: string;
  facebook: string;
  id: number;
  images: string;
  instagram: string;
  latitude: string;
  longitude: string;
  main_image: string;
  municipality: string;
  parish: string;
  phone: string;
  phone_2: string;
  slogan: string;
  slug: string;
  start_time_week: string;
  start_time_weekend: string;
  status: string;
  twitter: string;
  updated_at: string;
  web: string;
  wifi: string;
  youtube: string;
  zip_code: string;
  youtube_url: string;
}
interface Category {
  id: number;
  name: string;
  parent_id: number;
  created_at: string;
  updated_at: string;
}

interface Country {
  id: number;
  name: string;
  created_at: string;
  iso: string;
  iso3: string;
  nicename: string;
  numcode: number;
  phonecode: number;
  slug: string;
  updated_at: string;
}
interface State {
  id: number;
  state_code: string;
  state_name: string;
  country_id: number;
  slug: string;
}
interface City {
  id: number;
  city: string;
  county: string;
  latitude: string;
  longitude: string;
  state: string;
  country: string;
  created_at: string;
  updated_at: string;
}
interface County {
  id: number;
  municipality: string;
  slug: string;
  city: string;
  state: string;
  country: string;
}
interface Parish {
  id: number;
  municipality_id: number;
  parish: string;
  slug: string;
  created_at: string;
  updated_at: string;
}
@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss'],
})
export class ShowComponent implements OnInit {
  showPlace: Place;
  showCategory: Category;
  showCountry: Country;
  showState: State;
  showCity: City;
  showCounty: County;
  showParish: Parish;
  bandPlace: boolean = false;
  bandCategory: boolean = false;
  bandCountry: boolean = false;
  bandState: boolean = false;
  bandCity: boolean = false;
  bandCounty: boolean = false;
  bandParish: boolean = false;
  load: boolean = false;


  image;
  images = [];
  url: string = '';
  urls: string = '';
  @Input() SendUrl: string = '';
  @Input() data;

  info: any;
  zoom: number = 8;
  lat: number = 7.760189199999999;
  lng: number = -72.2137194;
  arrayMarker: any = [];

  constructor(
    private PlaceService: PlaceService,
    private GeneralService: GeneralService,
    private _router: Router,
    private router: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    let id = this.router.snapshot.params.id;
    console.log(id, 'xxxxx');
    this.load = true;
    this.PlaceService.get_placesShow(id).subscribe((res) => {
      console.log('ressssssssssssssssssssss:: ', res);
      this.info = res;
      if (res.message_return.ERROR == false) {
        console.log('url image:: ', res.main_image);
        this.image = res.main_image;
        this.showPlace = res.result;
        this.bandPlace = true;
        this.url = this.info.result.youtube_url;
        this.images = this.info.images;
        console.log('=================', this.images);
        this.load = false;

      }
    });
    // console.log(id);
  }



  onKey($event) {
    this.urls = $event.target.value;
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.data.currentValue, 'desc');
    if (changes.data.currentValue != null) {
      this.url = changes.data.currentValue.result.youtube_url;
    }
  }
}
