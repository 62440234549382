<div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px" *ngIf="showchanges">
	<mat-card fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="60" fxLayoutGap="20px" class="card">
		<div div fxLayout="row" fxLayoutAlign="space-between center">
			<p class="title-card">{{'ev-create.basic' | translate}}</p>
			<p class="bg-orange text-required">* Campos requeridos</p>
		</div>
		<mat-form-field>
			<mat-label class="label-input" translate>place.name <span class="bg-orange">*</span></mat-label>
			<input name="names" [(ngModel)]="names" matInput placeholder="{{'place.placeHolderName' | translate}}" [formControl]="name">
			<mat-error *ngIf="name.invalid">{{getErrorMessage()}}</mat-error>
		</mat-form-field>
		<div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">

			<mat-form-field fxFlex="50%">
				<mat-label class="label-input" translate>place.category <span class="bg-orange">*</span></mat-label>
				<mat-select (selectionChange)="Subcategory()" name="category_id" [(ngModel)]="category_id" [formControl]="cat">
				<mat-option   *ngFor="let category of categories" [value]="category.id">
					{{category.name}}
				</mat-option>
				</mat-select>
				<mat-error *ngIf="cat.invalid">{{getErrorMessage()}}</mat-error>
			</mat-form-field>
			<mat-form-field fxFlex>
				<mat-label class="label-input" translate>place.subCategory</mat-label>
				<mat-select name="subCategory_id" [(ngModel)]="subCategory_id">
				<mat-option  *ngFor="let subCategory of subCategories" [value]="subCategory.id">
					{{subCategory.name}}
				</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">
			<mat-form-field fxFlex="50%">
				<mat-label class="label-input" translate>place.phone</mat-label>
				<input name="phone" [(ngModel)]="phone" matInput placeholder="04147865985">
			</mat-form-field>
			<mat-form-field fxFlex="50%">
				<mat-label class="label-input">{{"place.phone"  |  translate}} 2</mat-label>
				<input name="phone" [(ngModel)]="phone_2" matInput placeholder="04147865985">
			</mat-form-field>
		</div>

		<div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">
			<mat-form-field fxFlex="50">
				<mat-label class="label-input" translate>place.web</mat-label>
				<input name="web" [(ngModel)]="web" matInput placeholder="www.tr.es">
			</mat-form-field>
			<mat-form-field fxFlex="50">
				<mat-label class="label-input" translate>place.email</mat-label>
				<input name="email" [(ngModel)]="email" matInput placeholder="example@tu.es">
			</mat-form-field>
		</div>

		<div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">
			<mat-form-field fxFlex="30">
				<mat-label class="label-input" translate>place.country <span class="bg-orange">*</span></mat-label>
				<mat-select  (selectionChange)="getStates()" name="country_id" [(ngModel)]="country_id" [formControl]="countr">
				<mat-option *ngFor="let country of countries" [value]="country.id">
					{{country.name  | titlecase}}
				</mat-option>
				</mat-select>
				<mat-error *ngIf="countr.invalid">{{getErrorMessage()}}</mat-error>
			</mat-form-field>
			<mat-form-field fxFlex="30">
				<mat-label class="label-input" translate>place.state <span class="bg-orange">*</span></mat-label>
				<mat-select (selectionChange)="getCities()" name="state_id" [(ngModel)]="state_id" [formControl]="stat">
				<mat-option *ngFor="let state of states" [value]="state.id">
					{{state.state_name}}
				</mat-option>
				</mat-select>
				<mat-error *ngIf="stat.invalid">{{getErrorMessage()}}</mat-error>
			</mat-form-field>
			<mat-form-field fxFlex="30">
				<mat-label class="label-input" translate>place.city <span class="bg-orange">*</span></mat-label>
				<mat-select (selectionChange)="getCounties()" name="city_id" [(ngModel)]="city_id" [formControl]="cit">
				<mat-option *ngFor="let city of cities" [value]="city.id">
					{{city.city}}
				</mat-option>
				</mat-select>
				<mat-error *ngIf="cit.invalid">{{getErrorMessage()}}</mat-error>
			</mat-form-field>
		</div>

		<div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">
			<mat-form-field fxFlex="30">
				<mat-label class="label-input" translate>place.county</mat-label>
				<mat-select (selectionChange)="getParish()" name="county_id" [(ngModel)]="county_id"  [formControl]="munic">
				<mat-option *ngFor="let county of counties" [value]="county.id">
					{{county.municipality}}
				</mat-option>
				</mat-select>
				<mat-error *ngIf="munic.invalid">{{getErrorMessage()}}</mat-error>

			</mat-form-field>
			<mat-form-field fxFlex="30">
				<mat-label class="label-input" translate>place.parish</mat-label>
				<mat-select name="parish_id" [(ngModel)]="parish_id">
				<mat-option *ngFor="let parish of parishes" [value]="parish.id">
					{{parish.parish}}
				</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field fxFlex="30">
				<mat-label class="label-input" translate>place.zip_code <span class="bg-orange">*</span></mat-label>
				<input ame="zip_code" [(ngModel)]="zip_code" matInput placeholder="5001" [formControl]="zip">
      <mat-error *ngIf="zip.invalid">{{getErrorMessage()}}</mat-error>

			</mat-form-field>
		</div>

		<mat-form-field fxFlex>
			<mat-label class="label-input" translate>place.address <span class="bg-orange">*</span></mat-label>
			<textarea name="address" [(ngModel)]="address" rows="5" matInput placeholder="av 19 de abril..." [formControl]="addre"></textarea>
			<mat-error *ngIf="addre.invalid">{{getErrorMessage()}}</mat-error>
		</mat-form-field>
	</mat-card>

	<mat-card fxFlex="35" fxLayout="column" fxLayoutGap="36px" class="card">
		<app-google-maps (mark)="getmark($event)" fxFlex latitud="{{lat}}" longitud="{{lng}}" [coords]="mark">

    </app-google-maps>
	</mat-card>
</div>
