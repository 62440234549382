  <mat-horizontal-stepper #stepper linear>

  <mat-step [completed]="controlStepper.step1">
    <form fxLayout="column" fxLayoutGap="36px" style="margin-top: 24px;">
      <ng-template matStepLabel><p (click)="only(1)">{{"place.basicData" | translate }}</p></ng-template>
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <app-message *ngIf="msg!=null" [msg]="msg" [typeMsg]="typeMsg"></app-message>
      </div>
      <app-basic-data [data]="showPlace"></app-basic-data>
    <span class="mensajemapa" *ngIf="showErrorMap" >* Debe seleccionar un punto en el mapa</span>

      <div *ngIf="basicData" fxLayoutAlign="center center" fxFlex>
        <button  (click)="goStep2()" class="btn-orange btn" >{{'ev-create.next' | translate }}</button>
      </div>
    </form>
  </mat-step>

  <mat-step [completed]="controlStepper.step2">
    <form  fxLayout="column" fxLayoutGap="36px" style="margin-top: 24px;">
      <ng-template matStepLabel > <p (click)="only(2)">{{"place.description" | translate }}</p></ng-template>
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <app-message *ngIf="msg!=null" [msg]="msg" [typeMsg]="typeMsg"></app-message>
      </div>
      <div *ngIf="showPlace">
        <app-descriptions [url]="this.urlMainImage" [data]="showPlace" [hiddenx]="hiddenUpload"></app-descriptions>

      </div>

        <div fxLayoutAlign="center center" fxFlex>
          <button  (click)="goStep3()" class="btn-orange btn" matStepperNext >{{"ev-create.next" | translate}}</button>
        </div>
    </form>
  </mat-step>
  <mat-step [completed]="controlStepper.step3">
    <div  fxLayout="column" fxLayoutGap="36px" style="margin-top: 24px;">
      <ng-template matStepLabel ><p (click)="only(3)">{{"place.gallery" | translate }}</p></ng-template>
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <app-message *ngIf="msg!=null" [msg]="msg" [typeMsg]="typeMsg"></app-message>
      </div>
      <div *ngIf="showPlace">
      <app-gallery [data]="showPlace"></app-gallery>

      </div>
      <div fxLayoutAlign="center center" fxFlex >
        <mat-spinner *ngIf="load" strokeWidth="10" [diameter]="24"></mat-spinner>
        <button *ngIf="!load" class="btn-orange-auto btn" (click)="onGetStep3()">{{"place.createPlace" | translate}}</button>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>
