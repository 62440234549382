import { Input, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Components */
import { AuthComponent } from './auth.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';


const routes: Routes = [
  { 

    path: 'auth', component: AuthComponent,
      children: [
        { path: 'login', component: LoginComponent },
        { path: 'register', component: RegisterComponent },
        { path: 'reset-password', component: ResetPasswordComponent },
        { path: 'forgot-password', component: ForgotPassComponent },        
        { path: 'facebook/callback', component: LoginComponent },
        { path: 'facebook', component: LoginComponent },        
        { path: 'google/callback', component: LoginComponent },
        { path: 'google', component: LoginComponent }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
