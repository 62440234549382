<div fxLayout="column" fxLayoutGap="10px">
     <!-- <app-filter></app-filter> -->
    <div fxLayout="row" fxLayoutAlign="start none" fxLayoutGap="10px">
      <mat-form-field fxFlex="40%">
        <mat-label>{{'events.search' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <button class="btn-filter" [ngClass]="{'border-orange':!hide}" (click)="hide = !hide">
        <mat-icon class="icon-grey" style="margin-top: -8px" [ngClass]="{'color-orange':!hide}">filter_list</mat-icon>
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="!hide">
      <p fxFlex="6%" class="paragraph">Filtrar por:</p>

      <mat-form-field fxFlex="17%">
        <mat-label translate>job.category</mat-label>
        <mat-select [formControl]="category" multiple>
            <mat-select-trigger>
              {{category.value ? category.value[0] : ''}}
              <span *ngIf="category.value?.length > 1" class="example-additional-selection">(+{{category.value.length - 1}}
                {{category.value?.length === 2 ? 'other' : 'others'}})</span>
            </mat-select-trigger>
            <mat-option *ngFor="let item of categoryList" [value]="item">{{item}}</mat-option>
          </mat-select>

      </mat-form-field>

      <mat-form-field fxFlex="17%">
        <mat-label translate>job.address</mat-label>
        <mat-select [formControl]="address" multiple>
          <mat-select-trigger>
            {{address.value ? address.value[0] : ''}}
            <span *ngIf="address.value?.length > 1" class="example-additional-selection">(+{{address.value.length - 1}}
              {{address.value?.length === 2 ? 'other' : 'others'}})</span>
          </mat-select-trigger>
          <mat-option *ngFor="let item of addressList" [value]="item">{{item}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center stretch">
      <app-message *ngIf="msg!=null" [msg]="msg" [typeMsg]="typeMsg"></app-message>
    </div>

    <mat-card>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
          <div *ngIf="column=='menu'; else blockHead">
            <th mat-header-cell *matHeaderCellDef></th>
          </div>
          <ng-template #blockHead>
            <th mat-header-cell *matHeaderCellDef class="text-bold">{{'table.' + column | translate}}</th>
          </ng-template>

          <div *ngIf=" column=='menu'; else blockData">
            <td style="width: 5%;" mat-cell *matCellDef="let element" class="pointer">
              <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="msg!=null">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/dashboard/jobs/courses/{{element.id}}">
                  <span>Ver</span>
                </button>
                <button mat-menu-item routerLink="/dashboard/jobs/courses/edit/{{element.id}}">
                  <span>Editar</span>
                </button>
              </mat-menu>
            </td>
          </div>

          <ng-template #blockData>
            <td mat-cell *matCellDef="let element"> {{element[column]}}</td>
          </ng-template>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="element-row"></tr>
      </table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </mat-card>
  </div>
