import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray, FormControl, ValidationErrors, AbstractControl } from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as moment from 'moment';


@Component({
  selector: 'app-dialog-schedule',
  templateUrl: './dialog-schedule.component.html',
  styleUrls: ['./dialog-schedule.component.scss']
})
export class DialogScheduleComponent implements OnInit {

  // empForm:FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private fb: FormBuilder) {}

  typeSchedule;
  allDayStart;
  allDayEnd;
  form: FormGroup;
  week = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];


  ngOnInit(): void {
    this.form = this.fb.group({
      typeSchedule: ['', Validators.required],
      allDayStart: ['',[Validators.required]],
      allDayEnd: ['',[Validators.required, this.timeValidator]],
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
      schedule: this.fb.array([
      ]),
    });
    this.addSchedule();
  }


  schedules(): FormArray {
    return this.form.get("schedule") as FormArray
  }


  newSchedule(num: number): FormGroup {
    return this.fb.group({
      day: num,
      hour: this.fb.array([this.fb.group({
        start: ['',[Validators.required]],
        end: ['',[Validators.required, this.timeValidatorArray]]
      })])
    })
  }


  addSchedule() {
    for (let i = 0; i < 7; i++)
      this.schedules().push(this.newSchedule(i));
  }


  removeSchedule(empIndex: number) {
    this.schedules().removeAt(empIndex);
  }




  scheduleHour(empIndex: number): FormArray {
    return this.schedules().at(empIndex).get("hour") as FormArray
  }


  newHour(): FormGroup {
    return this.fb.group({
      start: '',
      end: '',
    })
  }

  addscheduleHour(empIndex: number) {
    this.scheduleHour(empIndex).push(this.newHour());
  }

  removescheduleHour(empIndex: number, skillIndex: number) {
    this.scheduleHour(empIndex).removeAt(skillIndex);
  }





  timeValidator(control: FormControl): { [s: string]: boolean } | null {
    if (control.value) {
      const end = moment(control.value, "h:mm");

      let controlStart: any = null;
      let valueStart: any = null;

      if (control?.parent?.controls) {
        controlStart = control?.parent?.controls;
        valueStart = moment(controlStart["allDayStart"].value, "h:mm");
      }
      // if(moment.duration(valueStart.diff(end)).asMilliseconds() > 0){
      //   console.log("ANTES")
      // }else{
      //   console.log("DESPUES");
      // }

      if (end.isBefore(valueStart)) {
        return { invalidTime: true };
      }
    }
    return null;
  }



  timeValidatorArray(control: FormControl): { [s: string]: boolean } | null {
    if (control.value) {
      const end = moment(control.value, "h:mm");

      let controlStart: any = null;
      let valueStart: any = null;
console.log(control?.parent?.controls)
      if (control?.parent?.controls) {
        controlStart = control?.parent?.controls;
        valueStart = moment(controlStart["start"].value, "h:mm");
      }
      // if(moment.duration(valueStart.diff(end)).asMilliseconds() > 0){
      //   console.log("ANTES")
      // }else{
      //   console.log("DESPUES");
      // }

      if (end.isBefore(valueStart)) {
        return { invalidTime: true };
      }
    }
    return null;
  }
}
