import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide:boolean = true;
  email:string = '';
  password = '';
  isMobile: boolean = false;
  error:boolean=false;
  userData;
  menu_user;
  dashCards;
  load = false;
  Handset: '(max-width: 599px)';
  disabled = true;
  msg:string ='';
  typeMsg:string;
  regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%&*]{6,15}$/;
  callback;
  cartData;

  constructor(private AuthService:AuthService,
    private _router: Router,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,
    ) {
   
    var splited_url = this._router.url.split("?");


      if(this._router.url!=="/auth/login"){
        if(this._router.url === "/auth/facebook"){
          this.AuthService.post_auth_provider('auth/facebook').subscribe(res => {
            if(!res.message_return.ERROR)
              window.location.href = res.url;
          },err => {
            console.log("Err-facebook:: ", err)
          });
        }else if(this._router.url === "/auth/google"){
          this.AuthService.post_auth_provider('auth/google').subscribe(res => {
            if(!res.message_return.ERROR)
              window.location.href = res.url;
          },err => {
            console.log("Err-google:: ", err)
          });
        }else if(splited_url[0] === "/auth/facebook/callback"){
          this.callback = this._router.url.split('/auth/facebook/callback')[1];
          this.AuthService.get_login_provider('auth/facebook/callback', this.callback).subscribe(res => {
            this.userData = res.result;
            this.menu_user = res.menu_user;
            this.dashCards = res.stats;
            this.cartData = res.cartID;
            localStorage.setItem('userData', JSON.stringify(this.userData));
            localStorage.setItem('menu_user', JSON.stringify(this.menu_user));
            localStorage.setItem('dashCards', JSON.stringify(this.dashCards));
            localStorage.setItem('access_token', JSON.stringify(res.result.access_token));
            localStorage.setItem('cartData', JSON.stringify(this.cartData));
            this._router.navigateByUrl("/dashboard");
          },err => {
            console.log("Err-facebook-callback: ", err)
          });

        }else if(splited_url[0] === "/auth/google/callback"){
          this.callback = this._router.url.split('/auth/google/callback')[1];
          this.AuthService.get_login_provider('auth/google/callback', this.callback).subscribe(res => {
            this.userData = res.result;
            this.menu_user = res.menu_user;
            this.dashCards = res.stats;
            this.cartData = res.cartID;
            localStorage.setItem('userData', JSON.stringify(this.userData));
            localStorage.setItem('menu_user', JSON.stringify(this.menu_user));
            localStorage.setItem('dashCards', JSON.stringify(this.dashCards));
            localStorage.setItem('access_token', JSON.stringify(res.result.access_token));
            localStorage.setItem('cartData', JSON.stringify(this.cartData));
            this._router.navigateByUrl("/dashboard");
          },err => {
            console.log("Err-google-callback: ", err)
          });
        }
      }
    }

  ngOnInit(): void {
    const CustomBreakpoints = {
      Handset: '(max-width: 599px)', // Mobile devices up to 599px
    };
    this.breakpointObserver.observe([CustomBreakpoints.Handset])
    .subscribe(result => this.isMobile = result.matches);
    let data=localStorage.getItem('userData');
    if(this.callback === null){
      if(data!==null){
        if(data!=='')
          this._router.navigateByUrl("/dashboard");
        else
          this._router.navigateByUrl("/auth/login");
      }else
          this._router.navigateByUrl("/auth/login");
      if( this.AuthService.getMessage() ){
        this.typeMsg="success";
        this.msg = this.AuthService.getMessage();
      }
    }
  }

  onInputChange(){
    if(this.email !== '' && this.password !== ''){
      this.disabled = false;
    }else{
      this.disabled = true;
    }
  }

  onSubmitLogin(){
    this.load = true;
    this.error=false;
    this.msg='';
    if(!this.email.match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$") || !this.password.match(this.regex)){
      this.error=true;
      this.typeMsg="error";
      this.translate.get('login.error').subscribe( (text: string) => {
        this.msg = text;
      });
      this.load = false;
    }else{
      let data = {
        "email": this.email,
        "password": this.password,
        "remember_me":true
        };

      this.AuthService.post_auth_login(data).subscribe(res => {
        if( res.message_return && !res.message_return.ERROR ){
          this.userData = res.result;
          this.menu_user = res.menu_user;
          this.dashCards = res.stats;
          this.cartData = res.cartID;
          localStorage.setItem('userData', JSON.stringify(this.userData));
          localStorage.setItem('menu_user', JSON.stringify(this.menu_user));
          localStorage.setItem('dashCards', JSON.stringify(this.dashCards));
          localStorage.setItem('access_token', JSON.stringify(res.result.access_token));
          localStorage.setItem('cartData', JSON.stringify(this.cartData));
          localStorage.setItem('cartProducts', JSON.stringify(res.products));
          const previusRoute =  localStorage.getItem('previusRoute');
          if(previusRoute){
            if(previusRoute.includes("/detail/teacher-public/")){
              this._router.navigateByUrl(previusRoute);
              localStorage.setItem("previusRoute", "")
            }
          }
          else{
            this._router.navigateByUrl("/dashboard");
          }

        }else{
          console.log("Error")
        }
        this.error = false;
        this.load  = false;
      },err => {
        this.load  = false;
        this.error = true;
        this.msg   = err?.error?.message_return?.ERROR_MENSAGGE;

      });
    }
  }
  onSubmitLoginFacebook(){

  }
}