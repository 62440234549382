<div *ngIf="!flagOwnProposal" fxLayoutAlign="center center" fxflex>
  <mat-spinner strokeWidth="10" [diameter]="24"></mat-spinner>
</div>
<div fxLayout="column" fxflex *ngIf="flagOwnProposal">
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <mat-form-field fxFlex="40%">
      <mat-label>{{ "create-teacher.search" | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div fxLayout="column" fxLayoutAlign="center stretch">
    <app-message
      *ngIf="msg != null"
      [msg]="msg"
      [typeMsg]="typeMsg"
    ></app-message>
  </div>

  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="image">
      <mat-header-cell fxFlex="20" *matHeaderCellDef></mat-header-cell>
      <mat-cell fxFlex="20" *matCellDef="let element">
        <div class="perfil">
          <div>
            <img
              [src]="element.avatar"
              class="img"
              (error)="onImgError($event)"
            />
          </div>
          <div><a [routerLink]="['/','dashboard','teacher',element.UserId]">Ir a perfil</a></div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="body">
      <mat-header-cell fxFlex="60" *matHeaderCellDef></mat-header-cell>
      <mat-cell fxFlex="60" class="tab-td" *matCellDef="let element">
        <div fxLayout="column" fxFlex>
          <div fxLayout="row" fxFlex>
            <div
              fxLayout="column"
              fxFlex="50"
              style="padding-right: 30px"
              fxLayoutAlign="space-between start"
            >
              <p class="raleway-14-bold">
                {{ element.name }} {{ element.last_name }}
              </p>
              <p class="oxigen-13" style="margin-top: 16px">
                {{ element.Ubication[0] }}, {{ element.Ubication[1] }},
                {{ element.Ubication[2] }}Ubicacion
              </p>
            </div>

            <div fxLayout="column" fxFlex="50">
              <p class="raleway-14-bold">{{ element.title }}</p>
              <div fxLayout="row" style="margin-top: 16px">
                <mat-icon *ngFor="let item of [].constructor(element.Stars)"
                  >star</mat-icon
                >
                <!-- <mat-icon>star_border</mat-icon> -->
              </div>
            </div>
          </div>
          <mat-chip-list style="margin-top: 15px">
            <mat-chip *ngFor="let item of element.skills">{{ item }} </mat-chip>
          </mat-chip-list>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="invite">
      <mat-header-cell fxFlex="20" *matHeaderCellDef></mat-header-cell>
      <mat-cell fxFlex="20" *matCellDef="let element">
        <div
          fxLayout="row"
          id="event.target.id"
          (click)="openDialogSendProposal(element)"
          fxFlex
          fxLayoutAlign="end center"
        >
          <mat-icon
            [ngClass]="{ coloricon: element.invited == true }"
            class="btn-add pointer"
            >work_outline</mat-icon
          >
          <button
            [ngClass]="{ desativado: element.invited == true }"
            [disabled]="element.invited"
            class="add-event pointer"
          >
            <span *ngIf="!element.invited">Invitar</span>
            <span *ngIf="element.invited">Invitado</span>
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
  <div fxLayoutAlign="center center" fxFlex="30">
    <button routerLink="/dashboard/courses" class="btn-orange-auto btn btns">
      Finalizar
    </button>
    <button
      routerLink="/dashboard/courses/publish/{{ idcureven }}"
      class="btn-orange-auto btn"
    >
      Publicar
    </button>
  </div>
</div>
