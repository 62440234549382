<form [formGroup]="form">
    <mat-card style="padding: 20px 30px;">
    
        <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="110px">
            <div fxLayout="column" fxFlex="65">
                <p class="title">Enviar comentarios</p>
    
                <mat-form-field style="margin-top: 40px;">
                    <mat-label class="paragraph-16">¿En qué podemos ayudarte?</mat-label>
                    <mat-select formControlName="problem_id">
                        <mat-option *ngFor="let item of problemList" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field style="margin-top: 60px;">
                    <mat-label class="paragraph-16">Descripcion</mat-label>
                    <textarea matInput rows="5" formControlName="description">
                </textarea>
                </mat-form-field>
    
                <p style="margin-top: 15px;" class="paragraph">Cuantos más detalles proporciones, más útil será para que
                    nuestro
                    equipo pueda
                    diagnosticar el problema.</p>
    
                <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 50px;">
                    <p class="paragraph-16">Añade un archivo (Opcional)</p>
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <mat-icon class="btn-icon pointer">insert_drive_file</mat-icon>
                        <button class="btn btn-border">ADJUNTAR</button>
                    </div>
                </div>
    
    
    
                <mat-form-field style="margin-top: 40px;">
                    <mat-label class="inputs">Añade una URL (Opcional)</mat-label>
                    <input matInput placeholder="www.elproblema.com" formControlName="url">
                </mat-form-field>
    
                <div fxLayoutAlign="center center">
                    <button mat-button class="orange-btn" style="margin-top: 40px;" (click)="servicePostContact()">ENVIAR</button>
                </div>
            </div>
    
            <div fxLayout="column" fxLayoutAlign="center center" style="border-left: 4px solid #D7D7D7; padding-left: 50px;">
                <img src="../../../../../assets/images/operator.png" style="width: 70%;" alt="">
                <p class="paragraph-16" style="margin-top: 45px;">¿Quieres Contactarnos? ¡Escríbenos! y déjanos tu consulta, sugerencia o feedback a
                    nuestro correo.</p>
                <div fxLayoutAlign="center center" style="margin-top: 30px; margin-bottom: 100px;">
                    <p class="paragraph-16-bold">soporte@sogcial.com</p>
                </div>
            </div>
        </div>
    </mat-card>
</form>