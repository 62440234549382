<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" style="padding-bottom: 5em;">
    <mat-icon>people</mat-icon>
</div>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" style="padding-bottom: 3em;">
    <p class="title">Un sitio para ti</p>
</div>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" style="padding-bottom: 3em;">
    <p class="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
</div>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" style="padding-bottom: 2em;">
    <p class="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
</div>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" style="padding-bottom: 2em;">
    <button  mat-button class="example-icon favorite-icon style-login registrate" >
        contratar
    </button>
</div>