import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mini-section-rigth',
  templateUrl: './mini-section-rigth.component.html',
  styleUrls: ['./mini-section-rigth.component.scss']
})
export class MiniSectionRigthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
