import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  selectSearch: string = "";
  filter: string = "";
  typeCourses: string = "Cursos";
  typeEvents: string = "Eventos";
  typeSites: string = "Lugares";
  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    private _router: Router,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => this.isMobile = result.matches);

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => this.isMobile = result.matches);
    this.isTablet = !this.isMobile && window.innerWidth >= 600 && window.innerWidth <= 1023;
  }


  searchRedirect() {
    if (this.selectSearch == this.typeCourses) {
      if (this.filter.length > 0)
        this._router.navigateByUrl(`search/cursos/all/${this.filter}`);
      else
        this._router.navigateByUrl(`search/cursos`);
    } else if (this.selectSearch == this.typeEvents) {
      if (this.filter.length > 0)
        this._router.navigateByUrl(`search/eventos/all/${this.filter}`);
      else
        this._router.navigateByUrl(`search/eventos`);
    } else if (this.selectSearch == this.typeSites) {
      if (this.filter.length > 0)
        this._router.navigateByUrl(`search/lugares/all/${this.filter}`);
      else
        this._router.navigateByUrl(`search/lugares`);
    }
  }

}
