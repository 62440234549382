<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="15px" style="padding-bottom: 50px;">
    <mat-card fxFlex="76%" fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
            <h2 class="title" >{{"offer-create.main_title" | translate }}</h2>
        </div>
        <mat-form-field class="example-full-width" >
            <mat-label translate>offer-create.title</mat-label>
            <input  matInput placeholder="" [(ngModel)]="title" value="" required>
        </mat-form-field>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
            <mat-form-field class="example-full-width" fxFlex="50%">
                <mat-label translate>offer-create.contract_type</mat-label>
                <mat-select [(ngModel)]="contracts"  required>
                  <mat-option *ngFor="let contr of contract" [value]="contr.type">
                    {{contr.type}}
                  </mat-option>
                </mat-select>   
            </mat-form-field>  
            <mat-form-field *ngIf="show" class="example-full-width" fxFlex="50%">
                <mat-label translate>offer-create.job_type</mat-label>
                <mat-select [(ngModel)]="job" required>
                  <mat-option *ngFor="let j of jobs" [value]="j.id">
                    {{j.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>    
        </div>
        
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" fxFlex="50%">

        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" fxFlex="50%">
            <mat-form-field class="example-full-width" fxFlex="50%">
                <mat-label translate>offer-create.currency</mat-label>
                <mat-select [(ngModel)]="currencies" required>
                  <mat-option *ngFor="let current of currency" [value]="current.id">
                    {{current.code}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="example-full-width" fxFlex="50%">
                <mat-label translate>offer-create.amount</mat-label>
                <input  matInput placeholder="" [(ngModel)]="price_total" value="" required>
            </mat-form-field>

            
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" fxFlex="50%">
            

            <mat-form-field class="example-full-width" fxFlex="30%">
                <mat-label translate>offer-create.estimate_time</mat-label>
                <input  matInput placeholder="" [(ngModel)]="time_length" value="" required>
            </mat-form-field>

            <mat-form-field class="example-full-width" fxFlex="30%">
                <mat-label translate>offer-create.hours</mat-label>
                <mat-select [(ngModel)]="time" required>
                  <mat-option *ngFor="let tiem of listTime" [value]="tiem.time">
                    {{tiem.time}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" fxFlex="30%">
                <mat-label translate>Horas Semanales</mat-label>
                <input  matInput placeholder="" [(ngModel)]="weekly_limit" value="" required>
            </mat-form-field>
            
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" fxFlex="50%">
            <mat-form-field class="example-full-width"  fxFlex="100%">
                <mat-label translate>offer-create.description</mat-label>
                <textarea matInput placeholder="" [(ngModel)]="description" rows="10" ></textarea>
              </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column"style="" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" fxFlex="100%">
            <app-upload></app-upload>
        </div>
        
    </mat-card>


    <mat-card fxFlex="24%" fxLayout="column" fxLayoutAlign="start start stretch">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <h2 class="title" translate>offer-create.detalles</h2>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <p class="subtitle" fxFlex translate>offer-create.course</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" *ngIf="show">
            <p class="texting padding">{{userDetails.course!=null?userDetails.course:userDetails.event}}</p>
            
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <p class="texting-2" ><span><strong>{{"offer-create.enterprise" | translate}}</strong> {{"offer-create.businness" | translate}}</span></p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" *ngIf="show">
            <p class="texting">{{userDetails.user_recipient_name}}</p>
            
        </div>
        <div fxLayout="row"  fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" *ngIf="show">
            <span class="subtitle" [ngSwitch]="userDetails.user_average_stars">
                <span class="star" *ngSwitchCase="0"><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon></span>
                <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
              </span>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" *ngIf="show">
            <p class="texting">{{"offer-create.member" | translate}} {{userDetails.user_creation}}</p>
            
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" *ngIf="show">
            <p class="texting">{{userDetails.publish_work[0].publish_work}} Trabajos Publicados</p>
            
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <p class="subtitle">{{"offer-create.place" | translate}}</p>
            
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px"  *ngIf="show">
            <p class="texting">{{places.place}}</p>
            
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" *ngIf="show">
            
            <p class="texting">{{places.ubication}}</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <p class="subtitle">{{"offer-create.schedule" | translate}}</p>
            
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" *ngIf="show">
            <p class="texting">Lunes - Viernes <br>{{places.start_time_week}} am - {{places.end_time_week}} pm </p>
            
        </div>
    </mat-card>
    

</div>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="15px" style="padding-bottom: 50px;">
    <div fxFlex="76%" fxLayout="column" fxLayoutAlign="center center" >
        <button mat-raised-button color="primary" (click)="save()">{{"offer-create.save" | translate}}</button>
    </div>
    <div fxFlex="24%" fxLayout="column" fxLayoutAlign="center center" ></div>

</div>
