<swiper [config]="config" >
    <div *ngFor="let slide of sliders; let i=index"  class="swiper-slide">
        <mat-card class="example-card">
            <mat-card-header style="background:url('../../../../assets/images/{{images[i]}}'); background-size: cover; height: 400px;">
                

            </mat-card-header>
            <mat-card-content style="padding: 14px;">
              <p class="p">
                {{slide.Category}}
              </p>
            </mat-card-content>
            <mat-card-actions style="padding: 14px;" routerLink="/search/cursos">
                <p class="footer-slide pointer"><mat-icon>menu_book</mat-icon> {{slide.quantity}} {{slide.quantity==1?'Curso':'Cursos'}}</p>
            </mat-card-actions>
          </mat-card>
    </div>
</swiper>