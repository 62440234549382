<div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="21px" style="padding: 30px 60px; height: 100%;">
    <div fxLayout="column" fxLayoutGap="20px" style="width: 100%;">
        <div fxLayout="row" fxLayoutAlign="space-between start" style="width: 100%; margin-bottom: 10px;">
            <p class="raleway-23-g"><b translate>modal.rejectOffer</b></p>
            <button [mat-dialog-close]="null" class="btn-close">
                <mat-icon aria-hidden="false">clear</mat-icon>
            </button>
        </div>
        <p class="oxigen-16-b" translate>modal.whyDeclineOffer</p>
        <mat-select class="border-b">
            <mat-option value="">Seleccione un motivo de rechazo</mat-option>
        </mat-select>
        
        <p class="oxigen-16-b" translate>modal.spedificReason</p>
        <div class="border-b"><textarea matInput rows="5"></textarea></div>
    </div>
    
    <div fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="20px" style="width: 100%;">
        <div class="btn-border-orange pointer" [mat-dialog-close]="null" fxLayoutAlign="center center">
            <p class="font">{{'send-proposal.cancel' | translate}}</p>
        </div>
        <div fxLayoutAlign="center center" [mat-dialog-close]="onSave" (click)="onSaving()">
            <button class="btn-orange btn">{{'send-proposal.accept' | translate}}</button>
        </div>
    </div>
</div>