<mat-horizontal-stepper labelPosition="bottom" #stepper>
  <mat-step [stepControl]="firstFormGroup" errorMessage="{{ 'courses.basic-data-error' | translate }}">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>{{ 'courses.basic-data' | translate }}</ng-template>
      <div fxLayout="row" fxLayout.xs="column" fxlayout.sm="column" fxLayoutGap="38px">
        <mat-card fxFlex="70" style="flex: 1 1 100%;box-sizing: border-box;">
          <mat-card-title translate>courses.basic-data</mat-card-title>
          <mat-card-content>
            <mat-form-field fxLayout="row">
              <mat-label class="oxygen-normal" translate>courses.course-name</mat-label>
              <input matInput placeholder="{{ 'courses.basic-program' | translate }}" formControlName="courseName" required>
            </mat-form-field>
            <mat-form-field fxLayout="row" fxflex>
              <mat-label class="oxygen-normal" translate>courses.place</mat-label>
              <mat-select>
                <mat-option value="option">{{ 'courses.name-place' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxLayout="row" fxflex>
              <mat-label class="oxygen-normal" translate>courses.category</mat-label>
              <mat-select>
                <mat-option value="option">{{ 'courses.category' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-card-content>
          <mat-card-content fxLayout="row" fxLayoutGap="50px">
            <mat-form-field fxLayout="column" fxflex class="select-width">
              <mat-label class="oxygen-normal" translate>courses.level</mat-label>
              <mat-select>
                <mat-option value="option">{{ 'courses.level' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxLayout="column" fxflex class="select-width">
              <mat-label class="oxygen-normal" translate>courses.age</mat-label>
              <input matInput placeholder="00" formControlName="courseAge" required>
            </mat-form-field>
            <div class="place-field" fxLayout="column" fxflex>
              <mat-label class="oxygen-normal" translate>courses.evaluated</mat-label>
              <mat-slide-toggle class="evaluated-margin"></mat-slide-toggle>
            </div>
          </mat-card-content>
          <mat-card-content fxLayout="row" fxLayoutGap="50px">
            <mat-form-field fxLayout="column" fxflex class="select-width">
              <mat-label class="oxygen-normal" translate>courses.start_date</mat-label>
              <input matInput [matDatepicker]="start_date">
              <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
              <mat-datepicker #start_date></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxLayout="column" fxflex class="select-width">
              <mat-label class="oxygen-normal" translate>courses.end_date</mat-label>
              <input matInput [matDatepicker]="end_date">
              <mat-datepicker-toggle matSuffix [for]="end_date"></mat-datepicker-toggle>
              <mat-datepicker #end_date></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxLayout="column" fxflex class="select-width">
              <mat-label class="oxygen-normal" translate>courses.enrollment</mat-label>
              <input matInput placeholder="0000" formControlName="courseEnrollment" required>
            </mat-form-field>
          </mat-card-content>
          <mat-label class="oxygen-normal">TAGS</mat-label>
          <mat-card-content fxLayout="row" fxLayoutGap="38px">
            <br>
            <mat-chip-list #chipList aria-label="Tag selection">
              <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable" (removed)="remove(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input placeholder="New tag..." #tagInput [formControl]="tagCtrl" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
          </mat-card-content>
        </mat-card>
        <mat-card fxflex>

          <mat-card-content>
            <mat-form-field>
              <mat-label translate>courses.price</mat-label>
              <input matInput placeholder="0000" formControlName="coursePrice" required>
            </mat-form-field>
            <div class="place-field" fxLayout="column" fxflex>
              <mat-checkbox>{{ 'courses.define-later' | translate }}</mat-checkbox>
            </div>
            <div class="place-field" fxLayout="column" fxflex>
              <mat-checkbox>{{ 'courses.allow-teacher-mod' | translate }}</mat-checkbox>
            </div>
            <div class="place-field" fxLayout="column" fxflex>
              <mat-checkbox>{{ 'courses.define-teacher-later' | translate }}</mat-checkbox>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <br>
      <div fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button class="btn-orange card-dashboard-button" matStepperNext>{{ 'courses.next' | translate }}</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <mat-card fxFlex style="flex: 1 1 100%;box-sizing: border-box;">
      <form [formGroup]="secondFormGroup">
        <mat-tab-group>
          <mat-tab label="{{ 'courses.description' | translate }}">
            <ng-template matStepLabel>{{ 'courses.content' | translate }}</ng-template>
            <mat-form-field>
              <mat-label translate>courses.what-learn</mat-label>
              <input matInput formControlName="courseWhatLearn" required>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>courses.to-who</mat-label>
              <input matInput formControlName="courseToWho" required>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>courses.requirements</mat-label>
              <input matInput formControlName="courseRequirements" required>
            </mat-form-field>
            <div>
              <button mat-button matStepperPrevious class="btn-orange card-dashboard-button">Back</button>
              <button mat-button matStepperNext class="btn-orange card-dashboard-button">Next</button>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'courses.course-content' | translate }}">
            <div class="course-content-margins">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="6">
                  <button mat-fab class="add-objective" matTooltip="Agregar Lugares" routerLink="/dashboard/courses/add-objective" aria-label="Example icon button with a delete icon">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
                <mat-label translate>courses.add-objective</mat-label>
              </div>
              <div fxLayout="column">
                <div style="padding-top: 20px;">
                  <mat-card fxLayout="column" style="padding: 35px;">
                    <div fxLayout="row" fxLayoutAlign="end center">
                      <button mat-button class="btn-add-delete">x</button>
                    </div>
                    <mat-form-field>
                      <mat-label translate>courses.objective</mat-label>
                      <input matInput formControlName="courseObjective" required>
                    </mat-form-field>
                    <!-- <div>  testing
                      <mat-form-field>
                        <mat-label> - Contenido</mat-label>
                        <input matInput  required>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="10px" style="padding-top: 10px;">
                      <button mat-button class="btn-add" (click)=" deleteContentLast()">-</button>
                      <button mat-button class="btn-add" (click)="addContent()">+</button>
                    </div> -->
                    <div fxLayout="column" formArrayName="courseContent" fxFlex="100">
                      <div fxLayout="column" *ngFor="let control of courseContent.controls; let ic = index" fxFlex="100">
                        <mat-form-field>
                          <mat-label translate>courses.content</mat-label>
                          <input matInput [formControlName]="ic" required>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="10px" style="padding-top: 10px;">
                      <button mat-button class="btn-add-delete" (click)=" deleteContentLast()">-</button>
                      <button mat-button class="btn-add-delete" (click)="addContent()">+</button>
                    </div>
                  </mat-card>
                </div>
                <div fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="10px" style="padding-top: 10px;">
                  <button mat-button class="btn-add-delete">-</button>
                  <button mat-button class="btn-add-delete">+</button>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="{{ 'courses.schedule' | translate }}">
            <div class="table-margins">
              <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="period">
                  <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="period-color"> {{row.period}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sunday">
                  <mat-header-cell *matHeaderCellDef class='schedule-header-color' translate>courses.sunday</mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.sunday}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="monday">
                  <mat-header-cell *matHeaderCellDef class='schedule-header-color' translate>courses.monday</mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.monday}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tuesday">
                  <mat-header-cell *matHeaderCellDef class='schedule-header-color' translate>courses.tuesday</mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.tuesday}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="wednesday">
                  <mat-header-cell *matHeaderCellDef class='schedule-header-color' translate>courses.wednesday</mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.wednesday}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="thursday">
                  <mat-header-cell *matHeaderCellDef class='schedule-header-color' translate>courses.thursday</mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.thursday}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="friday">
                  <mat-header-cell *matHeaderCellDef class='schedule-header-color' translate>courses.friday</mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.friday}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="saturday">
                  <mat-header-cell *matHeaderCellDef class='schedule-header-color' translate>courses.saturday</mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.saturday}} </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </div>
            <mat-card>
              <div div fxLayout="column" fxLayoutAlign="center center">
                <mat-card-title translate>courses.schedule</mat-card-title>
                <mat-card-content fxLayout="row" fxLayoutGap="50px">
                  <div formArrayName="courseSchedule">
                    <div fxLayout="row" fxLayoutGap="50px" *ngFor="let control of courseSchedule.controls; let ic = index">
                      <mat-form-field fxLayout="column" fxflex class="select-width">
                        <mat-label class="oxygen-normal" translate>courses.day</mat-label>
                        <mat-select>
                          <mat-option value="option">{{ 'courses.day' | translate }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field fxLayout="column" fxflex class="select-width">
                        <mat-label class="oxygen-normal" translate>courses.start_date</mat-label>
                        <input matInput [matDatepicker]="schedule_start_date">
                        <mat-datepicker-toggle matSuffix [for]="schedule_start_date"></mat-datepicker-toggle>
                        <mat-datepicker #schedule_start_date></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field fxLayout="column" fxflex class="select-width">
                        <mat-label class="oxygen-normal" translate>courses.end_date</mat-label>
                        <input matInput [matDatepicker]="schedule_end_date">
                        <mat-datepicker-toggle matSuffix [for]="schedule_end_date"></mat-datepicker-toggle>
                        <mat-datepicker #schedule_end_date></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="10px" class="schedule-add-delete">
                    <button mat-button class="btn-add-delete" (click)=" deleteScheduleLast()">-</button>
                    <button mat-button class="btn-add-delete" (click)="addSchedule()">+</button>
                  </div>
                </mat-card-content>
              </div>
            </mat-card>
          </mat-tab>
          <mat-tab label="{{ 'courses.images' | translate }}">
            <mat-card fxLayout="row" fxLayoutAlign="center stretch" class="card">
              <div fxLayout="row" fxLayoutGap="20px">
                <mat-card fxLayoutAlign="center center" class="card">
                  <div fxLayout="column">
                    <mat-card fxLayout="column" class="card">
                      <div fxFlex="10px" fxLayoutAlign="end end">
                        <button mat-icon-button>
                          <mat-icon class="icon-grey">delete</mat-icon>
                        </button>
                      </div>
                      <span class="material-icons icon-large" fxLayoutAlign="center center">movie</span>
                    </mat-card>
                    <mat-form-field style="padding-top: 50px;">
                      <mat-label>URL</mat-label>
                      <input matInput formControlName="title" placeholder="https://www.youtube.com/watch?v=AbCdE1FgH" required>
                    </mat-form-field>
                    <button mat-raised-button class="btn-orange">Cargar video</button>
                  </div>
                </mat-card>
                <mat-card style="padding: 35px;">
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayout="row" fxLayoutGap="70px">
                      <!--  (change)="onUpload($event)" -->
                      <input type="file" name="file" accept=".png, .jpg" id="file" class="btn-file">
                      <label for="file" class="label-file">Subir imagen</label>
                      <button mat-icon-button>
                        <mat-icon class="icon-grey">delete</mat-icon>
                      </button>
                    </div>
                    <span class="material-icons icon-large" fxLayoutAlign="center center" style="padding-top: 50px;">perm_media</span>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 50px;" fxLayoutGap="5px">
                    <button mat-icon-button class="btn-side">
                      <mat-icon class="orange">keyboard_arrow_left</mat-icon>
                    </button>
                    <mat-card>
                      <span class="material-icons icon-medium" fxLayoutAlign="center center">perm_media</span>
                    </mat-card>
                    <mat-card>
                      <span class="material-icons icon-medium" fxLayoutAlign="center center">perm_media</span>
                    </mat-card>
                    <mat-card>
                      <span class="material-icons icon-medium" fxLayoutAlign="center center">perm_media</span>
                    </mat-card>
                    <button mat-icon-button class="btn-side">
                      <mat-icon class="orange">keyboard_arrow_right</mat-icon>
                    </button>
                  </div>
                </mat-card>
              </div>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </form>
    </mat-card>
  </mat-step>
  <mat-step>
    <div fxLayout="column" fxLayoutAlign="space-around stretch">
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex="70%">
          <mat-form-field appearance="fill">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
        <div fxFlex="10%">
          <!-- <span class="material-icons add-icon pointer" (click)="onOpenSearchAdvance()">list</span> -->
          <span class="material-icons add-icon pointer">list</span>
        </div>
      </div>
    </div>
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>{{ 'courses.profesor' | translate }}</ng-template>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="profesorSource">
          <ng-container matColumnDef="avatar">
            <th mat-header-cell *matHeaderCellDef> Avatar </th>
            <td class="profesor-avatar-width" mat-cell *matCellDef="let element">
              <img class="img-profile" src="/assets/images/{{element.avatar}}"> </td>
          </ng-container>
          <ng-container matColumnDef="info">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element">
              <mat-list role="list">
                <mat-list-item role="listitem">{{element.name}}</mat-list-item>
                <mat-list-item role="listitem">{{element.title}}</mat-list-item>
                <mat-list-item role="listitem">{{element.habilities}}</mat-list-item>
              </mat-list>
            </td>
          </ng-container>
          <ng-container matColumnDef="rating">
            <th mat-header-cell *matHeaderCellDef> Rating </th>
            <td mat-cell *matCellDef="let element">
              <mat-list role="list">
                <mat-list-item role="listitem">
                  <div *ngFor="let a of fakeArray; let index = index">
                    <mat-icon>stars</mat-icon>
                  </div>
                </mat-list-item>
                <mat-list-item role="listitem">{{element.ubication}}</mat-list-item>
              </mat-list>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button class="btn-orange">Invitar</button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="profesorColumns" style="display: none;"></tr>
          <tr mat-row *matRowDef="let row; columns: profesorColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
