import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../../services/general.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../../../../services/profile.service'


@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent implements OnInit {

  src_img: string = '';
  formData;
  form: FormGroup;
  user;
  country;
  state;
  city;
  county;
  parish;
  disableItem = [false, false, false, false];
  email;


  constructor(private GeneralService: GeneralService,
    private ProfileService: ProfileService,
    private fb: FormBuilder,) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [''],
      phone: ['', Validators.required],
      phone2: [''],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      county: ['', Validators.required],
      parish: ['', Validators.required],
      address: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("userData"));
    console.log("user:", this.user);
    // this.getCountry();
    // this.getState();
    // this.getCity();
    // this.getCounty();
    // this.getParish();
    this.loadcountrys();
    this.loadform();
  }



  receiveSrcImg(event) {
    this.src_img = event;
  }

  receiveFile(file){
    let id = 2;
    // this.GeneralService.onUploadOneImage(file, id);
  }


  loadform() {
    this.form.reset({
      name: this.user.name,
      lastName: this.user.last_name,
      email: this.user.email,
      phone: this.user.phone_1,
      phone2: this.user.phone_2,
      country: this.user.country_id,
      state: this.user.state_id,
      city: this.user.city_id,
      county: this.user.county_id,
      parish: this.user.parish_id,
      address: this.user.address,
    });
  }


  getCountry() {
    this.GeneralService.get_countries().subscribe(res => {
      console.log("RES get_countries::", res);
      this.country = res.countries;
    }, err => {
      console.log("ERRor get_countries incorrecto", err);
    });
  }

  getState() {
    this.GeneralService.get_getStatesByCountry(this.form.get("country").value).subscribe(res => {
      console.log("RES get_getStatesByCountry::", res);
      this.state = res.states;
      this.disableItem = [false, true, true, true];
    }, err => {
      console.log("ERRor get_getStatesByCountry incorrecto", err);
    });
    this.form.reset({
      name: this.form.get("name").value,
      lastName: this.form.get("lastName").value,
      email: this.form.get("email").value,
      phone: this.form.get("phone").value,
      phone2: this.form.get("phone2").value,
      country: this.form.get("country").value,
    });
  }

  getCity() {
    this.GeneralService.get_getCitiesByState(this.form.get("state").value).subscribe(res => {
      console.log("RES get_getCitiesByState::", res);
      this.city = res.City;
      this.disableItem = [false, false, true, true];

    }, err => {
      console.log("ERRor get_getCitiesByState incorrecto", err);
    });
    this.form.reset({
      name: this.form.get("name").value,
      lastName: this.form.get("lastName").value,
      email: this.form.get("email").value,
      phone: this.form.get("phone").value,
      phone2: this.form.get("phone2").value,
      country: this.form.get("country").value,
      state: this.form.get("state").value,
    });

  }

  getCounty() {
    this.GeneralService.get_getCountiesByCity(this.form.get("city").value).subscribe(res => {
      console.log("RES get_getCountiesByCity::", res);
      this.county = res.counties;
      this.disableItem = [false, false, false, true];

    }, err => {
      console.log("ERRor get_getCountiesByCity incorrecto", err);
    });
    this.form.reset({
      name: this.form.get("name").value,
      lastName: this.form.get("lastName").value,
      email: this.form.get("email").value,
      phone: this.form.get("phone").value,
      phone2: this.form.get("phone2").value,
      country: this.form.get("country").value,
      state: this.form.get("state").value,
      city: this.form.get("city").value,
    });
  }

  getParish() {
    this.GeneralService.get_getParishesByCounty(this.form.get("county").value).subscribe(res => {
      console.log("RES get_getParishesByCounty::", res);
      this.parish = res.parishes;
      this.disableItem = [false, false, false, false];

    }, err => {
      console.log("ERRor get_getParishesByCounty incorrecto", err);
    });
    this.form.reset({
      name: this.form.get("name").value,
      lastName: this.form.get("lastName").value,
      email: this.form.get("email").value,
      phone: this.form.get("phone").value,
      phone2: this.form.get("phone2").value,
      country: this.form.get("country").value,
      state: this.form.get("state").value,
      city: this.form.get("city").value,
      county: this.form.get("county").value,
    });
  }


  loadcountrys() {
    this.GeneralService.get_countries().subscribe(res => {
      console.log("RES get_countries::", res);
      this.country = res.countries;

      this.GeneralService.get_getStatesByCountry(this.form.get("country").value).subscribe(res => {
        console.log("RES get_getStatesByCountry::", res);
        this.state = res.states;

        this.GeneralService.get_getCitiesByState(this.form.get("state").value).subscribe(res => {
          console.log("RES get_getCitiesByState::", res);
          this.city = res.City;

          this.GeneralService.get_getCountiesByCity(this.form.get("city").value).subscribe(res => {
            console.log("RES get_getCountiesByCity::", res);
            this.county = res.counties;

            this.GeneralService.get_getParishesByCounty(this.form.get("county").value).subscribe(res => {
              console.log("RES get_getParishesByCounty::", res);
              this.parish = res.parishes;
            }, err => {
              console.log("ERRor get_getParishesByCounty incorrecto", err);
            });
          }, err => {
            console.log("ERRor get_getCountiesByCity incorrecto", err);
          });
        }, err => {
          console.log("ERRor get_getCitiesByState incorrecto", err);
        });
      }, err => {
        console.log("ERRor get_getStatesByCountry incorrecto", err);
      });
    }, err => {
      console.log("ERRor get_countries incorrecto", err);
    });
  }


  
  save() {
    let data = {};
    data = {
      "name": this.form.get("name").value,
      "last_name": this.form.get("lastName").value,
      // "email": this.form.get("email").value,
      "phone_1": this.form.get("phone").value,
      "phone_2": this.form.get("phone2").value,
      "rol_id": this.user.rol_id,
      "avatar": [
        "string"
      ],
      "images": [
        "string"
      ],
      "country_id": this.form.get("country").value,
      "state_id": this.form.get("state").value,
      "city_id": this.form.get("city").value,
      "county_id": this.form.get("county").value,
      "parish_id": this.form.get("parish").value,
      "address": this.form.get("address").value
    }
    
    this.ProfileService.post_auth_image_user_id(data, this.user.id).subscribe(res => {
      console.log("RES post_auth_image_user_id::", res);
      localStorage.removeItem("userData");
      this.user.name = this.form.get("name").value;
      this.user.last_name = this.form.get("lastName").value;
      this.user.phone_1 = this.form.get("phone").value;
      this.user.phone_2 = this.form.get("phone2").value;
      this.user.country_id = this.form.get("country").value;
      this.user.state_id = this.form.get("state").value;
      this.user.city_id = this.form.get("city").value;
      this.user.county_id = this.form.get("county").value;
      this.user.parish_id = this.form.get("parish").value;
      this.user.address = this.form.get("address").value;
      // console.log("this.user:", this.user);
      localStorage.setItem("userData", JSON.stringify(this.user));

    }, err => {
      console.log("ERRor post_auth_image_user_id incorrecto", err);
    });
  }

}
