<div fxLayout="column" fxLayoutGap="10px">
  <!-- <app-filter></app-filter> -->
  <div fxLayout="row" fxLayoutAlign="start none" fxLayoutGap="10px">
    <mat-form-field fxFlex="40%">
      <mat-label>{{ "events.search" | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event)" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <button
      class="btn-filter"
      [ngClass]="{ 'border-orange': !hide }"
      (click)="hide = !hide"
    >
      <mat-icon
        class="icon-grey"
        style="margin-top: -8px"
        [ngClass]="{ 'color-orange': !hide }"
        >filter_list</mat-icon
      >
    </button>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    *ngIf="!hide"
  >
    <p fxFlex="6%" class="paragraph">Filtrar por:</p>

    <mat-form-field fxFlex="17%">
      <mat-label>{{ "events.category" | translate }}</mat-label>
      <mat-select [formControl]="category" multiple>
        <mat-select-trigger>
          {{ category.value ? category.value[0] : "" }}
          <span
            *ngIf="category.value?.length > 1"
            class="example-additional-selection"
            >(+{{ category.value.length - 1 }}
            {{ category.value?.length === 2 ? "other" : "others" }})</span
          >
        </mat-select-trigger>
        <mat-option *ngFor="let item of categoryList" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <span><button class="reset" (click)="clearcategory()">X</button></span>

    <mat-form-field fxFlex="17%">
      <mat-label>{{ "events.place" | translate }}</mat-label>
      <mat-select [formControl]="place" multiple>
        <mat-select-trigger>
          {{ place.value ? place.value[0] : "" }}
          <span
            *ngIf="place.value?.length > 1"
            class="example-additional-selection"
            >(+{{ place.value.length - 1 }}
            {{ place.value?.length === 2 ? "other" : "others" }})</span
          >
        </mat-select-trigger>
        <mat-option *ngFor="let item of placesList" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <span><button class="reset" (click)="clearplace()">X</button></span>

    <mat-form-field fxFlex="17%">
      <mat-label>{{ "events.status" | translate }}</mat-label>
      <mat-select [formControl]="status" multiple>
        <mat-select-trigger>
          {{ status.value ? status.value[0] : "" }}
          <span
            *ngIf="status.value?.length > 1"
            class="example-additional-selection"
            >(+{{ status.value.length - 1 }}
            {{ status.value?.length === 2 ? "other" : "others" }})</span
          >
        </mat-select-trigger>
        <mat-option *ngFor="let item of statusList" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <span><button class="reset" (click)="clearstatus()">X</button></span>
  </div>

  <div fxLayout="column" fxLayoutAlign="center stretch">
    <app-message
      *ngIf="msg != null"
      [msg]="msg"
      [typeMsg]="typeMsg"
    ></app-message>
  </div>

  <div
    *ngIf="user.rol_id === 1"
    fxLayout="row"
    fxLayoutAlign="end center"
    (click)="create()"
  >
    <mat-icon class="btn-icon pointer">add</mat-icon>
    <button class="btn btn-border">{{ "courses.add" | translate }}</button>
  </div>

  <mat-card>
    <table class="table-course" mat-table [dataSource]="dataSource" multiTemplateDataRows>
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of columnsToDisplay"
      >
        <div *ngIf="column == 'status' || column == 'menu'; else blockHead">
          <th mat-header-cell *matHeaderCellDef></th>
        </div>
        <ng-template #blockHead>
          <th mat-header-cell *matHeaderCellDef class="text-bold">
            {{ "table." + column | translate }}
          </th>
        </ng-template>

        <div *ngIf="column == 'status'; else blockData">
          <td
            mat-cell
            *matCellDef="let element"
            class="status pointer"
          >
            <div fxLayout="row" fxLayoutAlign="center center">
              <img
                class="imagesProcess"
                src="assets/images/status1.png"
                alt=""
              />
              <img
                *ngFor="let item of [].constructor(element.countStatus)"
                src="assets/images/status2.png"
                alt=""
              />
              <img
                *ngFor="let item of [].constructor(3 - element.countStatus)"
                src="assets/images/status3.png"
                alt=""
              />
              <div
                class="inProcess"
                fxLayoutGap="6px"
                *ngIf="element.countStatus < 3"
              >
                <p class="oxigen-12-white">EN PROCESO</p>
                <p *ngIf="element.place == null" class="oxigen-12-white">
                  - Falta Lugar
                </p>
                <p *ngIf="element.proposal_id == null" class="oxigen-12-white">
                  - Falta propuesta de empleo
                </p>

                <p
                  *ngIf="element.teacher == '0' || element.teacher == null"
                  class="oxigen-12-white"
                >
                  - Falta Teacher
                </p>

                <p *ngIf="element.boolPublish == false" class="oxigen-12-white">
                  - Falta Publicar
                </p>
              </div>
              <div
                class="inProcess"
                fxLayoutGap="6px"
                *ngIf="element.countStatus == 3"
              >
                <p class="oxigen-12-white">PROCESO COMPLETO</p>
              </div>
            </div>
          </td>
        </div>

        <div *ngIf="column == 'menu'; else blockData">
          <td
            style="width: 5%"
            mat-cell
            *matCellDef="let element"
            class="pointer"
          >
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [disabled]="msg != null"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngIf="user.rol_id === 1">
                <button
                  *ngIf="
                    ((element.place === null || element.proposal_id === null) &&
                      element.skip_proposal == false) ||
                    element.place === null
                  "
                  mat-menu-item
                  (click)="continue(element)"
                >
                  <span>Curso incompleto/Continuar</span>
                </button>
              </ng-container>
              <button
                *ngIf="
                  ((element.place !== null && element.proposal_id !== null) ||
                    element.skip_proposal == true) &&
                  element.place !== null
                "
                mat-menu-item
                routerLink="/dashboard/courses/{{ element.id_course }}"
              >
                <span>Ver</span>
              </button>
              <ng-container *ngIf="user.rol_id === 1">
                <button
                  *ngIf="
                    ((element.place !== null && element.proposal_id !== null) ||
                      element.skip_proposal == true) &&
                    element.place !== null
                  "
                  mat-menu-item
                  (click)="goEdit(element)"
                >
                  <span>Editar</span>
                </button>
              </ng-container>
              <ng-container *ngIf="user.rol_id === 1">
                <button
                  mat-menu-item
                  (click)="openDialogRemoveEvent('delete', element)"
                >
                  <!-- <button mat-menu-item> -->
                  <span>Eliminar</span>
                </button>
              </ng-container>
              <ng-container *ngIf="user.rol_id === 1">
                <button
                  *ngIf="
                    ((element.place !== null && element.proposal_id !== null) ||
                      element.skip_proposal == true) &&
                    element.place !== null
                  "
                  mat-menu-item
                  [disabled]="element.boolPlace || element.boolPublish"
                  (click)="serviceDataToCancelReservation(element)"
                >
                  <span>Anular reserva</span>
                </button>
              </ng-container>
              <ng-container *ngIf="user.rol_id === 1">
                <button
                  *ngIf="
                    ((element.place !== null && element.proposal_id !== null) ||
                      element.skip_proposal == true) &&
                    element.place !== null
                  "
                  mat-menu-item
                  [disabled]="!element.boolPlace"
                  (click)="sendToContractPlace(element.id_course)"
                >
                  <span>Enviar reserva</span>
                </button>
              </ng-container>

              <ng-container *ngIf="user.rol_id === 1">
                <ng-container *ngIf="element.skip_proposal == false">
                  <button
                    *ngIf="
                      ((element.place !== null &&
                        element.proposal_id !== null) ||
                        element.skip_proposal == true) &&
                      element.place !== null
                    "
                    mat-menu-item
                    [disabled]="element.boolTeacher || element.boolPublish"
                    (click)="serviceDataToCancelOffer(element)"
                  >
                    <span>Anular propuestas</span>
                  </button>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="user.rol_id === 1">
                <button
                  *ngIf="
                    ((element.place !== null && element.proposal_id !== null) ||
                      element.skip_proposal == true) &&
                    element.place !== null
                  "
                  mat-menu-item
                  [disabled]="
                    !element.boolTeacher || element.proposal_id == null
                  "
                  (click)="sendToProposalTeacher(element.proposal_id)"
                >
                  <span>Enviar propuestas</span>
                </button>
              </ng-container>
              <ng-container *ngIf="user.rol_id === 1">
                <ng-container *ngIf="!element.boolPublish">
                  <button
                    *ngIf="
                      ((element.place !== null &&
                        element.proposal_id !== null) ||
                        element.skip_proposal == true) &&
                      element.place !== null
                    "
                    mat-menu-item
                    [disabled]="
                      element.boolTeacher == true && element.boolPlace == true
                    "
                    (click)="sendToPublish(element.id_course)"
                  >
                    <span>Publicar</span>
                  </button>
                </ng-container></ng-container
              >

            <ng-container *ngIf="user.rol_id === 1" >
              <button
              *ngIf="
                ((element.place !== null && element.proposal_id !== null) ||
                  element.skip_proposal == true) &&
                element.place !== null
              "
              mat-menu-item
              [disabled]="!element.boolPublish"
              (click)="openDialogRemoveEvent('cancel', element)"
            >
              <span>Anular Publicación</span>
            </button>
            </ng-container>
            </mat-menu>
          </td>
        </div>

        <ng-template #blockData>
          <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
        </ng-template>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
          style="background: rgba(0, 0, 0, 0.04)"
        >
          <div
            fxLayout="row"
            fxLayoutGap="20px"
            class="element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div
              class="content-img"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              <img [src]="element.main_image" />
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="space-between start"
              class="item_container"
              fxLayoutGap="12px"
            >
              <div
                fxLayout="row"
                fxFlex
                fxLayoutAlign="end center"
                style="width: 100%"
              >
                <p>
                  <span class="material-icons circle">trip_origin</span
                  ><span class="text-bold">{{
                    "events.start-date" | translate
                  }}</span>
                  {{ element.start_date }} / {{ element.start_hour }}
                  <span class="material-icons circle">radio_button_checked</span
                  ><span class="text-bold">{{
                    "events.end-date" | translate
                  }}</span>
                  {{ element.end_date }} / {{ element.end_hour }}
                </p>
                <p>
                  <span class="material-icons circle">lens</span
                  ><span class="text-bold">{{
                    "events.capacity" | translate
                  }}</span>
                  {{ element.availability }}/{{ element.capacity }}
                </p>
              </div>
              <p style="font-size: 14px">{{ element.description }}</p>
              <div fxFlex fxLayoutAlign="end center" style="width: 100%">
                <p>
                  <span class="text-bold">{{
                    "events.price" | translate
                  }}</span>
                  {{ element.price }}
                </p>
              </div>
              <mat-chip-list>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="10px"
                  *ngFor="let tag of element.tags"
                >
                  <mat-chip class="text-bold">{{ tag }}</mat-chip>
                </div>
              </mat-chip-list>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
      ></tr>
    </table>
    <div class="mensaje" *ngIf="dataSource?.data?.length === 0">
      No se encontró resultados
    </div>
    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </mat-card>
</div>
