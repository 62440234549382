import { ModalPostulationComponent } from './../components/modal-postulation/modal-postulation.component';
import { User } from './../../../../auth/reset-password/reset-password.component';
import { ProposalService } from 'src/app/services/proposal.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { send } from 'process';
import { M } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-postulationTeacher',
  templateUrl: './postulation-teacher.component.html',
  styleUrls: ['./postulation-teacher.component.scss'],
})
export class PostulationTeacherComponent implements OnInit {
  id;
  iscourse;
  data;
  horario;
  dias = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
  ];
  diashorario = [];
  preguntas = [];
  precio;
  currency;
  currencyselect;
  arrayduration = {
    hour: 'Horas',
    day: 'Dias',
    week: 'Semanas',
    month: 'Meses',
    year: 'Año ',
  };
  user;
  questions;
  isPostulation=true
  constructor(
    private router: ActivatedRoute,
    private proposalService: ProposalService, public dialog: MatDialog,
    private _router:Router
  ) {}

  ngOnInit(): void {
    console.log(this._router.url)
    if(this._router.url.includes("detail-postulation")){
      this.isPostulation=false;
    }
   this.user=JSON.parse(localStorage.getItem('userData'))
    this.id = this.router.snapshot.params.id;
    this.proposalService.get_postulation(this.id).subscribe((data) => {
      console.log('.................////', data);
      this.data = data.proposal;
      this.iscourse = this.data.course_id !== null ? true : false;
      this.horario = data.schedule;
      this.preguntas = data.questions?.map((m)=>{return {...m,questionid:m.id}});
      this.precio = this.data.price_total;
      this.currency = data.currencies;
      this.currencyselect = this.data.currency_id;
      this.data.schedule.forEach((element) => {
        console.log('////////////////////////', element);
        const fecha = new Date(element.start_date);
        fecha.getUTCDay();
        console.log('............', this.dias[fecha.getUTCDay()]);
        this.diashorario.push({
          dia: this.dias[fecha.getUTCDay()],
          horas: element.times,
        });
      });
    });
  }
  save() {
    const request = {
      questionsresponses:this.preguntas,
      proposal_id: this.id,
      price: this.precio,
      currency_id: this.currencyselect.toString(),
      user_id: this.user.id.toString(),
    };
    console.log(this.preguntas)

    this.proposalService.post_postulation(request).subscribe((response)=>{
console.log(response)
this.openDialog()
    }



    )
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalPostulationComponent, {
      width: '450px',
      height: '290px',

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

    });
  }
}
