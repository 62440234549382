<!-- <mat-spinner *ngIf="!load" strokeWidth="10" [diameter]="48"></mat-spinner> -->
<div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="21px" style="padding: 20px 30px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <p class="raleway-23-g" style="padding-top: 10px;"><b>Cancelar reservacion</b></p>
        <button [mat-dialog-close]="null" class="btn-close">
            <mat-icon aria-hidden="false">clear</mat-icon>
        </button>
    </div>


    <p class="oxigen-16-b">¿Estas seguro de que quieres cancelar esta reservacion?</p>
    <p class="raleway-16-g"> Curso/Evento: {{data.reservation.course}}</p>
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="40px">
        <img src="assets/images/image-default.png" alt="" class="imgPlace" style="padding-right: 30px;">
        <div fxLayout="column" fxLayoutAlign="space-around start" >
            <p class="raleway-16-g"><b>{{data.reservation.place}}</b></p>
            <p class="raleway-14-g"><b>Categoria:</b> {{data.reservation.place_category}}</p>
            <p class="raleway-14-g">{{data.reservation.Ubication}}</p>
        </div>
    </div>
    <p class="oxigen-14-b">En las siguientes fechas y horarios:</p>
    <p *ngFor="let item of data.schedule">
        <span class="material-icons circle">lens</span>
        <span class="oxigen-14-b">{{item.start_date}} <b>|</b> {{item.start_time}}  -  {{item.end_time}}</span>
    </p>
    <p class="oxigen-14-b">*Ciertas condiciones aplican</p>

    <div fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="20px">
        <div class="btn-border-orange pointer" [mat-dialog-close]="null" fxLayoutAlign="center center">
            <p class="font">{{'send-proposal.cancel' | translate}}</p>
        </div>
        <div fxLayoutAlign="center center" [mat-dialog-close]="data.reservation.reservation_id">
            <button class="btn-orange btn pointer">{{'send-proposal.accept' | translate}}</button>
        </div>
    </div>
</div>
