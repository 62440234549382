<div style="padding-left: 50px">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <p class="raleway-23-g" style="padding-top: 18px">
      <b>Rechazar propuesta</b>
    </p>
    <button [mat-dialog-close]="null" class="btn-close">
      <mat-icon aria-hidden="false">clear</mat-icon>
    </button>
  </div>

  <div class="question">
    <span>¿Quieres realizar alguna pregunta?</span>
  </div>

  <div>
    <textarea [(ngModel)]="reason" name="" id="" cols="99" rows="3"></textarea>
  </div>

  <!-- <div class="enviar">
    <span>Enviar un mensaje al cliente</span>
  </div> -->
  <!-- <textarea name="" id="" cols="99" rows="3"></textarea> -->

  <div class="botones">
    <button mat-dialog-close="remove" class="btn">CANCELAR</button>
    <button (click)="rejectproposal()" class="btn">ACEPTAR</button>
  </div>
</div>
