import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../../services/general.service';
import { AddressService } from '../../../../services/address.service';

import {FormControl, Validators} from '@angular/forms';
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
 addresses:boolean=false
  userData: any;
  id;
 constructor(
  private GeneralService:GeneralService,
  private AddressService:AddressService,
) { }
  ngOnInit(){
    // get_addresses
    
  }
  onCreate(){
    console.log("hola",this.addresses)
    if(this.addresses==false){
      this.addresses=true;
    }else{
      this.addresses=false
    }
    localStorage.setItem('address_id','');
  }
  saving() {
    // this.message = $event
    
    if(this.addresses==false){
      this.addresses=true;
    }else{
      this.addresses=false
    }
  }
  savingsx() {
    // this.message = $event
    
      this.id=event
    if(this.addresses==false){
      this.addresses=true;
    }else{
      this.addresses=false
    }
  }
}
