import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProposalService {
  public baseurl: string;
  temp: string;

  constructor(private __http: HttpClient, private http: HttpClient) {
    this.baseurl = environment.url_server;
  }

  post_adminuserProposals(id): Observable<any> {
    this.temp = this.baseurl + `admin/userProposals/${id}`;
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  post_postulation(data): Observable<any> {
    this.temp = this.baseurl + `admin/proposalpostulation`;
    return this.__http
      .post(this.temp, data)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  post_acceptproposalcourse(idcourse, iduser, data): Observable<any> {
    this.temp =
      this.baseurl + `admin/acceptinvitationcourse/${idcourse}/${iduser}`;
    return this.__http
      .post(this.temp, data)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  post_accepteventproposal(idevent, iduser, data): Observable<any> {
    this.temp =
      this.baseurl + `admin/acceptinvitationevent/${idevent}/${iduser}`;
    return this.__http
      .post(this.temp, data)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  post_rejectproposalcourse(idcourse, iduser, data): Observable<any> {
    this.temp =
      this.baseurl + `admin/rejectinvitationcourse/${idcourse}/${iduser}`;
    return this.__http
      .post(this.temp, data)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  post_rejectproposalevent(idcourse, iduser, data): Observable<any> {
    this.temp =
      this.baseurl + `admin/rejectinvitationevent/${idcourse}/${iduser}`;
    return this.__http
      .post(this.temp, data)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  deleteproposal(proposal_id): Observable<any> {
    this.temp = this.baseurl + `admin/proposalpostulation/${proposal_id}`;
    return this.__http
      .delete(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getproposals(id): Observable<any> {
    this.temp = this.baseurl + `admin/proposalsteacher/${id}`;
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  getdetailproposals(id): Observable<any> {
    this.temp = this.baseurl + `admin/detailproposal/${id}`;
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  get_postulation(id) {
    this.temp = this.baseurl + `admin/proposalpostulation/${id}`;
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  get_adminsubmittedproposals(id): Observable<any> {
    this.temp = this.baseurl + `admin/submitted-proposals/${id}`;
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  get_adminreceivedproposals(id): Observable<any> {
    this.temp = this.baseurl + `admin/received-proposals/${id}`;
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  post_admin_sendProposals(data): Observable<any> {
    this.temp = this.baseurl + 'admin/sendProposals';
    return this.__http
      .post(this.temp, data)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  get_admin_userProposals(id): Observable<any> {
    this.temp = this.baseurl + 'admin/userProposals/' + id;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  //https://backend.newyorkcityloop.com/api/admin/proposals-box/18
  get_my_Proposals(type): Observable<any> {
    let user = JSON.parse(localStorage.getItem('userData'));
    this.temp =
      this.baseurl + 'admin/proposals-box/' + user['id'] + '?tab=' + type;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  get_Proposal_id(id): Observable<any> {
    //let user = JSON.parse( localStorage.getItem("userData"));
    //console.log("USER = ",user)
    this.temp = this.baseurl + 'admin/proposals/' + id;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  accept_Proposal(id): Observable<any> {
    //let user = JSON.parse( localStorage.getItem("userData"));
    //console.log("USER = ",user)
    this.temp = this.baseurl + 'admin/acceptProposal/' + id;
    return this.__http
      .post(this.temp, {})
      .pipe(retry(1), catchError(this.errorHandl));
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message; // Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }
  public getIPAddress() {
    return this.http.get(environment.ipify_url);
  }

  get_proposal_course_event(id): Observable<any> {
    this.temp = this.baseurl + `getproposal/${id}`;
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  get_proposal_enviadas(): Observable<any> {
    this.temp = this.baseurl + `admin/proposalsteacher`;
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
