<!-- <mat-horizontal-stepper linear #stepper> esta linea para version web y final -->
<mat-horizontal-stepper #stepper linear>
    <mat-step [completed]="controlStepper.step1" [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup" fxLayout="column" fxLayoutGap="36px" style="margin-top: 24px;">
            <ng-template matStepLabel>{{'ev-create.basic' | translate}}</ng-template>

            <div fxLayout="column" fxLayoutAlign="center stretch">
               <app-message *ngIf="msg!=null" [msg]="msg" [typeMsg]="typeMsg"></app-message>
            </div>


            <div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">
                <mat-card fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="60" fxLayoutGap="30px"
                    class="card">
                    <div div fxLayout="row" fxLayoutAlign="space-between center">
                        <p class="title-card">{{'ev-create.basic' | translate}}</p>
                        <p class="bg-orange text-required">* Campos requeridos</p>
                    </div>
                    <mat-form-field>
                        <mat-label>Nombre del curso<span class="bg-orange">*</span></mat-label>
                        <input matInput placeholder="Nombre del curso" formControlName="name">
                        <mat-error *ngIf="firstFormGroup.get('name').invalid && firstFormGroup.get('name').touched" class="error" translate>errRequired
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Categoria <span class="bg-orange">*</span></mat-label>
                        <mat-select formControlName="category">
                            <mat-option *ngFor="let option of categories" [value]="option.id"> {{option.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="firstFormGroup.get('category').invalid && firstFormGroup.get('category').touched" class="error" translate>errRequired
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Niveles <span class="bg-orange">*</span></mat-label>
                        <mat-select formControlName="level">
                            <mat-option *ngFor="let option of levels" [value]="option.id"> {{option.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="firstFormGroup.get('level').invalid && firstFormGroup.get('level').touched" class="error" translate>errRequired
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Privacidad <span class="bg-orange">*</span></mat-label>
                        <mat-select formControlName="state">
                            <mat-option value=0>{{'ev-create.public' | translate}}</mat-option>
                            <mat-option value=1>{{'ev-create.private' | translate}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="firstFormGroup.get('state').invalid && firstFormGroup.get('state').touched" class="error" translate>errRequired
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Descripcion <span class="bg-orange">*</span></mat-label>
                        <textarea matInput cdkTextareaAutosize rows="30" #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="description"></textarea>
                            <mat-error *ngIf="firstFormGroup.get('description').invalid && firstFormGroup.get('description').touched" class="error" translate>errRequired
                            </mat-error>
                    </mat-form-field>
                </mat-card>

                <mat-card fxFlex="35" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="36px" class="card">
                    <img *ngIf="src_img!=''" [src]="src_img" style="width:100%; border-radius: 5px;">
                    <mat-card *ngIf="src_img==''" fxLayoutAlign="center center" style="min-height: 210px;">
                        <span class="material-icons icon-large">perm_media</span>

                    </mat-card>
                    <span *ngIf="showErrorImg" class="errorimg"> * La imagen es obligatoria</span>

                    <app-upload (fileEvent)="receiveFile($event)" (srcImgEvent)="receiveSrcImg($event)"></app-upload>


                    <mat-form-field fxFlex style="padding-top:150px">
                        <mat-chip-list #chipList aria-label="Tag selection">
                            <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                                (removed)="remove(tag)">
                                {{tag}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input (click)="add($event)" (focus)="add($event)" placeholder="Tags" #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
                                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                                {{tag}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="chipList.errorState" class="error" translate>errRequired
                        </mat-error>
                    </mat-form-field>
                </mat-card>
            </div>


            <div *ngIf="load" fxLayoutAlign="center center" fxflex>
                <mat-spinner strokeWidth="10" [diameter]="24"></mat-spinner>
            </div>
            <div *ngIf="!load" fxLayoutAlign="center center" fxFlex="30">
                <button (click)="serviceSave()" class="btn-orange-auto btn">{{'save' | translate }}</button>
            </div>
        </form>
    </mat-step>

    <!-- segundo paso -->
    <mat-step [completed]="controlStepper.step2">
        <ng-template matStepLabel>{{'ev-create.places' | translate}}</ng-template>
        <div fxLayout="column" fxLayoutGap="36px" style="margin-top: 24px;">
            <app-step-place-course (nextStep)="nextStep2($event)" [createPage]="controlStepper.step1"></app-step-place-course>
        </div>
    </mat-step>

    <!-- tercer paso -->
    <mat-step [completed]="controlStepper.step3">
        <ng-template matStepLabel>{{'ev-create.proposal' | translate}}</ng-template>
        <div fxLayout="column" fxLayoutGap="36px" style="margin-top: 24px;">
            <app-step-proposal-course></app-step-proposal-course>
        </div>
    </mat-step>


</mat-horizontal-stepper>

<!-- <pre><button (click)="test()">test subir imagen curso con id 6</button></pre> -->
