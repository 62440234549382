<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between none"
  fxLayoutGap="36px"
>
  <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
    <mat-card>
      <mat-tab-group class="scroll" mat-stretch-tabs>
        <mat-tab label="Propuestas enviadas">
          <div *ngFor="let item of data" class="list">
            <div class="fila">
              <div>{{ item?.extra?.place_name }}</div>
              <div>{{ item?.extra?.name }}</div>
              <div>
                {{ item?.time_length }} {{ arrayduration[item?.time_unit] }} /
                {{ item?.price_teacher }} {{item?.currency }}
              </div>
            </div>
            <div class="fila1">
              <div class="usuario">
                <div class="margen">{{ item?.extra?.name_business }}</div>
                <div class="margen estrella">
                  <mat-icon *ngFor="let s of [].constructor(item.starscont)"
                    >star</mat-icon
                  >
                </div>
                <div class="margen">
                  Miembro desde
                  {{ item?.extra?.member_since | date: " LLLL yyyy " }}
                </div>
              </div>
              <div class="perfil">
                <img [src]="item?.extra?.main_image" />
              </div>
            </div>

            <div class="fila2">
              <div class="menu">
                <ng-container matColumnDef="action">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button
                      routerLink="/dashboard/teacher/detail-postulation/{{
                        item?.proposal_id
                      }}"
                      mat-menu-item
                    >
                      <span>Ver</span>
                    </button>
                    <button (click)="deleteproposal(item)" mat-menu-item>
                      <span>Eliminar</span>
                    </button>
                    <button routerLink="" mat-menu-item>
                      <span>Mensajes</span>
                    </button>
                  </mat-menu>
                </ng-container>
              </div>
              <div class="status">{{ item?.status_postulation }}</div>
              <div class="enviado">
                <span class="enviado">Enviado hace </span> {{ item?.dias }}
                <span class="enviado"> dias</span>
              </div>
            </div>
          </div>
          <!-- <app-ofert-list [type]="0">
       =================================================             </app-ofert-list> -->
        </mat-tab>
        <mat-tab label="Propuestas recibidas">
          <div *ngFor="let recibido of received" class="list">
            <div class="fila">
              <div>{{ recibido?.extra?.place_name }}</div>
              <div>{{ recibido?.extra?.name }}</div>
              <div>
                {{ recibido?.time_length }}
                {{ arrayduration[recibido?.time_unit] }} /
                {{ recibido?.price_total }} {{recibido?.currency}}
              </div>
            </div>
            <div class="fila1">
              <div class="usuario">
                <div class="margen">{{ recibido?.extra?.name_business }}</div>
                <div class="margen estrella">
                  <mat-icon *ngFor="let s of [].constructor(recibido.starscont)"
                    >star</mat-icon
                  >
                </div>
                <div class="margen">
                  Miembro desde
                  {{ recibido?.extra?.member_since | date: " LLLL yyyy " }}
                </div>
              </div>
              <div class="perfil">
                <img [src]="recibido?.extra?.main_image" />
              </div>
            </div>

            <div class="fila2">
              <div class="menu">
                <ng-container matColumnDef="action">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button (click)="getRecord(recibido)" mat-menu-item>
                      <span>Ver</span>
                    </button>
                    <button
                      (click)="acceptproposalreceived(recibido)"
                      mat-menu-item
                    >
                      <span>Aceptar</span>
                    </button>
                    <button
                      (click)="rejectproposalreceived(recibido)"
                      mat-menu-item
                    >
                      <span>Rechazar</span>
                    </button>
                  </mat-menu>
                </ng-container>
              </div>
              <div class="status" *ngIf="recibido?.extra.status"><span>Pendiente</span></div>
              <div class="enviado">
                <span class="enviado">Recibido hace </span> {{ recibido?.dias }}
                <span class="enviado"> dias</span>
              </div>
            </div>
          </div>
          <!-- <app-ofert-list [type]="1"></app-ofert-list> -->
        </mat-tab>
        <!-- <mat-tab label="Ofertas recibidas">
                    <app-ofert-list  [type]="2"></app-ofert-list>
                </mat-tab> -->

        <!-- =============================================== -->
        <mat-tab label="Historial">
          <div *ngFor="let history of historial" class="list">
            <div class="fila">
              <div>{{ history?.extra?.place_name }}</div>
              <div>{{ history?.extra?.name }}</div>
              <div>
                {{ history?.time_length }}
                {{ arrayduration[history?.time_unit] }} /
                {{ history?.price_total }} {{history?.currency}}
              </div>
            </div>
            <div class="fila1">
              <div class="usuario">
                <div class="margen">{{ history?.extra?.name_business }}</div>
                <div class="margen estrella">
                  <mat-icon *ngFor="let s of [].constructor(history.starscont)"
                    >star</mat-icon
                  >
                </div>
                <div class="margen">
                  Miembro desde
                  {{ history?.extra?.member_since | date: " LLLL yyyy " }}
                </div>
              </div>
              <div class="perfil">
                <img [src]="history?.extra?.main_image" />
              </div>
            </div>

            <div class="fila2">
              <div class="status">
                <span>Status</span>
              </div>
              <div *ngIf="history.extra.status=='approved'" class="enviado">Aprobado</div>
              <div *ngIf="history.extra.status=='rejected'" class="enviado">Rechazado</div>
              <div class="enviado">
                <span class="enviado">Recibido hace </span> {{ history?.dias }}
                <span class="enviado"> dias</span>
              </div>
            </div>
          </div>

          <!-- <app-ofert-list [type]="'received'"></app-ofert-list> -->
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
