<agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [streetViewControl] = "false"
  (mapClick)="getMapClick($event)">
  <agm-marker *ngIf="markers"
    [latitude]="markers.lat"
    [longitude]="markers.lng"
    [markerDraggable]="false"
  >
  </agm-marker>
  <!-- <agm-marker *ngFor="let m of markers; let i = index" (markerClick)="clickedMarker(m.label, i)" [latitude]="m.lat"
    [longitude]="m.lng" [label]="m.label" [markerDraggable]="m.draggable" (dragEnd)="markerDragEnd(m, $event)">
  </agm-marker> -->
</agm-map>

<!-- <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="false" [streetViewControl] = "false"
  (mapClick)="mapClicked($event)">
  <agm-marker *ngFor="let m of markers; let i = index" (markerClick)="clickedMarker(m.label, i)" [latitude]="m.lat"
    [longitude]="m.lng" [label]="m.label" [markerDraggable]="m.draggable" (dragEnd)="markerDragEnd(m, $event)">
  </agm-marker>
</agm-map> -->
