<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
      <app-header></app-header>
        <app-sub-header [screen]="'Proceso de pago'" ></app-sub-header>
        <app-header-payment></app-header-payment>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" class="p-term" style="padding-left: 11em;padding-right: 11em; margin-top: 3em;margin-bottom: 3em;">
            <div fxFlex="30%" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
                    <mat-radio-group aria-label="Select an option">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond" style="padding-bottom: 2em;">
                            <mat-radio-button value="1"></mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div fxFlex="30%" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
                    <img src="/assets/images/paypal.png" width="20%" alt="">
                </div>
            </div>
            <div fxFlex="40%" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
                    <p class="email">Iniciaste sesión con<br><span class="email-black">web@paypal.com</span></p>
                    
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" class="p-term" style="padding-left: 11em;padding-right: 11em; margin-top: 3em;margin-bottom: 3em;">
            <div fxFlex="30%" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
                    <mat-radio-group aria-label="Select an option">
                        
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond" style="padding-bottom: 2em;">
                            <mat-radio-button value="1"></mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div fxFlex="30%" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
                    <p>para pagar con otra cuenta, Inicia sesion con Paypal</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond" style="margin-bottom: 10px;">
                    <button   mat-button class="example-icon favorite-icon style-login ingresar"  >
                        Paypal
                    </button>
                </div>
            </div>
            <div fxFlex="40%" fxLayout="column" fxLayoutAlign="space-between stretch">

            </div>
        </div>

        
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" class="p-term" style="padding-left: 11em;padding-right: 11em; margin-top: 3em;margin-bottom: 3em;">
            <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
                    <button   mat-button class="example-icon favorite-icon style-login registrate"  >
                        Completar pago
                    </button>
                </div>
            </div>
        </div>
        
        <app-footer></app-footer>  
    </div>
</mat-drawer-container>