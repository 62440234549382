<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="fecha">
      <th mat-header-cell *matHeaderCellDef> {{"method_payment.cash-flow.date" | translate}}. </th>
      <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="referencia">
      <th mat-header-cell *matHeaderCellDef> {{"method_payment.cash-flow.reference" | translate}}. </th>
      <td mat-cell *matCellDef="let element"> {{element.referencia}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="metodo">
      <th mat-header-cell *matHeaderCellDef> {{"method_payment.cash-flow.method" | translate}} </th>
      <td mat-cell *matCellDef="let element" style="width: 500px;"> 
            
                <img [src]="element.metodo" width="30%" style="padding: 10px;"> 
              
          </td>  
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="monto">
      <th mat-header-cell *matHeaderCellDef> {{"method_payment.cash-flow.amount" | translate}} </th>
      <td mat-cell *matCellDef="let element" style="color: brown;"> {{element.monto}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="categoria">
        <th mat-header-cell *matHeaderCellDef> {{"method_payment.cash-flow.category" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.categoria}} </td>
      </ng-container>

      <!-- Symbol Column -->
    <ng-container matColumnDef="detalle">
        <th mat-header-cell *matHeaderCellDef> {{"method_payment.cash-flow.details" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.detalle}} </td>
      </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
