import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { google } from '@agm/core/services/google-maps-types';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {
// google maps zoom level
zoom: number = 13;

@Input() longitud;
@Input() latitud;
@Input() coords;
markers:any =null;
@Output() mark: EventEmitter<any>=new EventEmitter();


lat;
lng;

@Output() id_locker = new EventEmitter<string>();

constructor() {}

ngOnInit() {
  if( this.longitud && this.latitud  ){
    this.lng = parseFloat(this.longitud);
    this.lat = parseFloat(this.latitud);
  }

  // if( this.coords ){
  //   this.markers = JSON.parse(this.coords);
  // }
}

ngOnChanges(changes:SimpleChanges){
  if( this.longitud && this.latitud  ){
    this.lng = parseFloat(this.longitud);
    this.lat = parseFloat(this.latitud);
  }
  if( this.coords ){
    this.markers=this.coords;

  }
}

clickedMarker(label: string, index: number) {
  this.id_locker.emit(this.markers[index]['id_locker']);
}

getMapClick(e) {
  this.markers={
    lat: e.coords.lat,
    lng: e.coords.lng
  };

  this.mark.emit(this.markers)
}

markerDragEnd(m: marker, $event: MouseEvent) {
  console.log('dragEnd', m, $event);
}
}

// just an interface for type safety.
interface marker {
lat: number;
lng: number;
label?: string;
draggable: boolean;
}

