import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  public baseurl: string;
  temp: string;

  constructor(private __http: HttpClient,  private http: HttpClient) {
    this.baseurl = environment.url_server;
  }

  post_adminaddresses(data):Observable<any> {
    this.temp = this.baseurl + 'admin/addresses' ;
    console.log("--------------data:", data);
    return this.__http.post( this.temp, data )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  get_addresses(id):Observable<any> {
    this.temp = this.baseurl + `addresses/${id}` ;
    // console.log("--------------data:", data);
    return this.__http.get( this.temp )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  delete_adminaddresses(id):Observable<any>{
    this.temp = this.baseurl + `admin/addresses/${id}` ;
    // console.log("--------------data:", data);
    return this.__http.delete( this.temp )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  get_adminaddresses(id):Observable<any>{
    this.temp = this.baseurl + `admin/addresses/${id}` ;
    // console.log("--------------data:", data);
    return this.__http.get( this.temp )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  put_adminaddresses(data,id){
    // /admin/places/update/{id}
        this.temp = this.baseurl + `admin/addresses/${id}`;
        return this.__http.put<any>(this.temp, data)
          .pipe(retry(1),
            catchError(this.errorHandl)
          )
      }
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }
}
