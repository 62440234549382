<div fxLayout="row" fxLayoutAlign="start none" fxLayoutGap="10px">
  <mat-form-field fxFlex="100%">
    <mat-label>{{ "events.search" | translate }}</mat-label>
    <input matInput (keyup)="applyFilter($event)" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <button
    class="btn-filter"
    [ngClass]="{ 'border-orange': !hide }"
    (click)="hide = !hide"
  >
    <mat-icon
      class="icon-grey"
      style="margin-top: -8px"
      [ngClass]="{ 'color-orange': !hide }"
      >filter_list</mat-icon
    >
  </button>
</div>

<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="20px"
  *ngIf="!hide"
>
  <p fxFlex="6%" class="paragraph">Filtrar por:</p>

  <mat-form-field fxFlex="17%">
    <mat-label>{{ "events.category" | translate }}</mat-label>
    <mat-select
      #selectcategory
      (selectionChange)="categoryreserva($event)"
      [formControl]="category"
    >
      <mat-select-trigger>
        {{ category.value }}
      </mat-select-trigger>
      <mat-option *ngFor="let item of categoryList" [value]="item">{{
        item
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <span><button class="reset" (click)="clearcategory()">X</button></span>

  <mat-form-field fxFlex="17%">
    <mat-label>{{ "events.place" | translate }}</mat-label>
    <mat-select
      #selectlocation
      (selectionChange)="ciudadfiltro($event)"
      [formControl]="place"
    >
      <mat-select-trigger>
        {{ place.value }}

        >
      </mat-select-trigger>
      <mat-option *ngFor="let item of placesList" [value]="item">{{
        item
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <span><button class="reset" (click)="clearubication()">X</button></span>
</div>

<div class="titulo"><h2>Lista de Empleos</h2></div>

<mat-card class="card">
  <div class="detalles">
    <div class="contenido">
      <table class="tableteacher" mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="nombre">
          <th class="nombre" mat-header-cell *matHeaderCellDef>Nombre</th>
          <td class="nombre" mat-cell *matCellDef="let element">
            {{ element.nombre }} - {{ element.titulo }}
          </td>
        </ng-container>
        <ng-container matColumnDef="categoria">
          <th mat-header-cell *matHeaderCellDef>Categoria</th>
          <td mat-cell *matCellDef="let element">{{ element.categoria }}</td>
        </ng-container>
        <ng-container matColumnDef="privacidad">
          <th mat-header-cell *matHeaderCellDef>Privacidad</th>
          <td mat-cell *matCellDef="let element">{{ element.privacidad }}</td>
        </ng-container>
        <ng-container matColumnDef="fechadeinicio">
          <th class="fechainicio" mat-header-cell *matHeaderCellDef>
            Fecha de inicio
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.fechadeinicio }}
          </td>
        </ng-container>
        <ng-container matColumnDef="direccion">
          <th mat-header-cell *matHeaderCellDef>Direccion</th>
          <td mat-cell *matCellDef="let element">{{ element.direccion }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="getRecord(element)" mat-menu-item>
                <span>Ver</span>
              </button>
              <button
                routerLink="/dashboard/teacher/teacher-postulation/{{
                  element.proposal_id
                }}"
                mat-menu-item
              >
                <span>Postulación</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</mat-card>
<mat-paginator
  [pageSize]="10"
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
></mat-paginator>
