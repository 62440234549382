<div fxLayout="column" fxLayoutAlign="space-around stretch">
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <div fxFlex="5%">
      <button mat-fab color="primary" matTooltip="Agregar Lugares" routerLink="/dashboard/courses/create" class="add-button" aria-label="Example icon button with a delete icon">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div fxFlex="70%">
      <mat-form-field>
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex="10%">
      <!-- <span class="material-icons add-icon pointer" (click)="onOpenSearchAdvance()">list</span> -->
      <span class="material-icons add-icon pointer">list</span>
    </div>
  </div>
</div>

<!-- sslects -->
<mat-card-content>
  <div fxLayout="row" fxLayout.xs="column" fxlayout.sm="column" fxLayoutGap="38px">
    <mat-form-field appearance="fill" fxflex >
      <mat-label>Select</mat-label>
      <mat-select>
        <mat-option value="option">Option</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" fxflex >
      <mat-label>Select</mat-label>
      <mat-select>
        <mat-option value="option">Option</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-card-content>
<mat-card-content>
  <div fxLayout="row" fxLayout.xs="column" fxlayout.sm="column" fxLayoutGap="38px">
    <mat-form-field appearance="fill" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
      <mat-label>Select</mat-label>
      <mat-select>
        <mat-option value="option">Option</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
      <mat-label>Select</mat-label>
      <mat-select>
        <mat-option value="option">Option</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-card-content>

<!-- table -->
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
    <div fxLayout="row" fxLayout.xs="column" fxlayout.sm="column">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef class="thead"> {{ 'courses.' + column | translate }} </th>
      <div *ngIf="column=='edit' || column=='copy'; else notIcon">
        <td mat-cell *matCellDef="let element">
          <mat-icon>{{element[column]}}</mat-icon>
        </td>
      </div>
      <ng-template #notIcon>
        <td mat-cell *matCellDef="let element" class="oxygen-normal"> {{element[column]}} </td>
      </ng-template>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="table-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <mat-card-content>
            <div fxlayout="row" style="flex-direction: row; box-sizing: border-box; display: flex;">
              <div class="table-element-diagram" fxflex="50%" fxflex.gt-sm="25" fxhide.md style="flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 100%; max-width: 25%;">
                <img class="img-profile" src="/assets/images/default.png" width="80px" max-width="254px" max-height="164px">
              </div>
              <div class="table-element-description" fxflex style="cursor: pointer; flex-direction: column; box-sizing: border-box; display: flex; flex: 1 1 0%;">
                <div class="oxygen-normal">
                  <span>Nivel</span><span class="oxygen-bold">: Basico</span><span> - </span><span>Personas Inscritas</span><span class="oxygen-bold">: XX</span><span> - </span><span>Cupos Disponibles</span><span class="oxygen-bold">: XX</span>
                </div>
                <br>
                <div class="oxygen-normal-description">
                  {{element.description}}
                  <br>
                  <span class="table-element-description-attribution"></span>
                  <mat-chip-list aria-label="Tags selection">
                    <mat-chip>Programacion</mat-chip>
                    <mat-chip>Curso</mat-chip>
                    <mat-chip>Tachira</mat-chip>
                    <mat-chip>PHP</mat-chip>
                  </mat-chip-list>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </td>
    </ng-container>
    </div>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="table-element-row" [class.table-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
  </table>
  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
