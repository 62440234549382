import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from "../../services/auth.service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

 interface Usuario {
  value: number;
  viewValue: string;
}


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public_key_recaptcha:string;
  recaptchaCheck:boolean = false;
  form: FormGroup;
  isMobile: boolean;
  invalidConfirPass = false;
  invalidConfirPass2 = false;
  recapcha : string;
  invalidFormatPass1: boolean;
  invalidSamePass2: boolean;
  configPattern =/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%&*]{6,15}$/;
  invalidAllVadations : boolean = true;
  ipAddress:string;
  load = false;
  errMsg = '';
  hidePass = true;
  hideRepassword = true;
  disabled = true;

  constructor( private fb: FormBuilder,
    private AuthService: AuthService,
    private ip:AuthService,
    private breakpointObserver: BreakpointObserver,
    private _router: Router) { 
    this.public_key_recaptcha = environment.public_key_recaptcha;

    this.form = this.fb.group({
      name   : ['', [ Validators.required, Validators.pattern('[a-zA-Z ]*'), Validators.minLength(2) ] ],
      lastName : ['', [ Validators.required, Validators.pattern('[a-zA-Z ]*'), Validators.minLength(2) ] ],
      email   : ['', [ Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')] ],
      pass1    : ['', Validators.required],
      pass2    : ['', Validators.required ],
      acceptTermsCond : ['', Validators.requiredTrue ]
    });
  }

  ngOnInit(): void {  
    const CustomBreakpoints = {
      Handset: '(max-width: 599px)', // Mobile devices up to 599px
    };
    this.breakpointObserver.observe([CustomBreakpoints.Handset])
    .subscribe(result => this.isMobile = result.matches);
    this.getIP();  
  } 

  getIP() {  
    this.ip.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  }  

  resolved(captchaResponse: string) {
    this.recapcha=captchaResponse;
    this.recaptchaCheck = captchaResponse != null?true:false;
  }

  get invalidName() {
    return this.form.get('name').invalid && this.form.get('name').touched
  }

  get invalidLastName() {
    return this.form.get('lastName').invalid && this.form.get('lastName').touched
  }

  get invalidEmail() {
    return this.form.get('email').invalid && this.form.get('email').touched
  }

  get invalidPass() {
    return this.form.get('pass1').invalid && this.form.get('pass1').touched;
  }

  get invalidPassFormat() {
    if(!this.form.get('pass1').value.match(this.configPattern)){ 
      if(this.form.get('pass1').touched){
        this.invalidFormatPass1=false;
      }
    }else{
      this.invalidFormatPass1=true;
    }
    return this.invalidFormatPass1;
  }

  get invalidPass2() {
    this.invalidAllVadation();
    return this.form.get('pass2').invalid && this.form.get('pass2').touched;
  }

  get samePass(){
    if(( this.form.get('pass1').value === this.form.get('pass2').value)  ){
      this.invalidSamePass2=false;
    }else{
      if(this.form.get('pass2').touched){
        this.invalidSamePass2=true;
      }
    }
     return this.invalidSamePass2;
  }

  invalidAllVadation(){
    this.invalidConfirPass = ( this.form.get('pass1').value === this.form.get('pass2').value ) ? false : true;
    if( (this.form.status=="VALID")  && (this.invalidFormatPass1==true) &&  (this.invalidConfirPass==false) && (this.form.get('acceptTermsCond').value==true) && (this.recaptchaCheck==true) ){
      this.invalidAllVadations=false;
    }else{
      this.invalidAllVadations=true;
    }
  }

  onInputChange(){
    if(this.form.get('pass1').value != '' && this.form.get('pass2').value != ''){
      this.disabled = false;
    }else{
      this.disabled = true;
    }
  }

  onCreateAccount() {
    this.load = true;
    this.errMsg = '';
    if( this.invalidAllVadations==false ){
      let lang=(window.navigator.language).split("-");
      let data = {
        "name": this.form.get('name').value,
        "last_name": this.form.get('lastName').value,
        "email": this.form.get('email').value,
        "password": this.form.get('pass1').value,
        "password_confirmation": this.form.get('pass2').value,
        "rol_id": 1,
        "language": lang[0] ,
        "token": this.recapcha,
        "remoteip": "192.168.0.130"
        //  "remoteip": this.ipAddress
      };

      this.AuthService.post_auth_signup(data).subscribe( res => {
          if( res.message_return.ERROR == false ){
           this.AuthService.setMessage(res.message_return.ERROR_MENSAGGE);
            this._router.navigateByUrl("auth/login");
          }else{
            console.log("Error")
          }
          this.load = false;
      },err => {
        this.errMsg = err.error.message_return.ERROR_MENSAGGE;
        this.load = false;
      });
    }
  }
}