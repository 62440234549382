import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

export interface User{
  id: number,
  email: string,
  token: string,
  created_at: string,
  updated_at: string
}
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  hidePass = true;
  hideRepassword = true;
  new_password = '';
  confir_password = '';
  error=false;
  token: string;
  user: User;
  msg:string ='';
  typeMsg:string;
  load = false;
  disabled = true;
  regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%&*]{6,15}$/;

  constructor(
    private AuthService:AuthService,
    private _router: Router,
    private translate: TranslateService) { 

    let url = this._router.parseUrl(this._router.url);
    this.token = url.queryParams['token'];
  }

  ngOnInit(): void {
    this.AuthService.get_password_find(this.token).subscribe(res => {
      if(res.message_return.ERROR == false ){
        this.user = res.result;
      }
    },err => {
      this._router.navigateByUrl("auth/forgot-password");
    });
  }

  onInputChange(){
    if(this.new_password != '' && this.confir_password != ''){
      this.disabled = false;
    }else{
      this.disabled = true;
    }
  }

  onChangesPass(){
    this.load = true;
    this.msg='';
    if( !this.new_password.match(this.regex) || (this.new_password != this.confir_password)){
      this.error=true;
      this.load = false;
      this.typeMsg="error";
      this.translate.get('reset.error').subscribe( (text: string) => {
        this.msg = text;
      });
    }else{
      this.error=false;
      if(this.user!=null){
        let data = {
          email: this.user.email ,
          password: this.new_password,
          password_confirmation: this.new_password,
          token: this.user.token
        }
        this.AuthService.post_password_reset(data).subscribe(res => {
          if(res.message_return.ERROR == false ){
            this.typeMsg="success";
            this.translate.get('reset.success').subscribe( (text: string) => {
              this.msg = text;
            });
          }
          this.load = false;
        },err => {
            this.error=true;
            this.typeMsg="error";
            this.msg = err.error.message_return.ERROR_MENSAGGE;
            this.load = false;
        });
      }
    }
    
  }

}
