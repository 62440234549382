import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import {
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventApi,
  EventInput,
} from '@fullcalendar/angular';
import { GeneralService } from '../../../services/general.service';
import { EventsService } from '../../../services/events.service';
import { CourseDashboardService } from '../../../services/course-dashboard.service';
import esLocale from '@fullcalendar/core/locales/es';
import esusLocale from '@fullcalendar/core/locales/es-us';
import { Subscription } from 'rxjs';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ModalDeleteHourComponent } from './modal-delete-hour/modal-delete-hour.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() idNum: number = -1;
  @Input() rentPlace = false;
  @Input() modify = true;
  @Input() idCourse: number = -1;

  INITIAL_EVENTS: EventInput[];
  titleEvent = 'seleccionado';
  idEventString = 'selected';
  eventId =
    this.EventsService.get_eventId() == undefined
      ? -1
      : this.EventsService.get_eventId();
  courseId =
    this.CourseDashboardService.get_courseId() == undefined
      ? -1
      : this.CourseDashboardService.get_courseId();
  businessHours;
  startCalendar;
  endCalendar;

  loadCalendar = false;

  calendarOptions: CalendarOptions;
  currentEvents: EventApi[] = [];

  TODAY_STR = new Date().toISOString().replace(/T.*$/, '');

  msg;
  typeMsg;
  showMsg;
  timeEvent;
  msgSubscription: Subscription;
  typeMsgSubscription: Subscription;
  showMsgSubscription: Subscription;

  timeCount = 0;
  amount;
  code;

  constructor(
    private EventsService: EventsService,
    private GeneralService: GeneralService,
    public dialog: MatDialog,
    private CourseDashboardService: CourseDashboardService
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    console.log(
      'inicio calendar id:',
      this.idNum,
      '    rent place:',
      this.rentPlace,
      '   idCurse',
      this.idCourse
    );
    this.msgSubscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSubscription = this.GeneralService.typeMsg$.subscribe(
      (data) => {
        this.typeMsg = data;
      }
    );

    this.showMsgSubscription = this.GeneralService.showMsg$.subscribe(
      (data) => {
        this.showMsg = data;
      }
    );

    if (this.modify == true) {
      this.loadSchedule();
    } else {
      console.log('loadScheduleStatic');
      if (this.idNum > 0) {
        this.loadScheduleStatic();
      }else if (this.idCourse > 0) {
        this.loadScheduleStaticCourse();
      }
    }
  }

  initCalendar() {
    this.calendarOptions = {
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridWeek,timeGridDay',
      },
      // hiddenDays: [ 2, 4 ],
      allDaySlot: false,
      height: 'inherit',
      slotDuration: '01:00:00',
      slotLabelInterval: '01:00:00',
      locale: esLocale,
      validRange: {
        start: this.TODAY_STR,
        // end: '2021-06-01'
      },
      businessHours: this.businessHours,
      initialView: 'timeGridWeek',
      initialEvents: this.INITIAL_EVENTS,
      weekends: true,
      editable: false,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      select: this.handleDateSelect.bind(this),
      eventClick: this.handleEventClick.bind(this),
      eventsSet: this.handleEvents.bind(this),
      // eventBorderColor:'#00d1ce',
      // eventBackgroundColor:'#00d1ce',
    };
  }

  initCalendarDisabled() {
    this.calendarOptions = {
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridWeek,timeGridDay',
      },
      allDaySlot: false,
      height: 400,
      slotDuration: '01:00:00',
      slotLabelInterval: '01:00:00',
       locale: esLocale,
      validRange: {
        start: this.startCalendar,
        end: this.endCalendar,
      },
      businessHours: this.businessHours,
      initialView: 'timeGridWeek',
      initialEvents: this.INITIAL_EVENTS,
      weekends: true,
      editable: false,
      selectable: false,
      selectMirror: false,
      dayMaxEvents: false,
    };
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    // const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (
      this.sameBusinessHours(selectInfo.start, selectInfo.end) &&
      this.sameDate(selectInfo.start, selectInfo.end) &&
      this.scheduleClash(selectInfo.start, selectInfo.end) &&
      this.maxPerDay(selectInfo.start, selectInfo.end) &&
      this.typeMsg != 'error' &&
      this.typeMsg != 'success'
    ) {
      console.log('se crea evento');
      calendarApi.addEvent({
        title: this.titleEvent,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        overlap: false,
        id: this.idEventString,
        durationEditable: true,
        startEditable: true,
        constraint: 'businessHours',
        backgroundColor: '#00d1ce',
        borderColor: '#00d1ce',
      });
    }
    // else {
    //   alert("selecciona horario valido");
    // }

    // console.log(" selectinfo:", selectInfo);
    // console.log("calendarApi:", calendarApi);
    // console.log("currentEvents:::", this.currentEvents);
  }

  handleEventClick(clickInfo: EventClickArg) {
    console.log('handleEventClick clickInfo.event::delete', clickInfo);

    if (clickInfo.event.groupId != 'unselectable') {
      // if (confirm(`Are you sure you want to delete the event `)) {
      //   clickInfo.event.remove();
      // }
      const dialogRef = this.dialog.open(ModalDeleteHourComponent, {
        height: 'auto',
        width: 'auto',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed');
        console.log('result', result);
        if (result == true) {
          console.log('borrar');
          clickInfo.event.remove();
        }
      });
    }
  }

  handleEvents(events: EventApi[]) {
    console.log('handleEvents events siempre::', events);
    let info = [];
    this.timeCount = 0;
    this.currentEvents = events;
    this.currentEvents.forEach((element) => {
      if (element.id == 'selected') {
        info.push(element);
        this.timeCount += element.end.getHours() - element.start.getHours();
      }
    });
    let calendarData = {
      code: this.code,
      amount: this.amount,
      hour: this.timeCount,
      rentPlace: this.rentPlace,
    };
    this.GeneralService.set_calendarData(calendarData);
    this.GeneralService.set_timeEvent(info);
  }

  get_current() {
    console.log('currentEvents', this.currentEvents);
    return this.currentEvents;
  }

  sameBusinessHours(start: Date, end: Date) {
    let flag = false;
    console.log('this.businessHours', this.businessHours);
    this.businessHours.forEach((element) => {
      if (
        element.daysOfWeek[0] == start.getDay() &&
        start.getHours() >= element.startTime.split(':')[0] &&
        end.getHours() <= element.endTime.split(':')[0]
      ) {
        flag = true;
      }
    });
    if (!flag) {
      this.GeneralService.typeMsg$.emit('error');
      this.GeneralService.msg$.emit(
        '¡Seleccion no valida! Esta fuera de los horarios de funcionamiento del establecimiento'
      );
    }
    return flag;
  }

  sameDate(start: Date, end: Date) {
    let flag = false;
    if (
      start.getDate() == end.getDate() &&
      start.getMonth() == end.getMonth() &&
      start.getFullYear() == end.getFullYear()
    ) {
      flag = true;
    }
    if (!flag) {
      this.GeneralService.typeMsg$.emit('error');
      this.GeneralService.msg$.emit('Debe seleccionar día por día');
    }
    return flag;
  }

  scheduleClash(start: Date, end: Date) {
    let flag, send;
    let res;
    this.currentEvents.forEach((element) => {
      if (
        element.start.getDate() == end.getDate() &&
        element.start.getMonth() == end.getMonth() &&
        element.start.getFullYear() == end.getFullYear()
      ) {
        res = element.start.getHours() - end.getHours();
        if (res < 0 && element.end.getHours() > start.getHours()) {
          flag = true;
        }
      }
    });
    flag = flag == true ? false : true;
    if (!flag) {
      this.GeneralService.typeMsg$.emit('error');
      this.GeneralService.msg$.emit(
        '¡Seleccion no valida! Choque entre horarios'
      );
    }
    return flag;
  }

  // dayOfficeClash(start: Date, end: Date) { no es necesario
  //   let flag = false;
  //   this.dayOffice.forEach(element => {
  //     // console.log(element, end.getDay());
  //     if (element == start.getDay() || element == end.getDay()) {
  //       flag = true
  //     }
  //   });
  //   console.log("dayOfficeClash",flag )
  //   return flag
  // }

  maxPerDay(start: Date, end: Date) {
    let flag = true;
    let cont = 0;
    this.currentEvents.forEach((element) => {
      if (
        element.id == this.idEventString &&
        element.start.getDate() == start.getDate() &&
        element.start.getMonth() == start.getMonth() &&
        element.start.getFullYear() == start.getFullYear()
      ) {
        cont++;
        if (cont == 3) {
          flag = false;
        }
      }
    });
    if (!flag) {
      this.GeneralService.typeMsg$.emit('error');
      this.GeneralService.msg$.emit(
        '¡ERROR!  No puedes seleccionar mas de 3 bloques en un dia'
      );
    }
    return flag;
  }

  loadSchedule() {
    let arrayDay = [];
    this.EventsService.get_schedule(this.idNum).subscribe(
      (res) => {
        console.log('res get_schedule', res);
        let type = false;

        if (res.day_array && type == false) {
          console.log('es tipo 3');
          res.day_array.forEach((element) => {
            element.times.forEach((hour) => {
              arrayDay.push({
                daysOfWeek: [element.dayofweek],
                startTime: hour.start_time,
                endTime: hour.end_time,
              });
            });
          });
          type = true;
        }
console.log(res)
        if (res.place_times.length>0&& res.place_times[0].all_day == 1 && type == false) {
          console.log('es tipo 1');
          for (let i = 0; i < 7; i++) {
            arrayDay.push({
              daysOfWeek: [i],
              startTime: '00:00:00',
              endTime: '24:00:00',
            });
          }
          type = true;
        }

        if (res.place_times[0] && type == false) {
          console.log('es tipo 2');
          console.log('res', res);
          for (let i = 0; i < 7; i++) {
            arrayDay.push({
              daysOfWeek: [i],
              startTime: res.place_times[0].start_time_week,
              endTime: res.place_times[0].end_time_week,
            });
            type = true;
          }
        }


        this.amount = (res.place_times.lenght > 0)? res.place_times[0].amount : 0;
        this.code = (res.place_times.lenght > 0)? res.place_times[0].code : 0;

        let init_events = [];
        console.log("ELEMENT:", res);
        console.log("eventID:", this.eventId);
        console.log("courseId:", this.courseId);


        res.array_dates.forEach((element) => {
          element.times.forEach((time) => {
            if (
              time.event_id == this.eventId ||
              time.course_id == this.courseId
            ) {
              init_events.push({
                title: this.titleEvent,
                start: element.start_date + 'T' + time.start_time,
                end: element.end_date + 'T' + time.end_time,
                overlap: false,
                id: this.idEventString,
                durationEditable: true,
                startEditable: true,
                constraint: 'businessHours',
                backgroundColor: '#00d1ce',
                borderColor: '#00d1ce',
              });
            } else {
              init_events.push({
                groupId: 'unselectable',
                id: 'database',
                title: 'No disponible',
                start: element.start_date + 'T' + time.start_time,
                end: element.end_date + 'T' + time.end_time,
                backgroundColor: '#e36161',
                borderColor: '#e36161',
                // textColor: "black",
                overlap: false,
              });
            }
          });
        });
        this.INITIAL_EVENTS = init_events;
        // console.log("INITIAL_EVENTS", this.INITIAL_EVENTS);
        this.businessHours = arrayDay;
        // console.log("businessHours: ", this.businessHours);
        this.initCalendar();
        this.GeneralService.showMsg$.emit('active');
        this.loadCalendar = true;
      },
      (err) => {
        console.log('ERRor create-get_event_create', err);
      }
    );
  }

  loadScheduleStatic() {
    let arrayDay = [];
    this.EventsService.get_admin_eventSchedules(this.idNum).subscribe(
      (res: any) => {
        console.log('res get_admin_eventSchedules', res);
        if (res.message_return.ERROR) {
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit('Not found');
        } else {
          let type = false;
          if (res.message_return.ERROR == false) {
            if (res.day_array && type == false) {
              console.log('es tipo 3');
              res.day_array.forEach((element) => {
                element.times.forEach((hour) => {
                  arrayDay.push({
                    daysOfWeek: [element.dayofweek],
                    startTime: hour.start_time,
                    endTime: hour.end_time,
                  });
                });
              });
              type = true;
            }

            if (res.place_times[0].all_day == 1 && type == false) {
              console.log('es tipo 1');
              for (let i = 0; i < 7; i++) {
                arrayDay.push({
                  daysOfWeek: [i],
                  startTime: '00:00:00',
                  endTime: '24:00:00',
                });
              }
              type = true;
            }
            if (res.place_times[0] && type == false) {
              console.log('es tipo 2');
              for (let i = 0; i < 7; i++) {
                arrayDay.push({
                  daysOfWeek: [i],
                  startTime: res.place_times[0].start_time_week,
                  endTime: res.place_times[0].end_time_week,
                });
                type = true;
              }
            }

            this.amount = res.place_times[0].amount;
            this.code = res.place_times[0].code;

            let init_events = [];
            res.event_schedule.forEach((element) => {
              element.times.forEach((time) => {
                init_events.push({
                  groupId: 'unselectable',
                  id: 'database',
                  title: 'Tu evento',
                  start: element.start_date + 'T' + time.start_time,
                  end: element.end_date + 'T' + time.end_time,
                  backgroundColor: '#e36161',
                  borderColor: '#e36161',
                  // textColor: "black",
                  overlap: false,
                });
              });
            });
            this.INITIAL_EVENTS = init_events;
            console.log('init_events', init_events);
            this.businessHours = arrayDay;
            console.log('businessHours', this.businessHours);
            this.startCalendar = init_events[0].start;
            this.endCalendar = init_events[init_events.length - 1].end;
            this.initCalendarDisabled();
            this.GeneralService.showMsg$.emit('active');
            this.loadCalendar = true;
          }
        }
      },
      (err) => {
        console.log('ERRor create-get_event_create', err);
        this.GeneralService.typeMsg$.emit('error');
        this.GeneralService.msg$.emit(err.message_return.ERROR_MENSAGGE);
      }
    );
  }

  loadScheduleStaticCourse() {
    let arrayDay = [];
    this.EventsService.get_admin_courseSchedule(this.idCourse).subscribe(
      (res: any) => {
        console.log('res get_admin_courseSchedule', res);
        if (res.message_return.ERROR) {
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit('Not found');
        } else {
          let type = false;
          if (res.message_return.ERROR == false) {
            if (res.day_array && type == false) {
              console.log('es tipo 3');
              res.day_array.forEach((element) => {
                element.times.forEach((hour) => {
                  arrayDay.push({
                    daysOfWeek: [element.dayofweek],
                    startTime: hour.start_time,
                    endTime: hour.end_time,
                  });
                });
              });
              type = true;
            }

            if (res.place_times[0].all_day == 1 && type == false) {
              console.log('es tipo 1');
              for (let i = 0; i < 7; i++) {
                arrayDay.push({
                  daysOfWeek: [i],
                  startTime: '00:00:00',
                  endTime: '24:00:00',
                });
              }
              type = true;
            }
            if (res.place_times[0] && type == false) {
              console.log('es tipo 2');
              for (let i = 0; i < 7; i++) {
                arrayDay.push({
                  daysOfWeek: [i],
                  startTime: res.place_times[0].start_time_week,
                  endTime: res.place_times[0].end_time_week,
                });
                type = true;
              }
            }

            this.amount = res.place_times[0].amount;
            this.code = res.place_times[0].code;

            let init_events = [];
            res.course_schedule.forEach((element) => {
              element.times.forEach((time) => {
                init_events.push({
                  groupId: 'unselectable',
                  id: 'database',
                  title: 'Tu evento',
                  start: element.start_date + 'T' + time.start_time,
                  end: element.end_date + 'T' + time.end_time,
                  backgroundColor: '#e36161',
                  borderColor: '#e36161',
                  // textColor: "black",
                  overlap: false,
                });
              });
            });
            this.INITIAL_EVENTS = init_events;
            console.log('init_events', init_events);
            this.businessHours = arrayDay;
            console.log('businessHours', this.businessHours);
            this.startCalendar = init_events[0].start;
            init_events?.forEach((element) => {
              const dt1 = new Date(element.end);
              const dt2 = new Date(element.start);
              console.log(this.diff_hours(dt1, dt2));
              this.timeCount += this.diff_hours(dt1, dt2);
            });
            this.endCalendar = init_events[init_events.length - 1].end;
            this.initCalendarDisabled();
            this.GeneralService.showMsg$.emit('active');
            this.loadCalendar = true;
            // setTimeout(() => {
            //   this.initCalendar();
            // }, 200);
          }
        }
      },
      (err) => {
        console.log('ERRor create-get_event_create', err);
        this.GeneralService.typeMsg$.emit('error');
        this.GeneralService.msg$.emit(err.message_return.ERROR_MENSAGGE);
      }
    );
  }
  diff_hours(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSubscription;
    this.typeMsgSubscription;
    this.showMsgSubscription;
  }

  openDialog() {
    const dialogRef = this.dialog.open(ModalDeleteHourComponent, {
      height: 'auto',
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log('result', result);
      if (result != undefined) {
        console.log('borrar reservation');
        this.EventsService.get_admin_CancelReservation(result).subscribe(
          (res: any) => {
            console.log('get_admin_CancelReservation', res);
          },
          (err) => {
            console.log('ERRor get_admin_CancelReservation', err);
          }
        );
      }
    });
  }
}
