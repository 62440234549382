import { ProposalService } from 'src/app/services/proposal.service';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import { environment } from 'src/environments/environment';
import { CourseDashboardService } from '../../../../services/course-dashboard.service';
import { EventsService } from '../../../../services/events.service';

@Component({
  selector: 'app-detail-proposal',
  templateUrl: './detail-proposal.component.html',
  styleUrls: ['./detail-proposal.component.scss'],
})
export class DetailProposalComponent implements OnInit {

  idProposal = null;
  chips = [];
  detail = null;
  place_id = null;
  idCourseEvent = null;
  youtube = null;
  urls = null;
  images = null;
  dataSource;
  displayedColumns = ['name', 'identity', 'age', 'email'];
iscourse=false;
info;
idcourse;
idevent;



  constructor(
    private route: ActivatedRoute,
    private proposalService: ProposalService
  ) {

  }

  ngOnInit(): void {

    let URLactual = window.location.href;
    this.idProposal = this.route.snapshot.params.id
    this.getdetailproposals()
    this.idcourse
    this.idevent
  }

  onKey($event) {
    this.urls = $event.target.value;
    console.log('onKey', this.urls);
  }


  getdetailproposals(){

    this.proposalService.getdetailproposals( this.idProposal).subscribe((data) => {
      this.info = data.proposal
      this.iscourse=(this.info.course_id!==null)?true:false
      this.idcourse= this.info?.course_id
      this.idevent= this.info?.event_id
      this.images= this.info?.extra.images
      this.urls=this.info?.extra.url
      this.youtube=this.info?.extra.url
      console.log(this.info)
    console.log('================================================', data);

  });









  // get_data_students() {
  //   let ELEMENT_DATA = [];
  //   this.CourseDashboardService.getAllUsersByCourse(this.id).subscribe(
  //     (res) => {
  //       res.listStudent.forEach((element) => {
  //         ELEMENT_DATA.push({
  //           name: element.User,
  //           identity: element.dni,
  //           age: element.age,
  //           email: element.email,
  //         });
  //       });
  //       this.dataSource = ELEMENT_DATA;
  //     },
  //     (err) => {
  //       console.log('ERRor ', err);
  //     }
  //   );
  // }

}
}
