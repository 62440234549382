import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-modal-postulation',
  templateUrl: './modal-postulation.component.html',
  styleUrls: ['./modal-postulation.component.scss']
})
export class ModalPostulationComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

  }

}
