<div class="box">
    <form [formGroup]="form">
        <div fxLayout="column" fxLayoutAlign="space-between stretch" style="padding:25px 36px 45px 36px">
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <p class="oxigen-16-bold"><b>Agregar horarios</b></p>
                <button [mat-dialog-close]='null' class="btn-close">
                    <mat-icon aria-hidden="false">clear</mat-icon>
                </button>
            </div>
            <p class="oxigen-12" style="margin-top: 20px;">Agregar horario de funcionamiento</p>
            <div fxLayout="column" fxLayoutAlign="space-between stretch" style="margin-left:20px; margin-top: 40px;">
                <mat-radio-group formControlName="typeSchedule" fxLayout="column" fxLayoutGap="15px">
                    <mat-radio-button class="oxigen-13" value="1">Siempre disponible</mat-radio-button>

                    <div fxLayout="row" fxLayoutAlign="space-between center"  fxFlex fxLayoutGap="10px">
                        <mat-radio-button class="oxigen-13" fxFlex="225px" value="2">Todos los dias</mat-radio-button>
                        <!-- *ngIf="form.controls.typeSchedule.value==2" -->
                        <div *ngIf="form.controls.typeSchedule.value==2" fxLayoutGap="10px"
                            fxLayoutAlign="start center">
                            <mat-form-field fxFlex="100px">
                                <mat-label>Hora inicio</mat-label>
                                <input #start type="time" matInput formControlName="allDayStart">
                            </mat-form-field>
                            <p class="oxigen-13"> - </p>
                            <mat-form-field fxFlex="100px">
                                <mat-label>Hora Fin</mat-label>
                                <input type="time" #end [min]="start.value" matInput formControlName="allDayEnd">
                            </mat-form-field>

                        </div>
                    </div>
                    <p *ngIf="form.get('allDayEnd').hasError('invalidTime')" class="text-right">Hora fin debe ser superior a hora inicio</p>
                    <mat-radio-button class="oxigen-13" value="3">Disponible en horas concretas</mat-radio-button>
                </mat-radio-group>



                <!-- *ngIf="form.controls.typeSchedule.value==3" -->
                <div *ngIf="form.controls.typeSchedule.value==3" fxLayout="row" fxFlex fxLayoutAlign="start center"
                    fxLayoutGap="10px" style="margin-top: 35px; margin-left:50px;">
                    <div formArrayName="schedule">
                        <div *ngFor="let days of week; let dayIndex=index">
                            <div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="10px"
                                style="padding-top: 30px; padding-bottom: 10px; border-bottom: solid 1px black;">
                                <form [formGroup]="form">
                                    <mat-checkbox fxFlex="180px" formControlName={{days}}>{{'week.' + week[dayIndex] | translate}}</mat-checkbox>
                                </form>
                                <!-- *ngIf="form.get(days).value==true" -->
                                <div  [formGroupName]="dayIndex">
                                    <div formArrayName="hour">
                                        <div *ngFor="let skill of scheduleHour(dayIndex).controls; let hourIndex=index">
                                            <div [formGroupName]="hourIndex" fxLayoutAlign="start center"
                                                fxLayoutGap="10px">
                                                <mat-form-field fxFlex="100px">
                                                    <mat-label>Hora Inicio</mat-label>
                                                    <input type="time" matInput formControlName="start">
                                                </mat-form-field>
                                                <p class="oxigen-13"> - </p>
                                                <mat-form-field fxFlex="100px">
                                                    <mat-label>Hora Fin</mat-label>
                                                    <input type="time" matInput formControlName="end">
                                                    <mat-error>Hora fin debe ser superior a hora inicio</mat-error>
                                                </mat-form-field>


                                                <!-- <button (click)="removescheduleHour(dayIndex,hourIndex)">Remove hour</button> -->
                                                <div fxLayout="column" fxLayoutGap="2px">
                                                    <div *ngIf="((form.controls.schedule.value[dayIndex].hour.length-1)==hourIndex)  &&  (form.controls.schedule.value[dayIndex].hour.length-1)<2"
                                                        class="btn-circle" fxLayoutAlign="center center"
                                                        (click)="addscheduleHour(dayIndex)">
                                                        <mat-icon class="para pointer">add</mat-icon>
                                                    </div>
                                                    <div *ngIf="hourIndex!=0" class="btn-circle"
                                                        fxLayoutAlign="center center"
                                                        (click)="removescheduleHour(dayIndex,hourIndex)">
                                                        <mat-icon class="para pointer">remove</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!-- esto tiene muchos divs xq sino no funcionaban los inputs (     no tengo culpa ='(     )      -->

            </div>
        </div>
    </form>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
        <button [mat-dialog-close]="form" cdkFocusInitial class="btn-orange btn pointer">ACEPTAR</button>

        <div class="btn-border-cancel pointer" [mat-dialog-close]='null' fxLayoutAlign="center center">
            <p class="font">CANCELAR</p>
        </div>
    </div>
</div>
