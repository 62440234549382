import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../../../../services/profile.service'

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  problemList;
  form: FormGroup;
  user;


  constructor(private ProfileService: ProfileService,
    private fb: FormBuilder) { 
      this.form = this.fb.group({
        "problem_id": ['', Validators.required],
        "description": ['', Validators.required],
        "url": ['']
      });
    }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.serviceGetListProblems();
  }

  serviceGetListProblems() {
    this.ProfileService.get_problemTypes().subscribe(res => {
      console.log("res get_problemTypes::", res);
      this.problemList = res.problem_type;
      console.log("problemList", this.problemList);
    }, err => {
      console.log("ERRor ", err)
    });
  }


  servicePostContact() {
    let data = {};
    data = {
      "name": this.user.name + this.user.last_name,
      "problem_type_id":  this.form.get("problem_id").value,
      "description": this.form.get("description").value,
      "email": this.user.email,
      "user_id": this.user.id,
      "file": "string",
      "url": this.form.get("url").value
    }
    this.ProfileService.post_contactSupport(data).subscribe(res => {
      console.log("RES post_contactSupport:: guardoooooo", res);
    }, err => {
      console.log("ERRor post_contactSupport incorrecto", err);
    });
  }

  test(){
  }
}


