import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GeneralService } from '../../../services/general.service';
import { JobsService } from '../../../services/jobs.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit,OnDestroy {
  dataSource;
  dataSourceAux;
  columnsToDisplay: string[];
  id;
  load = true;
  hide = true; //true

  filterValues = {
    category: '',
    address: ''
  };

  category = new FormControl();
  categoryList = [];
  address = new FormControl();
  addressList = [];

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  user;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public dialog: MatDialog,
    private _router: Router,
    private GeneralService: GeneralService,
    private JobsService: JobsService) { }

  ngOnInit(): void {
    this.msgSuscription = this.GeneralService.msg$.subscribe(data => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe(data => {
      this.typeMsg = data;
    });
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.getAllActiveCourses(this.user.id);
  }

  getAllActiveCourses(id) {
    let ELEMENT_DATA = [];
    let category_Aux;
    let address_Aux;

    this.JobsService.getAllActiveCoursesByTeacher(id).subscribe((res: any) => {
      res.coursesByTeacher.forEach(element => {
        ELEMENT_DATA.push({
          name: element.name,
          category: element.category,
          privacity: element.state,
          date: (element.start_date) ? element.start_date : "0000/00/00",
          address: element.address,
          id: element.id
        });

        
        if(element.category != category_Aux){
          category_Aux = element.category;
          this.categoryList.push(element.category);
        }

        if(element.address != address_Aux){
          address_Aux = element.address;
          this.addressList.push(element.address);
        }
      });

      this.dataSource = new MatTableDataSource(ELEMENT_DATA);
      this.dataSourceAux = new MatTableDataSource(ELEMENT_DATA);

      this.columnsToDisplay = ['name', 'category', 'privacity', 'date', 'address', 'menu'];

      this.category.valueChanges.subscribe(
        category => {
          this.filterValues.category = category;
          this.dataSource.data = this.dataSourceAux.data.filter(
            (dat) => {
              let ok = false;
              for (let i = 0; i < this.filterValues.category.length && !ok; i++) {
                let category = this.filterValues.category[i];
                if (category == dat.category)
                  ok = true;
              }
              return ok;
            })
        }
      )

      this.address.valueChanges.subscribe(
        address => {
          this.filterValues.address = address;
          this.dataSource.data = this.dataSourceAux.data.filter(
            (dat) => {
              let ok = false;
              for (let i = 0; i < this.filterValues.address.length && !ok; i++) {
                let address = this.filterValues.address[i];
                if (address == dat.address)
                  ok = true;
              }
              return ok;
            })
        }
      )

      this.dataSource.paginator = this.paginator;
    }, err => {
      console.log("ERRor ", err)
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    this.msgSuscription;
    this.typeMsgSuscription
  }
}

export interface PeriodicElement {
  name: string;
  category: string;
  privacity: string;
  date: string;
  address: string;
}

interface Filter {
  value: string;
  viewValue: string;
}