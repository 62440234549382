import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EventsService } from '../../../../../services/events.service'


@Component({
  selector: 'app-modal-cancel-proposal',
  templateUrl: './modal-cancel-proposal.component.html',
  styleUrls: ['./modal-cancel-proposal.component.scss']
})
export class ModalCancelProposalComponent implements OnInit {

  reservation;
  schedule;
  // load=false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private EventsService: EventsService) { }

  ngOnInit(): void {
    console.log("data on cancel proposal:", this.data)
    console.log("data on cancel proposal:", this.data.reservation.reservation_id)

    // this.EventsService.get_admin_dataToCancelReservation(3).subscribe((res:any) => {
    //   console.log("get_admin_dataToCancelReservation", res);
    //   this.reservation=res.reservation_data[0];
    //   this.schedule=res.schedules;
    //   this.load=true;
    //   console.log("reservation: ", this.reservation)
    //   console.log("schedule: ", this.schedule)

    // }, err => {
    //   console.log("ERRor get_admin_dataToCancelReservation", err);
    // });
  }

}
