import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { CardService } from "../../../../../services/card.service";

@Component({
  selector: 'app-table-method-payment',
  templateUrl: './table-method-payment.component.html',
  styleUrls: ['./table-method-payment.component.scss']
})
export class TableMethodPaymentComponent implements OnInit {
  displayedColumns: string[];
  dataSource=[];
  show=false
  userData
  @Input() allDataCard;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @Output() data= new EventEmitter()
  constructor(
    private CardService:CardService
  ) { }

  ngOnInit(): void {
   
    // this.dataSource = new MatTableDataSource<ELEMENT_DATA>();
    // this.dataSource.paginator = this.paginator;
    this.show=false

    this.userData=JSON.parse(localStorage.getItem('userData'))
    let ELEMENT_DATA=[]
    this.CardService.get_cards(this.userData.id).subscribe(
      resp=>{
        console.log(resp)
        if(resp.message_return.ERROR==false){
          this.show=true
          resp.cards.filter(re=>{
            let x=re.card_number==null?re.email:"*********"+re.card_number
            let y=re.card_name==null?"":re.card_name
            console.log(x,y)
            ELEMENT_DATA.push({
              proveedor:re.provider=="MasterCard"?"https://upload.wikimedia.org/wikipedia/commons/b/b7/MasterCard_Logo.svg":"/assets/images/paypal.png",
              datos:[
                {
                  x:x,
                  y:y,
                  z:re.expiration_date==null?"":re.expiration_date,
                  cvc:re.CVC==null?"":"***"
                }
              ],
              pais:[{
                  x:re.country==null?"":re.country,
                  y:re.zip_code
                }
              ],
              accion:'close',
            })
          })
          this.displayedColumns = ['proveedor', 'datos', 'pais', 'accion'];

          this.dataSource=ELEMENT_DATA
          
        }

          
      }
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    
    // this.displayedColumns = ['proveedor', 'datos', 'pais', 'accion'];
  }

}
// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   symbol: string;
// }

// const ELEMENT_DATA: PeriodicElement[] = [
//   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
//   {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
//   {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
//   {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
//   {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
//   {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
//   {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
//   {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
//   {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
//   {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
//   {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
//   {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
//   {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
//   {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
//   {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
//   {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
//   {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
//   {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
//   {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
//   {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
// ];