<form [formGroup]="form" *ngIf="loadPage">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <app-message
      *ngIf="msg != null"
      [msg]="msg"
      [typeMsg]="typeMsg"
    ></app-message>
  </div>

  <div fxLayout="row" style="margin-top: 13px" fxLayoutAlign="end center">
    <mat-icon class="icon-grey" style="color: black">folder</mat-icon>
    <div>
      <a class="raleway-14-b" href="">Panel/</a>
      <a class="raleway-14-b" href=""> Cursos/</a>
      <a class="raleway-14-b" href=""> Detalles</a>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="center stretch"
    fxLayoutGap="150px"
    style="margin-top: 20px"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
      <p class="raleway-23-g-bold">Edición del curso</p>

      <div fxLayout="column" fxLayoutAlign="start start">
        <mat-form-field style="width: 500px">
          <mat-label>Nombre de curso</mat-label>
          <input matInput formControlName="name" placeholder="Nombre curso" />
        </mat-form-field>
        <small *ngIf="validNameEvent" class="error" style="margin-top: -15px"
          >* Ingrese nombre de curso</small
        >
      </div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <mat-form-field style="width: 500px">
          <mat-label translate>ev-edit.category</mat-label>
          <mat-select formControlName="category_id">
            <mat-option *ngFor="let option of categories" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <small *ngIf="validCategory" class="error" style="margin-top: -15px"
          >* Ingrese curso</small
        >
      </div>

      <mat-form-field style="width: 500px">
        <mat-label>Nombre de lugar</mat-label>
        <input matInput formControlName="place" />
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="35px" class="div-border-orange">
        <div
          fxLayout="row"
          fxLayoutAlign="start end"
          fxFlex="160px"
          fxLayoutGap="10px"
        >
          <div fxLayout="column" fxFlex="50">
            <mat-form-field style="width: 100%">
              <mat-label>Precio</mat-label>
              <input
                type="number"
                matInput
                formControlName="price"
                placeholder="23"
              />
            </mat-form-field>
            <small *ngIf="validPrice" class="error" style="margin-top: -15px"
              >* Ingrese precio</small
            >
          </div>
          <mat-form-field fxFlex="50">
            <mat-label class="oxigen-14">Moneda</mat-label>
            <mat-select formControlName="currency_id">
              <mat-option *ngFor="let item of currency" [value]="item.id">{{
                item.code
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="end start" style="width: 150px">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div fxLayout="column" fxLayoutAlign="end start" fxFlex>
              <mat-form-field style="width: 75px">
                <mat-label>{{ "ev-detail.room" | translate }}</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="availability"
                  placeholder="23"
                />
              </mat-form-field>

              <mat-error
                *ngIf="
                  this.form.get('availability').invalid &&
                  this.form.get('availability').touched &&
                  this.form.get('availability').errors['required']
                "
                >Requerido</mat-error
              >
              <mat-error
                *ngIf="
                  this.form.get('availability').invalid &&
                  this.form.get('availability').touched &&
                  this.form.get('availability').errors['max']
                "
                >Excede capacidad</mat-error
              >
            </div>
            <p>/</p>
            <div fxLayout="column" fxLayoutAlign="end start" fxFlex>
              <mat-form-field style="width: 75px">
                <mat-label>Capacidad</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="enrollment"
                  placeholder="23"
                  [disabled]="true"
                />
              </mat-form-field>
              <small
                *ngIf="validCapacity"
                class="error"
                style="margin-top: -15px"
                >* Ingrese capacidad</small
              >
            </div>
          </div>
        </div>
      </div>

      <div style="margin-top: 35px" style="width: 100%">
        <mat-form-field style="width: 500px">
          <mat-chip-list #chipList aria-label="Tag selection">
            <mat-chip
              *ngFor="let tag of tags"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(tag)"
            >
              {{ tag }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Tags"
              #tagInput
              [formControl]="tagCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)"
            />
          </mat-chip-list>
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selected($event)"
          >
            <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
              {{ tag }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="start end">
      <mat-spinner
        *ngIf="loadService"
        strokeWidth="10"
        [diameter]="24"
      ></mat-spinner>
      <div
        *ngIf="!loadService"
        fxLayout="row"
        style="width: 100%"
        fxLayoutAlign="end none"
        class="m-top"
        (click)="save()"
      >
        <mat-icon class="btn-icon pointer">edit</mat-icon>
        <button class="btn btn-border">GUARDAR</button>
      </div>
      <img
        *ngIf="events.main_image != null"
        [src]="src_img !== '' ? src_img : events.main_image"
        alt=""
        class="img"
        style="margin-top: 20px"
      />
      <img
        *ngIf="events.main_image == null"
        src="assets/images/image-default.png"
        alt=""
        class="img"
        style="margin-top: 20px"
      />
      <div class="guardarimg">
        <app-upload
          #upedit
          title="Editar imagen"
          (fileEvent)="receiveFileMain($event)"
          (srcImgEvent)="receiveSrcImgMain($event)"
          origin="one"
        ></app-upload>
      </div>
    </div>
  </div>
  <div fxLayout="column">
    <mat-tab-group mat-align-tabs="center" style="margin-top: 40px">
      <mat-tab label="Datos basicos">
        <div fxLayout="column" class="m-tab" fxLayoutGap="18px">
          <div
            fxLayout="row"
            style="width: 60%"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex>
              <mat-label class="label-input" translate>ev-edit.dateS</mat-label>
              <input
                matInput
                [matDatepicker]="dp1"
                formControlName="dateStart"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp1"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp1 disabled="true"></mat-datepicker>
            </mat-form-field>
            <p>-</p>
            <mat-form-field fxFlex>
              <mat-label class="label-input" translate>ev-edit.dateE</mat-label>
              <input
                matInput
                [matDatepicker]="dp2"
                formControlName="dateEnd"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp2"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp2 disabled="true"></mat-datepicker>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            style="width: 60%"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
          >
            <mat-form-field fxFlex>
              <mat-label>Nivel</mat-label>
              <mat-select formControlName="level_id">
                <mat-option [value]="1">Basico</mat-option>
                <mat-option [value]="2">Intermedio</mat-option>
                <mat-option [value]="3">Avanzado</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            style="width: 60%"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
          >
            <mat-form-field fxFlex>
              <mat-label>Edad</mat-label>
              <input
                type="number"
                matInput
                placeholder="23"
                formControlName="age"
              />
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            style="width: 60%"
            fxLayoutAlign="start center"
            fxLayoutGap="30px"
          >
            <!-- <mat-form-field fxFlex>
                            <mat-label>Cuantitativo</mat-label>
                            <mat-select formControlName="evaluate">
                                <mat-option [value]=0>NO</mat-option>
                                <mat-option [value]=1>SI</mat-option>
                            </mat-select>
                        </mat-form-field> -->

            <!-- <mat-form-field fxFlex>
                            <mat-label class="oxigen-14">Visibilidad</mat-label>
                            <mat-select formControlName="visible">
                                <mat-option [value]="0">Publico</mat-option>
                                <mat-option [value]="1">Privado</mat-option>
                            </mat-select>
                        </mat-form-field> -->
          </div>
          <div
            fxLayout="row"
            style="width: 60%"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
          >
            <mat-form-field fxFlex>
              <mat-label>Descripcion</mat-label>
              <textarea
                rows="4"
                matInput
                placeholder="description"
                formControlName="description"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{ 'ev-detail.content' | translate }}" class="mtab">
        <div fxLayout="column" class="m-tab">
          <mat-form-field fxFlex="60">
            <mat-label>Contenido</mat-label>
            <textarea
              rows="7"
              matInput
              placeholder=""
              formControlName="content"
            ></textarea>
          </mat-form-field>

          <mat-form-field fxFlex="60">
            <mat-label>Requerimientos</mat-label>
            <textarea
              rows="3"
              matInput
              placeholder=""
              formControlName="requirements"
            ></textarea>
          </mat-form-field>
          <mat-form-field fxFlex="60">
            <mat-label>Aprenderas</mat-label>
            <textarea
              rows="3"
              matInput
              placeholder=""
              formControlName="learn"
            ></textarea>
          </mat-form-field>
          <mat-form-field fxFlex="60">
            <mat-label>Dirigido hacia</mat-label>
            <textarea
              rows="1"
              matInput
              placeholder=""
              formControlName="aimed_to"
            ></textarea>
          </mat-form-field>
        </div>
      </mat-tab>

      <mat-tab label="Horario" class="mtab">
        <ng-template matTabContent>
          <div class="m-tab">
            <app-calendar
              *ngIf="form.controls.place.value != null"
              [idCourse]="id"
              [modify]="false"
            >
            </app-calendar>

            <p *ngIf="form.controls.place.value == null">Sin definir</p>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Profesores" class="mtab">
        <div class="m-tab">
          <div
            *ngIf="events.course_id != null"
            fxLayout="row"
            style="width: 100%"
            fxLayoutAlign="end none"
            class="m-top"
            (click)="redirectToTeacherList()"
          >
            <mat-icon class="btn-icon pointer">person_add</mat-icon>
            <button class="btn btn-border">AGREGAR PROFESOR</button>
          </div>
          <div
            *ngFor="let item of teacher"
            fxLayout="row"
            style="
              border-bottom: 1px solid black;
              padding-bottom: 20px;
              padding-top: 20px;
            "
          >
            <img
              [src]="item.avatar"
              alt=""
              class="avatar"
              (error)="errorHandler($event)"
            />
            <div fxLayout="column" style="margin-left: 20px">
              <p class="teacher">{{ item.name }} {{ item.last_name }}</p>
              <p class="skill" style="margin-top: 15px">{{ item.title }}</p>
              <mat-chip-list style="margin-top: 10px">
                <div *ngFor="let tags of item.skill">
                  <mat-chip
                    ><b>{{ tags }}</b></mat-chip
                  >
                </div>
              </mat-chip-list>
            </div>
          </div>
          <p *ngIf="teacher == null">Sin asignar</p>
        </div>
      </mat-tab>
      <mat-tab
        label="{{ 'ev-detail.image' | translate }}/{{
          'ev-detail.video' | translate
        }}"
        class="mtab"
      >
        <ng-template matTabContent>
          <div fxLayout="row" fxFlex fxLayoutGap="40px" class="m-tab">
            <mat-card fxLayout="column" fxFlex="40" style="height: 500px">
              <app-video-youtube [SendUrl]="urls"></app-video-youtube>
              <div
                fxLayout="column"
                fxLayoutAlign="end stretch"
                fxFlex
                fxLayoutGap="18px"
              >
                <p class="title-card">Video</p>
                <mat-form-field>
                  <mat-label class="label-input">{{
                    "place.urlYoutube" | translate
                  }}</mat-label>
                  <textarea
                    (keyup)="onKey($event)"
                    matInput
                    placeholder="https://www.youtube.com/watch?v=ozXIAg7zp9I"
                    formControlName="url"
                  ></textarea>
                </mat-form-field>
              </div>
            </mat-card>
            <mat-card fxFlex style="padding: 24px; height: 500px">
              <!-- <app-carousel [arrayImage]='images' [edit]="true"></app-carousel> -->
              <!-- ====={{images}}=====
                            <button (click)="prueba()">zzz</button>
                            <app-carousel *ngIf="images==null" [edit]="true"></app-carousel>
                            <app-carousel *ngIf="images!=null" (sendImages)="getImages(events)" [arrayImage]='images' [edit]="true"></app-carousel> -->

              <div (click)="flagIma()">
                <!-- [arrayImage]='images' -->
                <app-carousel
                  origin="gallery"
                  #ca3
                  *ngIf="image == null"
                  [edit]="true"
                ></app-carousel>
                <app-carousel
                  #ca4
                  origin="gallery"
                  *ngIf="image != null"
                  [arrayImage]="image"
                  [edit]="true"
                ></app-carousel>
              </div>
            </mat-card>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="Alumnos" class="mtab" *ngIf="jobs">
        <div class="m-tab">
          <table mat-table [dataSource]="dataSource" style="width: 100%">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef class="h-table">Name</th>
              <td mat-cell *matCellDef="let element" class="text-center">
                {{ element.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="identity" class="text-center">
              <th mat-header-cell *matHeaderCellDef class="h-table">
                Identity
              </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                {{ element.identity }}
              </td>
            </ng-container>

            <ng-container matColumnDef="age" class="text-center">
              <th mat-header-cell *matHeaderCellDef class="h-table">Age</th>
              <td mat-cell *matCellDef="let element" class="text-center">
                {{ element.age }}
              </td>
            </ng-container>

            <ng-container matColumnDef="email" class="text-center">
              <th mat-header-cell *matHeaderCellDef class="h-table">Email</th>
              <td mat-cell *matCellDef="let element" class="text-center">
                {{ element.email }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </mat-tab>

      <mat-tab label="Calificaciones" class="mtab" *ngIf="jobs">
        <div class="m-tab"></div>
      </mat-tab>
    </mat-tab-group>
  </div>
</form>
