<mat-card>
    <mat-tab-group>
        <mat-tab label="{{'payments.payment_method' | translate}}">
            <app-method-payment></app-method-payment>
        </mat-tab>
        <mat-tab label="{{'payments.addresses' | translate}}">
            <app-address></app-address>
        </mat-tab>
        <mat-tab label="{{'payments.transiction' | translate}}">
            <app-my-payments></app-my-payments>
        </mat-tab>
    </mat-tab-group>
</mat-card>
