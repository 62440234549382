<form [formGroup]="form">
    <mat-card>

        <div fxLayout="row" fxLayoutAlign="center stretch" style="padding: 20px">
            <div fxLayout="column" style="padding: 100px;" fxLayoutAlign="start center" fxLayoutGap="20px" fxFlex="40">

                <img *ngIf="src_img=='' "
                    src="https://cdn.discordapp.com/attachments/710104693426356305/730420117812281394/unknown_1.png"
                    alt="" class="img">
                <img *ngIf="src_img!='' " [src]="src_img" alt="" class="img">

                <app-upload (fileEvent)="receiveFile($event)" (srcImgEvent)="receiveSrcImg($event)"></app-upload>
            </div>
            <div style="border-left: 4px solid #D7D7D7; padding-left: 45px;" fxFlex="60">
                <p class="title">Datos basicos</p>
                <mat-tab-group style="margin-top: 50px;">
                    <mat-tab class="relaway-14-blue" label="Perfil">
                        <div fxLayout="column" class="m-tab" style="height: 410px;">
                            <div fxLayout="row" fxFlex fxLayoutGap="60px">
                                <mat-form-field fxFlex>
                                    <mat-label class="oxygen-14">Nombre</mat-label>
                                    <input matInput placeholder="Nombre" formControlName="name">
                                </mat-form-field>

                                <mat-form-field fxFlex>
                                    <mat-label class="oxygen-14">Apellido</mat-label>
                                    <input matInput placeholder="Apellido" formControlName="lastName">
                                </mat-form-field>
                            </div>

                            <mat-form-field fxFlex>
                                <mat-label class="oxygen-14">Email</mat-label>
                                <input matInput disabled [value]=user.email>
                            </mat-form-field>

                            <div fxLayout="row" fxLayoutGap="60px">
                                <mat-form-field fxFlex>
                                    <mat-label class="oxygen-14">Telefono</mat-label>
                                    <input type="tel" matInput placeholder="555-555-1234" formControlName="phone">
                                </mat-form-field>

                                <mat-form-field fxFlex>
                                    <mat-label class="oxygen-14">Numero de telefono alterno</mat-label>
                                    <input type="tel" matInput placeholder="555-555-1234" formControlName="phone2">
                                </mat-form-field>
                            </div>
                            <div fxFlex style="border: 1px #FE862A solid; padding: 35px 39px; margin-top: 56px;">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <p class="verify">Verifica tu cuenta</p>
                                    <div fxLayout="row" fxLayoutAlign="end center"
                                        routerLink="/dashboard/events/create">
                                        <mat-icon class="btn-icon pointer">check</mat-icon>
                                        <button class="btn btn-border">VERFICAR</button>
                                    </div>
                                </div>
                                <p style="margin-top: 20px;" class="paragraph">Para usar algunas de las características
                                    es
                                    necesario
                                    verificar la dirección de correo electrónico de tu cuenta.</p>

                            </div>
                            <!-- <div fxLayoutAlign="center center">
                            <button mat-button style="margin-top: 65px;" class="orange-btn">GUARDAR</button>
                        </div> -->
                        </div>
                    </mat-tab>




                    <mat-tab class="relaway-14-blue" label="Direccion">
                        <div class="m-tab" fxLayout="column" fxLayoutGap="40px" style="height: 410px;">
                            <div fxLayoutGap="68px">
                                <mat-form-field fxFlex>
                                    <mat-label class="oxygen-14">País</mat-label>
                                    <mat-select (selectionChange)="getState()" formControlName="country">
                                        <!-- <mat-option *ngFor="let option of categories" [value]="option.id"> {{option.name}} -->
                                        <mat-option *ngFor="let item of country" [value]="item.id">{{item.nicename}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field fxFlex="50" *ngIf="!disableItem[0]">
                                    <mat-label class="oxygen-14">Estado</mat-label>
                                    <mat-select [disabled]="disableItem[0]" (selectionChange)="getCity()"
                                        formControlName="state">
                                        <mat-option *ngFor="let item of state" [value]="item.id">{{item.state_name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div fxLayoutGap="68px">
                                <mat-form-field fxFlex="50" *ngIf="!disableItem[1]">
                                    <mat-label class="oxygen-14">Ciudad</mat-label>
                                    <mat-select [disabled]="disableItem[1]" (selectionChange)="getCounty()"
                                        formControlName="city">
                                        <mat-option *ngFor="let item of city" [value]="item.id">{{item.city}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field fxFlex="50" *ngIf="!disableItem[2]">
                                    <mat-label class="oxygen-14">Municipio</mat-label>
                                    <mat-select [disabled]="disableItem[2]" (selectionChange)="getParish()"
                                        formControlName="county">
                                        <mat-option *ngFor="let item of county" [value]="item.id">{{item.municipality}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div fxLayoutGap="68px">
                                <mat-form-field fxFlex="50" *ngIf="!disableItem[3]">
                                    <mat-label class="oxygen-14">Sector</mat-label>
                                    <mat-select [disabled]="disableItem[3]" formControlName="parish">
                                        <mat-option *ngFor="let item of parish" [value]="item.id">{{item.parish}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div fxFlex="50"></div>
                            </div>


                            <mat-form-field>
                                <mat-label class="oxygen-14">Direccion</mat-label>
                                <textarea rows="5" matInput placeholder="av 19 de abril..."
                                    formControlName="address"></textarea>
                            </mat-form-field>
                        </div>

                    </mat-tab>




                    <mat-tab class="relaway-14-blue" label="Biografía" disabled>
                        <div class="m-tab"></div>
                    </mat-tab>
                </mat-tab-group>
                <div fxLayoutAlign="center center">
                    <button mat-button (click)="save()" style="margin-top: 20px;" class="orange-btn">GUARDAR</button>
                </div>
            </div>

        </div>
    </mat-card>
</form>