import { data } from 'jquery';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SendProposalComponent } from '../../events/create/step-teacher/send-proposal/send-proposal.component';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Params, ActivatedRoute, RouterLink, Router } from '@angular/router';
import { EventsService } from '../../../../services/events.service';
import { GeneralService } from '../../../../services/general.service';
import { ProposalService } from '../../../../services/proposal.service';

@Component({
  selector: 'app-list-teacher-course',
  templateUrl: './list-teacher-course.component.html',
  styleUrls: ['./list-teacher-course.component.scss'],
})
export class ListTeacherCourseComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  hide = true;
  dataSource;
  displayedColumns: string[] = ['image', 'body', 'invite'];
  teacher = [];
  idProposal;
  user;
  idcureven;

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  countrySelect = 0;
  rankSelect = 0;
  skillSelect = '';

  country = [];
  skills = [];

  skillsTeacher;
  flagOwnProposal = false;
  rank = [
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' },
  ];
  proposal = null;

  constructor(
    private EventsService: EventsService,
    private GeneralService: GeneralService,
    public dialog: MatDialog,
    private ProposalService: ProposalService,
    private route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('userData'));
    this.route.params.subscribe((params: Params) => {
      this.idProposal = params.id;
      console.log('---page:id', this.idProposal);
    });
    this.ownProposal();

    this.msgSuscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });

    this.GeneralService.get_countries().subscribe(
      (res) => {
        console.log('res teacher-get_countries:', res);
        res.countries.forEach((element) => {
          this.country.push({
            name: element.nicename,
            id: element.id,
          });
        });
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
    this.EventsService.get_tags().subscribe(
      (res) => {
        console.log('res:teacher get_tags:', res);
        this.skills = res.tags;
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
    this.ProposalService.get_proposal_course_event(this.idProposal).subscribe(
      (data) => {
        this.idcureven = data.id_event_course;

      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  consult() {
    let data = [];
    data.push({
      state_id: 71, // this.user.country_id  (71 es el q segun tienen mas teachers) el valor a setear es this.user.country_id para que quede correcto
    });
    console.log('data: servicio', data);
    this.EventsService.post_teacher_search(data).subscribe(
      (res) => {
        console.log('res post_teacher_search:', res);
        this.teacher = res.result;
        this.teacher.forEach((element) => {
          element.skills =
          element.skills = element.skills?.map((t)=>t.name)
          element.Ubication =
            element.Ubication != null ? element.Ubication.split(',') : '';

          console.log(
            this.proposal.teachers.filter((t) => t.user_id === element.UserId)
          );

          element.invited =
            this.proposal.teachers.filter((t) => t.user_id === element.UserId)
              .length > 0;
        });
        console.log(this.proposal);
        console.log(this.teacher);

        console.log('teacher:::', this.teacher);
        this.teacher.filter((resp) => {
          resp.hidden = false;
        });
        this.dataSource = new MatTableDataSource(this.teacher);

        console.log('teacher:', this.teacher);
        this.dataSource.paginator = this.paginator;
        // console.log("datasource:",this.dataSource);
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
  }

  onFilter($event) {
    let data = [];
    data.push({
      skill: this.skillSelect,
      city_id: this.rankSelect,
      country: this.countrySelect,
    });
    console.log('data:', data);
    this.EventsService.post_teacher_search(data).subscribe(
      (res) => {
        console.log('res post_teacher_search-onfilter:', res);
        this.teacher = res.result;
        this.teacher.filter((resp) => {
          resp.hidden = false;
        });
        this.teacher.forEach((element) => {
          element.skills =
            element.skills != null ? element.skills.split(',') : '';
        });
        console.log('teacher:::', this.teacher);
        this.dataSource = new MatTableDataSource(this.teacher);
        this.dataSource.paginator = this.paginator;
        console.log('datasource-onfiletr:', this.dataSource);
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
  }

  openDialogSendProposal(element) {
    if (!element.invited) {
      console.log(`DATA ELEMENT = `, element);
      localStorage.setItem('teacherProposal', JSON.stringify(element));
      const dialogRef = this.dialog.open(SendProposalComponent, {
        height: 'auto',
        width: 'auto',
        data: { element },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed');
        if (result) {
          console.log('resultado de dialogo:', result);
          this.teacher.filter((resp) => {
            if (resp.UserId == result) {
              resp.invited = true;
              this.inviteTeacher();
            }
          });
        }
      });
    }
  }

  inviteTeacher() {
    let data = {
      proposal_id: this.idProposal,
      users: [
        {
          user_id: JSON.parse(localStorage.getItem('teacherProposal')).UserId,
        },
      ],
    };
    console.log('data send teacher = ', data);
    this.ProposalService.post_admin_sendProposals(data).subscribe(
      (res) => {
        console.log('res post_admin_sendProposals:', res);
        this.GeneralService.typeMsg$.emit('success');
        this.GeneralService.msg$.emit('propuesta enviada');
      },
      (err) => {
        console.log('ERRor ', err);
        this.GeneralService.typeMsg$.emit('error');
        this.GeneralService.msg$.emit(err.name);
      }
    );
  }

  ownProposal() {
    this.ProposalService.get_admin_userProposals(this.user.id).subscribe(
      (res) => {
        this.proposal = res.proposal.find(
          (p) => p.proposal_id == this.idProposal
        );
        console.log('res get_admin_userProposals:', res);
        res.proposal.forEach((element) => {
          if (element.proposal_id == this.idProposal) {
            this.flagOwnProposal = true;
          }
        });
        if (this.flagOwnProposal == false) {
          console.log('se va de la pagina');
          this._router.navigateByUrl('/dashboard/');
        }
        this.consult();
      },
      (err) => {
        console.log('ERRor get_admin_userProposals', err);
        this.GeneralService.typeMsg$.emit('error');
        this.GeneralService.msg$.emit(err.name);
      }
    );
  }
  onImgError(event) {
    event.target.src = './assets/images/person-default.svg';
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSuscription;
    this.typeMsgSuscription;
  }
}
