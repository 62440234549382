import { Component, OnInit,Input,SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
	url:string = '';
	urls:string = '';
  @Input() SendUrl: string = "";
  @Input() data;
  flagImage = false;
  image;

  constructor(private _sanitizer: DomSanitizer)
  {

  }

  ngOnInit(): void {
    console.log(this.data)
    this.image=this.data.images;
  }
  onKey($event){
  	this.urls = $event.target.value;
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.data.currentValue,"desc")
    if(changes.data.currentValue!=null){
      this.url=changes.data.currentValue?.result?.youtube_url;
    }
  }

  flagIma() {
    console.log('activa flagImage');
    this.flagImage = true;
  }


}
