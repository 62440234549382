<div style="padding: 15px 35px;">
    <div fxLayout="column" fxLayoutAlign="center stretch">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <p class="raleway-23-bold">{{"create-question.create-question" | translate}}</p>
            <button mat-dialog-close class="btn-close">
                <mat-icon aria-hidden="false">clear</mat-icon>
            </button>
        </div>
        <mat-form-field style="margin-top: 45px; width: 500px;">
            <mat-label class="oxigen-14">{{"create-question.write-question" | translate}}</mat-label>
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5" [(ngModel)]="data.question"></textarea>
        </mat-form-field>

        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end end" style="margin-top: 45px;">
            <div fxLayoutAlign="center center">
                <!-- <button class="btn-orange btn">ACEPTAR</button> -->
                <button [mat-dialog-close]="data.question" cdkFocusInitial
                    class="btn-orange btn">{{"create-question.accept" | translate}}</button>
            </div>
            <div  mat-dialog-close class="btn-border pointer" fxLayoutAlign="center center">
                <p class="font">{{"create-question.skip" | translate}}</p>
            </div>
        </div>


    </div>
</div>