<section
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  fxLayoutGap="50px"
>
  <div fxLayouts="row" fxLayoutAling="space-between center" fxLayoutGap="38px">
    <div class="panel">
      <mat-card fxFlex="100%" class="welcome-card" fxLayoutGap="10px">
        <div class="titulo">
          <mat-card-title translate>dashboard.panel</mat-card-title>
        </div>

        <div>
          <p>
            {{ "dashboard.hi" | translate }},
            <span class="user-name">{{ user.name }}</span>
          </p>
          <p>
            <b>{{ "dashboard.last-access" | translate }}: </b>{{ date }}
          </p>
        </div>
      </mat-card>
    </div>


  </div>
  <div fxLayouts="row" fxLayoutAling="center center" fxLayoutGap="38px">
    <mat-card fxFlex style="justify-content: center">
      <mat-card-content
        class="card-dashboard-content"
        fxLayout="column"
        fxLayoutAlign="space-between center"
        fxLayoutGap="7px"
      >
        <p class="card-number">{{ info?.places.length }}</p>
        <p>Lugares</p>
        <a routerLink="/dashboard/places" mat-button class="btn-orange">{{
          "dashboard.view-all" | translate
        }}</a>
      </mat-card-content>
    </mat-card>
    <mat-card fxFlex style="justify-content: center">
      <mat-card-content
        class="card-dashboard-content"
        fxLayout="column"
        fxLayoutAlign="space-between center"
        fxLayoutGap="7px"
      >
        <p class="card-number">{{ info?.courses.length }}</p>
        <p>Cursos</p>
        <a routerLink="/dashboard/courses" mat-button class="btn-orange">{{
          "dashboard.view-all" | translate
        }}</a>
      </mat-card-content>
    </mat-card>
    <mat-card fxFlex style="justify-content: center">
      <mat-card-content
        class="card-dashboard-content"
        fxLayout="column"
        fxLayoutAlign="space-between center"
        fxLayoutGap="7px"
      >
        <p class="card-number">{{ info?.events.length }}</p>
        <p>Eventos</p>
        <a routerLink="/dashboard/events" mat-button class="btn-orange">{{
          "dashboard.view-all" | translate
        }}</a>
      </mat-card-content>
    </mat-card>
    <mat-card fxFlex style="justify-content: center">
      <mat-card-content
        class="card-dashboard-content"
        fxLayout="column"
        fxLayoutAlign="space-between center"
        fxLayoutGap="7px"
      >
        <p class="card-number">{{ info?.teachers.length }}</p>
        <p>Instructores</p>
        <a routerLink="/dashboard/teachers" mat-button class="btn-orange">{{
          "dashboard.view-all" | translate
        }}</a>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-card class="items-card">
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Lugares">
        <table class="tablecurso car">
          <thead>
            <th>Nombre</th>
            <th>Categoria</th>
            <th class="ubi">Ubicacion</th>
          </thead>
          <tbody style="height: 0">
            <ng-container *ngFor="let place of info?.places">
              <tr>
                <td class="name">
                  {{ place.Name }}
                </td>
                <td class="category">
                  <span>
                    {{ place.Category }}
                  </span>
                </td>

                <td class="ubicacion">
                  {{ place.Ubication }}
                </td>
              </tr>
            </ng-container>

            <tr *ngIf="info?.places.length === 0">
              <td class="mensaje" colspan="4">No se encontró resultados</td>
            </tr>
          </tbody>
        </table></mat-tab
      >
      <mat-tab label="Cursos">
        <table class="tablecurso car">
          <thead>
            <th>Nombre</th>
            <th>Categoria</th>
            <th>Nivel</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let course of info?.courses">
              <tr>
                <td class="name">
                  {{ course.courseName }}
                </td>
                <td class="category">
                  <span>
                    {{ course.category }}
                  </span>
                </td>

                <td class="ubicacion">
                  {{ course.level }}
                </td>
              </tr>
            </ng-container>

            <tr *ngIf="info?.courses.length === 0">
              <td class="mensaje" colspan="4">No se encontró resultados</td>
            </tr>
          </tbody>
        </table>
      </mat-tab>

      <mat-tab label="Eventos">
        <table class="tablecurso car">
          <thead>
            <th>Nombre</th>
            <th>Categoria</th>
            <th>Descriptcion</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let event of info?.events">
              <tr>
                <td class="name">
                  {{ event.name }}
                </td>
                <td class="category">
                  <span>
                    {{ event.category }}
                  </span>
                </td>

                <td class="ubicacion">
                  {{ event.description }}
                </td>
              </tr>
            </ng-container>

            <tr *ngIf="info?.events.length === 0">
              <td class="mensaje" colspan="4">No se encontró resultados</td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
      <mat-tab label="Instructores">
        <table class="tablecurso car">
          <thead>
            <th>Nombre</th>
            <th>Profesion</th>
            <th class="ubi">Ubicacion</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let profe of info?.teachers">
              <tr>
                <td class="name">{{ profe.name }} {{ profe.last_name }}</td>
                <td class="Profesion">
                  <span>
                    {{ profe.title }}
                  </span>
                </td>

                <td class="Ubicacion">
                  {{ profe.Ubication }}
                </td>
              </tr>
            </ng-container>

            <tr *ngIf="info?.teachers.length === 0">
              <td class="mensaje" colspan="4">No se encontró resultados</td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</section>
