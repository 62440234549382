<div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">
	<mat-card fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex="60" fxLayoutGap="20px" class="card">
		<div div fxLayout="row" fxLayoutAlign="space-between center">
			<p class="title-card">{{'place.description' | translate}}</p>
			<p class="bg-orange text-required">* Campos requeridos</p>
		</div>

		<mat-form-field class="example-full-width">
			<mat-label>{{"place.titteDescription" | translate}}</mat-label>
			<textarea name="description" [(ngModel)]="description" matInput placeholder=""
				[formControl]="descr" rows="5"></textarea>
		</mat-form-field>

		<div fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="36px">
			<div fxLayout="row" fxLayoutGap="40px" fxFlex>

				<mat-slide-toggle [(ngModel)]="wifi">Wifi?</mat-slide-toggle>
				<mat-slide-toggle [(ngModel)]="availability">{{'place.rent'  |  translate}}</mat-slide-toggle>


			</div>

			<!-- <div fxLayout="row" fxflex fxLayoutGap="40px">
				<mat-form-field fxFlex="50" *ngIf="availability==true">
					<mat-label class="label-input" translate>place.min_time</mat-label>
					<input [(ngModel)]="min_time" matInput placeholder="Numero de horas">
				</mat-form-field>
				<div fxFlex="50"></div>
			</div> -->

			<div fxLayout="row" fxLayoutGap="40px" fxFlex *ngIf="availability==true">
				<mat-form-field fxFlex="50">
					<mat-label class="label-input" translate>place.base_price</mat-label>
					<input [(ngModel)]="base_price" matInput placeholder="">
				</mat-form-field>

				<!-- <mat-form-field fxFlex="50">
					<mat-label class="label-input" translate>place.currency</mat-label>
					<mat-select name="currency" [(ngModel)]="currency" placeholder="">
						<mat-option value="VES"> Bs </mat-option>
						<mat-option value="USD"> USD </mat-option>
						<mat-option value="COP"> COP </mat-option>
					</mat-select>
				</mat-form-field> -->
				<mat-form-field fxFlex>
					<mat-label class="label-input" translate>place.currency</mat-label>
					<mat-select [(ngModel)]="currency">
						<mat-option *ngFor="let item of currencyList" [value]="item.id">{{item.title | titlecase}}
							({{item.code}})</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<div fxLayout="row" fxflex>
			<mat-form-field fxFlex="50">
				<mat-label class="label-input">Capacidad</mat-label>
				<input [(ngModel)]="capacity" type="number" matInput placeholder="capacidad de personas" required>
			</mat-form-field>
		</div>


		<div fxLayout="row" fxLayoutAlign="space-between center">
			<p class="title-card">Horarios</p>
			<div *ngIf="schedule==null" fxLayout="row" fxLayoutAlign="end center" (click)="openDialog()">
				<mat-icon class="btn-icon pointer">event_note</mat-icon>
				<button class="btn btn-border color-orange">AGREGAR</button>
			</div>
		</div>
		<p *ngIf="schedule==null; else hours" style="margin-top: 25px;">No hay información disponible</p>


		<ng-template #hours>
			<div *ngIf="schedule.value.typeSchedule==1" fxLayout="column">
				<p class="raleway-14-bold">Siempre disponible</p>
			</div>

			<div *ngIf="schedule.value.typeSchedule==2" fxLayout="column">
				<p class="raleway-14-bold">Trabaja todos los dias desde las {{schedule.value.allDayStart}} hasta las {{schedule.value.allDayEnd}}</p>
			</div>

			<div *ngIf="schedule.value.typeSchedule==3" fxLayout="column">
				<div fxLayout="row" style="border-bottom: solid 1px black; padding-bottom: 20px;">
					<p class="raleway-14-bold" fxFlex="50" style="padding-left: 10px;">Dia</p>
					<p class="raleway-14-bold" fxFlex="25">Hora inicio</p>
					<p class="raleway-14-bold" fxFlex="25">Hora fin</p>
				</div>
				<div fxLayout="column" fxFlex *ngFor="let item of schedule.value.schedule; let dayIndex=index" >
					<div fxLayout="row" fxFlex *ngIf="arraybool[dayIndex]==true" style="border-bottom: solid 1px black; padding-top:30px; padding-bottom: 20px;">
						<div fxFlex="50" fxLayoutAlign="start center">
							<p class="raleway-14-bold" style="padding-left: 10px;"> {{'week.' + week[dayIndex] | translate}} </p>
						</div>
						<div fxFlex="25" fxLayout="column" fxLayoutGap="7px">
							<p class="oxigen-14" *ngFor="let hour of item.hour">{{hour.start}}</p>
						</div>
						<div fxFlex="25" fxLayout="column" fxLayoutGap="7px">
							<p class="oxigen-14" *ngFor="let hour of item.hour">{{hour.end}}</p>
						</div>
					</div>
				</div>
			</div>


		</ng-template>

	</mat-card>



	<mat-card fxFlex="35" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="18px" class="card">
		<!-- *ngIf="hiddenUpload" -->
    <div >
			<img *ngIf="src_img!=''" [src]="src_img" style="width:100%; border-radius: 5px;">
			<mat-card *ngIf="src_img==''|| src_img==null" fxLayoutAlign="center center" style="min-height: 210px;">
				<span class="material-icons icon-large">perm_media</span>
			</mat-card>
			<app-upload  (fileEvent)="receiveFile($event)" (srcImgEvent)="receiveSrcImg($event)"></app-upload>
		</div>
		<mat-form-field>
			<mat-label class="label-input" translate>place.facebook</mat-label>
			<input type="url" name="facebook" [(ngModel)]="facebook" #fb (keyup)="validateUrl(fb)" matInput placeholder="">


		</mat-form-field>
    <div class="error" *ngIf="fb?.error" > * Url invalida</div>

		<mat-form-field>
			<mat-label class="label-input" translate>place.twitter</mat-label>
			<input name="twitter" [(ngModel)]="twitter" #tw (keyup)="validateUrl(tw)" matInput placeholder="">
		</mat-form-field>
    <div class="error" *ngIf="tw?.error" > * Url invalida</div>
		<mat-form-field>
			<mat-label class="label-input" translate>place.instagram</mat-label>
			<input name="instagram" [(ngModel)]="instagram" #inst (keyup)="validateUrl(inst)" matInput placeholder="">
		</mat-form-field>
    <div class="error" *ngIf="inst?.error" > * Url invalida</div>

		<mat-form-field>
			<mat-label class="label-input" translate>place.youtube</mat-label>
			<input name="youtube" [(ngModel)]="youtube"  #yout (keyup)="validateUrl(yout)" matInput placeholder="">
		</mat-form-field>
    <div class="error" *ngIf="yout?.error" > * Url invalida</div>
	</mat-card>
</div>
