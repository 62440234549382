import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { TeacherService } from 'src/app/services/teacher.service';
import { environment } from 'src/environments/environment';
import { ModalContactMessageComponent } from '../modal-contact-message/modal-contact-message.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'details-teachers',
  templateUrl: './details-teachers.component.html',
  styleUrls: ['./details-teachers.component.scss']
})
export class DetailsTeacherComponent implements OnInit {
perfil;
id;
baseUrl = environment.storage;
coursesData;
teacherData;
eventsData;
flag;
  constructor( private teacherService: TeacherService,
    private router: ActivatedRoute,
    private route: Router, 
    public dialog: MatDialog
    ) {
      let userData = localStorage.getItem("userData");
      if (userData !== "" && userData != null) {
        this.flag = true;
      } else {
        this.flag = false;
      }

    }

  ngOnInit(): void {
    
    this.id = this.router.snapshot.params.id;

console.log( this.id = this.router.snapshot.params.id);

   this.teacherService.getTeacher(this.id).subscribe((info)=>{

this.teacherData = info.teacher;
this.coursesData = info.courses;    
this.eventsData = info.events;  
console.log("data del cucho ")
console.log(this.teacherData);
console.log(this.coursesData);
console.log(this.eventsData);
   })

  }

  rejectproposalreceived() {
    if(  this.flag === false ){
      localStorage.setItem("previusRoute", this.route.url)
      this.route.navigate(['/auth/login']);
    }
    else{
      const dialogRef = this.dialog.open(ModalContactMessageComponent, {
        width: '1000px',
        data : this.teacherData
        
      
  
      });
    }

  }
  onImgError(event){
    event.target.src = './../../../../assets/images/person-default.svg'
   //Do other stuff with the event.target
   }

}
