import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
  SwiperScrollbarInterface,
  SwiperPaginationInterface,
  SwiperModule,
  SWIPER_CONFIG
} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-slide-course-details',
  templateUrl: './slide-course-details.component.html',
  styleUrls: ['./slide-course-details.component.scss']
})

export class SlideCourseDetailsComponent implements OnInit {
  /*public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    keyboard: true,
    autoplay:false,
    spaceBetween:4,
    navigation: true,
    speed:1000,
    setWrapperSize:true,
    breakpoints: {
      // when window width is <= 1024px
      1024: {
        slidesPerView: 1,
        initialSlide:0,
      }
    }
    // mousewheel: true,
    // scrollbar: false,
    // navigation: true,
  };*/
  sliders=[];
  @Input() images: string[]=[];
  show: boolean = false;
  public config_thumbs: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    observer: true,
    spaceBetween: 10,
    slideToClickedSlide: true,
    slidesPerView: 4.5,
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 20,
    simulateTouch: true,
    resistanceRatio: 0.6,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    watchOverflow: true,
    navigation: {
        nextEl: '.carousel__arrow--prev',
        prevEl: '.carousel__arrow--next'
    },
    breakpoints: {
        // when window width is <= 1024px
        1024: {
            slidesPerView: 5.5
        }
    }
  };
  public config_gallerySwiperConfigInterface: SwiperConfigInterface = {
      a11y: true,
      effect: 'slide',
      loop: true,
      initialSlide: 0,
      thumbs: {
          swiper: this.sliders
      },
      spaceBetween: 0,
      simulateTouch: true,
      preloadImages: false,
      observer: true,
      lazy: {
          loadPrevNext: true,
          loadOnTransitionStart: true,
      },
      zoom: {
          maxRatio: 5
      }
  };

  bigSliderConfig: SwiperConfigInterface = {
    slidesPerView: 1,
    pagination: true,
    grabCursor: true,
    observer: true,
    navigation: {
        nextEl: '.btn-Left',
        prevEl: '.btn-Right',
    },
    autoplay: {
            delay: 5000,
            disableOnInteraction: false,
    }
  };

  thumbsSliderConfig: SwiperConfigInterface = {
      slidesPerView: 4,
      pagination: false,
      observer: true,
      centeredSlides: true,
      slideToClickedSlide: true
  };

  public type: string = 'directive';
  public disabled: boolean = false;
  public config: SwiperConfigInterface = {};/*{
      //a11y: true,
      direction: 'horizontal',
      loop: true,
      loopedSlides: 1,
      initialSlide: 0,
      slidesPerView: 1,
      allowSlideNext: true,
      allowSlidePrev: true,
      mousewheel: true,
      scrollbar: false,
      navigation: true,
      keyboard: true,
      pagination: false,
      centeredSlides: true,
      spaceBetween: 0,
      autoplay: {
        delay: 10000,
        disableOnInteraction: false
      },
      observer: true,
      observeParents: true,
      observeSlideChildren: true
  };/*{
    a11y: true,
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: true,
    observer: true
  };*/

  private scrollbar: SwiperScrollbarInterface = {
      el: '.swiper-scrollbar',
      hide: false,
      draggable: true
  };

  private pagination: SwiperPaginationInterface = {
      el: '.swiper-pagination',
      clickable: true,
      hideOnClick: false
  };

  @ViewChild(SwiperComponent) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;

  constructor() { }

  ngOnInit(): void { this.sliders = this.images;

    this.config = {
      a11y: true,
      direction: 'horizontal',
      loop: true,
      loopedSlides: 1,
      initialSlide: 0,
      slidesPerView: 1,
      allowSlideNext: true,
      allowSlidePrev: true,
      mousewheel: true,
      scrollbar: false,
      navigation: true,
      keyboard: true,
      pagination: false,
      centeredSlides: true,
      spaceBetween: 0,
      autoplay: {
        delay: 10000,
        disableOnInteraction: false
      },
      observer: true,
      observeParents: true,
      observeSlideChildren: true
    };

  }

  ngAfterViewInit(){


  }


  public toggleType(): void {
        this.type = (this.type === 'component') ? 'directive' : 'component';
  }

  public toggleDisabled(): void {
        this.disabled = !this.disabled;
  }

  public toggleDirection(): void {
    this.config.direction = (this.config.direction === 'horizontal') ? 'vertical' : 'horizontal';
  }

  public toggleSlidesPerView(): void {
    if (this.config.slidesPerView !== 1) {
      this.config.slidesPerView = 1;
    } else {
      this.config.slidesPerView = 2;
    }
  }

  public toggleOverlayControls(): void {
    // if (this.config.navigation) {
    //   this.config.scrollbar = false;
    //   this.config.navigation = false;

    //   this.config.pagination = this.pagination;
    // } else if (this.config.pagination) {
    //   this.config.navigation = false;
    //   this.config.pagination = false;

    //   this.config.scrollbar = this.scrollbar;
    // } else {
    //   this.config.scrollbar = false;
    //   this.config.pagination = false;

    //   this.config.navigation = true;
    // }

    // if (this.type === 'directive' && this.directiveRef) {
    //   this.directiveRef.setIndex(0);
    // } else if (this.type === 'component' && this.componentRef && this.componentRef.directiveRef) {
    //   this.componentRef.directiveRef.setIndex(0);
    // }
  }

  public toggleKeyboardControl(): void {
    this.config.keyboard = !this.config.keyboard;
  }

  public toggleMouseWheelControl(): void {
    this.config.mousewheel = !this.config.mousewheel;
  }

  public onIndexChange(index: number): void {
 
  }

  public onSwiperEvent(event: string): void {
  
  }

  public index(index: number): void {

  }

}
