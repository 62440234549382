import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-modal-cancel-event',
  templateUrl: './modal-cancel-event.component.html',
  styleUrls: ['./modal-cancel-event.component.scss']
})
export class ModalCancelEventComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log("data on elimnar proposal:", this.data)
  }

}
