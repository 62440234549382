<div class="mat-elevation-z8" *ngIf="show">
    <table mat-table [dataSource]="dataSource">
  
      <!-- Position Column -->
      <ng-container matColumnDef="proveedor">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element" style="width: 500px;"> 
          
              <img [src]="element.proveedor" width="20%" style="padding: 10px;"> 
          
        </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="datos">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> {{element.datos[0].x}}<br>{{element.datos[0].y}}<br>{{element.datos[0].z}} {{element.datos[0].cvc}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="pais">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> {{element.pais[0].x}}<br>{{element.pais[0].y}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="accion">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element"> <mat-icon>{{element.accion}}</mat-icon> </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    
  </div>