import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactSupportService {
  public baseurl: string;
  temp: string;

  constructor(private __http: HttpClient,  private http: HttpClient) {
    this.baseurl = environment.url_server;
  }

  post_contactSupport(data): Observable<any> {
    this.temp = this.baseurl + "contactSupport";
    return this.__http.post<any>(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

  public getIPAddress() {
    return this.http.get(environment.ipify_url);
  }
}
