import { Component, OnInit, OnChanges,Input,SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-youtube',
  templateUrl: './video-youtube.component.html',
  styleUrls: ['./video-youtube.component.scss']
})
export class VideoYoutubeComponent implements OnInit {
  @Input() SendUrl: string;

values = '';
  aux = '';
  tokens:SafeResourceUrl = '';
  url:string = '';
  show:boolean = false;
  load: boolean = false;
  constructor(private _sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(this.SendUrl)
    console.log(changes.SendUrl)

    if(changes.SendUrl.currentValue==""){
      this.show = false;
    }else{
       this.onKey(changes.SendUrl.currentValue);
    }
  }
  clearInput(){

    this.url = "";
    this.show = false;

  }
  onKey(urls) { // without type info
    this.values = urls;
    this.load = true;
    if(this.values?.length == 0){
    	this.show = false;
      this.load = false;
    }else if(this.values){
    	const splittedStringArray = this.values.split(".");
    	const youtube = splittedStringArray.find((item) => item === 'youtube')
    	if(youtube=='youtube'){
        const splittedStringArray2 = this.values.split("=");
        // console.log(youtube,splittedStringArray2.length==3,splittedStringArray2.length,splittedStringArray2)

    		if(splittedStringArray2.length==2){
          // const splittedStringArray3 = splittedStringArray2[1].split("&");
          // if(splittedStringArray3.length==2){
            this.aux = 'https://www.youtube.com/embed/'+splittedStringArray2[1]
            this.tokens = this._sanitizer.bypassSecurityTrustResourceUrl(this.aux);
            this.show = true;
            this.load = false;
          }else{
            this.show = false;
            this.load = false;
          }

    		}else{
    			this.show = false;
          this.load = false;

    		}
    	}
    }
  }
