<div fxLayout="column" *ngIf="load">
  <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="27px" fxFlex>
    <mat-card fxLayout="column" fxFlex="75" class="m-card">
      <form [formGroup]="form">
        <p class="relaway-23-bold">Publicar curso</p>
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <app-message
            *ngIf="msg != null"
            [msg]="msg"
            [typeMsg]="typeMsg"
          ></app-message>
        </div>
        <mat-tab-group style="margin-top: 64px">
          <mat-tab class="raleway-14-bold" label="Datos">
            <div fxLayout="column" class="tabs" fxLayoutGap="50px">
              <mat-form-field fxFlex="50" style="width: 46.5%">
                <mat-label class="oxigen-14"
                  >Visibilidad<span class="bg-orange">*</span></mat-label
                >
                <mat-select formControlName="visible">
                  <mat-option [value]="0">Publico</mat-option>
                  <mat-option [value]="1">Privado</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    form.get('visible').invalid && form.get('visible').touched
                  "
                  >Campo requerido</mat-error
                >
              </mat-form-field>

              <div
                fxLayout="row"
                fxLayoutGap="45px"
                fxLayoutAlign="center center"
              >
                <mat-form-field fxFlex="50">
                  <mat-label class="oxigen-14"
                    >Moneda <span class="bg-orange">*</span></mat-label
                  >
                  <mat-select formControlName="currency">
                    <mat-option *ngFor="let item of currency" [value]="item.id"
                      >{{ item.title }} ({{ item.code }})</mat-option
                    >
                  </mat-select>
                  <mat-error
                    *ngIf="
                      form.get('currency').invalid &&
                      form.get('currency').touched
                    "
                    >Campo requerido</mat-error
                  >
                </mat-form-field>

                <mat-form-field fxFlex="50">
                  <mat-label class="oxigen-14"
                    >Monto<span class="bg-orange">*</span></mat-label
                  >
                  <input
                    matInput
                    type="number"
                    placeholder="0000"
                    formControlName="price"
                  />
                  <mat-error
                    *ngIf="
                      form.get('price').invalid && form.get('price').touched
                    "
                    >Campo requerido</mat-error
                  >
                </mat-form-field>
              </div>

              <div
                fxLayout="row"
                fxLayoutGap="45px"
                fxLayoutAlign="center center"
              >
                <mat-form-field fxFlex="50">
                  <mat-label class="oxigen-14"
                    >{{ "step-proposal.level" | translate }}
                    <span class="bg-orange">*</span></mat-label
                  >
                  <mat-select formControlName="level">
                    <mat-option [value]="1">{{
                      "step-proposal.basic" | translate
                    }}</mat-option>
                    <mat-option [value]="2"
                      >{{ "step-proposal.intermediate" | translate }}
                    </mat-option>
                    <mat-option [value]="3">{{
                      "step-proposal.expert" | translate
                    }}</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      form.get('level').invalid && form.get('level').touched
                    "
                    >Campo requerido</mat-error
                  >
                </mat-form-field>
                <!-- <small *ngIf="validLevel" class="error">*Error en level</small> -->

                <mat-form-field fxFlex="50">
                  <mat-label class="oxigen-14"
                    >Edad <span class="bg-orange">*</span></mat-label
                  >
                  <input

                    matInput
                    type="number"
                    placeholder="0000"
                    formControlName="age"
                  />
                  <mat-icon matSuffix  matTooltip="La edad ingresada es la edad mínima requerida para ingresar al curso/evento"><span>help</span></mat-icon>

                  <mat-error
                    *ngIf="form.get('age').invalid && form.get('age').touched"
                    >Campo requerido</mat-error
                  >
                </mat-form-field>
              </div>

              <div
                fxLayout="row"
                fxLayoutGap="45px"
                fxLayoutAlign="center center"
              >
                <mat-form-field fxFlex="50">
                  <mat-label class="oxigen-14"
                    >Matricula <span class="bg-orange">*</span></mat-label
                  >
                  <input
                    matInput
                    type="number"
                    placeholder="Cupos"
                    formControlName="capacity"
                  />
                  <mat-error
                    *ngIf="
                      form.get('capacity').invalid &&
                      form.get('capacity').touched
                    "
                    >Campo requerido</mat-error
                  >
                </mat-form-field>

                <div fxLayout="column" fxFlex="50">
                  <mat-label class="oxigen-14">Evaluado</mat-label>
                  <mat-slide-toggle
                    formControlName="evaluate"
                  ></mat-slide-toggle>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab class="raleway-14-bold" label="Contenido del curso">
            <div class="tabs" fxFlex>
              <mat-form-field fxFlex>
                <mat-label class="label-input"
                  >Contenido del curso
                  <span class="bg-orange">*</span></mat-label
                >
                <textarea
                  matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="5"
                  cdkAutosizeMaxRows="30"
                  formControlName="content"
                ></textarea>
                <!-- <mat-error *ngIf="invalidDescription" class="error" translate>errRequired</mat-error> -->
                <mat-error
                  *ngIf="
                    form.get('content').invalid && form.get('content').touched
                  "
                  >Campo requerido</mat-error
                >
              </mat-form-field>
            </div>
          </mat-tab>
          <mat-tab class="raleway-14-bold" label="Horario">
            <ng-template matTabContent>
              <div class="tabs">
                <!-- <app-calendar [idNum]="data.element.id"  [rentPlace]='data.showRent'></app-calendar> -->
                <app-calendar [idCourse]="id" [modify]="false"></app-calendar>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab class="raleway-14-bold" label="Imagenes">
            <ng-template matTabContent>
              <div style="margin-top: 75px" (click)="flagIma()">
                <!-- [arrayImage]='images' -->
                <app-carousel
                  (sendImages)="getImages($event)"
                  *ngIf="image == null"
                  [edit]="true"
                  origin="gallery"
                ></app-carousel>
                <app-carousel
                  (sendImages)="getImages($event)"
                  *ngIf="image != null"
                  [arrayImage]="image"
                  [edit]="true"
                  origin="gallery"
                ></app-carousel>
                <!-- <span *ngif="image?.length <= 2">
                  * Las imagenes son obligatorias
                </span> -->
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab class="raleway-14-bold" label="Video">
            <mat-card>
              <div style="margin-top: 75px">
                <mat-card fxLayout="column" fxFlex style="height: 500px">
                  <app-video-youtube [SendUrl]="urls"></app-video-youtube>
                  <div
                    fxLayout="column"
                    fxLayoutAlign="end stretch"
                    fxFlex
                    fxLayoutGap="18px"
                  >
                    <p class="title-card">Video</p>
                    <mat-form-field>
                      <mat-label class="label-input"
                        >{{ "place.urlYoutube" | translate }}
                        <span class="bg-orange">*</span></mat-label
                      >
                      <textarea
                        (keyup)="onKey($event)"
                        matInput
                        placeholder="https://www.youtube.com/watch?v=ozXIAg7zp9I"
                        formControlName="url"
                      ></textarea>
                      <mat-error
                        *ngIf="
                          form.get('url').invalid && form.get('url').touched
                        "
                        >Campo requerido</mat-error
                      >
                    </mat-form-field>
                  </div>
                </mat-card>
              </div>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </form>
    </mat-card>

    <mat-card fxLayout="column" fxFlex="25" class="m-card">
      <p class="relaway-23-bold">Detalles</p>
      <p class="raleway-14-bold" style="margin-top: 60px">Curso</p>
      <p class="oxigen-13" style="margin-top: 14px">{{ event.name }}</p>
      <p class="raleway-14-bold" style="margin-top: 32px">Lugar</p>
      <p class="oxigen-13" style="margin-top: 14px">{{ event.place }}</p>
      <p class="oxigen-13" style="margin-top: 13px">
        {{ event.state }}, {{ event.country }}
      </p>
      <p class="oxigen-13" style="margin-top: 13px">{{ event.address }}</p>
      <p class="raleway-14-bold" style="margin-top: 34px">Instructor</p>
      <div fxLayout="column" *ngFor="let item of teachers">
        <p class="oxigen-13" style="margin-top: 18px">{{ item.user }}</p>
        <p class="oxigen-13" style="margin-top: 12px">{{ item.title }}</p>
        <p class="oxigen-13" style="margin-top: 12px">{{ item.ubication }}</p>
      </div>
    </mat-card>
  </div>

  <div fxLayoutAlign="center center">
    <mat-spinner *ngIf="!load" strokeWidth="10" [diameter]="24"></mat-spinner>
    <button
      *ngIf="load && msg == null"
      mat-button
      style="margin-top: 30px"
      class="orange-btn"
      (click)="onSaveService()"
    >
      PUBLICAR
    </button>
  </div>
</div>
