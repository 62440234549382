import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Input,
} from '@angular/core';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  headers;
  upload = false;
  src_img = '';
  files = [];

  @Output() srcImgEvent = new EventEmitter<String>();
  @Output() fileEvent = new EventEmitter<any>();
  @ViewChild('inputFile') fileInput: ElementRef;
  @ViewChild('inputFileGallery') fileInputGallery: ElementRef;

  @Output() srcImgEventGallery = new EventEmitter<String>();
  @Output() fileEventGallery = new EventEmitter<any>();

  @Input() title = null;
  @Input() origin = 'one';

  constructor(private GeneralService: GeneralService) {
    this.headers = new Headers();
    this.headers.set('Content-Type', 'multipart/form-data');
  }

  ngOnInit(): void {
    console.log("este es de:", this.origin);
  }

  clearFile() {
    this.src_img = '';
    this.upload = false;
    this.srcImgEvent.emit(this.src_img);
  }

  onFileChange(event) {
    console.log(event)
    if (event.target.files.length > 0) {
      this.files = event.target.files;
      for (let file of this.files) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.src_img = event.target.result;

          this.srcImgEvent.emit(this.src_img);

        };
        reader.readAsDataURL(file);
      }
      this.upload = true;

      if (this.fileInput.nativeElement.value == '') {
        console.log('Error archivo');
        return;
      } else {
        console.log(this.files)

        this.fileEvent.emit(this.files);
      }
    }
  }

  onFileChangeGallery(event) {
    console.log(event)
    if (event.target.files.length > 0) {
      this.files = event.target.files;
      for (let file of this.files) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.src_img = event.target.result;
          this.srcImgEventGallery.emit(this.src_img);

        };
        reader.readAsDataURL(file);
      }
      this.upload = true;

      if (this.fileInputGallery.nativeElement.value == '') {
        console.log('Error archivo');
        return;
      } else {
        console.log(this.files)
        this.fileEventGallery.emit(this.files);
      }
    }
  }

  activeFile(){
    console.log(this.origin)
  }

  test(){
    console.log(this.origin)
  }
}
