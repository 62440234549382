import { Component, OnInit } from '@angular/core';
import { ProposalService } from "../../../services/proposal.service";
import { ActivatedRoute, Router } from '@angular/router';
import { OffetService } from "../../../services/offet.service";
import { GeneralService } from "../../../services/general.service";
import { MatDialog } from '@angular/material/dialog';
import { ModalRejectComponent } from './modal-reject/modal-reject.component';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent implements OnInit {

  constructor(private ProposalService:ProposalService,
    private _router: Router,
    private router: ActivatedRoute,
    private OffetService: OffetService,
    private GeneralService: GeneralService,
    public dialog: MatDialog,) { }
  proposal:any;
  contStar=0;
  type;
  ngOnInit(): void {
    var url=this._router.url.toString()
    console.log("data-url:: ", url)
    this.type = url.indexOf('ofert-details')!== -1 ? 'ofert' : url.indexOf('proposal-details')!== -1 ? 'proposal' : '';

    if(this.type=='ofert'){
      this.OffetService.get_offers_id(this.router.snapshot.params.id).subscribe(data=>{
        console.log("res-oferta: ", data)
        this.contStar = parseInt(data.user_business.original.user[0].business_average_stars);
        this.proposal = {
          id:data.offer[0].id,
          image: '',
          weekly_hours: '',
          type_user: data.user_business.original.user[0].rol_id == 1,
          contract_title: data.offer[0].contract_title,
          description:data.offer[0].work_description,
          course_id:data.course? data.course[0].course_id : null,
          event_id:data.event ? data.event[0].event_id : null,
          title: data.event? data.event[0].name : data.course ? data.course[0].name : '',
          level:data.event? data.event[0].level : data.course ? data.course[0].level : '',
          time_length:data.offer[0].time_length,
          time_unit:data.offer[0].time_unit,
          user_name:data.user_business.original.user[0].user_name,
          publish_work: data.user_business.original.user[0].publish_work[0].publish_work,
          type_contract: data.offer[0].type_contract,
          place:data.place,
          user_creation: data.user_business.original.user[0].user_creation ? this.GeneralService.m_es[data.user_business.original.user[0].user_creation.split("-")[1]-1]+' '+ data.user_business.original.user[0].user_creation.split("-")[0]:'',
          price_total: data.offer[0].price_total,
          price_per_hour: data.offer[0].price_per_hour,
          currency: data.offer[0].currency=='USD'? 'Dólares ($)': 'Pesos'};
      },error => {
        console.log("error = ",error)
      });
    }else{
      this.ProposalService.get_Proposal_id(this.router.snapshot.params.id).subscribe(data=>{
        console.log("res-propuesta: ", data)
        this.contStar = parseInt(data.user_business.original.user[0].business_average_stars);
        this.proposal = {
          id:data.proposal.id,
          description:data.proposal.description,
          questions_and_responses: data.questions_and_responses,
          course_id:data.proposal.course_id!=null?data.proposal.course_id:null,
          event_id:data.proposal.event_id!=null?data.proposal.event_id:null,
          title:data.proposal.title,
          level:data.proposal.level,
          time_length:data.proposal.time_length,
          time_unit:data.proposal.time_unit,
          user_name:data.user_business.original.user[0].user_name,
          publish_work: '',//data.user_business.original.user[0].publish_work[0].publish_work,
          type_contract: data.proposal.type_contract,
          place:data.place,
          user_creation: data.user_business.original.user[0].user_creation ? data.user_business.original.user[0].user_creation.split("-")[1]+'-'+ data.user_business.original.user[0].user_creation.split("-")[0]:'',
          course: data.course,
          price_total: data.proposal.price_total,
          currency: data.proposal.currency=='USD'? 'Dólares ($)': 'Pesos'};
      },error => {
        console.log("error = ",error)
      });
    }

  }

  acceptProposal(e){

  }

  editProposal(id){}

  deleteProposal(id){}

  rejectOffer(id){
    const dialogRef = this.dialog.open(ModalRejectComponent,{
      height: '430px',
      width: '808px',
      data: { name: 'Alejandra', type: 'type' }
    });
  }

  acceptOffer(id){
    this.OffetService.post_acceptOffer(id).subscribe(data=>{
      console.log("res oferta aceptada:: ", data)
    });
  }

}
