<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" >
    <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
        <app-table-method-payment *ngIf="!flag" [allDataCard]="allDataCard"></app-table-method-payment>
    </div>
</div>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" >
    <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
        <app-form-payment *ngIf="flag" (form)="form($fo)"></app-form-payment>
    </div>
</div>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign=" start start" fxLayoutGap="36px" >
    <div fxFlex="100%" fxLayout="column" fxLayoutAlign=" start start" style="padding: 22px;">
        <button  *ngIf="!flag" mat-raised-button  (click)="showHidden()">Crear Tarjeta</button>
    </div>
</div>
