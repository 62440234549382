<div id="register-auth-div" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between stretch">
    <section fxFlex="70%" 
    fxFlex.gt-sm="95%"
        fxLayout="column" 
        fxLayoutAlign="space-around none" 
        class="left-box">
        <img *ngIf="!isMobile" width="50%"  routerLink="/" class="pointer"  src="/assets/images/logo.svg" alt="logo">
        <h1 class="title-box" translate>register.title</h1>
        <mat-label style="margin-bottom: 5%;" translate>register.account <a href="/auth/login" class="link-blue" translate>register.login</a></mat-label>   

        <form [formGroup]="form" fxLayout="column" class="form-register">
            <div fxLayout="row"  fxLayoutGap="4.5%">
                <span class="material-icons grey-icon" [ngClass]="{'err-icon':(invalidName || invalidLastName)}">person</span>
                <mat-form-field fxFlex="40%">
                    <mat-label translate>register.name</mat-label> 
                    <input  matInput formControlName="name">
                </mat-form-field>
                <mat-form-field fxFlex="35%">
                    <mat-label translate>register.surname</mat-label>
                    <input matInput formControlName="lastName">
                </mat-form-field>
            </div>
            <div fxLayoutAlign="start none" fxFlex>
                <small *ngIf="(invalidName || invalidLastName )" class="error">* {{'register.errName' | translate }}</small>
            </div>
            <div fxLayout="row" fxLayoutGap="20px" class="group-box">
                <span class="material-icons grey-icon" [ngClass]="{'err-icon':invalidEmail}">email</span>
                <mat-form-field fxFlex="80%">
                    <mat-label translate>register.email</mat-label>

                    <input matInput formControlName="email">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign=" none" >
                <small *ngIf="invalidEmail" class="error">* {{'register.errEmail' | translate }}</small>
            </div>
            
            <div fxLayout="row" fxLayoutGap="20px" class="group-box">
                <span class="material-icons grey-icon" [ngClass]="{'err-icon':((invalidPass) || (invalidPassFormat==false) )}">lock</span>
                <mat-form-field fxFlex="80%" [ngClass]="{'alert-red': (invalidPass) || (invalidPassFormat==false)}">
                    <mat-label translate>register.password</mat-label>
                    <input matInput formControlName="pass1" name="password" (input)="onInputChange()" [type]="hidePass ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass">
                        <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <a style="cursor: pointer;" matTooltip="{{'register.infoPass' | translate }}">
                    <span *ngIf="!isMobile" style="font-size: 35px; color:#ffcd3f;" class="material-icons">info</span>
                </a>
            </div>
            <div fxLayout="column" fxLayoutAlign=" space-between start" >
                <small *ngIf="invalidPassFormat==false || invalidPass" class="error">* {{'register.error' | translate }}</small>
            </div>
            <div fxLayout="row" fxLayoutGap="20px" class="group-box">
                <span class="material-icons grey-icon" [ngClass]="{'err-icon':( (invalidPass2) ||  (samePass==true) )}">lock</span>
                <mat-form-field fxFlex="80%" [ngClass]="{'alert-red': ( (invalidPass2) ||  (samePass==true) )}">
                    <mat-label translate>register.confirmPassword</mat-label>
                    <input matInput formControlName="pass2" name="confir_password" (input)="onInputChange()" [type]="hideRepassword ? 'password' : 'text'">
                    <button mat-icon-button matSuffix (click)="hideRepassword = !hideRepassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideRepassword">
                        <mat-icon>{{hideRepassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign=" space-between start" >
                <div *ngIf="invalidPass2; then errInval else errRegex"></div>
                <ng-template #errInval>
                    <small class="error">* {{'register.errPass' | translate }}</small>
                </ng-template>
                <ng-template #errRegex>
                    <small *ngIf="invalidSamePass2" class="error">* {{'register.errConfPass' | translate }}</small>
                </ng-template>
            </div>
            <app-message *ngIf="errMsg != ''" type="error"  message="{{errMsg}}"></app-message>
            <mat-checkbox class="mat-check" formControlName="acceptTermsCond">{{'register.accept' | translate }} <a href="#" class="link-blue" translate>register.terms</a></mat-checkbox> 
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin-top: 10px;">
                <re-captcha (resolved)="resolved($event)" siteKey="{{ public_key_recaptcha }}"></re-captcha>
                <mat-spinner *ngIf="load" strokeWidth="10" [diameter]="24"></mat-spinner>
                <button *ngIf="!load" mat-raised-button  class="btn-blue" (click)="onCreateAccount()" [disabled]="invalidAllVadations">
                {{'register.createAccount' | translate }}</button>
            </div>
        </form>  
    </section>
    <section fxFlex fxLayout="column" fxLayout.gt-md="column" fxLayout.lt-sm="row" fxLayoutAlign="center center" class="right-box footer">
        <img routerLink="/" class="pointer logo-sogcial-auth" width="70%" src="/assets/images/isotipo-white.svg" style="margin-bottom: 20px;">
        <div fxLayout="column">
          <p translate>layout.text1</p>
          <p translate>layout.text2</p>
        </div>
      </section>
      
      
    
</div>
    