import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProposalService } from 'src/app/services/proposal.service';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss'],
})
export class ModaldeleteComponent implements OnInit {
  user;
  propuesta;
  idcourse;
  idevent;
  iscourse = false;

  constructor(
    private router: Router,
    private proposalService: ProposalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    console.log(this.data);
    this.user = JSON.parse(localStorage.getItem('userData'));
    this.propuesta = this.data.proposal_id;
    this.idcourse = this.data.course_id;
    this.idevent = this.data.event_id;

    if (this.data.id_course) {
      this.iscourse = true;
    }
  }

  deleteproposal() {
    this.proposalService.deleteproposal(this.propuesta).subscribe((data) => {
      this.router.navigateByUrl('/dashboard/proposals');
      console.log('-----------', data);
      this.dialog.closeAll();
    });
  }
}
