import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  private baseurl: string;
  private temp;

  constructor(private __http: HttpClient) {
    this.baseurl = environment.url_server;
  }

  Post_favorite(request): Observable<any> {
    this.temp = this.baseurl + 'admin/favorites/' +'newFavorite';
    return this.__http.post(this.temp, request);
  }
}