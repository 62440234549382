import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TeacherService {
  public baseurl: string;
  temp: string;

  constructor(private __http: HttpClient, private http: HttpClient) {
    this.baseurl = environment.url_server;
  }

  getProfile(id): Observable<any> {
    this.temp = this.baseurl + `admin/profile-teacher/${id}`;
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  getTeacher(id):  Observable<any> {
    this.temp = this.baseurl + `teachers/${id}`;
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  get_ListTeacher(text, limit, lastId, skill, country, title): Observable<any> {
    this.temp = this.baseurl + `listeacherpublic?limit=${limit}`;
    if (text && text !== '') {
      this.temp = this.temp + `&text=${text}`;
    }

    if (lastId && lastId !== '') {
      this.temp = this.temp + `&lastId=${lastId}`;
    }
    if (skill && skill !== '') {
      this.temp = this.temp + `&skill=${skill}`;
    }
    if (country && country !== '') {
      this.temp = this.temp + `&country=${country}`;
    }
    if (title && title !== '') {
      this.temp = this.temp + `&title=${title}`;
    }
    return this.__http
      .get<any>(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message; // Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }
}
