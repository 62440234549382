import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SocketIoService } from '../services/socket-io.service';
interface Meeting {
  id: number;
  name: string;
 
}

interface UserData {
  id: string ;
  name: string;
  avatar: string;
  last_name: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  opened:boolean = true;
  flag:boolean=false;
  meetings:any;

  constructor(
    private _router: Router,
    private socket: SocketIoService,
  ) { }
 
  

   ngOnInit(): void {
  //   const datas=JSON.parse(localStorage.getItem('userData'))
  //    const data: UserData = {
  //     id: String(datas.id),
  //     name: datas.name,
  //     avatar: datas.avatar,
  //     last_name: datas.last_name

  //   }
  //   this.socket.register(data);
  //   this.socket.newMeeting(data.id, "18")
  //     console.log(data);
  //     this.socket.listenForChatsData().subscribe((privateChats) => {
  //       this.meetings = privateChats;
  //       console.log('Reuniones recuperadas:', privateChats);
  //     });
  
      // Emite el evento "get_chats" para solicitar las reuniones
  //    this.socket.getMeeting(data.id);
  }
}