<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
        <app-header></app-header>
        <app-sub-header *ngIf="load" [screen]="1"></app-sub-header>

        <div class="container-fluid">
            <div class="row">
                <div class="col col-md-6 col-xl-8 col-lg-6">
                    <div class="containerCourseDetails">
                        <div class="row">
                            <div class="col-md-12">
                                <h2 *ngIf="load && event.Event && event.Event[0]" class="title">Nombre del evento: {{event.Event[0].name}}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p *ngIf="load" class="subtitle">Categoria: {{event.Event[0].category}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p *ngIf="load" class="description">{{event.Event[0].description}}</p>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-12">
                                <mat-chip-list aria-label="Fish selection" class="space-image">
                                    <mat-chip *ngFor="let item of tags">{{item}}</mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row" style="width: 100%;">
                                    <div class="col-md-12 col-xs-12">
                                        <app-slide-course-details *ngIf="load" [images]="imagesCarousel"></app-slide-course-details>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="sectionSocial">
                                    <p class="space">Compartir <span style="margin-left: 10px;"> <img class="pointer" src="/assets/images/facebook_copia.png" width="25px" alt="Facebook">
                                      <img class="pointer" src="/assets/images/twitter_copia_2.png" width="25px" alt="Twitter">
                                      <img class="pointer" src="/assets/images/link_copia.png" width="25px" alt="Enlace"></span></p>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12" *ngIf="videoId !== null" >
                                <mat-tab-group>
                                    <mat-tab label="Video">
                                        <div class="long-tabs">
                                            <iframe width="100%" height="500" [src]="videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-md-6 col-lg-6 col-xl-4">
                    <div class="sidebarRight">
                        <div class="gray-box box-price">
                            <p *ngIf="load" class="p-space prices-big">$ {{event.Event[0].price}} USD</p>
                        </div>
                        <div class="item-event mt-5  row g-0">
                            <div class="col-12 col-sm-6 ">
                                <a fxFlex routerLink="/cart" (click)="addEvent()" mat-button class="example-icon favorite-icon style-login registrate btn-event" style="margin: 0px 20px;">
                                    Añadir Evento
                                </a>
                            </div>
                            <div class="col-4 col-sm-2 mx-auto pt-1 pt-sm-0">
                                <a *ngIf="isLoggedIn" (click)="storeOrDelete(prodId)" mat-button class=" mx-auto example-icon favorite-icon style-login registrate2" [ngClass]="{'favorite': eventDetail.isFavorite === 1, 'no-favorite': eventDetail.isFavorite === 0}">
                                    <mat-icon>{{ eventDetail.isFavorite === 1 ? 'favorite' : 'favorite_border' }}</mat-icon>
                                </a>
                            </div>
                            <div class="col-6 col-sm-4 pt-1 pt-sm-0 ">
                                <div class="wrapper mx-auto">
                                    <button class="plusminus" (click)="counterDe()">-</button>
                                    <input type="number" class="num" [(value)]="counter" />
                                    <button class="plusminus" (click)="counterIn()">+</button>
                                  </div>
                            </div>
                        </div>
                        
                        <div style="padding-bottom: 20px;">
                            <div class="row ms-3 mt-3" style="width: 100%;">
                                <span class="date">Fecha de Evento:<span class="date-bold ms-2" *ngIf="load">{{format_date(event.Event[0].start_date)}}</span></span>
                                <span class="date">Disponibilidad: <span class="date-bold ms-2" *ngIf="load">{{event.Event[0].availability}}</span></span>
                                <span class="date">Edad:<span class="date-bold ms-2" *ngIf="load">{{event.Event[0].age}}</span> </span>


                            </div>
                           
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="gray-box">
                                    <p *ngIf="load" class="p-space academy">Acerca del Evento</p>
                                </div>
                                <div class="sideUser">
                                    <div class="row ms-3">
                                        <div class="col-3">
                                            <img *ngIf="load" src="/assets/images/default.png" width="100%" alt="">
                                        </div>
                                        <div class="col-9">
                                            <div class="row">
                                                <p *ngIf="load" class="address-user ">Responsable:</p>
                                                <p style="display: block;" class="name-user" *ngFor="let teacher of teachers">{{ teacher.name }}  {{ teacher.last_name }} </p>
                                                <p *ngIf="load" class="address-user">San Cristobal, Venezuela</p>
                                            </div>
                                            <div class="item-event">
                                                <div class="row">
                                                    <span *ngIf="load" fxFlex [ngSwitch]="star" class="start-rigth">
                                                        <span class="star" *ngSwitchCase="0"><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                            <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                            <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                            <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                            <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon></span>
                                                            <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
                                                    </span>
                                                    <a class="start-rigth width-full-side link-start" href="/detail/teacher-public/{{ teacherId }}">Ver Calificaciones</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="gray-box">
                                    <p *ngIf="load" class="p-space academy">Acerca del Anfitrion</p>
                                </div>
                                <div class="pt-teachers pb-teachers">
                                    <div class="row ms-3" *ngFor="let teacher of teachers" routerLink="/bio-teacher/1" title="Ver bio de Jose Rojas">
                                        <div class="col-3">
                                            <img *ngIf="load" src="/assets/images/default.png" width="100%" alt="">
                                        </div>
                                        <div class="col-9">
                                            <p *ngIf="load" class="name-teacher">Jose Rojas</p>
                                            <p *ngIf="load" class="teacher-carrer">Ingeniero</p>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <mat-chip-list aria-label="Fish selection">
                                                        <mat-chip *ngFor="let item of tagsArray(tags)">{{item}}</mat-chip>
                                                    </mat-chip-list>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <p *ngIf="load && teachers.length == 0" class="itemTeacher">
                                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> No Disponible</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="gray-box">
                                    <p *ngIf="load" class="p-space academy">Horario</p>
                                </div>
                                <div class="pt-schedule pb-schedule">
                                    <div class="row">
                                        <p *ngIf="load && schedule.length > 0" class="itemSchedule">
                                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> Fecha de Inicio 10/10/2020: 10:00 - 15:00</p>
                                    </div>
                                    <div class="row">
                                        <p *ngIf="load && schedule.length > 0" class="itemSchedule">
                                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> Fecha de Fin 10/10/2020: 10:00 - 15:00</p>
                                    </div>
                                    <div class="row">
                                        <p *ngIf="load && schedule.length == 0" class="itemSchedule">
                                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> No Disponible</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    <app-footer></app-footer>
    </div>
</mat-drawer-container>