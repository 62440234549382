import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GeneralService } from '../../../services/general.service'

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Input() type: string;
  @Input() message: string;

  @Input() msg: string=null;
  @Input() typeMsg: string;
  @Input() show: boolean;

  // show=true;




  
  constructor(private GeneralService: GeneralService) { }

  ngOnInit(): void {
    console.log("messege msg",this.msg, "typeMsg",this.typeMsg);
    window.scroll(0,0);
  }
  

  close(){
    this.msg=null;
    this.typeMsg=null;
    this.GeneralService.msg$.emit(null);
    this.GeneralService.typeMsg$.emit(null);

  }

}
