import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartService } from 'src/app/services/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


export interface DialogData {
  courseName: string;
  price: any;
  category: string;
  image: any;
  courseAffliates: string[];
  productId: number;
  amount: any;
  userRegistered:any;
  registeredAffiliates: any;
}

@Component({
  selector: 'app-dialog-course',
  templateUrl: './dialog-course.component.html',
  styleUrls: ['./dialog-course.component.scss']
})
export class DialogCourseComponent implements OnInit {

  selectedAffiliateId: number = 0;
  id: number;

  constructor(
    public dialogRef: MatDialogRef<DialogCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private cartService: CartService, 
    private snackBar: MatSnackBar,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    console.log(this.data.userRegistered);
  }

  isAffiliateRegistered(affiliateId: number): boolean {
    // Verifica si el ID del afiliado está en el array de afiliados registrados
    return this.data.registeredAffiliates.includes(affiliateId);
  }

  onNoClick(): void {
    this.dialogRef.close();
    console.log
  }


  addProduct(): void {
    const dataToSend = {
      product_id: this.data.productId,
      quantity: 1,
      amount: this.data.amount,
      affiliate_id: this.selectedAffiliateId = (this.selectedAffiliateId === 0) ? null : this.selectedAffiliateId,
    };
  
    this.cartService.post_admin_products(dataToSend).subscribe(
      response => {
        this.showSnackbar('Producto añadido con éxito', 'success-snackbar');
        console.log(response);
  
        // Redirige a /cart después de que la solicitud HTTP se haya completado
        this.router.navigate(['/cart']);
      },
      error => {
        this.showSnackbar('Error al agregar el producto', 'error-snackbar');
        console.error(error);
  
        // Si ocurre un error, también podrías decidir si quieres redirigir aquí.
        // this.router.navigate(['/cart']);
      }
    );
  }
  
  
  showSnackbar(message: string, panelClass: string): void {
    this.snackBar.open(message, 'Cerrar', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: panelClass
    });
  }

}
