import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CourseDashboardService {

  public baseurl: string;
  temp: string;
  headers = new Headers();


  constructor(private __http: HttpClient) {
    this.baseurl = environment.url_server;
    this.headers.set("Content-Type", "multipart/form-data");
    this.headers.set("Authorization", 'Bearer ' + JSON.parse(localStorage.getItem('access_token')));
  }

  // courseId=44;
  courseId;
  get_courseId() {
    return this.courseId;
  }
  set_courseId(element) {
    this.courseId = element;
  }

  dataBasic$ = new EventEmitter();
  place$ = new EventEmitter();
  get_courses(limit, id): Observable<any> {
    this.temp = this.baseurl + 'courses?limit=' + limit + '&lastId=' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_Placebycourses(id): Observable<any> {
    this.temp = this.baseurl + 'getPlacesByCourse/' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_courseDetail(id): Observable<any> {
    this.temp = this.baseurl + 'courses/' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_courses_create(): Observable<any> {
    this.temp = this.baseurl + 'admin/courses/create';
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  post_admin_courses(data): Observable<any> {
    this.temp = this.baseurl + 'admin/courses';
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_coursesById(id: number): Observable<any> {
    this.temp = this.baseurl + 'admin/courses/' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_course(id): Observable<any> {
    this.temp = this.baseurl + 'courses?user_id='+id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_admin_dataToCancelOffer(id) {
    this.temp = this.baseurl + 'admin/dataToCancelReservation?course_id=' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_admin_dataToCancelReservation(id) {
    this.temp = this.baseurl + 'admin/dataToCancelReservation?course_id=' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  post_admin_statusCourse(id: number, data: any) {
    this.temp = this.baseurl + 'admin/statusCourse/' + id;
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_coursesCategories() {
    this.temp = this.baseurl + 'coursesCategories';
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_home(id:number) {
    this.temp = this.baseurl +  'business/' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }


  get_placesLimit() {
    this.temp = this.baseurl + 'places?limit=10000';
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_admin_publishCourse(id: number) {
    this.temp = this.baseurl + 'admin/publishCourse/' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }


  post_admin_publishCourse(data: any, id: number) {
    this.temp = this.baseurl + 'admin/publishCourse/'+ id;
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  updateCourse(data: any, id: number) {



    // const requestFormData = new FormData();
    // requestFormData.append(`age`, data.age);
    // requestFormData.append(`aimed_to`, data.aimed_to);
    // requestFormData.append(`approved`, data.approved);
    // requestFormData.append(`availability`, data.availability);
    // requestFormData.append(`category_id`, data.category_id);
    // requestFormData.append(`content`, data.content);
    // requestFormData.append(`course_category_id`, data.course_category_id);
    // requestFormData.append(`currency_id`, data.currency_id);
    // requestFormData.append(`description`, data.description);
    // requestFormData.append(`enrollment`, data.enrollment);
    // requestFormData.append(`fileArray`, data.fileArray);
    // requestFormData.append(`learn`, data.learn);
    // requestFormData.append(`level_id`, data.level_id);
    // requestFormData.append(`name`, data.name);
    // requestFormData.append(`price`, data.price);
    // requestFormData.append(`requirements`, data.requirements);
    // requestFormData.append(`state`, data.state);
    // // requestFormData.append(`tag`, data.tag);
    // requestFormData.append(`url`, data.url);


    // const headers = new HttpHeaders();
    // headers.append('Content-Type', 'multipart/form-data');
    // requestFormData.append(`tag`, JSON.stringify(data.tag));
    // if (data.tag) {
    //   for (let i = 0; i < data.tag.length; i++) {
    //     requestFormData.append(`tag[]`, JSON.stringify(data.tag[i]));
    //   }
    // }

    const apiURL = this.baseurl  + 'admin/updateCourse/'+ id;
    return this.__http.post<any>(apiURL, data);






  }

  put_admin_events(id, data): Observable<any> {
    this.temp = this.baseurl + 'admin/events/' + id;
    return this.__http.put(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  getAllUsersByCourse(id): Observable<any>{
    this.temp = this.baseurl + 'admin/getAllUsersByCourse/'+id ;
    return this.__http.get( this.temp)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }
}
