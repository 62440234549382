<div fxLayout="column" fxFlex fxLayoutAlign="center start" fxLayoutGap="24px" style="width: 600px; padding:20px 30px;">
    <div fxLayout="row" style="width: 100%;" fxLayoutAlign="space-between center">
        <p class="raleway-23-grey"><b>Confirmar horarios</b></p>
        <button [mat-dialog-close]='null' class="btn-close pointer">
            <mat-icon aria-hidden="false">clear</mat-icon>
        </button>
    </div>
    <p class="oxigen-16-black">¿Estas seguro de que quieres enviar una solicitud de reservar a este lugar?</p>
    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex fxLayoutGap="10px">
        <img src="/assets/images/image-default.png" alt="" style="width: 150px;">
        <div fxLayout="column" fxFlex fxLayoutGap="12px">
            <p class="raleway-14-grey"><b>{{data.element.Name}}</b></p>
            <p class="raleway-14-grey"><b>Categoria:</b>{{data.element.Category}}</p>
            <p class="raleway-14-grey">{{data.element.Ubication}}</p>
            <div fxLayout="row" class="border-orange-grey" fxFlex>
                <p class="oxigen-16-black">Horas: <b>{{calendarData.hour}}:00 h</b></p>
                <p *ngIf="calendarData.rentPlace==true" style="padding-left: 8px;"> - Precio Total:
                    <b>{{calendarData.code}} {{calendarData.hour*calendarData.amount}}</b></p>
            </div>
        </div>
    </div>
    <p class="oxigen-14-black" style="padding-top: 18px">En las siguientes fechas y horarios:</p>

    <div fxLayout="row" fxLayoutAlign="space-around center" *ngFor="let item of arrayDay">
        <span class="material-icons color-orange" style="font-size: 8px !important;">lens</span>
        <p class="oxigen-14-black" style="padding-left: 8px;">{{item | date:'yyyy/MM/dd'}}: </p>
        <!-- <p class="oxigen-14-black">{{item.startStr | date:'yyyy'}}/{{item.startStr | date:'MM'}}/{{item.startStr | date:'dd'}} |  {{item.startStr | date:'HH'}}:00  -  {{item.endStr  | date:'HH'}}:00</p> -->
        <div fxLayout="row" *ngFor="let day of arrayTemp">
            <div fxLayout="row" *ngIf="item==day.day" fxLayoutGap="5px">
                <p style="padding-left: 15px;" class="oxigen-14-black">{{day.start}}</p>
                <p class="oxigen-14-black"> <b>-</b> </p>
                <p class="oxigen-14-black">{{day.end}}</p>
            </div>
        </div>
    </div>
    <form [formGroup]="form">
        <mat-checkbox class="mat-check" formControlName="acceptTermsCond">{{'register.accept' | translate }} <a href="#"
                class="link-blue">Acepto los terminos y condiciones</a>
        </mat-checkbox>
    </form>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center" style="margin-top: 10px; width: 100%;">
        <div fxLayoutAlign="center center">
            <button *ngIf="form.controls.acceptTermsCond.value==true" [mat-dialog-close]="form.controls.acceptTermsCond.value" cdkFocusInitial
                class="btn-orange btn pointer">{{"create-question.accept" | translate}}</button>
            <button *ngIf="form.controls.acceptTermsCond.value==false" cdkFocusInitial
            class="btn-orange btn pointer">{{"create-question.accept" | translate}}</button>
        </div>
        <div [mat-dialog-close]='null' class="btn-border-cancel pointer" fxLayoutAlign="center center">
            <p class="font">{{"create-question.skip" | translate}}</p>
        </div>
    </div>
</div>