import { element } from 'protractor';
import { TeacherService } from '../../../../services/teacher.service';
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PlaceService } from '../../../../services/place.service';
import { GeneralService } from '../../../../services/general.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { ProposalService } from 'src/app/services/proposal.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dashboard-teachers',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardTeacherComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'nombre',
    'categoria',
    'privacidad',
    'fechadeinicio',
    'direccion',
    'action',
  ];
  dataSource = new MatTableDataSource([]);

  hide=true;
  category = new FormControl();
  categoryList = [];
  place = new FormControl();
  placesList = [];
  infoTabla;

  constructor(
    private proposalserverice: ProposalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('userData'));
    this.proposalserverice.getproposals(user.id).subscribe((data) => {
      console.log(data);
      const elements = [];
      data.proposal.forEach((element) => {
        if (
          element.extra?.category !== '' &&
          !this.categoryList.includes(element.extra?.category)
        ) {
          this.categoryList.push(element.extra?.category);
        }

        if (
          element.extra?.ubication !== '' &&
          !this.placesList.includes(element.extra?.ubication)
        ) {
          this.placesList.push(element.extra?.ubication);
        }

        console.log(element);
        elements.push({
          nombre: element.extra?.place_name,
          categoria: element.extra?.category,
          privacidad: element.publishable,
          fechadeinicio: element.extra?.start_date,
          direccion: element.extra?.ubication,
          titulo: element.title,
          proposal_id: element.proposal_id,
        });
      });
      this.infoTabla = elements;
      this.dataSource = new MatTableDataSource(elements);
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getRecord(row) {
    const proposal_id = row.proposal_id;
    this.router.navigateByUrl(
      '/dashboard/teacher/detail-proposal/' + proposal_id
    );
  }

  categoryreserva(c) {
    console.log(c.value);
    console.log(this.infoTabla);c
    const nuevadatafiltrada = this.infoTabla.filter((data) =>
      c.value == data.categoria
    );
    console.log(nuevadatafiltrada);

    this.dataSource = new MatTableDataSource(nuevadatafiltrada);
    this.dataSource.paginator = this.paginator;
  }

  ciudadfiltro(d) {
    console.log(d.value);
    const nuevadatafiltrada = this.infoTabla.filter((data) =>
      d.value === data.direccion
    );
    this.dataSource = new MatTableDataSource(nuevadatafiltrada);
    this.dataSource.paginator = this.paginator;
  }

  clearcategory() {
    this.category.setValue(null);
    this.dataSource = new MatTableDataSource(this.infoTabla);
    this.dataSource.paginator = this.paginator;
  }

  clearubication() {
    this.place.setValue(null);

    this.dataSource = new MatTableDataSource(this.infoTabla);
    this.dataSource.paginator = this.paginator;
  }
}
