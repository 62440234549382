import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith, last } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


export interface PeriodicElement {
	period: string;
	monday: string;
	tuesday: string;
	wednesday: string;
	thursday: string;
	friday: string;
	saturday: string;
	sunday: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
	{ period: '06:00 - 07:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '07:00 - 08:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '08:00 - 09:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '09:00 - 10:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '10:00 - 11:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '11:00 - 12:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '12:00 - 13:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '13:00 - 14:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '14:00 - 15:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '15:00 - 16:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '16:00 - 17:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '17:00 - 18:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '18:00 - 19:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
	{ period: '18:00 - 20:00', monday: '', tuesday: '', wednesday: '', thursday: '', friday: '', saturday: '', sunday: '' },
];

export interface ProfesorElement {
	avatar: string;
	name: string;
	title: string;
	habilities: string;
	rating: number;
	ubication: string;
}

const PROFESOR_DATA: ProfesorElement[] = [
	{ avatar: 'default.png', name: 'Nombre', title: 'Titulo', habilities: 'Habilidades', rating: 5, ubication: 'Ubicacion' },
	{ avatar: 'default.png', name: 'Nombre', title: 'Titulo', habilities: 'Habilidades', rating: 5, ubication: 'Ubicacion' },
	{ avatar: 'default.png', name: 'Nombre', title: 'Titulo', habilities: 'Habilidades', rating: 5, ubication: 'Ubicacion' },
	{ avatar: 'default.png', name: 'Nombre', title: 'Titulo', habilities: 'Habilidades', rating: 5, ubication: 'Ubicacion' },
	{ avatar: 'default.png', name: 'Nombre', title: 'Titulo', habilities: 'Habilidades', rating: 5, ubication: 'Ubicacion' },
	{ avatar: 'default.png', name: 'Nombre', title: 'Titulo', habilities: 'Habilidades', rating: 5, ubication: 'Ubicacion' },
	{ avatar: 'default.png', name: 'Nombre', title: 'Titulo', habilities: 'Habilidades', rating: 5, ubication: 'Ubicacion' },
];



@Component({
	selector: 'app-courses-add',
	templateUrl: './courses-add.component.html',
	styleUrls: ['./courses-add.component.scss']
})
export class CoursesAddComponent implements OnInit {
	tags: string[] = ['Programacion', 'Curso', 'Tachira', 'Php',];
	tagCtrl = new FormControl();
	allTags: string[] = ['Programacion', 'Curso', 'Tachira', 'Php', 'html', 'Css', 'Clases'];
	visible = true;
	selectable = true;
	removable = true;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	filteredTags: Observable<string[]>;
	dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
	profesorSource = new MatTableDataSource<ProfesorElement>(PROFESOR_DATA);
	displayedColumns: string[] = ['period', 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
	profesorColumns: string[] = ['avatar', 'info', 'rating', 'action'];
	fakeArray = new Array(5);


	@ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	firstFormGroup: FormGroup;
	secondFormGroup: FormGroup;
	thirdFormGroup: FormGroup;

	constructor(private _formBuilder: FormBuilder, private fb: FormBuilder) {
		this.filteredTags = this.tagCtrl.valueChanges.pipe(
			startWith(null),
			map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
	}

	ngOnInit() {
    const urlRegex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
		this.firstFormGroup = this._formBuilder.group({
			courseName: ['', Validators.required],
			coursePlace: ['', Validators.required],
			courseAge: ['', Validators.required],
			coursePrice: ['', Validators.required],
			courseEnrollment: ['', Validators.required],
		});
		this.secondFormGroup = this._formBuilder.group({
			courseWhatLearn: ['', Validators.required],
			courseToWho: ['', Validators.required],
			courseRequirements: ['', Validators.required],
			courseObjective: ['', Validators.required],
			courseContent: this.fb.array([[]]),
			objetive: ['', Validators.required],
			title: ['', Validators.required,Validators.pattern(urlRegex)],
			courseSchedule: this.fb.array([[]]),
		});

		this.thirdFormGroup = this._formBuilder.group({
		});
		this.profesorSource.paginator = this.paginator;
	}

	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		// Add our tag
		if ((value || '').trim()) {
			this.tags.push(value.trim());
		}

		// Reset the input value
		if (input) {
			input.value = '';
		}

		this.tagCtrl.setValue(null);
	}

	remove(tag: string): void {
		const index = this.tags.indexOf(tag);

		if (index >= 0) {
			this.tags.splice(index, 1);
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.tags.push(event.option.viewValue);
		this.tagInput.nativeElement.value = '';
		this.tagCtrl.setValue(null);
	}

	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();

		return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
	}

	addObjetive() {
		this.courseObjective.push(this.fb.control(''));
	}

	deleteObjetiveLast() {
		if (this.courseContent.length > 1) {
			this.courseContent.removeAt(this.courseContent.length - 1)
		}
	}
	deleteObjetive(i: number) {
		this.courseObjective.removeAt(i);
	}

	get courseObjective() {
		return this.secondFormGroup.get('courseObjective') as FormArray;
	}


	addContent() {
		this.courseContent.push(this.fb.control(''));
	}

	deleteContentLast() {
		if (this.courseContent.length > 1) {
			this.courseContent.removeAt(this.courseContent.length - 1)
		}
	}

	get courseContent() {
		return this.secondFormGroup.get('courseContent') as FormArray;
	}




	addSchedule() {
		this.courseSchedule.push(this.fb.control(''));
	}

	deleteScheduleLast() {
		if (this.courseSchedule.length > 1) {
			this.courseSchedule.removeAt(this.courseSchedule.length - 1)
		}
	}

	get courseSchedule() {
		return this.secondFormGroup.get('courseSchedule') as FormArray;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}


}
