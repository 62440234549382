<section class="left-o-box" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="20px">
    <img width="100%" src="/assets/images/isotipo-white.svg" />
    <div>
        <p class="text-white-orange-square" translate>layout.text1</p>
        <p class="text-white-orange-square" translate>layout.text2</p>
    </div>
</section>

<section class="right-w-box" fxFlex fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
    <img routerLink="auth/login" class="pointer" width="100%" src="/assets/images/logo.svg" />
    <h1 class="title-box" translate>reset.title</h1>
    <div *ngIf="typeMsg !='success'" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <p translate>reset.sub-title</p>
        <a style="cursor: pointer;" matTooltip="{{'register.infoPass' | translate }}" matTooltipPosition="right">
            <span style="font-size: 35px; color:#ffcd3f;" class="material-icons">info</span>
        </a>
    </div>
    <div *ngIf="typeMsg !='success'" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px" class="group-input">
        <span class="material-icons grey-icon" [ngClass]="{'err-icon': error}">lock</span>
        <mat-form-field fxFlex [ngClass]="{'alert-red': error}">
            <mat-label translate>reset.password</mat-label>
            <input matInput name="new_password" (input)="onInputChange()" [(ngModel)]="new_password" [type]="hidePass ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass">
        <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        </mat-form-field>
    </div>
    <div *ngIf="typeMsg !='success'" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px" class="group-input">
        <span class="material-icons grey-icon" [ngClass]="{'err-icon':error}">lock</span>
        <mat-form-field fxFlex [ngClass]="{'alert-red': error}">
            <mat-label>{{"reset.repassword"|translate}}</mat-label>
            <input matInput name="confir_password" (input)="onInputChange()" [(ngModel)]="confir_password" [type]="hideRepassword ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hideRepassword = !hideRepassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideRepassword">
            <mat-icon>{{hideRepassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
    </div>

    <app-message *ngIf="msg != ''" [type]="typeMsg" message="{{msg}}"></app-message>
    <div fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="15px" style="width: 100%;">
        <p>hola</p>
        <mat-spinner *ngIf="load" strokeWidth="10" [diameter]="24"></mat-spinner>
        <button *ngIf="!load && typeMsg!='success'" mat-raised-button (click)="onChangesPass()" class="btn-blue" [disabled]="disabled">{{"reset.send" | translate }}</button>
        <a *ngIf="typeMsg =='success'" mat-raised-button href="auth/login" class="btn-blue">{{"login.login" | translate }}</a>
    </div>
</section>