import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teacher-frontend',
  templateUrl: './teacher-frontend.component.html',
  styleUrls: ['./teacher-frontend.component.scss']
})
export class TeacherFrontendComponent implements OnInit {
  
  data={
    image:"/assets/images/section-2.jpg",
    icon:"business_center",
    firstText:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    textTitle:"Conviertete en instructor",
    textTitle2:"Proporcionamos las herramientas y plataforma para que puedas pertenecer a una academia y dictar un curso",
    color:"#FFCD3F"

  }
  constructor() { }

  ngOnInit(): void {
  }

}
