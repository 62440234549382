import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { AffiliateService } from '../../../services/affiliate.service';
/*import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs";*/

export interface RelationShip {
  id: number;
  name: string;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss']
})

export class AffiliatesComponent implements OnInit {

  statusAffiliates = 0;
  nameFormControl = new FormControl('', [Validators.required, Validators.pattern('([A-Za-z ]*)+')]);
  lastNameFormControl = new FormControl('', [Validators.required, Validators.pattern('([A-Za-z ]*)+')]);
  dniFormControl = new FormControl('', [Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(9)]);
  birthdayFormControl = new FormControl('', [Validators.required]);
  relationShipFormControl = new FormControl('', [Validators.required]);
  emailFormControl  = new FormControl('', [Validators.required, Validators.email]);
  matcher = new MyErrorStateMatcher();
  relationships: RelationShip[] = [];
  errors = {
    nameFormControl: { label: 'Nombre', msg: { required: `Nombre es requerido.`, pattern: `Nombre debe contener solo letras.` }},
    lastNameFormControl: { label: 'Apellido', msg: { required: `Apellido es requerido.`, pattern: `Apellido debe contener solo letras.` }},
    dniFormControl: { label: 'No. Documento', msg: {
        required: `No. Documento es requerido.`,
        pattern: `No. Documento debe contener solo números.`,
        maxlength: `No. Documento debe contener máximo 9 caracteres.`
      }
    },
    birthdayFormControl: { label: 'Fecha de Nacimiento', msg: { required: `Fecha de Nacimiento es requerido.` }},
    relationShipFormControl: { label: 'Parentesco', msg: { required: `Parentesco es requerido.` }},
    emailFormControl: { label: 'Email', msg: { required: `Fecha de Nacimiento es requerido.`, email:  `Email es inválido.` }}
  };
  affiliates = [];
  editStatus = 0;
  selected = 1;
  disabledBtn = false;

  constructor(private AffiliateService:AffiliateService) {}

  ngOnInit(): void {
    this.refreshList();
    this.AffiliateService.get_admin_affiliates_create().subscribe(res => {
      const { relationships } = res;
      this.relationships = relationships;
    },err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  refreshList() {
    const data = JSON.parse(localStorage.getItem('userData'));
    this.AffiliateService.get_admin_useraffiliates(data.id).subscribe( res => {
      const { affiliate } = res;
      this.affiliates = affiliate;
      this.statusAffiliates = this.affiliates.length === 0 ? 0 : 2;
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  addStatusAffiliates(){
    this.nameFormControl.reset();
    this.lastNameFormControl.reset();
    this.dniFormControl.reset();
    this.birthdayFormControl.reset();
    this.relationShipFormControl.reset();
    this.emailFormControl.reset();
    this.statusAffiliates = 1;
  }

  saveAffiliates(){
    this.disabledBtn = true;
    if(!this.activeErrors()) {
      let data = JSON.parse(localStorage.getItem('userData'));
      let date_now = new Date();
      let diff = date_now.getTime() - this.birthdayFormControl.value.getTime();
      let years = Math.trunc(diff / (1000 * 60 * 60 * 24 * 365));
      const birthdate = `${this.birthdayFormControl.value.getFullYear()}-${this.birthdayFormControl.value.getMonth() < 9 ? `0${this.birthdayFormControl.value.getMonth() + 1}`: (this.birthdayFormControl.value.getMonth() + 1)}-${this.birthdayFormControl.value.getDate() < 10 ? `0${this.birthdayFormControl.value.getDate()}` : this.birthdayFormControl.value.getDate()}`;
      let jsonData = {
        user_id: data.id,
        name: this.nameFormControl.value,
        last_name: this.lastNameFormControl.value,
        age: years,
        birthdate,
        email: this.emailFormControl.value,
        dni: this.dniFormControl.value,
        relationship_id: this.relationShipFormControl.value
      };
      if(this.editStatus) {
        this.AffiliateService.put_admin_affiliates(this.editStatus, jsonData).subscribe(async res => {
          await this.refreshList();
          this.statusAffiliates = 2;
          this.editStatus = 0;
          this.disabledBtn = false;
        },err => {
          alert(err.error.message_return.ERROR_MENSAGGE);
          this.disabledBtn = false;
        });
      } else {
        this.AffiliateService.post_admin_affiliates(jsonData).subscribe(async res => {
          await this.refreshList();
          this.statusAffiliates = 2;
          this.editStatus = 0;
          this.disabledBtn = false;
        },err => {
          alert(err.error.message_return.ERROR_MENSAGGE);
          this.disabledBtn = false;
        });
      }
    }
  }

  activeErrors() {
    if(!this.nameFormControl.valid || !this.lastNameFormControl.valid || !this.dniFormControl.valid ||
       !this.birthdayFormControl.valid ||  !this.relationShipFormControl.valid || !this.emailFormControl.valid) {
      Object.keys(this.errors).forEach(key => {
        this[key].touched = true;
      });
      return true;
    }
    return false;
  }

  editAffiliate(id){
    this.AffiliateService.get_admin_affiliates(id).subscribe(res => {
      const { affiliate } = res;
      this.nameFormControl.reset(affiliate[0].name);
      this.lastNameFormControl.reset(affiliate[0].last_name);
      this.dniFormControl.reset(affiliate[0].DNI);
      const arrayD = affiliate[0].birthdate.split('-');
      this.birthdayFormControl.reset( new Date(arrayD[0], Number(arrayD[1]) - 1, Number(arrayD[2])) );
      this.relationShipFormControl.reset(affiliate[0].relationship_id);
      this.emailFormControl.reset(affiliate[0].email);
      this.statusAffiliates = 1;
      this.editStatus = id;
    },err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  deleteAffiliate(id){
    this.AffiliateService.delete_admin_affiliates(id).subscribe(res => {
      this.affiliates = this.affiliates.filter(item => item.affiliate_id !== id);
      alert(res.message_return.ERROR_MENSAGGE);
    },err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  formatDate(date){
    let arrayD = date.split('-');
    return `${arrayD[2]}/${arrayD[1]}/${arrayD[0]}`;
  }
}
