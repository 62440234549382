<div *ngIf="data.remove=='cancel'" style="padding: 25px 65px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <p class="raleway-23-g" style="padding-top: 18px"><b>Anular Publicacion</b></p>
        <button [mat-dialog-close]="null" class="btn-close">
            <mat-icon aria-hidden="false">clear</mat-icon>
        </button>
    </div>
    <p class="oxigen-16-b" style="padding-top: 20px">¿Estas seguro de que quieres anular la publicacion?</p>
    <div fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="20px" style="padding-top: 45px;">
        <div class="btn-border-orange pointer" [mat-dialog-close]="null" fxLayoutAlign="center center">
            <p class="font">{{'send-proposal.cancel' | translate}}</p>
        </div>
        <div fxLayoutAlign="center center" mat-dialog-close='pause'>
            <button class="btn-orange btn pointer">{{'send-proposal.accept' | translate}}</button>
        </div>
    </div>
</div>

<div *ngIf="data.remove=='delete'" style="padding: 25px 65px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <p class="raleway-23-g" style="padding-top: 18px"><b>Eliminar registro</b></p>
        <button [mat-dialog-close]="null" class="btn-close">
            <mat-icon aria-hidden="false">clear</mat-icon>
        </button>
    </div>
    <p class="oxigen-16-b" style="padding-top: 20px">¿Estas seguro de que quieres elimninar?</p>
    <p class="raleway-16-g" style="padding-top: 14px; padding-left: 15px;"><b>Registro:</b> {{data.element.name}}</p>
    <p style="padding-top: 14px">*Perderas todos los datos</p>
    <div fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="20px" style="padding-top: 45px;">
        <div class="btn-border-orange pointer" [mat-dialog-close]="null" fxLayoutAlign="center center">
            <p class="font">{{'send-proposal.cancel' | translate}}</p>
        </div>
        <div fxLayoutAlign="center center" mat-dialog-close='remove'>
            <button class="btn-orange btn pointer">{{'send-proposal.accept' | translate}}</button>
        </div>
    </div>
</div>
