<div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="30px">
  <mat-card fxFlex="65" style="padding: 46px 45px 55px 33px">
    <p class="raleway-23-bold">Descripcion</p>
    <div class="descripcion">
      <p>
        {{ data?.description }}
      </p>
    </div>

    <div class="propuesta">
      <p *ngIf="isPostulation" class="raleway-23-bold">Enviar propuesta</p>
      <p *ngIf="!isPostulation"  class="raleway-23-bold">Propuesta enviada</p>
    </div>

    <div *ngFor="let pregunta of preguntas ;let i = index;" class="pregunta">
      <p>
        {{ pregunta.question }}
      </p>

      <textarea class="texto" [(ngModel)]="preguntas[i].response" name="" id="" cols="90" rows="4" [disabled]="!isPostulation"> </textarea>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      style="margin-top: 35px"
      fxLayoutGap="20px"
      *ngIf="isPostulation"
    >
      <button routerLink="/dashboard/teacher" class="btn-btn">CANCELAR</button>

      <div
        fxLayoutAlign="center center"
        style="margin-top: 35px"
        fxLayoutGap="20px"
      >
        <button (click)="save()" class="btn-btn">POSTULAR</button>
      </div>
    </div>
  </mat-card>

  <mat-card fxFlex="35" style="padding: 46px 32px 30px 32px">
    <div fxLayout="column" fxLayoutGap="24px">
      <div class="detalles">
        <p class="raleway-14-bold" *ngIf="iscourse">Detalles del Curso</p>
        <p class="raleway-14-bold" *ngIf="!iscourse">Detalles del Evento</p>
      </div>

      <div fxLayout="row" style="margin-top: 20px">
        <p class="raleway-18-g-bold item">Categoria:</p>
        <p class="raleway-18-g item espacio">{{ data?.extra.category }}</p>
      </div>

      <div fxLayout="row" style="margin-top: 20px">
        <p class="raleway-18-g-bold item">Titulo:</p>
        <p class="raleway-18-g item espacio">{{ data?.title }}</p>
      </div>

      <div fxLayout="row" style="margin-top: 20px">
        <p class="raleway-18-g-bold item">Nivel:</p>
        <p class="raleway-18-g item espacio">{{ data?.extra.level }}</p>
      </div>

      <div class="moneda">
        <div>
          <p class="oxigen-13 item">Precio Fijo / Por horas</p>
          <mat-form-field class="precio">
            <input [disabled]="!isPostulation" [(ngModel)]="precio" type="text" matInput />
          </mat-form-field>

          <mat-form-field class="precio">
            <mat-select [disabled]="!isPostulation" [(value)]="currencyselect">
              <mat-option
                class="money"
                *ngFor="let item of currency"
                [value]="item.id"
              >
                {{ item.code }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" style="margin-top: 20px">
        <p class="raleway-18-g-bold item">Tiempo estimado:</p>
        <p class="raleway-18-g item espacio">
          {{ data?.duration }}{{ arrayduration[data?.duration_unit] }}
        </p>
      </div>

      <div fxLayout="row" style="margin-top: 20px">
        <p class="raleway-14-bold empresa">Empresa/Cliente:</p>
        <p class="raleway-18-g espacio usuario">{{data?.extra.name_business}}</p>
      </div>

      <div fxLayout="row">
        <mat-icon>star</mat-icon>
      </div>

      <div>
        <p class="oxigen-13 item">Miembro desde</p>
        <p class="oxigen-13 item mes" style="margin-top: 12px">
          {{ data?.extra.member_since | date: "yyyy LLLL" }}
        </p>
      </div>

      <div fxLayout="row" style="margin-top: 20px">
        <p class="raleway-18-g item">{{ data?.extra.countPublish }}</p>
        <p class="raleway-18-g item espacio">Trabajos Publicados</p>
      </div>

      <div>
        <p class="raleway-14-bold place">Lugar</p>
        <p class="oxigen-13 item" style="margin-top: 12px">
          {{ data?.extra.country }} , {{data?.extra.city }},{{data?.extra.place }}
        </p>
      </div>

      <div>
        <p class="raleway-14-bold place">Horario</p>
        <p
          *ngFor="let fecha of diashorario"
          class="oxigen-13 item dia"
          style="margin-top: 12px"
        >
          {{ fecha.dia }}
          <span class="horas" *ngFor="let hora of fecha.horas">
            {{ hora.start_time.substring(0, 5) }}-{{
              hora.end_time.substring(0, 5)
            }}
          </span>
        </p>
      </div>
    </div>
  </mat-card>
</div>

<!-- <div  fxLayoutAlign="center center" fxflex style="margin-top: 35px">
  <mat-spinner strokeWidth="10" [diameter]="24"></mat-spinner>
</div> -->
