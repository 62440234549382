<div fxLayout="column" style="overflow-y: hidden">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <p class="relawey-23-bold" style="margin-bottom: 24px">Detalles de lugar</p>
    <button [mat-dialog-close]="null" class="btn-close pointer">
      <mat-icon aria-hidden="false">clear</mat-icon>
    </button>
  </div>
  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="Detalles">
      <div
        fxLayout="row"
        fxLayoutAlign="center stretch"
        fxLayoutGap="30px"
        class="m-tab"
      >
        <div style="width: 50%">
          <img
            *ngIf="data.element.main_image == null"
            src="assets/images/image-default.png"
            class="img"
          />
          <img
            *ngIf="data.element.main_image"
            [src]="data.element.main_image"
            class="img"
          />
        </div>
        <div fxLayout="column" fxLayoutGap="24px" fxFlex>
          <p class="relawey-23-bold">{{ data.element.Name }}</p>
          <p class="relawey-14">
            <b>{{ "dialog-place.category" | translate }}:</b>
            <b>{{ data.element.Category }}</b>
          </p>
          <p class="relawey-14">
            <b
              >{{ "dialog-place.ubication" | translate }}: {{ splitted[0] }},
              {{ splitted[1] }}</b
            >
          </p>
          <div
            fxflex="100"
            style="
              background-color: #e9e9e9;
              border-left: 23px #fe8426 solid;
              height: auto;
              padding: 15px 20px;
            "
          >
            <div fxLayout="row" fxLayoutGap="70px">
              <div fxLayout="column" fxLayoutGap="12px">
                <p class="oxygen-16-bold">
                  {{ "dialog-place.price" | translate }}
                </p>
                <p class="oxigen-18-bold">${{ data.element.amount }},0</p>
              </div>

              <div fxLayout="column" fxLayoutGap="16px">
                <p class="oxygen-16-bold">
                  {{ "dialog-place.capacity" | translate }}
                </p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <p class="oxigen-18-bold">
                    {{ data.element.capacity }}&#160; &#160;
                  </p>
                  <mat-icon style="font-size: 30px">people_alt</mat-icon>
                </div>
              </div>
              <div>

                <p class="oxygen-16-bold">
                 Cupos
                </p>
                <p class="oxigen-18-bold">
                  <input [(ngModel)]="cupos" class="cupos" type="text">
                </p>
              </div>
            </div>
          </div>
          <p class="oxygen-14-bold">
            <b>{{ "dialog-place.telephone" | translate }}</b>
            {{ data.element.phone }}
          </p>
          <p class="oxygen-14-bold">
            <b>{{ "dialog-place.mail" | translate }} </b
            >{{ data.element.email }}
          </p>
          <p class="oxygen-14-bold">
            <b>{{ "dialog-place.web" | translate }} </b>{{ data.element.web }}
          </p>
          <p class="oxygen-14-bold">
            <b>{{ "dialog-place.direction" | translate }}</b>
            {{ data.element.Ubication }}
          </p>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Descripcion">
      <div class="m-tab">
        <p class="oxigen-14-black">{{ data.element.description }}</p>
      </div>
    </mat-tab>

    <mat-tab label="Horario">
      <ng-template matTabContent>
        <div class="m-tab">
          <app-calendar
            [idNum]="data.element.id"
            [rentPlace]="data.showRent"
          ></app-calendar>
          <!-- <app-calendar [idNum]=2></app-calendar> -->
          <div fxLayoutAlign="center center" *ngIf="!serviceButton">
            <mat-spinner strokeWidth="10" [diameter]="48"></mat-spinner>
          </div>

          <div class="saltar">
            <div >
              <div>
                <div
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  *ngIf="
                    data.showRent == false &&
                    showMsg == 'active' &&
                    serviceButton
                  "
                  style="margin-top: 20px"
                >
                  <mat-icon class="btn-icon pointer">update</mat-icon>
                  <button class="btn btn-border"(click)="openDialogConfirm(data.element)">PRE-RESERVA</button>

                  <div
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    style="margin-left: 10px"
                    fxLayoutGap="20px"
                  >
                    <!-- <button (click)="onSave()" class="btn-orange btn">{{'ev-create.acept' | translate}}</button> ojo -->
                    <div
                      class="btn-border-orange pointer"
                      style="width: 230px !important"
                      fxLayoutAlign="center center"
                      (click)="openDialogConfirm(data.element,true)"

                    >
                      <p class="font"> PRE-RESERVA / SALTAR PROPUESTA</p>
                    </div>
                  </div>
                </div>
                <div class="errorcupos" *ngIf="cupos > data.element.capacity" >
                 * Excediste la capacidad de cupos
                </div>
              </div>
            </div>


              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                *ngIf="
                  data.showRent == true && showMsg == 'active' && serviceButton
                "
                style="margin-top: 20px"
              >
                <mat-icon class="btn-icon pointer">update</mat-icon>
                <button (click)="openDialogConfirm(data.element)" class="btn btn-border">Pre-reserva</button>
                <div
                fxLayout="row"
                fxLayoutAlign="center center"
                style="margin-left: 10px; margin-top: auto"
                fxLayoutGap="20px"
              >
                <!-- <button (click)="onSave()" class="btn-orange btn">{{'ev-create.acept' | translate}}</button>   ojo -->
                <div
                  class="btn-border-orange pointer"
                  style="width: 230px !important"
                  fxLayoutAlign="center center"
                  (click)="openDialogConfirm(data.element,true)"
                >
                  <p class="font"> PRE-RESERVA / SALTAR PROPUESTA</p>
                </div>
              </div>
              </div>



          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- <div fxflex="100" fxLayout="column" fxLayoutAlign="center start" style="height: 480px; width: 920px;">
    <p class="relawey-28-bold-orange">title{{data.element.Name}}</p>

    <div fxLayout="column" fxflex="50">
        <div fxLayout="row" style="margin-top: 15px;">
            <p class="relawey-18-bold">{{'dialog-place.category' | translate }}: </p> &#160; &#160;
            <p class="relawey-18-bold-blue pointer"> {{data.element.Category}}categoria</p>
        </div>
        <p class="relawey-19" style="margin-top: 12px;">{{'dialog-place.ubication' | translate }}: {{splitted[0]}},
            {{splitted[1]}} </p>
    </div>
    <div fxLayout="row" fxFlex fxLayoutGap="20px" fxLayoutAlign="center start">
        <div fxLayout>
            <img *ngIf="data.element.main_image==null" src="https://i.imgur.com/fIHlXos.jpg" class="img">
            <img *ngIf="data.element.main_image" [src]="data.element.main_image" class="img">
        </div>


        <div fxLayout="column" fxflex>
            <mat-tab-group mat-align-tabs="center">
                <mat-tab label="Detalles" class="relawey-18-bold">
                    <div style="padding-left: 20px; padding-right: 20px;">
                        <div fxflex="100"
                            style=" background-color: #E9E9E9; border-left: 23px #FE8426 solid; height: auto; margin-top: 20px; padding-top: 18px;padding-bottom: 16px; padding-left: 20px; padding-right: 50px;">
                            <div fxLayout="row" fxLayoutGap="70px">
                                <div fxLayout="column" fxLayoutGap="12px">
                                    <p class="oxygen-14-bold">{{'dialog-place.price' | translate }}</p>
                                    <p class="oxigen-18-bold">${{data.element.amount}},0</p>
                                </div>
                                <div fxLayout="column" fxLayoutGap="12px">
                                    <p class="oxygen-14-bold">{{'dialog-place.capacity' | translate }}</p>
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <p class="oxigen-18-bold">{{data.element.capacity}}&#160; &#160;</p>
                                        <mat-icon style="font-size: 30px;">people_alt</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxLayout="column" fxLayoutGap="15px" style="margin-top: 20px;">
                            <div fxLayout="row">
                                <p class="oxygen-16-bold">{{'dialog-place.telephone' | translate }}&#160;</p>
                                <p class="oxygen-16">{{data.element.phone}}</p>
                            </div>
                            <div fxLayout="row">
                                <p class="oxygen-16-bold">{{'dialog-place.mail' | translate }}&#160;</p>
                                <p class="oxygen-16">{{data.element.email}} elcorreo@com</p>
                            </div>
                            <div fxLayout="row">
                                <p class="oxygen-16-bold">{{'dialog-place.web' | translate }}&#160;</p>
                                <p class="oxygen-16">{{data.element.web}} laweb</p>
                            </div>
                            <div fxLayout="row">
                                <p class="oxygen-16-bold">{{'dialog-place.direction' | translate }}&#160;</p>
                                <p class="oxygen-16">{{data.element.Ubication}} direccion</p>
                            </div>
                        </div>
                    </div>
                </mat-tab>




                <mat-tab class="relawey-18-bold" label="Descripcion">
                    <div style="padding: 20px 7px; padding-left: 20px; padding-right: 20px;">
                        <p class="oxygen-16">Detalles Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                            Consequuntur commodi explicabo voluptatibus id, ipsam et itaque quae. Molestiae sit harum
                            voluptatum beatae, itaque nesciunt quaerat, aliquam tenetur repellat impedit qui!</p>
                    </div>

                </mat-tab>




                <mat-tab class="relawey-18-bold" label="Horario">
                    <div fxLayout="column" style="padding-top: 20px; padding-left: 20px; padding-right: 20px;">
                        <p class="oxygen-16">startweek: {{data.element.start_time_week}}</p>
                        <p class="oxygen-16">endweek: {{data.element.end_time_week}}</p>
                        <p class="oxygen-16">start_time_weekend: {{data.element.start_time_weekend}}</p>
                        <p class="oxygen-16">end_time_weekend: {{data.element.end_time_weekend}}</p>
                    </div>
                </mat-tab>
            </mat-tab-group>

            <div fxLayout="row" fxLayoutAlign="center center" routerLink="" *ngIf="data.showRent"
                style="margin-top: 20px;">
                <mat-icon class="btn-icon pointer">update</mat-icon>
                <button class=" btn btn-border">Pre-reserva</button>
            </div>
        </div>

    </div>

</div> -->
