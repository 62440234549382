
<div class="row">
    <div class="col-md-8 col-xs-8">
        
    </div>
    <div class="col-md-3 col-xs-3" *ngIf="status">
      <mat-form-field class="example-full-width">
        <mat-label>Buscar Conversacion</mat-label>
        <input type="text" matInput placeholder=""  [(ngModel)]="contentMsg" (input)="Search(contentMsg)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="col-md-1 col-xs-1" *ngIf="status">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>settings</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <!-- (click)="openDialog()" -->
              <button mat-menu-item (click)="openDialog()">
                <mat-icon>add</mat-icon>
                <span>Agregar Usuario</span>
              </button>
              <a mat-menu-item href="/dashboard/ofert-create">
                <mat-icon>fact_check</mat-icon>
                <span>Crear Oferta</span>
              </a>
          </mat-menu>
    </div>

    <div class="col-md-12 col-xs-12 scroll" id="content-box-chat" (scroll)="scroll($event)">
          <mat-card class="cards-user" id="box-chat"><!-- ngIf="status" -->
            <div class="row"  *ngFor="let dat of childMessage; let i=index">
              
              <!--ngFor="let tests of test; let i=index"-->
                <div class="col-md-1 col-xs-1" style="padding-top: 15px; padding-bottom: 15px;">
                   <span matBadge="." ><img width="100%" [src]="dat.avatar"></span>
                </div>
                <div class="col-md-10 col-xs-10" style="padding-top: 15px; padding-bottom: 15px;">
                  <div class="row">
                    <div class="col-md-11">
                      <strong class="title">{{dat.fullname}}</strong>
                    </div>
                    <div class="col-md-1">
                      <i  class="subtitle">{{ moment(dat.created_at).format("DD-MM-YYYY HH:mm")  }}</i>
                    </div>
                  </div>

                    <p  id="text-{{dat._id}}" class="subtitle" style="max-width: 70%; overflow-wrap: break-word;"> {{dat.message}}</p>
                    <!-- <img *ngIf="dat.attachment != ''"  src="http://localhost:3000/{{dat.message}}" width="400" alt=""> -->
                    <mat-form-field id="text-box-{{dat._id}}" class="example-full-width " style="display: none;" >
                      <textarea (keyup.enter)="sendEdit(dat._id)" id="text-input-{{dat._id}}" (blur)="onBlur()" (focus)="onFocus()" matInput>{{dat.message}}</textarea>
                    <!-- <p id="message{{tests.message_id}}" class="subtitle">{{tests.message}}</p>
                    <mat-form-field class="example-full-width " *ngIf="!tests.hidden" id="edit{{tests.message_id}}"> -->
                    </mat-form-field>
                </div>
                <div *ngIf=" userData.id == dat.user_id " class="col-md-1 col-xs-1" style="padding-top: 15px; padding-bottom: 15px;">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>settings</mat-icon>
                      </button>
                       
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="edit( dat._id )">
                          <mat-icon>create</mat-icon>
                          <span>Editar</span>
                        </button>
                        <button mat-menu-item (click)="delete(dat._id)">
                          <mat-icon>delete</mat-icon>
                          <span>Eliminar</span>
                        </button>
                      </mat-menu>
                </div>
                <mat-divider style="padding-top: 10px;"></mat-divider>
            </div>
        </mat-card>
    </div>
    
</div>