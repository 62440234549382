import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventsService } from '../../../../services/events.service';
import { GeneralService } from '../../../../services/general.service';

import { CourseDashboardService } from '../../../../services/course-dashboard.service';
import { Observable, Subscription } from 'rxjs';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { map, retry, startWith } from 'rxjs/operators';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-edit-course',
  templateUrl: './edit-course.component.html',
  styleUrls: ['./edit-course.component.scss'],
})
export class EditCourseComponent implements OnInit {
  constructor(
    private CourseDashboardService: CourseDashboardService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private EventsService: EventsService,
    private _router: Router,
    private GeneralService: GeneralService
  ) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags.slice()
      )
    );
  }

  minDate = new Date();
  form: FormGroup;
  id;
  images;
  jobs = false;
  displayedColumns = ['name', 'identity', 'age', 'email'];
  dataSource;

  visible = true;
  selectable = true;
  removable = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  // fulltags =[{name:"", id:-1}];

  loadPage = false;
  load = true;
  loadService = false;

  places = [];
  categories = [];
  events;

  teacher = [];
  loadContent = [];
  loadTags = [];
  chips: string[];
  flagImage = false;
  image;

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  currency;
  url;
  urls: string = '';
  src_img: string = '';
  file;
  showUpload = false;
  files=null;
  loadSchedule: boolean = false;
  courseSelected=null;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  ngOnInit(): void {
    // this.events = JSON.parse(localStorage.getItem('events'));
    // console.log("---localstorage-event-edit", this.events);

    this.courseSelected=JSON.parse(localStorage.getItem('courseSelected'))
    let URLactual = window.location.href;

    this.msgSuscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });

    this.GeneralService.get_currencies().subscribe(
      (res) => {
        console.log('res get_currencies:', res);
        this.currency = res.currency;
      },
      (err) => {
        console.log('ERRor get_currencies', err);
      }
    );

    this.form = this.fb.group({
      name: ['', Validators.required],
      category_id: ['', Validators.required],
      // place: [''],
      place: new FormControl({ value: '', disabled: true }),
      dateStart: ['', Validators.required],
      dateEnd: ['', Validators.required],
      availability: ['', [Validators.required,Validators.max(this.courseSelected?.capacity)]],
      enrollment: [this.courseSelected?.capacity, Validators.required],
      price: [''],
      // timeStart: [''],
      // timeEnd: [''],
      description: ['', Validators.required],
      content: [''],
      level_id: ['', Validators.required],
      age: [''],
      //evaluate: ['',],
      requirements: [''],
      // visible: [''],
      currency_id: [''],
      url: [''],
      learn: [''],
      aimed_to: [''],
    });
    this.form.controls['enrollment'].disable();


    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
      console.log('---page:id', this.id);
      this.loadInfoEvent();
    });

    if (URLactual.indexOf('/jobs/') != -1) {
      this.get_data_students();
      this.jobs = true;
    }
    this.form.get('category_id').valueChanges.subscribe((value) => {
      console.log(value);
    });
  }

  get_data_students() {
    let ELEMENT_DATA = [];

    this.CourseDashboardService.getAllUsersByCourse(1).subscribe(
      (res) => {
        res.listStudent.forEach((element) => {
          ELEMENT_DATA.push({
            name: element.User,
            identity: element.dni,
            age: element.age,
            email: element.email,
          });
        });
        this.dataSource = ELEMENT_DATA;
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
  }

  get_data_ratings() {}

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our tag
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(
      (tag) => tag.toLowerCase().indexOf(filterValue) === 0
    );
  }

  loadInfoEvent() {
    this.CourseDashboardService.get_coursesById(this.id).subscribe(
      (res) => {
        console.log('res get_coursesById/', this.id, '  :', res);
        this.events = res.course[0];
        this.teacher = res.course_teacher;
        const imagenes=JSON.parse(this.events.images)?.map((imagen)=>(environment.url_server.replace('api/', ""))+imagen)
        console.log(imagenes)
        this.image = imagenes;
        this.loadDataAtForm();
        this.loadPage = true;
      },
      (err) => {
        console.log('ERRor get_adminEvents', err);
      }
    );

    this.EventsService.get_admin_courseSchedule(this.id).subscribe(
      (res: any) => {
        console.log(
          'res get_admin_courseSchedule on edit:',
          this.id,
          '  :',
          res
        );
        if (res.message_return.ERROR == true) {
          this.loadSchedule = true;
        } else {
          this.loadSchedule = false;
        }
      },
      (err) => {
        console.log('ERRor get_adminEvents', err);
      }
    );
  }

  setCategory() {
    this.CourseDashboardService.get_courses_create().subscribe((resp) => {
      this.categories = resp.course_categories;

      this.form.get('category_id').setValue(this.events.category_id);
    });
  }

  loadDataAtForm() {
    let cat: number, pla: number;

    this.EventsService.get_event_create().subscribe(
      (res) => {
        console.log('get_event_create', res);

        // this.places = res.places.original.places; ya no
        res.tags.original.tags.forEach((element) => {
          this.allTags.push(element.name);
          this.loadTags.push(element);
        });
        // this.load = false;

        for (let i = 0; i < this.categories.length; i++) {
          if (this.categories[i].name == this.events.category) {
            cat = this.categories[i].id;
            break;
          }
        }
        console.log('cat:', cat);

        let splitTags =
          this.events.tags != null ? this.events.tags.split(',') : [];
        for (let i = 0; i < splitTags.length; i++) {
          this.tags.push(splitTags[i]);
        }

        let dateS;
        let dateE;
        if (this.events.start_date != null) {
          let divide: String = this.events.start_date.split('-');
          dateS = new Date(
            Number(divide[0]) +
              '-' +
              Number(divide[1]) +
              '-' +
              Number(divide[2])
          );
          divide = this.events.end_date.split('-');
          dateE = new Date(
            Number(divide[0]) +
              '-' +
              Number(divide[1]) +
              '-' +
              Number(divide[2])
          );
        }

        console.log(
          'cat:',
          cat,
          ' pla:',
          this.events.place,
          ' dateS:',
          dateS,
          ' dateE:',
          dateE,
          ' this.events:',
          this.events
        );

        this.form.reset({
          name: this.events.course_name,
          // category_id: this.events.category_id,
          place: this.events.place ? this.events.place : null,
          dateStart: new Date(this.events.start_date),
          dateEnd: new Date(this.events.end_date),
          availability: this.events.availability,
          enrollment: this.courseSelected?.capacity ,
          price: this.events.price ? this.events.price : 0,
          // timeStart: this.events.start_hour,
          // timeEnd: this.events.end_hour,
          description: this.events.description,
          content: this.events.content,
          level_id: this.events.level_id,
          age: this.events.age,
          evaluate: this.events.quantitative,
          requirements: this.events.requirements,
          url: this.events.url,
          currency_id: this.events.currency_id,
          visible: this.events.visibility,
          learn: this.events.learn,
          aimed_to: this.events.aimed_to,
        });
        // this.form.controls['place'].disable();
        this.urls = this.events.url;
        this.setCategory();
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
  }

  receiveSrcImgMain(event) {
    console.log('principal src');
    this.src_img = event;
  }

  receiveFileMain(file) {
    console.log('principal file');
    this.file = file;
  }

  save() {
    console.log(this.form);
    console.log(this.form.valid)
    if (
      this.form.status == 'VALID' &&
      this.tags.length > 0
    ) {
      this.loadService = true;
      let array_tags = [];
    this.tags.forEach((element) => {
      for (let i = 0; i < this.loadTags.length; i++) {
        if (element == this.loadTags[i].name) {
          array_tags.push({
            tag_id: this.loadTags[i].id,
            tag_name: this.loadTags[i].name,
          });
        }
      }
    });
    this.files = this.GeneralService.get_carouselFile();
console.log(this.files)
    console.log('arra_tags:', array_tags);
      const request = {
        name: this.form.get('name').value,
        category_id: this.form.get('category_id').value,
       course_category_id: this.form.get('category_id').value,
        enrollment: this.form.get('enrollment').value,
        availability: this.form.get('availability').value,
        price: this.form.get('price').value,
        description: this.form.get('description').value,
        content: this.form.get('content').value,
        level_id: this.form.get('level_id').value,
        approved: 0,
        age: this.form.get('age').value,
        url: this.form.get('url').value,
        state: 1,
        currency_id: this.form.get('currency_id').value,
        learn: this.form.get('learn').value,
        aimed_to: this.form.get('aimed_to').value,
        requirements: this.form.get('requirements').value,
        tag: array_tags,
        // file: this.file,
        // fileArray: this.files,
      };
      console.log(request);
      this.CourseDashboardService.updateCourse(request,this.id).subscribe(
        (res:any) => {
          console.log('res editar', res);

          if (res.message_return.CODE === 200) {
            this.guaradarImagenes()
            this.loadService = false;
            this.GeneralService.typeMsg$.emit('success');
            this.GeneralService.msg$.emit('Modificacion Exitosa');
          } else {
            this.GeneralService.typeMsg$.emit('error');
            this.GeneralService.msg$.emit('error server');
            this.loadService = false;
          }
        },
        (err) => {
          console.log('ERRor put_admin_events', err);
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit('error server');
          this.loadService = false;
        }
      );
    } else {
    }

  }
  guaradarImagenes() {
    this.files = this.GeneralService.get_carouselFile();
    console.log(this.files)
    console.log(this.file)
     this.GeneralService.uploadGalleryandMainImages(this.files, this.id,(this.file?.length>0)?this.file[0]:null).pipe(retry(3)).subscribe(

       (res)=>{
         console.log("responde", res);
       }
     );
     console.log(this.image)
  }

  onUpload(e) {
    console.log('subir', e.target.files[0]);
  }

  get validNameEvent() {
    return this.form.get('name').invalid && this.form.get('name').touched;
  }

  get validCategory() {
    return (
      this.form.get('category_id').invalid &&
      this.form.get('category_id').touched
    );
  }

  get validAvailability() {
    return (
      this.form.get('availability').invalid &&
      this.form.get('availability').touched
    );
  }

  get validCapacity() {
    return (
      this.form.get('enrollment').invalid && this.form.get('enrollment').touched
    );
  }

  get validPrice() {
    return this.form.get('price').invalid && this.form.get('price').touched;
  }

  get validDescription() {
    return (
      this.form.get('description').invalid &&
      this.form.get('description').touched
    );
  }

  get validContent() {
    return this.form.get('content').invalid && this.form.get('content').touched;
  }

  get validLevel() {
    return (
      this.form.get('level_id').invalid && this.form.get('level_id').touched
    );
  }

  get validAge() {
    return this.form.get('age').invalid && this.form.get('age').touched;
  }

  get validEvaluate() {
    return (
      this.form.get('evaluate').invalid && this.form.get('evaluate').touched
    );
  }

  flagIma() {
    console.log('activa flagImage');
    this.flagImage = true;
  }

  redirectToTeacherList() {
    this._router.navigateByUrl(
      '/dashboard/courses/list-teachers/' + this.events.proposal_id
    );
  }

  @Input() SendUrl: string = '';
  onKey($event) {
    this.urls = $event.target.value;
    console.log('onKey', this.urls);
  }

  errorHandler(event) {
    console.debug(event);
    event.target.src = "./assets/images/person-default.svg";
 }
}
