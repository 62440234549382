<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px"
    style="height: 700px;background: url({{images}});background-size: cover;">
    <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" style="padding-top:3em;
        padding-bottom: 6em;">
            <mat-icon >{{icons}}</mat-icon>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" style="text-align: center;">
            <p class="subtitle">{{textOne}}</p>    
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
            <div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center stretch" style="background: {{color}}; ">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p class="title-yellow">{{textFirst}}</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p class="subtitle-yellow">
                        {{textSecond}}
                        </p>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" style="padding-bottom: 5em;">
            <button routerLink="/about-us" mat-button class="example-icon favorite-icon style-login registrate" style="margin: 0px 20px;" >
                APRENDE MÁS DE NOSOTROS
            </button>
        </div>
    </div>
</div>