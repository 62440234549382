<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
        <app-header></app-header>
        <app-sub-header *ngIf="load" [screen]="1"></app-sub-header>

        <div class="container-fluid">
            <div class="row">
                <div class="col col-md-6 col-lg-8">
                    <div class="containerCourseDetails">
                        <div class="row">
                            <div class="col-md-12">
                                <h2 class="title">Nombre del curso: {{courses.courseName}}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p *ngIf="load" class="subtitle">Categoria: {{courses.category}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p *ngIf="load" class="description">{{courses.description}}</p>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-12">
                                <mat-chip-list aria-label="Fish selection" class="space-image">
                                    <mat-chip *ngFor="let item of tags">{{item}}</mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row" style="width: 100%;">
                                    <div class="col-md-12 col-xs-12">
                                        <app-slide-course-details *ngIf="load" [images]="imagesCarousel"></app-slide-course-details>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="sectionSocial">
                                    <p class="space">Compartir <span style="margin-left: 10px;"> <img class="pointer" src="/assets/images/facebook_copia.png" width="25px" alt="Facebook">
                                      <img class="pointer" src="/assets/images/twitter_copia_2.png" width="25px" alt="Twitter">
                                      <img class="pointer" src="/assets/images/link_copia.png" width="25px" alt="Enlace"></span></p>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12" >
                                <mat-tab-group>
                                    <mat-tab label="Video">
                                        <div class="long-tabs">
                                            <iframe width="100%" height="500" [src]="videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-md-6 col-lg-4">
                    <div class="sidebarRight">
                        <div class="gray-box box-price">
                            <p *ngIf="load" class="p-space prices-big">$ {{courses.price}} USD</p>
                        </div>
                        <div class="item-event mt-5  row g-0">
                            <div class="col-8 col-sm-9 col-lg-9">
                                <button mat-raised-button fxFlex
                                *ngIf="isLoggedIn && allIn"
                                class="example-icon favorite-icon style-login registrate asd"
                                style="margin: 0px 20px; font-size: 12px;"
                                [disabled]="true">
                                Ya se encuentra registrado
                                </button>
            
                                <!-- Botón para usuarios no registrados -->
                                <button mat-raised-button fxFlex
                                *ngIf="isLoggedIn && !allIn"
                                (click)="openDialog()"
                                class="example-icon favorite-icon style-login registrate asd"
                                style="margin: 0px 20px; font-size: 12px;">
                                Agregar curso
                                </button>
            
                                <!-- Botón para usuarios no registrados (caso de no estar loggeado) -->
                                <button mat-raised-button fxFlex
                                *ngIf="!isLoggedIn"
                                (click)="redirectToLogin()"
                                class="example-icon favorite-icon style-login registrate asd"
                                style="margin: 0px 20px; font-size: 12px;">
                                Agregar curso
                                </button>
                            </div>
                            <div class="col-2 ">
                                <a *ngIf="isLoggedIn && courses" (click)="storeOrDelete(courses.productId)" mat-button class="example-icon favorite-icon style-login registrate2" [ngClass]="{'favorite': courses.isFavorite === 1, 'no-favorite': courses.isFavorite === 0}">
                                    <mat-icon>{{ courses?.isFavorite === 1 ? 'favorite' : 'favorite_border' }}</mat-icon>
                                  </a>         
                            </div>
                        </div>
                        <div style="padding-bottom: 30px;">
                            <div class="row ms-3 mt-3" style="width: 100%;">
                                <span class="date">Fecha de inicio:<span class="date-bold ms-2" *ngIf="load">{{format_data(courses.start_date)}}</span></span>
                                <span class="date">Disponibilidad: <span class="date-bold ms-2" *ngIf="load">{{courses.availability}}</span></span>
                                <span class="date">Edad:<span class="date-bold ms-2" *ngIf="load">{{courses.age}}</span> </span>
                                <span class="date">Nivel:<span class="date-bold ms-2" *ngIf="load">{{courses.level}}</span> </span>
                                <span class="date">Evaluado:<span class="date-bold ms-2" *ngIf="load">{{courses.approved ? 'Si': 'No'}}</span> </span>
                            </div>
                           
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="gray-box">
                                    <p *ngIf="load" class="p-space academy">Acerca del curso</p>
                                </div>
                                <div class="sideUser">
                                    <div class="row ms-3">
                                        <div class="col-3">
                                            <img *ngIf="load" src="/assets/images/default.png" width="100%" alt="">
                                        </div>
                                        <div class="col-9">
                                            <div class="row">
                                                <p *ngIf="load" class="address-user ">Responsable:</p>
                                                <p style="display: block;" class="name-user">{{course_teachers.name}} {{course_teachers.last_name}} </p>
                                                <p *ngIf="load" class="address-user">San Cristobal, Venezuela</p>
                                            </div>
                                            <div class="item-event">
                                                <div class="row">
                                                    <span *ngIf="load" fxFlex [ngSwitch]="star" class="start-rigth">
                                                        <span class="star" *ngSwitchCase="0"><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                            <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                            <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                            <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                            <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon></span>
                                                            <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
                                                    </span>
                                                    <a class="start-rigth width-full-side link-start" href="/detail/teacher-public/{{course_teachers.id}}">Ver Calificaciones</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="gray-box">
                                    <p *ngIf="load" class="p-space academy">Acerca del Anfitrion</p>
                                </div>
                                <div class="pt-teachers pb-teachers">
                                    <div class="row ms-3"  routerLink="/bio-teacher/1" title="Ver bio de Jose Rojas">
                                        <div class="col-3">
                                        </div>
                                        
                                    </div>
                                    <div class="row">
                                        <p  class="itemTeacher">
                                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> No Disponible</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="gray-box">
                                    <p *ngIf="load" class="p-space academy">Horario</p>
                                </div>
                                <div class="pt-schedule pb-schedule">
                                    <div class="row">
                                        <p *ngIf="load && schedule.length > 0" class="itemSchedule">
                                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> Fecha de Inicio 10/10/2020: 10:00 - 15:00</p>
                                    </div>
                                    <div class="row">
                                        <p *ngIf="load && schedule.length > 0" class="itemSchedule">
                                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> Fecha de Fin 10/10/2020: 10:00 - 15:00</p>
                                    </div>
                                    <div class="row">
                                        <p *ngIf="load && schedule.length == 0" class="itemSchedule">
                                            <mat-icon class="icon-s">fiber_manual_record</mat-icon> No Disponible</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    <app-footer></app-footer>
    </div>
</mat-drawer-container>