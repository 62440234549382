import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SocketIoService } from '../../../../services/socket-io.service'
import { User } from 'src/app/auth/reset-password/reset-password.component';

interface UserData {
  id: string ;
  name: string;
  avatar: string;
  last_name: string;
}


@Component({
  selector: 'app-modal-contact-message',
  templateUrl: './modal-contact-message.component.html',
  styleUrls: ['./modal-contact-message.component.scss']
})
export class ModalContactMessageComponent implements OnInit {
  title = 'frontend';
  newMessage = 'hola desde el fronted';
  messageList: string[] = [];
  affair;
  idMeet;

  constructor(

    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,

    private socket: SocketIoService,
    public dialogRef: MatDialogRef<ModalContactMessageComponent>
    )
{} 
textMessage: string;
message;

ngOnInit(): void {
  // this.socketIoService.getMessage().subscribe((message:string) => {
  //   this.messageList.push(message)
  // })
}

sendMessage() {
  if (this.textMessage && this.affair) {
    const datas=JSON.parse(localStorage.getItem('userData'))
    const user: UserData = {
     id: String(datas.id),
     name: datas.name,
     avatar: datas.avatar,
     last_name: datas.last_name
  
   }
   const user2 : UserData ={
    id: String(this.data.user_id),
    name: this.data.user_name,
    avatar: this.data.avatar,
    last_name: ""
  
   }
  
    this.socket.register(user);
    this.socket.register(user2);
    console.log("test del usuario que es el teacher");
    console.log(user2); 
    setTimeout(() => {
      this.socket.newMeeting(user.id, user2.id, this.affair)
    }, 4000);
  
    const menssagesLast = this.socket.listenIdMeet().subscribe(
      (data: any) => {
        this.idMeet = data;
        console.log(this.idMeet)
        console.log(this.idMeet)
        this.socket.JoinMeeting(this.idMeet);
        this.message = {
          meeting_id : this.idMeet  ,
          message: this.textMessage,
          user_id: user.id,
          image: user.avatar,
          typeFile: "data.typeFile",
          fullname: user.name,
        }
        this.socket.SenderMessage(this.message)
      },
      error => {
        console.error('Error en la solicitud HTTP:', error);
      }
    );
  
  
    this.dialogRef.close();
  }
 
}

}
