<div fxLayout="row" style="margin-top: 13px" fxLayoutAlign="end center">
  <mat-icon class="icon-grey" style="color: black">folder</mat-icon>
  <div>
    <a class="raleway-14-b" href="">Panel/</a>
    <a class="raleway-14-b" href=""> Eventos/</a>
    <a class="raleway-14-b" href=""> Detalles</a>
  </div>
</div>
<div
  fxLayout="row"
  fxLayoutAlign="center stretch"
  fxLayoutGap="150px"
  style="margin-top: 20px"
>
  <div fxLayout="column" fxLayoutAlign="start start">
    <p class="raleway-23-g-bold">Detalles del evento</p>

    <p
      style="margin-top: 53px"
      class="raleway-18-g-bold"
      style="margin-top: 30px"
    >
      {{ detail.title }}
    </p>
    <div fxLayout="row" style="margin-top: 20px">
      <p class="raleway-18-g-bold">Categoria:</p>
      <p class="raleway-18-g">{{ detail.category }}</p>
    </div>
    <p
      *ngIf="detail.place != null"
      class="raleway-18-g-bold"
      style="margin-top: 20px"
    >
      {{ detail.place }}
    </p>
    <p
      *ngIf="detail.place == null"
      class="raleway-18-g-bold"
      style="margin-top: 20px"
    >
      Sin place Asigando
    </p>

    <div fxLayout="row" fxLayoutGap="100px" class="div-border-orange">
      <div fxLayout="column" fxLayoutAlign="center start">
        <p class="oxigen-16-g-bold">Precio:</p>
        <p *ngIf="detail.price != null" class="oxigen-23-g-bold">
          {{ detail.price }} {{ detail.currency }}
        </p>
        <p *ngIf="detail.price == null" class="oxigen-23-g-bold">Sin asignar</p>
      </div>
      <div fxLayout="column" fxLayoutAlign="center start">
        <p class="oxigen-16-g-bold">Matricula:</p>
        <p
          *ngIf="detail.availability != null && detail.capacity != null"
          class="oxigen-23-g-bold"
        >
          {{ "ev-detail.room" | translate }}: {{ detail.availability }}/{{
            detail.capacity
          }}
        </p>
        <p
          *ngIf="detail.availability == null || detail.capacity == null"
          class="oxigen-23-g-bold"
        >
          Sin asignar
        </p>
      </div>
    </div>

    <div style="margin-top: 35px">
      <mat-chip-list>
        <p class="tags">Tags</p>
        <div *ngFor="let item of chips">
          <mat-chip class="raleway-14-g"
            ><b>{{ item }}</b></mat-chip
          >
        </div>
      </mat-chip-list>
    </div>
  </div>

  <div>
    <div *ngIf="user.rol_id===1"
      fxLayout="row"
      style="width: 100%"
      fxLayoutAlign="end none"
      class="m-top"
      routerLink="/dashboard/events/edit/{{ id }}"
    >
      <mat-icon class="btn-icon pointer">edit</mat-icon>
      <button class="btn btn-border">MODIFICAR</button>
    </div>
    <img
      *ngIf="detail.main_image == null"
      style="margin-top: 20px"
      src="assets/images/image-default.png"
      alt=""
      class="img"
    />
    <img
      *ngIf="detail.main_image != null"
      style="margin-top: 20px"
      [src]="detail.main_image"
      alt=""
      class="img"
    />
  </div>
</div>

<mat-tab-group mat-align-tabs="center" style="margin-top: 40px">
  <mat-tab label="Datos basicos">
    <div fxLayout="column" class="m-tab">
      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p class="oxigen-14-b-bold">Fecha:</p>
        <p
          *ngIf="detail.start_date != null && detail.end_date != null"
          class="oxigen-14-b"
        >
          {{ detail.start_date }} - {{ detail.end_date }}
        </p>
        <p
          *ngIf="detail.start_date == null || detail.end_date == null"
          class="oxigen-14-b"
        >
          Sin asignar
        </p>
      </div>
      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p class="oxigen-14-b-bold">Nivel:</p>
        <p *ngIf="detail.level != null" class="oxigen-14-b">
          {{ detail.level }}
        </p>
        <p *ngIf="detail.level == null" class="oxigen-14-b">Sin asignar</p>
      </div>
      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p class="oxigen-14-b-bold">Edad:</p>
        <p *ngIf="detail.age != null" class="oxigen-14-b">{{ detail.age }}</p>
        <p *ngIf="detail.age == null" class="oxigen-14-b">Sin asignar</p>
      </div>
      <!-- <div fxLayout="row" style="margin-top: 20px;" fxLayoutGap="5px">
                <p class="oxigen-14-b-bold">Visibilidad: </p>
                <p *ngIf="detail.visibility==0" class="oxigen-14-b">Publico</p>
                <p *ngIf="detail.visibility==1" class="oxigen-14-b">Privado</p>
            </div> -->
      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p class="oxigen-14-b-bold">Evaluado:</p>
        <p *ngIf="detail.approved == 0" class="oxigen-14-b">NO</p>
        <p *ngIf="detail.approved == 1" class="oxigen-14-b">SI</p>
      </div>
      <p class="oxigen-14-b-bold" style="margin-top: 20px">Descripcion:</p>
      <p class="oxigen-14-b" style="margin-top: 15px">
        {{ detail.description }}
      </p>
    </div>
  </mat-tab>

  <mat-tab label="{{ 'ev-detail.content' | translate }}" class="mtab">
    <div class="m-tab">
      <!-- <div *ngFor="let item of event_content">
                <p class="paragraph"> <b>{{item.objetive}}</b></p>
                <p *ngFor="let subitem of item.content" class="paragraph"> * {{subitem}}</p><br>
            </div> -->
      <p class="oxigen-14-b" style="margin-top: 15px">{{ detail.content }}</p>
    </div>
  </mat-tab>

  <mat-tab label="Horario" class="mtab">
    <div class="m-tab">
      <ng-template matTabContent>

        <app-calendar
          *ngIf="detail.place != null"
          [idNum]="id"
          [modify]="false"
        ></app-calendar>
        <p *ngIf="detail.place == null">No posee horario</p>
      </ng-template>
    </div>
  </mat-tab>

  <mat-tab label="Responsable del Evento" class="mtab">
    <div
          *ngFor="let item of teacher"
          fxLayout="row"
          style="
            border-bottom: 1px solid black;
            padding-bottom: 20px;
            padding-top: 20px;
          "
        >
          <img [src]="item.avatar" alt="" class="avatar" (error)="errorHandler($event)" />
          <div fxLayout="column" style="margin-left: 20px">
            <p class="teacher">{{ item.name }} {{ item.last_name }}</p>
            <p class="skill" style="margin-top: 15px">{{ item.title }}</p>
            <mat-chip-list style="margin-top: 10px">
              <div *ngFor="let tags of item.skill">
                <mat-chip
                  ><b>{{ tags }}</b></mat-chip
                >
              </div>
            </mat-chip-list>
          </div>
        </div>
  </mat-tab>

  <mat-tab
    label="{{ 'ev-detail.image' | translate }}/{{
      'ev-detail.video' | translate
    }}"
    class="mtab"
  >
    <ng-template matTabContent>
      <div
        fxLayout="row"
        fxLayoutAlign="space-around stretch"
        fxLayoutGap="20px"
        style="padding: 18px"
      >
        <mat-card fxLayout="column" fxFlex="35" style="height: 500px">
          <app-video-youtube [SendUrl]="urls"></app-video-youtube>
          <div
            fxLayout="column"
            fxLayoutAlign="end stretch"
            fxFlex
            fxLayoutGap="18px"
          >
            <p class="title-card">Video</p>
            <mat-form-field>
              <mat-label class="label-input">{{
                "place.urlYoutube" | translate
              }}</mat-label>
              <textarea
                [(ngModel)]="youtube"
                (keyup)="onKey($event)"
                matInput
                placeholder="https://www.youtube.com/watch?v=ozXIAg7zp9I"
              ></textarea>
            </mat-form-field>
          </div>
        </mat-card>
        <mat-card
          fxLayout="column"
          fxFlex="65"
          fxLayoutAlign="space-between stretch"
          fxFlex
          fxLayoutGap="20px"
          class="card"
        >
          <app-carousel [arrayImage]="images" [edit]="false"></app-carousel>
        </mat-card>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
