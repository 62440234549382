<div  fxLayout="row" fxLayout.xs="column">
    <section *ngIf="!isMobile" class="left-o-box" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="20px">
        <img routerLink="auth/login" width="100%" class="pointer"src="/assets/images/isotipo-white.svg" />
        <div>
            <p class="text-white-orange-square" translate>layout.text1</p>
            <p class="text-white-orange-square" translate>layout.text2</p>
        </div>
    </section>
    
    <section id="login-auth-div" class=" right-w-box " fxFlex fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
        <img *ngIf="!isMobile" routerLink="auth/login" class="pointer" width="100%" src="/assets/images/logo.svg" />
        <h1 class="title-box" translate>forgot.title</h1> 
        <p translate>forgot.input-email</p>
        <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px" class="group-input">
            <span class="material-icons grey-icon" [ngClass]="{'err-icon': error}">person</span>
            <mat-form-field fxFlex [ngClass]="{'alert-red': error}">
                <mat-label translate>forgot.email</mat-label>
                <input matInput name="email" [(ngModel)]="email" (input)="onInputChange()" required>
            </mat-form-field>
        </div>

        <app-message *ngIf="msg != ''" [type]="typeMsg" message="{{msg}}"></app-message>
        <div fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="15px" style="width: 100%;">
            <mat-spinner *ngIf="load" strokeWidth="10" [diameter]="24"></mat-spinner>
            <button *ngIf="!load && typeMsg!='success'" mat-raised-button (click)="onEmailVerify()" class="btn-blue" [disabled]="disabled">{{"forgot.send" | translate }}</button>
        </div>
        <div style="text-align: center; width: 100%;">        <a routerLink="/auth/login" class="link-blue">{{"register.login" | translate }}</a></div>

    </section>
<!-- footer -->
<section fxLayout="column" fxLayout.xs="row" *ngIf="isMobile" class="left-o-box footer" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="20px">
    <img routerLink="auth/login" class="logo-sogcial-auth pointer" src="/assets/images/isotipo-white.svg" />
    <div>
        <p class="text-white-orange-square" translate>layout.text1</p>
        <p class="text-white-orange-square" translate>layout.text2</p>
    </div>
</section>
</div>
