import { Component, OnInit, ViewChild,AfterViewInit } from '@angular/core';
import { FormPaymentComponent } from "./form-payment/form-payment.component";
import { TableMethodPaymentComponent } from "./table-method-payment/table-method-payment.component";
import { createComponent } from '@angular/compiler/src/core';
import { CardService } from "../../../../services/card.service";
@Component({
  selector: 'app-method-payment',
  templateUrl: './method-payment.component.html',
  styleUrls: ['./method-payment.component.scss']
})
export class MethodPaymentComponent implements OnInit {
  // @ViewChild(CreateCardComponent) createCard
  flag:boolean=false
  $fo: any
  userData: any;
  allDataCard:any=[];
  constructor(
    private CardService:CardService
  ) { }

  ngOnInit(): void {
    
  }
  ngAfterViewInit(){

  }
  showHidden(){
    console.log(this.flag)
    if(this.flag==false){
      this.flag=true;
    }else{
      this.flag=false
    }
    console.log(this.flag)
  }
  form($fo){
    this.showHidden()
    this.ngOnInit()
  }
  
}
