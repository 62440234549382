<div>
  Pruebas con selects <br>
  <select (change)="country($event)">
    <option value="venezuela">Venezuela</option>
    <option value="colombia">Colombia</option>

  </select>
</div>
<br><br>
<div class="form-group">
  <input
    placeholder="search for location"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="off"
    type="text"
    class="form-control"
    #search
    [formControl]="searchControl"
  />
</div>
<agm-map
  [latitude]="lat"
  [longitude]="lng"
  [zoom]="map_zoom"
  [disableDefaultUI]="true"
  [zoomControl]="true"
  (mapClick)="mapClicked($event)"
  (centerChange)="centerChange($event)"
  (mapReady)="mapReady($event)"
  class="map-outer"
>
  <!-- imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m" -->
  <!-- <agm-marker
      *ngFor="let m of markers | async; let i = index"
      (markerClick)="clickedMarker(m.label, i)"
      [latitude]="m.lat"
      [iconUrl]="{
        url: './assets/images/map-marker.png'
      }"
      [longitude]="m.lng"
      [label]="{
        color: 'red',
        fontWeight: 'bold',
        fontSize: '14px',
        fontFamily: 'nunito',
        lineHeight: '10px',
        text: m.label
      }"
      [markerDraggable]="false"
      (dragEnd)="markerDragEnd(m, $event)"
    >

    </agm-marker> -->
</agm-map>
