import { send } from 'process';
import { Component, OnInit, ViewChild } from '@angular/core';

import { ProposalService } from 'src/app/services/proposal.service';
import { MatDialog } from '@angular/material/dialog';
import { ModaloffertComponent } from './modal-offer/modal-offer.component';
import { ModaldeclineComponent } from './modal-decline/modal-decline.component';
import { Router } from '@angular/router';
import { ModaldeleteComponent } from './modal-delete/modal-delete.component';


@Component({
  selector: 'app-my-ofert-list',
  templateUrl: './my-ofert-list.component.html',
  styleUrls: ['./my-ofert-list.component.scss'],
})
export class MyOfertListComponent implements OnInit {
enviado;
  info;
  data;
  received;
  historial;

  arrayduration = {
    hour: 'Horas',
    day: 'Dias',
    week: 'Semanas',
    month: 'Meses',
    year: 'Año ',
  };
  constructor(
    private router:Router,
    private proposalService: ProposalService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadData()
  }

  acceptproposalreceived(recibido) {
    const dialogRef = this.dialog.open(ModaloffertComponent, {
      width: '1000px',
      height: '500px',
      data:recibido
    });
  }
  loadData(){
    this.proposalService.get_proposal_enviadas().subscribe((info) => {
      console.log('===================', info);
      this.data = info.sent;
      this.received = info.received;
      this.historial=info.history
      this.data = this.data.map((e) => {
        const date_1: any = new Date(e.since);
        const date_2: any = new Date();
        const day_as_milliseconds = 86400000;
        const diff_in_millisenconds = date_2 - date_1;
        const diff_in_days = Math.round(
          diff_in_millisenconds / day_as_milliseconds
        );

        console.log(diff_in_days);
        return { ...e, starscont: parseInt(e.stars), dias: diff_in_days };
      });

      this.received = this.received.map((e) => {
        const date_1: any = new Date(e.extra.since);
        console.log("//////////",e.since)
        const date_2: any = new Date();
        const day_as_milliseconds = 86400000;
        const diff_in_millisenconds = date_2 - date_1;
        const diff_in_days = Math.round(
          diff_in_millisenconds / day_as_milliseconds
        );

        console.log(diff_in_days);
        return { ...e, starscont: parseInt(e.stars), dias: diff_in_days };
      });

      this.historial = this.historial.map((e) => {
        const date_1: any = new Date(e.extra.since);
        console.log("//////////",e.since)
        const date_2: any = new Date();
        const day_as_milliseconds = 86400000;
        const diff_in_millisenconds = date_2 - date_1;
        const diff_in_days = Math.round(
          diff_in_millisenconds / day_as_milliseconds
        );

        console.log(diff_in_days);
        return { ...e, starscont: parseInt(e.stars), dias: diff_in_days };
      });
    });
  }

  rejectproposalreceived(recibido) {
    const dialogRef = this.dialog.open(ModaldeclineComponent, {
      width: '1000px',
      height: '300px',
      data:recibido
    });
  }

  deleteproposal(recibido) {
    const dialogRef = this.dialog.open(ModaldeleteComponent, {
      width: '1000px',
      height: '350px',
      data:recibido
    });
  }

  getRecord(recibido){
    const proposal_id = recibido.proposal_id;
    this.router.navigateByUrl('/dashboard/teacher/detail-proposal/'+proposal_id)
  }
}
