import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatAccordion } from '@angular/material/expansion';
import { EventsService } from '../../../../services/events.service';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ModalCancelContractComponent } from './modal-cancel-contract/modal-cancel-contract.component';
import { Router } from '@angular/router';
import { ModalCancelProposalComponent } from './modal-cancel-proposal/modal-cancel-proposal.component';
import { Subscription } from 'rxjs';
import { GeneralService } from '../../../../services/general.service';
import { ModalCancelEventComponent } from './modal-cancel-event/modal-cancel-event.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  dataSource;
  dataSourceAux;
  columnsToDisplay: string[];
  id;
  load = true;
  hide = true; //true
  expandedElement: PeriodicElement | null;

  filterValues = {
    category: '',
    place: '',
    status: '',
  };
  defaultFilterPredicate?: (data: any, filter: string) => boolean;
  category = new FormControl();
  categoryList = [];
  place = new FormControl();
  placesList = [];
  status = new FormControl();
  statusList = [];

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  user;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private EventsService: EventsService,
    public dialog: MatDialog,
    private _router: Router,
    private GeneralService: GeneralService
  ) {}

  ngOnInit(): void {
    this.msgSuscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });
    this.user = JSON.parse(localStorage.getItem('userData'));

    this.serviceGetEvents();
  }

  serviceGetEvents() {
    let ELEMENT_DATA = [];
    this.EventsService.events(this.user.id).subscribe(
      (res) => {
        console.log('-----res-event-home:', res);
        let countStatus = 0;
        res.event.forEach((element) => {
          if (element.place != null && element != 0 && element.place != '0') {
            countStatus++;
          }
          if (element.proposal_id != null) {
            countStatus++;
          }
          if (element.status == 'Publicado') {
            countStatus++;
          }

          // element.place != null ? countStatus++ : false;
          // (element.teachers != 0&&element.teachers != "0"&&element.teachers != null) || element.proposal_id == null
          //   ? countStatus++
          //   : false;
          // // (countStatus==2)? countStatus++: false;
          // element.status == 'Publicado' ? countStatus++ : false,
          ELEMENT_DATA.push({
            name: element.name,
            category: element.category,
            // place_id: element.place_id,
            place: element.place,
            date: element.start_date ? element.start_date : '0000/00/00',
            status: element.status,
            edit: 'create',
            copy: 'visibility',
            description: element.description,
            main_image: element.main_image,
            availability: element.availability,
            capacity: element.capacity,
            end_date: element.end_date,
            end_hour: element.end_hour,
            start_date: element.start_date,
            start_hour: element.start_hour,
            id_event: element.id,
            price: element.price,
            tags: element.tags != null ? element.tags.split(',') : '',
            state: element.state,
            countStatus: countStatus,
            teacher: element.teachers,
            proposal_id: element.proposal_id,
            boolTeacher: element.teachers != 0 ? false : true,
            boolPlace: element.place != null ? false : true,
            boolPublish: element.status == 'Publicado' ? true : false,
            skip_proposal: element.skip_proposal,
          });
          countStatus = 0;
        });

       let datatable=ELEMENT_DATA;
        if(this.user.rol_id === 2){
         datatable=ELEMENT_DATA.filter((f)=>f.place!==null && f.proposal_id!==null)
        }

        this.dataSource = new MatTableDataSource(datatable);
        this.dataSourceAux = new MatTableDataSource(datatable);
        this.defaultFilterPredicate = this.dataSource.filterPredicate;

        this.columnsToDisplay = ['name', 'category', 'date', 'status', 'menu'];

        if (this.user.rol_id === 2) {
          const indice = this.columnsToDisplay.indexOf('status');
          this.columnsToDisplay.splice(indice, 1);
        }

        this.category.valueChanges.subscribe((category) => {
          this.filterValues.category = category;
          console.log(
            'this.filterValues.category:: ',
            this.filterValues.category
          );

          this.dataSource.data = this.dataSourceAux.data.filter((dat) => {
            let ok = false;
            for (let i = 0; i < this.filterValues.category.length && !ok; i++) {
              let category = this.filterValues.category[i];
              if (category == dat.category) ok = true;
            }
            return ok;
          });
        });

        this.place.valueChanges.subscribe((place) => {
          this.filterValues.place = place;
          console.log('this.filterValues.place:: ', this.filterValues.place);

          this.dataSource.data = this.dataSourceAux.data.filter((dat) => {
            let ok = false;
            for (let i = 0; i < this.filterValues.place.length && !ok; i++) {
              let place = this.filterValues.place[i];
              if (place == dat.place) ok = true;
            }
            return ok;
          });
        });

        this.status.valueChanges.subscribe((status) => {
          this.filterValues.status = status;
          console.log('this.filterValues.status:: ', this.filterValues.status);

          this.dataSource.data = this.dataSourceAux.data.filter((dat) => {
            let ok = false;
            for (let i = 0; i < this.filterValues.status.length && !ok; i++) {
              let status = this.filterValues.status[i];
              if (status == dat.status) ok = true;
            }
            return ok;
          });
        });

        this.dataSource.paginator = this.paginator;

        res.event_categories.original.event_categories.forEach((element) => {
          this.categoryList.push(element.name);
        });

        res.places.original.places.forEach((element) => {
          this.placesList.push(element.name);
        });

        res.status.original.status.forEach((element) => {
          this.statusList.push(element.status);
        });
        this.load = false;
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialogCancelProposal(element, reservation: any, schedule: any) {
    const dialogRef = this.dialog.open(ModalCancelProposalComponent, {
      height: 'auto',
      width: 'auto',
      data: { element, reservation, schedule },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log('result', result);
      if (result != undefined) {
        console.log('borrar reservation');
        this.EventsService.get_admin_CancelReservation(result).subscribe(
          (res: any) => {
            console.log('get_admin_CancelReservation', res);
            // window.location.reload();

            this.GeneralService.typeMsg$.emit('success');
            this.GeneralService.msg$.emit('Operacion exitosa');
            this.serviceGetEvents();
          },
          (err) => {
            console.log('ERRor get_admin_CancelReservation', err);
          }
        );
      }
    });
  }

  openDialogCancelContract(element, results: any) {
    const dialogRef = this.dialog.open(ModalCancelContractComponent, {
      height: 'auto',
      width: 'auto',
      data: { element, results },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log('result', result);
      if (result != null) {
        let offers = [];
        for (let i = 0; i < result.length; i++) {
          offers.push({ offer_id: result[i] });
        }
        let data = { offers: offers };
        console.log('data a mariana: ', data);
        console.log('acepto borrrarrrrrrrrrrrrr');
        this.EventsService.post_admin_cancelOffer(data).subscribe(
          (res: any) => {
            console.log('post_admin_cancelOffer', res);
            // window.location.reload();
            this.serviceGetEvents();
            this.GeneralService.typeMsg$.emit('success');
            this.GeneralService.msg$.emit('Operacion exitosa');
          },
          (err) => {
            console.log('ERRor post_admin_cancelOffer', err);
          }
        );
      }
    });
  }

  openDialogRemoveEvent(remove: string, element) {
    const dialogRef = this.dialog.open(ModalCancelEventComponent, {
      height: 'auto',
      width: 'auto',
      data: { remove, element },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      let temp = result.split(',');
      if (result == 'pause') {
        console.log('pausar evento');
        this.serviceChangeStatus(element.id_event, 'Pendiente');
      }
      if (result == 'remove') {
        console.log('Borrar evento');
        this.serviceChangeStatus(element.id_event, 'Eliminado');
      }
    });
  }
  sendToProposalTeacher(idProposal: number) {
    this._router.navigateByUrl('/dashboard/events/list-teachers/' + idProposal);
  }

  sendToContractPlace(idEvent: number) {
    this.EventsService.set_eventId(idEvent);
    this._router.navigateByUrl('/dashboard/proposalPlace');
  }

  sendToPublish(idEvent: number) {
    this._router.navigateByUrl('/dashboard/events/publish/' + idEvent);
  }

  serviceDataToCancelOffer(element: any) {
    if (this.msg == null) {
      let results;
      this.EventsService.get_admin_dataToCancelReservation(
        element.id_event
      ).subscribe(
        (res: any) => {
          console.log('get_admin_dataToCancelOffer', res);
          results = res.results;
          console.log('result: ', results);
          this.openDialogCancelContract(element, results);
        },
        (err) => {
          console.log('ERRor get_admin_dataToCancelOffer', err);
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit(err.statusText);
        }
      );
    }
  }

  serviceDataToCancelReservation(element: any) {
    if (this.msg == null) {
      let reservation;
      let schedule;
      this.EventsService.get_admin_dataToCancelReservation(
        element.id_event
      ).subscribe(
        (res: any) => {
          console.log('get_admin_dataToCancelReservation', res);
          reservation = res.results[0].reservation;
          schedule = res.results[0].schedules;
          this.openDialogCancelProposal(element, reservation, schedule);
        },
        (err) => {
          console.log('ERRor get_admin_dataToCancelReservation', err);
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit(err.statusText);
        }
      );
    }
  }

  serviceChangeStatus(id_event: number, type: string) {
    let data = { status: type };
    this.EventsService.post_admin_statusEvent(id_event, data).subscribe(
      (res: any) => {
        console.log('post_admin_statusEvent', res);
        // setTimeout(() => { window.location.reload();},1000);
        this.GeneralService.typeMsg$.emit('success');
        this.GeneralService.msg$.emit('Operacion exitosa');
        this.serviceGetEvents();
      },
      (err) => {
        console.log('ERRor post_admin_statusEvent', err);
      }
    );
  }

  goEdit(element) {
    console.log(element);
    localStorage.setItem('eventsSelected', JSON.stringify(element));
    this._router.navigateByUrl('/dashboard/events/edit/' + element.id_event);
  }

  create() {
    localStorage.removeItem('eventincomplete');
    localStorage.removeItem('idevent');
    this._router.navigateByUrl('/dashboard/events/create');
  }
  continue(element) {
    console.log(element);
    if (element.place === null) {
      console.log('vamos al paso 2');
      localStorage.setItem('eventincomplete', 'step2');
      localStorage.setItem('idevent', element.id_event);
      this._router.navigateByUrl('/dashboard/events/create');
    } else if (element.proposal_id === null) {
      console.log('vamos al paso 3');
      localStorage.setItem('idevent', element.id_event);
      localStorage.setItem('eventincomplete', 'step3');
      this._router.navigateByUrl('/dashboard/events/create');
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSuscription;
    this.typeMsgSuscription;
  }

  clearcategory() {
    this.category.setValue(null, { emitEvent: false });
    this.dataSource.data = this.dataSourceAux.data;
  }

  clearplace() {
    this.place.setValue(null, { emitEvent: false });
    this.dataSource.data = this.dataSourceAux.data;
  }

  clearstatus() {
    this.status.setValue(null, { emitEvent: false });
    this.dataSource.data = this.dataSourceAux.data;
  }
}

export interface PeriodicElement {
  name: string;
  category: string;
  place: String;
  date: string;
  status: string;
  edit: string;
  copy: string;
}

interface Filter {
  value: string;
  viewValue: string;
}
