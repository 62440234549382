import { Component, Inject, OnInit } from '@angular/core';
import { GeneralService } from '../../../../../../../services/general.service'
import { EventsService } from '../../../../../../../services/events.service'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-confirm-schedule',
  templateUrl: './confirm-schedule.component.html',
  styleUrls: ['./confirm-schedule.component.scss']
})
export class ConfirmScheduleComponent implements OnInit {


  calendarData;
  timeEvent;
  arrayDay; 
  arrayTemp;
  form: FormGroup;


  


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private GeneralService: GeneralService,
    private EventsService: EventsService,
    private fb: FormBuilder,) { }

  ngOnInit(): void {
    console.log("data en confirm:", this.data)
    this.calendarData = this.GeneralService.get_calendarData();
    console.log("this.calendarData: ", this.calendarData);
    this.form = this.fb.group({
      acceptTermsCond : [false, Validators.requiredTrue ]
    });
    this.orderTimeEvent();
  }

  orderTimeEvent() {
    this.timeEvent = this.GeneralService.get_timeEvent();
    console.log("this.timeEvent: ", this.timeEvent);
    let arrayTemp = []; let arrayDay = [];
    this.timeEvent.sort(function (a, b) {
      return new Date(a.start).getTime() - new Date(b.end).getTime();
    });
    console.log("this.timeEvent order: ", this.timeEvent);
    arrayDay.push(this.timeEvent[0].startStr.split('T')[0]);
    this.timeEvent.forEach(element => {
      if (arrayDay[arrayDay.length - 1] != element.startStr.split('T')[0]) {
        arrayDay.push(element.startStr.split('T')[0]);
      }
      arrayTemp.push({
        'day': element.startStr.split('T')[0],
        'start': element.start.getHours() + ':00',
        'end': element.end.getHours() + ':00',
      });
    });
    this.arrayDay=arrayDay;
    this.arrayTemp=arrayTemp;
    console.log("arrayDay:", this.arrayDay);
    console.log("arrayTemp:", this.arrayTemp);
  }
}
