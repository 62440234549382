import { Component, OnInit } from '@angular/core';
import { AffiliateService } from '../../services/affiliate.service';
import { CartService } from '../../services/cart.service';
import { ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {DialogComponent} from 'src/app/website/dialog/dialog/dialog.component'
import { Router } from '@angular/router';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})

export class CartComponent implements OnInit {
  data=[];
  selects=[
    {
      value: 0,
      viewValue: 'Afiliado'
    }
  ]
  selected = 0
  cart: any;
  counter: any
  totalProducts = 0;
  eventProductId: number;
    constructor(private AffiliateService:AffiliateService, private CartService:CartService, private cdRef: ChangeDetectorRef,private snackBar: MatSnackBar, private dialog: MatDialog, private router: Router, private eventService: EventsService, ) { }

  ngOnInit(): void {
    this.loadAffiliates();
    this.refreshCart();
    console.log(this.totalProducts);
  }

  loadAffiliates() {
    const data = JSON.parse(localStorage.getItem('userData'));
    this.AffiliateService.get_admin_useraffiliates(data.id).subscribe( res => {
      const { affiliate } = res;
      this.selects = this.selects.concat(affiliate.map((elem) => {
        return {
          value: elem.affiliate_id,
          viewValue: `${elem.name} ${elem.last_name}`
        }
      }));
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  resetAndRefresh() {
    this.data = [];
    this.refreshCart();
  }

  refreshCart() {
    let cart = JSON.parse(localStorage.getItem('cartData'));
    cart = (Array.isArray(cart) && cart.length > 0) ? cart[0] : null;
    this.cart = cart;
  
    if (cart) {
      this.CartService.get_admin_getCartList(cart.cartID).subscribe(res => {
        console.log(res);
        const { courses, events } = res;
  
        if (Array.isArray(courses) && courses.length > 0) {
          courses.forEach(course => {
            const rAr = course['amountPerUnity'] ? course['amountPerUnity'].split(" ") : ["0", ""];
            const price = {
              currency: rAr[1],
              value: parseFloat(rAr[0])
            };
  
            this.data.push({
              id: course.courseId,
              title: course.courseName ? course.courseName : 'No Disponible',
              category: course.courseCategory ? course.courseCategory : 'Ninguna',
              percentage: `${course.discount}% Dto. ` ? `${course.discount}% Dto. ` : 'No Asignado',
              pricePP: course['amountPerUnity'] ? course['amountPerUnity'] : 'No Disponible',
              price: course.amountpp ? course.amountpp : 'No Disponible',
              priceObj: price,
              place: {
                name: course.place,
                own: null
              },
              address: "San Cristobal, Venezuela",
              dateStart: course.start_date ? course.start_date : 'No asignada',
              level: {
                name: course.levelName,
                target: `Para mayores de ${course.age} años`,
                approved: course.approved === 1 ? "Evaluado" : "No Evaluado"
              },
              quantity: course.quantity ? course.quantity : 'No Disponible',
              img: course.image ? course.image : '../../../../assets/images/image-default.png',
              type: 'course',
              teacher: course.teachers ? course.teachers : 'No Asignado',
              affiliate: course.affiliateName ? course.affiliateName : 'Yo',
              productId: course.IdProduct ? course.IdProduct : undefined,
              counter: 1,
            });
          });
        }
  
        if (Array.isArray(events) && events.length > 0) {
          events.forEach(event => {
            const rAr = event['amountPerUnity'] ? event['amountPerUnity'].split(" ") : ["0", ""];
            const price = {
              currency: rAr[1],
              value: parseFloat(rAr[0])
            };
            const storedCounter = JSON.parse(localStorage.getItem('counters')) || {};
            this.data.push({
              id: event.eventId,
              title: event.eventName ? event.eventName : 'No Disponible',
              category: event.eventCategory ? event.eventCategory : 'Ninguna',
              percentage: `${event.discount}% Dto. ` ?  `${event.discount} % Dto. ` : 'No Asignado',
              pricePP: event['amountPerUnity'] ? event['amountPerUnity'] : 'No Disponible',
              price: event.amountpp ? event.amountpp : 'No Disponible',
              priceObj: price,
              place: {
                name: event.place ? event.place : 'No Disponible',
                own: null
              },
              address: "San Cristobal, Venezuela",
              dateStart:  event.startDate ? event.startDate : 'No asignada',
              level: {
                name: event.levelName ? event.levelName : 'No disponible' ,
                target: `Para mayores de ${event.age} años`,
                approved: event.approved === 1 ? "Evaluado" : "No Evaluado"
              },
              quantity: event.quantity ? event.quantity : 'No Disponible',
              img:  event.image ? event.image : '../../../../assets/images/image-default.png',
              type: 'event',
              teacher: event.teachers ? event.teachers : 'No Asignado',
              affiliate: 'No Disponible',
              availability: event.availability ? event.availability : 'No Disponible',
              counter: event.quantity,
              slug: event.slug,
              eventIdQr: event.eventIdtoQr,
              productId: event.productId,
            });
          });
        }
        this.totalProducts = this.data.length;
      }, err => {
        alert(err.error.message_return.ERROR_MENSAGGE);
      });
    } else {
      this.data = [];
    }
    console.log(this.data);
  }
  
  
  reloadComponent() {
    this.router.navigate([this.router.url], { skipLocationChange: true });
  }

  updateItem(id, data) {
    this.CartService.put_admin_products(id, data).subscribe( res => {
      console.log(res);
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  affiliateChange(e) {

  }

  deleteItem(id) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '300px',
      data: { message: '¿Estás seguro de que quieres eliminar este elemento?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const data = this.cart;
        this.CartService.delete_admin_products(id, data).subscribe(
          res => {
            this.data = this.data.filter(elem => elem['id'] !== id);
            this.cdRef.detectChanges();
            this.resetAndRefresh();
            this.eventService.triggerHeaderReload(); // Emitir evento para recargar HeaderComponent
            this.reloadHeaderComponent(); // Llamar a la función para recargar HeaderComponent
          },
          err => {
            alert(err.error.message_return.ERROR_MENSAGGE);
          }
        );
      }
    });
  }

  reloadHeaderComponent() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  

  total() : any {
    if (!this.data || !Array.isArray(this.data) || this.data.length === 0) {
        return "0.00";
    }

    let calc = 0;

    for (let i = 0; i < this.data.length; i++) {
        if (this.data[i] && this.data[i]['priceObj']) {
            if (this.data[i].type === 'event') {
                calc += this.data[i]['priceObj'].value * this.data[i].counter;
            } else {
                calc += this.data[i]['priceObj'].value;
            }
        }
    }

    return {"currency " : this.data[0]['priceObj'].currency , "priceTotal" : calc.toFixed(2)};
}

  
  
  saveTotal() {
    const total =  this.total();
    const { name, email, id } = JSON.parse(localStorage.getItem('userData')) 

   const  dataOrden = {
    priceTotal:  total.priceTotal,
    products: this.data,
    UserData : {
      "name" : name,
      "email": email,
      "userId": id,
      "cartId" :  JSON.parse(localStorage.getItem('cartData'))[0].cartID
    }
    }
    localStorage.setItem('cartProducts',  JSON.stringify(dataOrden) );
    const totalString = this.total();
    const rAr = totalString.split(" ");
    const totalCart = {
      currency: rAr[0],
      value: parseFloat(rAr[1])
    };
    localStorage.setItem('totalCart', JSON.stringify(totalCart));
  }

  counterDe(item) {
    if (item.counter > 0) {
      item.counter--;

      this.CartService.decrementQuantity(item.productId).subscribe(
        response => {
          this.showSnackbar('Cantidad de entradas reducida con éxito!', 'success-snackbar');
          console.log(response);
        },
        error => {
          this.showSnackbar('Error al quitar entrada!', 'error-snackbar');
          console.error(error);
        }
      );
      
    }else {
      alert('No puedes reducir mas la cantidad de entradas');
    }
  }
  
  counterIn(item) {
    if (item.counter < item.availability) {
      // Incrementar el contador local
      item.counter++;
  
      // Hacer la llamada al servicio solo después de actualizar el contador local
      this.CartService.incrementQuantity(item.productId).subscribe(
        response => {
          // Actualizar el contador en el servicio fue exitoso
          this.showSnackbar('Cantidad de entradas aumentada con éxito!', 'success-snackbar');
          console.log(response);
        },
        error => {
          // Si hay un error en la llamada al servicio, revertir el incremento local
          item.counter--;
          this.showSnackbar('Error al agregar entrada!', 'error-snackbar');
          console.error(error);
        }
      );
    } else {
      alert("Se alcanzó el límite de disponibilidad");
    }
  }
  
  showSnackbar(message: string, panelClass: string): void {
    this.snackBar.open(message, 'Cerrar', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: panelClass
    });
  }
}
