<div id="newsletter-container" class="background" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"
    fxLayoutAlign="space-between none" fxLayoutGap="36px" fxLayoutGap.sm="0" fxLayoutGap.xs="0px" fxLayoutAlign.sm="center center">
    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">
            <p class="title" id="newsletter-title">Quieres recibir la <span id="newsletter-span">Newsletter?</span></p>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start stretch" fxLayoutAlign.xs="center center">
            <mat-icon id="drafts-icon">drafts</mat-icon>
            <p id="subtitle-newsletter" class="subtitle width">Sé el primero en recibir las últimas novedades sobre
                Sogcial</p>
        </div>
    </div>
    <div *ngIf="!isTablet" fxLayoutGap.lt-md="1em" fxLayoutAlign.lt-md="center center">
        <div fxFlex="40%" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" fxLayoutAlign="center center stretch">
                <input id="email-input" matInput [(ngModel)]="email" placeholder="Ingrese su correo" value="">
            </div>
            <div fxLayout="row" fxLayoutAlign="center center stretch" *ngIf="showError">
                <mat-icon *ngIf="data==false">highlight_off</mat-icon><mat-icon
                    *ngIf="data==true">check_circle_outline</mat-icon>
                <p style="color: #ffffff; font-weight: bold;"> {{error}}</p>
            </div>
        </div>
        <div fxFlex="10%" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div id="enter-email-container" fxLayout="row" fxLayout.lt-md="column" class="example-full-width-button"
                fxLayoutAlign="space-between stretch">
                <button id="send-button" mat-button class="example-icon style-button favorite-icon style-login"
                    (click)="save()">
                    Ingresar <mat-icon style="font-size: 23px;">send</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>