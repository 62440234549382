import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-boxes',
  templateUrl: './boxes.component.html',
  styleUrls: ['./boxes.component.scss']
})
export class BoxesComponent implements OnInit {
@Input() image 
  images
  icons
  textOne
  textFirst
  textSecond
  color: any;
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.images=changes.image.currentValue.image; 
    this.icons=changes.image.currentValue.icon; 
    this.textOne=changes.image.currentValue.firstText
    this.textFirst=changes.image.currentValue.textTitle
    this.textSecond=changes.image.currentValue.textTitle2
    this.color=changes.image.currentValue.color
    console.log(changes)
  }
}
