import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, ViewChildren,AfterViewInit } from '@angular/core';
import { GeneralService } from '../../../../../services/general.service';
import { AddressService } from '../../../../../services/address.service';
import { ListAddressComponent } from "../list-address/list-address.component";

import {FormControl, Validators} from '@angular/forms';
@Component({
  selector: 'app-form-address',
  templateUrl: './form-address.component.html',
  styleUrls: ['./form-address.component.scss']
})
export class FormAddressComponent implements OnInit {
  load:boolean=false;
  country_id:number ;
  state_id: number ;
  city_id: number;
  county_id: number;
  parish_id: number;
  showS: boolean;
  states: any;
  bandState: boolean;
  showC: boolean;
  cities: any;
  bandCity: boolean;
  showCo: boolean;
  counties: any;
  bandCounty: boolean;
  bandParish: boolean;
  showP: boolean;
  parishes: any;
  countries: any;
  bandCountry: boolean;
  isChecked: boolean=false

  name = new FormControl('', [
    Validators.required
  ]);
  nameError:string;

  cat = new FormControl('', [
    Validators.required
  ]);
  catError:string;
  pho = new FormControl('', [
    Validators.required
  ]);
  phoError:string;
  ema = new FormControl('', [
    Validators.required,Validators.email
  ]);
  emaError:string;
  countr = new FormControl('', [
    Validators.required
  ]);
  countrError:string;

  stat = new FormControl('', [
    Validators.required
  ]);
  statError:string;

  cit = new FormControl('', [
    Validators.required
  ]);
  citError:string;

  count = new FormControl('', [
    Validators.required
  ]);
  countError:string;

  pari = new FormControl('', [
    Validators.required
  ]);
  pariError:string;

  addre = new FormControl('', [
    Validators.required
  ]);
  addreError:string;
  address: any;
  zip_code: any;
  userData: any;
  @Output() saving = new EventEmitter<string>();
  @Input() address_id;
id;
  // @ViewChildren(ListAddressComponent) listAddress;
  constructor(
    private GeneralService:GeneralService,
    private AddressService:AddressService,
  ) { }
  // ngAfterViewInit() {
  //   console.log(this.listAddress.id)
  // }
  getErrorMessage() {
    const errorInput="No debe quedar en blanco";
    const errorEmail="Formato incorrecto de email: example@you.com";
    const errorSelect="No debe quedar sin seleccionar";
     if (this.name.hasError('required')) {
       
         this.nameError=errorInput
         return this.nameError
       
     }
     if (this.cat.hasError('required')) {
       
       this.catError=errorSelect;
       return this.catError
     
     }
     if (this.pho.hasError('required')) {
       
       this.phoError=errorInput
       return this.phoError
     
     }
     if (this.ema.hasError('email')) {
       
       this.emaError=errorEmail
       return this.emaError
     
     }
     if (this.ema.hasError('required')) {
       
       this.emaError=errorInput
       return this.emaError
     
     }
     if (this.countr.hasError('required')) {
       
       this.countrError=errorSelect
       return this.countrError
     
     }
     if (this.stat.hasError('required')) {
       
       this.statError=errorSelect
       return this.statError
     
     }
     if (this.cit.hasError('required')) {
       
       this.citError=errorSelect
       return this.citError
     
     }
     if (this.count.hasError('required')) {
       
       this.countError=errorSelect
       return this.countError
     
     }
     if (this.pari.hasError('required')) {
       
       this.pariError=errorSelect
       return this.pariError
     
     }
     if (this.addre.hasError('required')) {
       
       this.addreError=errorSelect
       return this.addreError
     
     }
     
     
   }
   onBack(){
    localStorage.setItem('address_id','');
    this.saving.emit();  
   }
   ngOnChanges(changes: SimpleChanges): void {
     //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
     //Add '${implements OnChanges}' to the class.
     console.log(changes,"xsssssss")
   }
   onSaveAddress(){
    this.userData=JSON.parse(localStorage.getItem('userData'))
    this.load=true;

     let data=[
       {
         address:[
           {
             address:this.address,
             main_address:this.isChecked?1:0,
             country_id:this.country_id,
             state_id:this.state_id,
             city_id:this.city_id,
             county_id:this.county_id,
             parish_id:this.parish_id,
             zip_code:this.zip_code,
             user_id:this.userData.id
           }
         ]
       }
     ]
     this.AddressService.post_adminaddresses(data).subscribe(
      res => {
       console.log(res)
        if(res.message_return.ERROR == false ){
          this.saving.emit();  
          localStorage.setItem('address_id','');
          this.load=false;
        }
      });
     console.log(data)
   }
   
  onEdit(id){
    console.log("hola",id)
    this.userData=JSON.parse(localStorage.getItem('userData'))
    this.load=true;

     let data=[
       {
         address:[
           {
             address:this.address,
             main_address:this.isChecked?1:0,
             country_id:this.country_id,
             state_id:this.state_id,
             city_id:this.city_id,
             county_id:this.county_id,
             parish_id:this.parish_id,
             zip_code:this.zip_code,
             user_id:this.userData.id
           }
         ]
       }
     ]
     this.AddressService.put_adminaddresses(data,id).subscribe(resp=>{
        if(resp.message_return.ERROR == false ){
          this.saving.emit();  
          localStorage.setItem('address_id','');
          this.load=false;
        }
     })
     console.log(data)
  }
  ngOnInit(): void {
    this.id=localStorage.getItem('address_id');
    console.log(this.id,"asdasdasdsadadsadasdadadsadasd")
    if(this.id!=''){
    this.AddressService.get_adminaddresses(this.id).subscribe(
      res=>{
        if(res.message_return.ERROR == false ){
          this.country_id=res.address[0].country_id;
          this.city_id=res.address[0].city_id;
          this.state_id=res.address[0].state_id;
          this.county_id=res.address[0].municipality_id;
          this.parish_id=res.address[0].parish_id;
          this.zip_code=res.address[0].zip_code;
          this.address=res.address[0].address;
          this.isChecked=res.address[0].main_address;
          console.log(res)
          this.GeneralService.get_countries().subscribe(
            resx => {
            //  console.log(res)
              if(resx.message_return.ERROR == false ){
                this.countries= resx.countries
                this.bandCountry=true
                this.getStates();
                this.getCities();
                this.getCounties();
                this.getParish();
      
              }
            });
          
        }
        
      }
    );
    }else{
    this.GeneralService.get_countries().subscribe(
      res => {
      //  console.log(res)
        if(res.message_return.ERROR == false ){
          this.countries= res.countries
          this.bandCountry=true

        }
      });
    }
  }
  getStates(){
    //
    this.showS=true;
    // console.log(this.country_id)
    this.GeneralService.get_getStatesByCountry(this.country_id).subscribe(
      resx => {
      //  console.log(resx)
        if(resx.message_return.ERROR == false ){
          this.states= resx.states
          this.bandState=true
          this.showS=false;

        }
     });
  }
  getCities(){
    console.log(this.state_id)
    //get_getCitiesByState
    this.showC=true;
    this.GeneralService.get_getCitiesByState(this.state_id).subscribe(
      resy => {
        // console.log(resy)

       if(resy.message_return.ERROR == false ){
          this.cities= resy.City
          this.bandCity=true
          this.showC=false;

        }
        
     });
  }
  getCounties(){
    this.showCo=true;
    this.GeneralService.get_getCountiesByCity(this.city_id).subscribe(
      resz => {
        // console.log(resz)
       if(resz.message_return.ERROR == false ){
          this.counties= resz.counties
          this.bandCounty=true
          this.showCo=true;

        }
        
     });
  }


  getParish(){
    // console.log(this.county_id)
    this.showP=true;
    this.GeneralService.get_getParishesByCounty(this.county_id).subscribe(
      resa => {
        // console.log(resa)
       if(resa.message_return.ERROR == false ){
          this.parishes= resa.parishes
          this.bandParish=true
          this.showP=true;
        }
        
     });
  }

}
