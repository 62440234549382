import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { GeneralService } from '../../../../../services/general.service';
import { PlaceService } from '../../../../../services/place.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';

interface Category {
  id: number;
  name: string;
  parent_id: number;
  created_at: string;
  updated_at: string;
}
interface Country {
  id: number;
  name: string;
  created_at: string;
  iso: string;
  iso3: string;
  nicename: string;
  numcode: number;
  phonecode: number;
  slug: string;
  updated_at: string;
}
interface State {
  id: number;
  state_code: string;
  state_name: string;
  country_id: number;
  slug: string;
}
interface City {
  id: number;
  city: string;
  county: string;
  latitude: string;
  longitude: string;
  state: string;
  country: string;
  created_at: string;
  updated_at: string;
}
interface County {
  id: number;
  municipality: string;
  slug: string;
  city: string;
  state: string;
  country: string;
}
interface Parish {
  id: number;
  municipality_id: number;
  parish: string;
  slug: string;
  created_at: string;
  updated_at: string;
}
interface Place {
  name: string;
  user_id: number;
  place_category: number;
  subCategory_id: number;
  description: string;
  main_image: string;
  images: string;
  address: string;
  slug: string;
  country_id: string;
  phone: string;
  email: string;
  web: string;
  latitude: string;
  longitude: string;
  status: string;
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  start_time_week: string;
  end_time_week: string;
  start_time_weekend: string;
  end_time_weekend: string;
  wifi: string;
  phone_2: string;
  slogan: string;
  county_id: number;
  state_id: number;
  city_id: number;
  parish_id: number;
  youtube_url: string;
}
@Component({
  selector: 'app-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.scss'],
})
export class BasicDataComponent implements OnInit {
  country_id: number;
  state_id: number;
  city_id: number;
  county_id: number;
  parish_id: number;
  category_id: number;
  subCategory_id: number;
  categories: Category[];
  subCategories: Category[];
  countries: Country[];
  states: State[];
  cities: City[];
  counties: County[];
  parishes: Parish[];
  email: string;
  address: string;
  phone: string;
  names: string;
  zip_code: string;
  web: string;
  phone_2: string; //es nuevo
  mark = null;

  zoom: number = 8;
  lat: number = 7.760189199999999;
  lng: number = -72.2137194;
  arrayMarker: any = null;

  @Input() data;

  showPlace;
  bandPlace;
  selectedCountry;
  showchanges = false;

  showSC: boolean = false;
  showS: boolean = false;
  showP: boolean = false;
  showCo: boolean = false;
  showC: boolean = false;
  name = new FormControl('', [Validators.required]);
  nameError: string;

  cat = new FormControl('', [Validators.required]);
  catError: string;

  countr = new FormControl('', [Validators.required]);
  countrError: string;

  stat = new FormControl('', [Validators.required]);
  statError: string;

  cit = new FormControl('', [Validators.required]);
  munic = new FormControl('', [Validators.required]);
  zip = new FormControl('', [Validators.required]);
  citError: string;

  addre = new FormControl('', [Validators.required]);
  addreError: string;

  idUrl: number;
  constructor(
    private GeneralService: GeneralService,
    private PlaceService: PlaceService,
    private _router: Router,
    private router: ActivatedRoute,
    private translate: TranslateService
  ) {}

  getErrorMessage() {
    const errorInput = 'Campo Requerido';
    if (this.name.hasError('required')) {
      this.nameError = errorInput;
      return this.nameError;
    }
    if (this.cat.hasError('required')) {
      this.catError = errorInput;
      return this.catError;
    }
    if (this.countr.hasError('required')) {
      this.countrError = errorInput;
      return this.countrError;
    }
    if (this.stat.hasError('required')) {
      this.statError = errorInput;
      return this.statError;
    }
    if (this.cit.hasError('required')) {
      this.citError = errorInput;
      return this.citError;
    }
    if (this.addre.hasError('required')) {
      this.addreError = errorInput;
      return this.addreError;
    }
  }
  ngOnInit(): void {
    console.log(this.data);
    let id = this.router.snapshot.params.id;
    this.idUrl = id;
    if (id == null) this.showchanges = true;

    this.GeneralService.get_countries().subscribe((res) => {
      if (res.message_return.ERROR == false) {
        this.countries = res.countries;
      }
    });
    this.PlaceService.get_placeCategories().subscribe((res) => {
      console.log("la respuesta del servidor es : ", res)
      if (res.message_return.ERROR === false) {
        this.categories = res.place_categories;
      }
    });

    combineLatest([
      this.countr.valueChanges,
      this.stat.valueChanges,
      this.cit.valueChanges,
      this.munic.valueChanges,
    ]).subscribe((data) => {
      if (
        data[0] != undefined &&
        data[1] != undefined &&
        data[2] != undefined &&
        data[3] != undefined
      ) {
        console.log(data);
        const country = this.countries?.find((c) => c.id === data[0]);
        const states = this.states?.find((c) => c.id === data[1]);
        const cities = this.cities?.find((c) => c.id === data[2]);
        const counties = this.counties?.find((c) => c.id === data[3]);
        console.log(country, states, cities, counties);
        if (
          country?.name &&
          states?.state_name &&
          cities?.city &&
          counties?.municipality
        ) {
          const direccion =
            country.name +
            ' ' +
            states.state_name +
            ' ' +
            cities.city +
            ' ' +
            counties.municipality;
          console.log('zzzzzzzzzzzzzzzz');
          console.log(direccion);
          this.getGeoLocation(direccion);
        }
      }
    });
  }

  getGeoLocation(address: string) {
    let geocoder = new google.maps.Geocoder();
    if (geocoder) {
      geocoder.geocode({ address: address }, (results, status) => {
        console.log('nada', results[0].geometry.viewport);
        if (status == google.maps.GeocoderStatus.OK) {
          console.log('LAT0', results[0].geometry.location);
          const latitud = results[0].geometry.location.lat();
          const longitud = results[0].geometry.location.lng();
          console.log('LAT', latitud);
          console.log(longitud);

          //this.ngZone.run(() => {
          this.lat = latitud;
          this.lng = longitud;
          //});
        } else {
          console.log('geocoding failed');
        }
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!!navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log('this.lng2:: ', position.coords.longitude);
        console.log('this.lat2:: ', position.coords.latitude);

        // let longitude = position.coords.longitude;
        // let latitude = position.coords.latitude;
        // this.lng = position.coords.longitude;
        // this.lat = parseInt(latitude.toString());
      });
    } else {
      console.log('No soporta geolocation');
    }

    if (changes.data.currentValue != null) {
      this.names = changes.data?.currentValue?.result?.Place;
      this.phone = changes.data?.currentValue?.result?.phone;
      this.email = changes.data?.currentValue?.result?.email;
      this.web = changes.data?.currentValue?.result?.web;
      this.zip_code = changes.data?.currentValue?.result?.zip_code;
      this.address = changes.data?.currentValue?.result?.address;
      this.country_id = changes.data?.currentValue?.result?.coutry_id;
      this.getStates();
      this.state_id = changes.data?.currentValue?.result?.state_id;
      this.getCities();
      this.city_id = changes.data?.currentValue?.result?.city_id;
      this.getCounties();
      this.county_id = changes.data?.currentValue?.result?.county_id;
      this.getParish();
      this.parish_id = changes.data?.currentValue?.result?.parish_id;
      this.category_id = changes.data?.currentValue?.category
        ? changes.data?.currentValue?.category[0].id
        : null;
      this.Subcategory();
      this.subCategory_id =
        changes.data?.currentValue?.Sub_category == null
          ? ''
          : changes.data?.currentValue?.Sub_category[0].id;
      this.showchanges = true;
      this.showC = this.showCo = this.showS = this.showSC = this.showP = true;

      if (
        changes.data?.currentValue?.result?.longitude &&
        changes.data?.currentValue?.result?.latitude
      ) {
        this.lng = parseFloat(changes.data?.currentValue?.result?.longitude);
        this.lat = parseFloat(changes.data?.currentValue?.result?.latitude);
        console.log(this.lng, this.lat);
        if (
          changes.data?.currentValue?.result?.longitude &&
          changes.data?.currentValue?.result?.latitude
        ) {
          this.mark = {
            lat: changes.data?.currentValue?.result?.latitude,
            lng: changes.data?.currentValue?.result?.longitude,
          };
        }
      }
    }
  }
  Subcategory() {
    this.showSC = true;
    this.PlaceService.get_getChieldPlaceCategory(this.category_id).subscribe(
      (resp) => {
        if (resp.message_return.ERROR == false) {
          this.subCategories = resp.place_category;
          this.showSC = false;
        }
      }
    );
  }
  getStates() {
    this.showS = true;
    this.GeneralService.get_getStatesByCountry(this.country_id).subscribe(
      (resx) => {
        if (resx.message_return.ERROR == false) {
          this.states = resx.states;
          this.showS = false;
        }
      }
    );
  }
  getCities() {
    console.log(this.state_id);
    this.showC = true;
    this.GeneralService.get_getCitiesByState(this.state_id).subscribe(
      (resy) => {
        if (resy.message_return.ERROR == false) {
          this.cities = resy.City;
          this.showC = false;
        }
      }
    );
  }
  getCounties() {
    this.showCo = true;
    this.GeneralService.get_getCountiesByCity(this.city_id).subscribe(
      (resz) => {
        if (resz.message_return.ERROR == false) {
          this.counties = resz.counties;
          this.showCo = true;
        }
      }
    );
  }

  getParish() {
    this.showP = true;
    this.GeneralService.get_getParishesByCounty(this.county_id).subscribe(
      (resa) => {
        if (resa.message_return.ERROR == false) {
          this.parishes = resa.parishes;
          this.showP = true;
        }
      }
    );
  }

  getmark(coord) {
    console.log(coord);
    this.arrayMarker = { ...coord };
  }
}
