<form [formGroup]="form">
    <div fxLayout="column" style="padding: 0px 30px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <p class="raleway-23-bold">{{'send-proposal.send' | translate}}</p>
            <button [mat-dialog-close]="null" class="btn-close">
                <mat-icon aria-hidden="false">clear</mat-icon>
            </button>
        </div>
        <p class="raleway-16-bold" style="margin-top: 21px;">{{data.element.title}}</p>
        <p class="oxigen-16" style="margin-top: 30px;">{{'send-proposal.long-sentence' | translate}}</p>

        <div fxLayout="row" style="margin-top: 40px;" fxLayoutGap="50px">
          <img [src]="data.element.avatar" class="img" (error)="onImgError($event)">
            <div fxLayout="column" fxFlex fxLayoutGap="16px">
                <div fxLayout="row" style="width: 100%" fxFlex="50">
                    <p class="raleway-14" style="width: 290px">{{data.element.name}} {{data.element.last_name}}</p>
                    <p class="raleway-14" style="width: 290px">{{data.element.title}}</p>
                </div>

                <div fxLayout="row">
                    <p class="oxigen-13" style="width: 290px">{{data.element.Ubication[2]}},
                        {{data.element.Ubication[1]}}</p>
                    <div fxLayout="row" style="width: 290px">
                        <mat-icon *ngFor="let item of countStars">star</mat-icon>
                        <!-- <mat-icon>star_border</mat-icon> -->
                    </div>
                </div>

                <mat-chip-list>
                    <mat-chip *ngFor="let item of data.element.skills"><b>{{item}}</b></mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <mat-checkbox class="mat-check" style="margin-top: 40px;" formControlName="acceptTermsCond">
            {{'register.accept' | translate }} <a href="#" class="link-blue" translate>register.terms</a>
        </mat-checkbox>

        <div fxLayout="row" fxLayoutGap="15px" style="margin-top: 33px;" fxLayoutAlign="end none">
            <div class="btn-border pointer" [mat-dialog-close]="null" fxLayoutAlign="center center">
                <p class="font">{{'send-proposal.cancel' | translate}}</p>
            </div>
            <div fxLayoutAlign="center center">
                <button *ngIf="form.controls.acceptTermsCond.value==false" class="btn-orange btn">{{'send-proposal.accept' | translate}}</button>
                <button *ngIf="form.controls.acceptTermsCond.value==true" [mat-dialog-close]="data.element.UserId" class="btn-orange btn">{{'send-proposal.accept' | translate}}</button>
            </div>
        </div>
    </div>
</form>
