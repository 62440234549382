import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { CreateQuestionComponent } from './create-question/create-question.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventsService } from '../../../../../services/events.service'
import { GeneralService } from '../../../../../services/general.service';
import { Router } from '@angular/router';
import { ModalPublishNoquestionComponent } from './modal-publish-noquestion/modal-publish-noquestion.component';
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-step-proposal',
  templateUrl: './step-proposal.component.html',
  styleUrls: ['./step-proposal.component.scss']
})
export class StepProposalComponent implements OnInit, OnDestroy {

  constructor(public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private EventsService: EventsService,
    private _router: Router,
    private GeneralService: GeneralService) { }

  nameEventSuscription: Subscription;
  placeSuscription: Subscription;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;


  data = null;
  firstFormGroup: FormGroup;
  nameEvent;
  placeEvent = null;
  currency;
  teacherStar;
  msg;
  typeMsg;
  user = JSON.parse(localStorage.getItem("userData"));

  load=false;

  ngOnInit(): void {
    this.nameEventSuscription = this.EventsService.dataBasic$.subscribe(data => {
      this.nameEvent = data;
    });
    this.placeSuscription = this.EventsService.place$.subscribe(data => {
      this.placeEvent = data;
    });
    this.msgSuscription = this.GeneralService.msg$.subscribe(data => {
      this.msg = data;
    });
    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe(data => {
      this.typeMsg = data;
    });
    this.firstFormGroup = this._formBuilder.group({
      title: ['', Validators.required],
      visibility: ['', Validators.required],
      type: ['', Validators.required],
      currency: ['', Validators.required],
      price: ['', Validators.required],
      level: ['', Validators.required],
      time: ['', Validators.required],
      timeType: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.EventsService.get_currencies().subscribe(res => {
      console.log('res  get_currencies on step-proposal:)', res);
      if (!res?.message_return?.ERROR) {
        this.currency = res.currency;
      } else {
        this.GeneralService.typeMsg$.emit("error");
        this.GeneralService.msg$.emit(res.message_return.ERROR_MENSAGGE + "get_currencies");
      }
    }, err => {
      console.log("ERRor get_currencies", err);
      this.GeneralService.typeMsg$.emit("error");
      this.GeneralService.msg$.emit(err.statusText);
    });

    this.get_teacherStars();
  }

  question;
  countQuestions = 0;
  questions = [];
  openDialogCreateQuestion() {
    const dialogRef = this.dialog.open(CreateQuestionComponent, {
      height: "auto",
      width: "auto",
      data: { question: this.question = "" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.question = result;
      if (result != "" && this.countQuestions < 5) {
        this.countQuestions++;
        this.questions.push({ "title": result });
      }
      console.log("question", this.questions);
    });
  }



  onSave() {
    this.load=true;
    if (this.firstFormGroup.status == "VALID") {//== "VALID"

      if (this.firstFormGroup.get("type").value == 'hourly') {
        console.log("es hourly");
        this.setDataHourly();
      }
      if (this.firstFormGroup.get("type").value == 'fixed price') {
        console.log("es fixed");
        this.setDataPrice();
      }

      if (this.countQuestions == 0) {
        this.openDialogNoQuestion();
      } else {
        console.log("data para mariana:", this.data);
        this.EventsService.post_admin_proposal(this.data).subscribe(res => {
          console.log("Propuesta se creo correcto: res", res);
          if (!res?.message_return?.ERROR) {
            localStorage.removeItem("eventincomplete")
            localStorage.removeItem("idevent")
            this._router.navigateByUrl("/dashboard/events/list-teachers/"+res.proposal_id);
          } else {
            this.load=false;
            this.GeneralService.typeMsg$.emit("error");
            this.GeneralService.msg$.emit(res.message_return.ERROR_MENSAGGE + "post_admin_proposal");
          }
        }, err => {
          console.log("ERRor post_admin_proposal", err);
          this.GeneralService.typeMsg$.emit("error");
          this.GeneralService.msg$.emit(err.statusText);
          this.load=false;
        });
      }
    }else{
      console.log("formulario incompleto");
      this.GeneralService.typeMsg$.emit("error");
      this.GeneralService.msg$.emit('Faltan campos por llenar');
      this.load=false;
      Object.keys(this.firstFormGroup.controls).forEach((key) => {
        this.firstFormGroup.get(key).markAsDirty();
        this.firstFormGroup.get(key).markAsTouched();
      });
    }
  }


  setDataHourly() {
    let idEvent = this.placeEvent == null ? null : this.placeEvent.id;
    if (this.countQuestions == 0) {
      this.data={
        'proposals': [
          {
            "title": this.firstFormGroup.get("title").value,
            "course_id": null,
            "event_id": this.EventsService.get_eventId(),
            "place_id": idEvent,
            "description": this.firstFormGroup.get("description").value,
            "type_contract": this.firstFormGroup.get("type").value,
            "level": this.firstFormGroup.get("level").value,
            "time_length": this.firstFormGroup.get("time").value,
            "time_unit": this.firstFormGroup.get("timeType").value,
            "price_per_hour": this.firstFormGroup.get("price").value,
            // "price_total": nada,
            "visibility": 1,
            "currency_id": this.firstFormGroup.get("currency").value,
            "publishable": this.firstFormGroup.get("visibility").value,
          }
        ]
      };
    } else {
      this.data={
        'proposals': [
          {
            "title": this.firstFormGroup.get("title").value,
            "course_id": null,
            "event_id": this.EventsService.get_eventId(),
            "place_id": idEvent,
            "description": this.firstFormGroup.get("description").value,
            "type_contract": this.firstFormGroup.get("type").value,
            "level": this.firstFormGroup.get("level").value,
            "time_length": this.firstFormGroup.get("time").value,
            "time_unit": this.firstFormGroup.get("timeType").value,
            "price_per_hour": this.firstFormGroup.get("price").value,
            // "price_total": nada,
            "visibility": 1,
            "currency_id": this.firstFormGroup.get("currency").value,
            "publishable": this.firstFormGroup.get("visibility").value,
          }
        ],
        "questions": [
          {
            "titles": this.questions,
            "user_id": this.user.id
          }
        ]
      };
    }
  }



  setDataPrice() {
    let idEvent = this.placeEvent == null ? null : this.placeEvent.id;
    if (this.countQuestions == 0) {
      this.data={
        'proposals': [
          {
            "title": this.firstFormGroup.get("title").value,
            "course_id": null,
            "event_id": this.EventsService.get_eventId(),
            "place_id": idEvent,
            "description": this.firstFormGroup.get("description").value,
            "type_contract": this.firstFormGroup.get("type").value,
            "level": this.firstFormGroup.get("level").value,
            "time_length": this.firstFormGroup.get("time").value,
            "time_unit": this.firstFormGroup.get("timeType").value,
            // "price_per_hour": nada,
            "price_total": this.firstFormGroup.get("price").value,
            "visibility": 1,
            "currency_id": this.firstFormGroup.get("currency").value,
            "publishable": this.firstFormGroup.get("visibility").value,
          }
        ]
      };
    } else {
      this.data={
        'proposals': [
          {
            "title": this.firstFormGroup.get("title").value,
            "course_id": null,
            "event_id": this.EventsService.get_eventId(),
            "place_id": idEvent,
            "description": this.firstFormGroup.get("description").value,
            "type_contract": this.firstFormGroup.get("type").value,
            "level": this.firstFormGroup.get("level").value,
            "time_length": this.firstFormGroup.get("time").value,
            "time_unit": this.firstFormGroup.get("timeType").value,
            // "price_per_hour": nada,
            "price_total": this.firstFormGroup.get("price").value,
            "visibility": 1,
            "currency_id": this.firstFormGroup.get("currency").value,
            "publishable": this.firstFormGroup.get("visibility").value,
          }
        ],
        "questions": [//hay que ver q pasa si no crea propuesta con preguntas
          {
            "titles": this.questions,
            "user_id": this.user.id
          }
        ]
      };
    }
  }

  openDialogNoQuestion() {
    const dialogRef = this.dialog.open(ModalPublishNoquestionComponent, {
      height: 'auto',
      width: 'auto',
      data: { data: this.data }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.load=false;
    });
  }

  flagStar = false;
  date;
  get_teacherStars() {
    this.EventsService.get_userstars_id(this.user.id).subscribe(res => {
      console.log("res get_userstars_id S-P:: ", res);
      if (!res?.message_return?.ERROR) {
        this.teacherStar = res.user[0];
        this.flagStar = true;
        this.date = this.teacherStar.user_creation.split("-");
        console.log("this.teacherStar", this.teacherStar);
      } else {
        this.GeneralService.typeMsg$.emit("error");
        this.GeneralService.msg$.emit(res?.message_return?.ERROR_MENSAGGE + "get_userstars_id");
      }
    }, err => {
      console.log("ERRor ", err)
      this.GeneralService.typeMsg$.emit("error");
      this.GeneralService.msg$.emit(err.statusText);
    });
  }

  get validTitle() {
    return this.firstFormGroup.get('title').invalid && this.firstFormGroup.get('title').touched
  }

  get validVisibility() {
    return this.firstFormGroup.get('visibility').invalid && this.firstFormGroup.get('visibility').touched
  }

  get validType() {
    return this.firstFormGroup.get('type').invalid && this.firstFormGroup.get('type').touched
  }

  get validCurrency() {
    return this.firstFormGroup.get('currency').invalid && this.firstFormGroup.get('currency').touched
  }

  get validPrice() {
    return this.firstFormGroup.get('price').invalid && this.firstFormGroup.get('price').touched
  }

  get validLevel() {
    return this.firstFormGroup.get('level').invalid && this.firstFormGroup.get('level').touched
  }

  get validTime() {
    return this.firstFormGroup.get('time').invalid && this.firstFormGroup.get('time').touched
  }

  get validTimeType() {
    return this.firstFormGroup.get('timeType').invalid && this.firstFormGroup.get('timeType').touched
  }

  get validDescription() {
    return this.firstFormGroup.get('description').invalid && this.firstFormGroup.get('description').touched
  }





  ngOnDestroy(): void {
    this.nameEventSuscription;
    this.placeSuscription;
    this.msgSuscription;
    this.typeMsgSuscription
  }
}
