<div fxLayout="row" fxLayout.xs="column">

  <section *ngIf="!isMobile" class="left-o-box" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="20px">
    <img routerLink="/" class="pointer" width="100%" src="/assets/images/isotipo-white.svg"/>
    <a class="btn-facebook" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px" href="/auth/facebook" target="_blank">
      <img fxFlex="22px" src="/assets/images/fb-logo.png">
      <span fxFlex class="text-btn " translate>layout.btn-facebook</span>
    </a>
    <a class="btn-google" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px" href="/auth/google" target="_blank">
      <img fxFlex="20px" src="/assets/images/google-logo.png">
      <span fxFlex class="text-btn parraphap-google" translate>layout.btn-google</span>
    </a>
    <div>
      <p class="text-white-orange-square" translate>layout.text1</p>
      <p class="text-white-orange-square" translate>layout.text2</p>
    </div>
  </section>
  
  <section id="login-auth-div" class="right-w-box" fxFlex
    fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
    <img *ngIf="isMobile==false" routerLink="/" clsass="pointer" src="/assets/images/logo.svg"/>
    <h1 class="title-box" translate>login.login</h1>
    <p translate>login.sing-in <a href="auth/register" class="link-blue" translate> login.account</a></p>
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="10px" class="group-input">
      <span  class="material-icons grey-icon" [ngClass]="{'err-icon': error}">person</span>
      <mat-form-field fxFlex [ngClass]="{'alert-red': error}">
          <mat-label translate>login.email</mat-label>
          <input name="email" (input)="onInputChange()" [(ngModel)]="email" matInput > 
      </mat-form-field>          
    </div>
    <div fxLayout="row" fxLayoutAlign=" center"  fxLayoutGap="10px" class="group-input">
      <span class="material-icons grey-icon" [ngClass]="{'err-icon': error}">lock</span>
      <mat-form-field fxFlex [ngClass]="{'alert-red': error}">
        <mat-label translate>login.password</mat-label>
        <input matInput name="password" (input)="onInputChange()" (keyup.enter)="onSubmitLogin()" [(ngModel)]="password" [type]="hide ? 'password' : 'text'">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <app-message *ngIf="msg != ''" [type]="typeMsg" message="{{msg}}"></app-message>
    <div fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="15px" style="width: 100%;">
      <mat-spinner *ngIf="load" strokeWidth="10" [diameter]="24"></mat-spinner>
      <button mat-raised-button *ngIf="!load" (click)="onSubmitLogin()"  class="btn-blue" [disabled]="disabled">{{"login.login" | translate }}</button>
<div *ngIf="isMobile"> 
  <p style="text-align: center ; margin-bottom: 15px;">o</p>  
  <a class="btn-facebook" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px" href="/auth/facebook" target="_blank">
    <img fxFlex="22px" src="/assets/images/fb-logo.png">
    <span fxFlex class="text-btn " translate >layout.btn-facebook</span>
  </a>
   <a class="btn-google" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px" href="/auth/google" target="_blank" style="box-shadow: 0px 0px 5px #00000026; margin: 15px 0;
   border-radius: 3px;">
      <img fxFlex="20px" src="/assets/images/google-logo.png">
  <span fxFlex class="text-btn parraphap-google" translate style="font-size: 12px; ">layout.btn-google</span>
  </a>
</div>
    
            <!-- forgot passwoor -->
      <a routerLink="/auth/forgot-password" class="link-blue">{{"login.password-lost" | translate }}</a>
    </div>
  </section>
  <section fxLayout="column" fxLayout.xs="row" *ngIf="isMobile" class="left-o-box footer" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="20px" >
    <img routerLink="/" class="pointer logo-sogcial-auth"  src="/assets/images/isotipo-white.svg"/>
    <a *ngIf="!isMobile" class="btn-facebook" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px" href="/auth/facebook" target="_blank">
      <img fxFlex="22px" src="/assets/images/fb-logo.png">
      <span fxFlex class="text-btn " translate>layout.btn-facebook</span>
    </a>
    <a *ngIf="!isMobile" class="btn-google" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="7px" href="/auth/google" target="_blank">
      <img fxFlex="20px" src="/assets/images/google-logo.png">
      <span fxFlex class="text-btn parraphap-google" translate>layout.btn-google</span>
    </a>
    <div>
      <p class="text-white-orange-square" translate>layout.text1</p>
      <p class="text-white-orange-square" translate>layout.text2</p>
    </div>
  </section>
</div>

