import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, last, retry } from 'rxjs/operators';
import { Router } from '@angular/router'; //preguntar

import { Params, ActivatedRoute, RouterLink } from '@angular/router';
import { EventsService } from '../../../../services/events.service';
import { GeneralService } from '../../../../services/general.service';

import { MatDateFormats } from '@angular/material/core';
import { environment } from 'src/environments/environment';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  minDate = new Date();
  form: FormGroup;
  id;
  images;

  visible = true;
  selectable = true;
  removable = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  // fulltags =[{name:"", id:-1}];

  loadPage = false;
  load = true;
  loadService = false;

  places = [];
  categories = [];
  events;

  teacher = [];
  loadContent = [];
  loadTags = [];
  chips: string[];

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  currency;
  url;
  src_img = '';
  file = null;
  files=null;
  loadSchedule: boolean;
  eventsSelected=null;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private fb: FormBuilder,
    private EventsService: EventsService,
    private route: ActivatedRoute,
    private _router: Router,
    private GeneralService: GeneralService
  ) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags.slice()
      )
    );
  }

  ngOnInit(): void {
    this.eventsSelected=JSON.parse(localStorage.getItem('eventsSelected'))
    // this.events = JSON.parse(localStorage.getItem('events'));
    // console.log("---localstorage-event-edit", this.events);
    this.src_img="";
    this.events=null;

    this.msgSuscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });

    this.GeneralService.get_currencies().subscribe(
      (res) => {
        console.log('res get_currencies:', res);
        this.currency = res.currency;
      },
      (err) => {
        console.log('ERRor get_currencies', err);
      }
    );

    this.form = this.fb.group({
      name: ['', Validators.required],
      category_id: ['', Validators.required],
      // place: [''],
      place: new FormControl({ value: '', disabled: true }),
      dateStart: [''],
      dateEnd: [''],
      availability: ['',[Validators.required,Validators.max(this.eventsSelected?.capacity)]],
      capacity:  ['', ],
      price: [''],
      timeStart: [''],
      timeEnd: [''],
      description: [''],
      content: [''],
      level_id: [''],
      age: [''],
      approved: [''],
      visibility: [''],
      currency_id: [''],
      urls: [''],
    });

    this.form.controls['capacity'].disable();
    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
      console.log('---page:id', this.id);
      this.loadInfoEvent();
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our tag
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(
      (tag) => tag.toLowerCase().indexOf(filterValue) === 0
    );
  }

  loadInfoEvent() {
    this.EventsService.get_adminEvents(this.id).subscribe(
      (res) => {
        console.log('res get_admin_events/', this.id, '  :', res);
        this.events = res.event;
        this.teacher = res.event_teacher;
        const imagenes=JSON.parse(this.events?.images)?.map((imagen)=>(environment.url_server.replace('api/', ""))+imagen)
        console.log(imagenes)
        this.images = imagenes;
        this.loadDataAtForm();
        this.loadPage = true;
      },
      (err) => {
        console.log('ERRor get_adminEvents', err);
      }
    );

    this.EventsService.get_admin_courseSchedule(this.id).subscribe(
      (res: any) => {
        console.log(
          'res get_admin_courseSchedule on edit:',
          this.id,
          '  :',
          res
        );
        if (res.message_return.ERROR == true) {
          this.loadSchedule = true;
        } else {
          this.loadSchedule = false;
        }
      },
      (err) => {
        console.log('ERRor get_adminEvents', err);
      }
    );
  }

  loadDataAtForm() {
    let cat: number, pla: number;

    this.EventsService.get_event_create().subscribe(
      (res) => {
        console.log('-----res-gcreate-edit(places,evetcategory,tags)', res);
        this.categories = res.event_categories.original.event_categories;
        // this.places = res.places.original.places; ya no
        res.tags.original.tags.forEach((element) => {
          this.allTags.push(element.name);
          this.loadTags.push(element);
        });
        // this.load = false;

        for (let i = 0; i < this.categories.length; i++) {
          if (this.categories[i].name == this.events.category) {
            cat = this.categories[i].id;
            break;
          }
        }
        console.log('cat:', cat);

        // for (let i = 0; i < this.places.length; i++) {
        //   if (this.places[i].name == this.events.place) {
        //     pla = this.places[i].id;
        //     break;
        //   }
        // }
        // console.log("pla:",pla);

        let splitTags =
          this.events.tags != null ? this.events.tags.split(',') : [];
        for (let i = 0; i < splitTags.length; i++) {
          this.tags.push(splitTags[i]);
        }

        let dateS;
        let dateE;
        if (this.events.start_date != null) {
          let divide: String = this.events.start_date.split('-');
          dateS = new Date(
            Number(divide[0]) +
              '-' +
              Number(divide[1]) +
              '-' +
              Number(divide[2])
          );
          divide = this.events.end_date.split('-');
          dateE = new Date(
            Number(divide[0]) +
              '-' +
              Number(divide[1]) +
              '-' +
              Number(divide[2])
          );
        }

        console.log(
          'cat:',
          cat,
          ' pla:',
          this.events.place,
          ' dateS:',
          dateS,
          ' dateE:',
          dateE,
          ' this.events:',
          this.events
        );

        this.form.reset({
          name: this.events.name,
          category_id: cat,
          place: this.events.place ? this.events.place : null,
          dateStart: dateS ? dateS : null,
          dateEnd: dateE ? dateE : null,
          availability: this.events.availability,
          capacity: this.eventsSelected.capacity,
          price: this.events.price ? this.events.price : 0,
          timeStart: this.events.start_hour,
          timeEnd: this.events.end_hour,
          description: this.events.description,
          content: this.events.content,
          level_id: this.events.level_id,
          age: this.events.age,
          approved: this.events.approved,
          urls: this.events.urls,
          currency_id: this.events.currency_id,
          visibility: this.events.visibility,
        });
        // this.form.controls['place'].disable();
        this.urls = this.events.urls;
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
  }

  save() {
    this.loadService = true;
    let array_tags = [];
    console.log(this.form);
    if (this.form.status == 'VALID') {
      this.tags.forEach((element) => {
        for (let i = 0; i < this.loadTags.length; i++) {
          if (element == this.loadTags[i].name) {
            array_tags.push({
              tag_id: this.loadTags[i].id,
              tag_name: this.loadTags[i].name,
            });
          }
        }
      });
      console.log('arra_tags:', array_tags);

      const request = {
        name: this.form.get('name').value,
        category_id: this.form.get('category_id').value,
        availability: this.form.get('availability').value,
        price: this.form.get('price').value,
        description: this.form.get('description').value,
        content: this.form.get('content').value,
        level_id: this.form.get('level_id').value,
        approved: this.form.get('approved').value,
        age: this.form.get('age').value,
        urls: this.form.get('urls').value,
        // state: this.form.get('state').value,
        currency_id: this.form.get('currency_id').value,
        capacity: this.form.get('capacity').value,
        state: this.form.get('visibility').value,
        tag: array_tags,
      };
      if (array_tags.length === 0) {
        delete request.tag;
      }
      this.EventsService.put_admin_events(this.id, request).subscribe(
        (res) => {
          console.log('res put_admin_events:', res);
          console.log('evento modificado exitosamente');
          if (res.message_return.ERROR == false) {
            this.guardarImagenes();
            this.loadService = false;
            this.GeneralService.typeMsg$.emit('success');
            this.GeneralService.msg$.emit('Modificacion Exitosa');
          } else {
            this.GeneralService.typeMsg$.emit('error');
            this.GeneralService.msg$.emit('error server');
            this.loadService = false;
          }
        },
        (err) => {
          console.log('ERRor put_admin_events', err);
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit('error server');
          this.loadService = false;
        }
      );
    } else {
      this.GeneralService.typeMsg$.emit('error');
      this.GeneralService.msg$.emit('Faltan campos');
    }
    return;

    let data = new Array();
    // let timeS = (this.form.get('timeStart').value).split(":");
    // let timeE = (this.form.get('timeEnd').value).split(":");

    // let divide: Date = (this.form.get('dateStart').value);
    // let dateS: String;
    // dateS = divide.getFullYear().toString();
    // (divide.getMonth() <= 9) ? dateS = dateS + "-0" + (divide.getMonth() + 1).toString() : dateS = dateS + "-" + (divide.getMonth() + 1).toString();
    // (divide.getDate() <= 9) ? dateS = dateS + "-0" + divide.getDate().toString() : dateS = dateS + "-" + divide.getDate().toString();

    // let dateE: String;
    // let divide1: Date = (this.form.get('dateEnd').value);
    // dateE = divide1.getFullYear().toString();
    // (divide1.getMonth() <= 9) ? dateE = dateE + "-0" + (divide1.getMonth() + 1).toString() : dateE = dateE + "-" + (divide1.getMonth() + 1).toString();
    // (divide1.getDate() <= 9) ? dateE = dateE + "-0" + divide1.getDate().toString() : dateE = dateE + "-" + divide1.getDate().toString();

    data.push({
      event: [
        {
          name: this.form.get('nameEvent').value,
          category_id: this.form.get('category').value,
          // "start_date": dateS,
          // "end_date": dateE,
          availability: this.form.get('availability').value,
          capacity: this.form.get('capacity').value,
          price: this.form.get('price').value,
          description: this.form.get('description').value,
          content: this.form.get('content').value,
          level_id: this.form.get('level').value,
          approved: this.form.get('evaluate').value,
          age: this.form.get('age').value,
          url: this.form.get('url').value,
          visibility: this.form.get('visible').value,
          currency_id: this.form.get('currency').value,
        },
      ],
      tag: array_tags,
    });
    console.log('DATA: ', data);
  }

  guardarImagenes() {
    this.files = this.GeneralService.get_carouselFile();
    console.log(this.files)
    console.log(this.file)
     this.GeneralService.uploadGalleryandMainImagesEvents(this.files, this.id,(this.file?.length>0)?this.file[0]:null).pipe(retry(3)).subscribe(

       (res)=>{
         console.log("responde", res);
       }
     );

  }


  receiveSrcImgMain(event) {
    console.log('principal src');
    this.src_img = event;
  }

  receiveFileMain(file) {
    console.log('principal file');
    this.file = file;
  }

  onUpload(e) {
    console.log('subir', e.target.files[0]);
  }

  get validNameEvent() {
    return this.form.get('name').invalid && this.form.get('name').touched;
  }

  get validCategory() {
    return (
      this.form.get('category_id').invalid &&
      this.form.get('category_id').touched
    );
  }

  redirectToTeacherList() {
    this._router.navigateByUrl(
      '/dashboard/events/list-teachers/' + this.events.proposal_id
    );
  }

  test() {
    console.log('form:', this.form);
  }

  urls: string = '';
  @Input() SendUrl: string = '';
  onKey($event) {
    this.urls = $event.target.value;
    console.log('onKey', this.urls);
  }


errorHandler(event) {
  console.debug(event);
  event.target.src = "./assets/images/person-default.svg";
}

}
