import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-send-proposal',
  templateUrl: './send-proposal.component.html',
  styleUrls: ['./send-proposal.component.scss']
})
export class SendProposalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) { }
  countStars;
  form: FormGroup;

  ngOnInit(): void {

    this.form = this.fb.group({
      acceptTermsCond : [false, Validators.requiredTrue ]
    });

    this.countStars=Array(this.data.element.Stars);
    console.log("data en dialog", this.data);
  }
  onImgError(event){
    event.target.src = './assets/images/user.svg';
   }
}
