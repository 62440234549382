<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between none"
  fxLayoutGap="36px"
>
  <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
    <mat-tab-group mat-stretch-tabs>
      <mat-tab label="Lista de Lugares">
        <div
          fxLayout="column"
          fxLayoutGap="10px"
          style="margin: 36px 2px 2px 2px"
        >
          <div fxLayout="column" fxLayoutAlign="center stretch">
            <app-message
              *ngIf="msg != null"
              [msg]="msg"
              [typeMsg]="typeMsg"
            ></app-message>
          </div>
          <!-- <app-filter></app-filter> -->
          <div fxLayout="row" fxLayoutAlign="start none" fxLayoutGap="10px">
            <mat-form-field fxFlex="40%">
              <mat-label>{{ "place.search" | translate }}</mat-label>
              <input matInput (keyup)="applyFilter($event)" />
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <button
              class="btn-filter"
              [ngClass]="{ 'border-orange': !hide }"
              (click)="hide = !hide"
            >
              <mat-icon
                class="icon-grey"
                style="margin-top: -8px"
                [ngClass]="{ 'color-orange': !hide }"
                >filter_list</mat-icon
              >
            </button>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            *ngIf="!hide"
          >
            <p fxFlex="6%" class="paragraph">Filtrar por:</p>

            <mat-form-field fxFlex="17%">
              <mat-label>{{ "place.category" | translate }}</mat-label>
              <mat-select [formControl]="category" multiple>
                <mat-select-trigger>
                  {{ category.value ? category.value[0] : "" }}
                  <span
                    *ngIf="category.value?.length > 1"
                    class="example-additional-selection"
                    >(+{{ category.value.length - 1 }}
                    {{
                      category.value?.length === 2 ? "other" : "others"
                    }})</span
                  >
                </mat-select-trigger>
                <mat-option *ngFor="let item of categoryList" [value]="item">{{
                  item
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <span><button class="reset" (click)="clearcategory()">X</button></span>

            <mat-form-field fxFlex="17%">
              <mat-label>{{ "place.location" | translate }}</mat-label>
              <mat-select [formControl]="location" multiple>
                <mat-select-trigger>
                  {{ location.value ? location.value[0] : "" }}
                  <span
                    *ngIf="location.value?.length > 1"
                    class="example-additional-selection"
                    >(+{{ location.value.length - 1 }}
                    {{
                      location.value?.length === 2 ? "other" : "others"
                    }})</span
                  >
                </mat-select-trigger>
                <mat-option *ngFor="let item of locationList" [value]="item">{{
                  item
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <span><button class="reset" (click)="clearlocation()">X</button></span>

          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="end center"
            routerLink="/dashboard/places/create"
          >
            <mat-icon class="btn-icon pointer">add</mat-icon>
            <button class="btn btn-border">
              {{ "place.add" | translate }}
            </button>
          </div>

          <mat-card>
            <div *ngIf="dataSource; then blockTable; else blockNoData"></div>
            <ng-template #blockTable>
              <table  class="table-lugares" mat-table [dataSource]="dataSource" multiTemplateDataRows>
                <ng-container
                  matColumnDef="{{ column }}"
                  *ngFor="let column of columnsToDisplay"
                >
                  <div
                    *ngIf="
                      column == 'edit' ||
                        column == 'copy' ||
                        column == 'delete';
                      else blockHead
                    "
                  >
                    <th mat-header-cell *matHeaderCellDef></th>
                  </div>

                  <ng-template #blockHead>
                    <th mat-header-cell *matHeaderCellDef class="text-bold">
                      {{ "table." + column | translate }}
                    </th>
                  </ng-template>

                  <div *ngIf="column == 'edit'; else blockData">
                    <td
                      style="width: 5%"
                      mat-cell
                      *matCellDef="let element"
                      class="pointer"
                      routerLink="/dashboard/places/edit/{{ element.id_place }}"
                    >
                      <span class="material-icons" style="font-size: 18px">{{
                        element[column]
                      }}</span>
                    </td>
                  </div>

                  <div *ngIf="column == 'copy'; else blockData">
                    <td
                      style="width: 5%"
                      mat-cell
                      *matCellDef="let element"
                      class="pointer"
                      routerLink="/dashboard/places/{{ element.id_place }}"
                    >
                      <span class="material-icons" style="font-size: 18px">{{
                        element[column]
                      }}</span>
                    </td>
                  </div>
                  <div *ngIf="column == 'delete'; else blockData">
                    <td
                      style="width: 5%"
                      mat-cell
                      *matCellDef="let element"
                      class="pointer"
                      (click)="deleteplace(element.id_place)"
                    >
                      <span class="material-icons" style="font-size: 18px">{{
                        element[column]
                      }}</span>
                    </td>
                  </div>

                  <ng-template #blockData>
                    <td mat-cell *matCellDef="let element">
                      {{ element[column] }}
                    </td>
                  </ng-template>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                  <td
                    mat-cell
                    *matCellDef="let element"
                    [attr.colspan]="columnsToDisplay.length"
                    style="background: rgba(0, 0, 0, 0.04)"
                  >
                    <div
                      fxLayout="row"
                      fxLayoutGap="20px"
                      class="element-detail"
                      [@detailExpand]="
                        element == expandedElement ? 'expanded' : 'collapsed'
                      "
                    >
                      <div
                        class="content-img"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      >
                        <img
                          [src]="element.main_image"
                          class="img"
                          (error)="onImgError($event)"
                        />
                      </div>
                      <!-- {{ element.main_image }} -->
                      <div
                        fxLayout="column"
                        fxLayoutAlign="space-between start"
                        class="item_container"
                        fxLayoutGap="12px"
                      >
                        <p style="font-size: 14px">{{ element.description }}</p>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr
                  mat-row
                  *matRowDef="let element; columns: columnsToDisplay"
                  class="element-row"
                  [class.expanded-row]="expandedElement === element"
                  (click)="
                    expandedElement =
                      expandedElement === element ? null : element
                  "
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: ['expandedDetail']"
                  class="detail-row"
                ></tr>
              </table>
              <mat-paginator
                #paginator
                [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons
              ></mat-paginator>
            </ng-template>

            <ng-template #blockNoData>
              <p
                style="
                  font-family: Raleway;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 600;
                  color: #4c4c4c;
                "
              >
                No se encontró resultados
              </p>
            </ng-template>
          </mat-card>
        </div>
      </mat-tab>

      <mat-tab label="Solicitudes de reserva">
        <div fxLayout="row" fxLayoutAlign="start none" fxLayoutGap="10px">
          <mat-form-field fxFlex="90%" class="input">
            <mat-label>{{ "place.search" | translate }}</mat-label>
            <input matInput (keyup)="applyFilterReseva($event)" />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
          <button
            class="btn-filter1"
            [ngClass]="{ 'border-orange': !hidePlace }"
            (click)="hidePlace = !hidePlace"
          >
            <mat-icon
              class="icon-grey"
              style="margin-top: -8px"
              [ngClass]="{ 'color-orange': !hidePlace }"
              >filter_list</mat-icon
            >
          </button>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          *ngIf="!hidePlace"
        >
          <p fxFlex="6%" class="paragraph">Filtrar por:</p>

          <mat-form-field fxFlex="17%">
            <mat-label>{{ "place.category" | translate }}</mat-label>
            <mat-select #selectcategory (selectionChange)="categoryreserva($event)" multiple>
              <mat-option
                *ngFor="let item of categoryListreserva"
                [value]="item"
                >{{ item }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <span><button class="reset" (click)="clearcateg(selectcategory)" >X</button></span>

          <mat-form-field fxFlex="17%">
            <mat-label>{{ "place.location" | translate }}</mat-label>
            <mat-select #selectlocation (selectionChange)="ciudadfiltro($event)" multiple>
              <mat-option *ngFor="let item of ciudades" [value]="item">{{
                item
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <span><button class="reset" (click)="clearubication(selectlocation)" >X</button></span>
        </div>

        <mat-card>
          <div *ngIf="dataSourcer; then blockTabler; else blockNoData"></div>

          <ng-template #blockTabler>
            <table class="tablecurso car">
              <thead>
                <th>Nombre</th>
                <th>Categoria</th>
                <th class="cur-even">Cursos/Eventos</th>
                <th>Ubicacion</th>
                <th></th>
              </thead>
              <tbody>
                <ng-container *ngFor="let evencur of contenido">
                  <tr (click)="evencur.expand = !evencur.expand">
                    <td class="name">
                      {{ evencur.name_place }}
                    </td>
                    <td class="category">
                      <span *ngIf="evencur.course_category">
                        {{ evencur.course_category }}
                      </span>
                      <span *ngIf="evencur.category">
                        {{ evencur.category }}
                      </span>
                    </td>
                    <td class="idlugares">
                      {{ evencur.id_course_event }}
                    </td>
                    <td class="ubicacion">
                      {{ evencur.country }}, {{ evencur.state }},
                      {{ evencur.city }}, {{ evencur.name_place }}
                    </td>

                    <td style="width: 5%" class="pointer">
                      <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <ng-container *ngIf="evencur.iscourse">
                          <button
                            routerLink="/dashboard/courses/{{
                              evencur.id_course_event
                            }}"
                            mat-menu-item
                          >
                            <span>ver</span>
                          </button>
                        </ng-container>
                        <ng-container *ngIf="!evencur.iscourse">
                          <button
                            routerLink="/dashboard/events/{{
                              evencur.id_course_event
                            }}"
                            mat-menu-item
                          >
                            <span>ver</span>
                          </button>
                        </ng-container>

                        <button
                          *ngIf="evencur.iscourse"
                          mat-menu-item
                          (click)="serviceDataToCancelReservation(evencur)"
                        >
                          <!-- <button mat-menu-item> -->
                          <span>Eliminar</span>
                        </button>
                        <button
                          *ngIf="!evencur.iscourse"
                          mat-menu-item
                          (click)="
                            serviceDataToCancelReservationevento(evencur)
                          "
                        >
                          <!-- <button mat-menu-item> -->
                          <span>Eliminar</span>
                        </button>
                      </mat-menu>
                    </td>
                  </tr>
                  <tr *ngIf="evencur.expand">
                    <td colspan="5">
                      <div class="descripcion">
                        <div class="imegen">
                          <div
                            class="content-img"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                          >
                            <img [src]="evencur.main_image" class="img" />
                          </div>
                        </div>
                        <div class="contenido">
                          {{ evencur.description }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>

                <tr *ngIf="contenido.length === 0">
                  <td class="mensaje" colspan="4">No se encontró resultados</td>
                </tr>
              </tbody>
            </table>
          </ng-template>

          <!-- <ng-template #blockNoData>
            <p
              style="
                font-family: Raleway;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                color: #4c4c4c;
              "
            >
              No se encontró resultados
            </p>
          </ng-template> -->
        </mat-card>
        <!-- <mat-card class="mat-card mat-focus-indicator ng-tns-c347-10" >
        <table class="tablecurso">
          <thead>
            <th>Nombre</th>
            <th>Categoria</th>
            <th>Cursos/Eventos</th>
            <th>Ubicacion</th>
          </thead>
          <tbody>
            <tr *ngFor="let evencur of contenido">
              <td>
                {{ evencur.place }}


              </td>
              <td>
                {{ evencur.course_category  }} {{evencur.category}}
              </td>
              <td>{{ evencur.id }}{{ evencur.course_id }}</td>
              <td>{{ cursos.end_date }}</td>
              <td>{{ cursos.tags }}</td>
            </tr>
            <tr class="mensaje" *ngIf="info?.courses.length===0"><td colspan="5">No hay cursos para este lugar</td></tr>
          </tbody>
        </table>
      </mat-card> -->

        <!-- <app-ofert-list
          [submitted]="received"
          [type]="'received'"
        ></app-ofert-list> -->
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
