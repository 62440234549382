import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CoursesListComponent implements OnInit {

 displayedColumns: string[] = ['name', 'category', 'instructor', 'start_date', 'end_date', 'status','edit', 'copy'];
   dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  expandedElement: PeriodicElement | null;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

export interface PeriodicElement {
  id: number;
	name: string;
  category: string;  
  instructor: string;
  start_date: string;
  end_date: string;
 	status: string;
 	edit: string;
 	copy: string;
 	description: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
 {
    id: 1,
    name: 'Lugar numero 1-Prueba',
    category: 'Education',
    instructor:  'Profesor 1',
    start_date: '00/00/0000',
    end_date: '20-02-1998',
    status: 'Saved',
    edit: "create",
    copy: "library_books",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod rhoncus dolor eu pretium. Nunc pulvinar nec tellus quis pretium. In quis porta est, a sagittis lacus. Suspendisse convallis facilisis neque ut dignissim. Mauris maximus pharetra iaculis. Fusce imperdiet elit at venenatis elementum. Etiam dignissim tincidunt ornare. Maecenas eget feugiat diam, nec vehicula elit.`
  },
  {
    id: 2,
    name: 'Lugar numero 1-Prueba',
    category: 'Education',
    instructor:  'Profesor 1',
    start_date: '00/00/0000',
    end_date: '20-02-1998',
    status: 'Published',
    edit: "create",
    copy: "library_books",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod rhoncus dolor eu pretium. Nunc pulvinar nec tellus quis pretium. In quis porta est, a sagittis lacus. Suspendisse convallis facilisis neque ut dignissim. Mauris maximus pharetra iaculis. Fusce imperdiet elit at venenatis elementum. Etiam dignissim tincidunt ornare. Maecenas eget feugiat diam, nec vehicula elit.`
  },
  {
    id: 3,
    name: 'Lugar numero 1-Prueba',
    category: 'Education',
    instructor:  'Profesor 1',
    start_date: '00/00/0000',
    end_date: '20-02-1998',
    status: 'Published',
    edit: "create",
    copy: "library_books",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod rhoncus dolor eu pretium. Nunc pulvinar nec tellus quis pretium. In quis porta est, a sagittis lacus. Suspendisse convallis facilisis neque ut dignissim. Mauris maximus pharetra iaculis. Fusce imperdiet elit at venenatis elementum. Etiam dignissim tincidunt ornare. Maecenas eget feugiat diam, nec vehicula elit.`
  },
  {
    id: 3,
    name: 'Lugar numero 1-Prueba',
    category: 'Education',
    instructor:  'Profesor 1',
    start_date: '00/00/0000',
    end_date: '20-02-1998',
    status: 'Published',
    edit: "create",
    copy: "library_books",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod rhoncus dolor eu pretium. Nunc pulvinar nec tellus quis pretium. In quis porta est, a sagittis lacus. Suspendisse convallis facilisis neque ut dignissim. Mauris maximus pharetra iaculis. Fusce imperdiet elit at venenatis elementum. Etiam dignissim tincidunt ornare. Maecenas eget feugiat diam, nec vehicula elit.`
  },
  {
    id: 4,
    name: 'Lugar numero 1-Prueba',
    category: 'Education',
    instructor:  'Profesor 1',
    start_date: '00/00/0000',
    end_date: '20-02-1998',
    status: 'Published',
    edit: "create",
    copy: "library_books",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod rhoncus dolor eu pretium. Nunc pulvinar nec tellus quis pretium. In quis porta est, a sagittis lacus. Suspendisse convallis facilisis neque ut dignissim. Mauris maximus pharetra iaculis. Fusce imperdiet elit at venenatis elementum. Etiam dignissim tincidunt ornare. Maecenas eget feugiat diam, nec vehicula elit.`
  },
  {
    id: 5,
    name: 'Lugar numero 1-Prueba',
    category: 'Education',
    instructor:  'Profesor 1',
    start_date: '00/00/0000',
    end_date: '20-02-1998',
    status: 'Published',
    edit: "create",
    copy: "library_books",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod rhoncus dolor eu pretium. Nunc pulvinar nec tellus quis pretium. In quis porta est, a sagittis lacus. Suspendisse convallis facilisis neque ut dignissim. Mauris maximus pharetra iaculis. Fusce imperdiet elit at venenatis elementum. Etiam dignissim tincidunt ornare. Maecenas eget feugiat diam, nec vehicula elit.`
  },
  {
    id: 6,
    name: 'Lugar numero 1-Prueba',
    category: 'Education',
    instructor:  'Profesor 1',
    start_date: '00/00/0000',
    end_date: '20-02-1998',
    status: 'Published',
    edit: "create",
    copy: "library_books",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod rhoncus dolor eu pretium. Nunc pulvinar nec tellus quis pretium. In quis porta est, a sagittis lacus. Suspendisse convallis facilisis neque ut dignissim. Mauris maximus pharetra iaculis. Fusce imperdiet elit at venenatis elementum. Etiam dignissim tincidunt ornare. Maecenas eget feugiat diam, nec vehicula elit.`
  },
  {
    id: 7,
    name: 'Lugar numero 1-Prueba',
    category: 'Education',
    instructor:  'Profesor 1',
    start_date: '00/00/0000',
    end_date: '20-02-1998',
    status: 'Published',
    edit: "create",
    copy: "library_books",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod rhoncus dolor eu pretium. Nunc pulvinar nec tellus quis pretium. In quis porta est, a sagittis lacus. Suspendisse convallis facilisis neque ut dignissim. Mauris maximus pharetra iaculis. Fusce imperdiet elit at venenatis elementum. Etiam dignissim tincidunt ornare. Maecenas eget feugiat diam, nec vehicula elit.`
  },
  {
    id: 8,
    name: 'Lugar numero 1-Prueba',
    category: 'Education',
    instructor:  'Profesor 1',
    start_date: '00/00/0000',
    end_date: '20-02-1998',
    status: 'Published',
    edit: "create",
    copy: "library_books",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod rhoncus dolor eu pretium. Nunc pulvinar nec tellus quis pretium. In quis porta est, a sagittis lacus. Suspendisse convallis facilisis neque ut dignissim. Mauris maximus pharetra iaculis. Fusce imperdiet elit at venenatis elementum. Etiam dignissim tincidunt ornare. Maecenas eget feugiat diam, nec vehicula elit.`
  },
  {
    id: 9,
    name: 'Lugar numero 1-Pruebaaaa',
    category: 'Education',
    instructor:  'Profesor 1',
    start_date: '00/00/0000',
    end_date: '20-02-1998',
    status: 'Published',
    edit: "create",
    copy: "library_books",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod rhoncus dolor eu pretium. Nunc pulvinar nec tellus quis pretium. In quis porta est, a sagittis lacus. Suspendisse convallis facilisis neque ut dignissim. Mauris maximus pharetra iaculis. Fusce imperdiet elit at venenatis elementum. Etiam dignissim tincidunt ornare. Maecenas eget feugiat diam, nec vehicula elit.`
  }
];