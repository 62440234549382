<div fxLayout="column" style="overflow-y: hidden">
  <div fxLayout="column" fxLayoutAlign="space-between start">
    <p class="relawey-23-bold" style="margin-bottom: 10px">Detalles de lugar</p>
    <div fxLayout="row">
      <mat-icon class="icon-grey" style="color: black">folder</mat-icon>
      <a class="raleway-14-b" href="">Panel/</a>
      <a class="raleway-14-b" href=""> Places/</a>
      <a class="raleway-14-b" href=""> Detalles</a>
    </div>
  </div>

  <mat-card>
    <div class="row space">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" style="padding: 2em">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <p
              style="margin-top: 53px"
              class="raleway-18-g-bold"
              style="margin-top: 20px"
            >
              Nombre del Lugar:
              <span class="lugar">{{ info?.result?.Place }}</span>
            </p>
            <!-- <p class="title-places" *ngIf="bandPlace">Nombre del lugar: {{showPlace.Place}}</p> -->
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <p
              style="margin-top: 53px"
              class="raleway-18-g-bold"
              style="margin-top: 10px"
            >
              Categoria: <span class="lugar">{{ info?.result?.Category }}</span>
            </p>
            <!-- <mat-chip-list aria-label="Fish selection">
                      <mat-chip *ngIf="bandPlace">{{showPlace.Category}}</mat-chip>
                    </mat-chip-list> -->
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margin-separate-address"
          >
            <div class="row" style="margin-top: 10px">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1 icon-size">
                <span class="material-icons">location_on</span>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11">
                <p>
                  <span class="lugar" *ngIf="bandPlace">{{
                    info?.result?.Country
                  }}</span>
                  -
                  <span class="lugar" *ngIf="bandPlace">{{
                    showPlace.State
                  }}</span>
                  - <span *ngIf="bandState">showCity.city</span
                  ><span class="lugar" *ngIf="bandPlace">{{
                    info?.result?.municipality
                  }}</span>
                </p>
                <p>
                  <span class="lugar" *ngIf="bandPlace">{{
                    showPlace.parish
                  }}</span>
                  -
                  <span class="lugar" *ngIf="bandPlace">
                    {{ info?.result?.zip_code }}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margin-separate-phone"
          ></div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1 icon-size">
            <span class="material-icons">call</span>
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-11 phone lugar"
            *ngIf="bandPlace"
          >
            {{ info?.result?.phone }}
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1 icon-size">
            <span class="material-icons">mail</span>
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-11 phone lugar"
            *ngIf="bandPlace"
          >
            {{ info?.result?.email }}
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1 icon-size">
            <span class="material-icons">language</span>
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-11 phone lugar"
            style="margin-bottom: 40px"
            *ngIf="bandPlace"
          >
            {{ info?.result?.web }}
          </div>

          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 margin-separate-phone"
          ></div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1 icon-size">
            <img
              *ngIf="showPlace?.youtube != ''"
              src="assets/images/tik-logo.svg"
              class="icon-default"
            />
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-11 title-address"
            *ngIf="bandPlace && showPlace.youtube != ''"
          >
            {{ info?.result?.youtube }}
          </div>

          <div
            *ngIf="showPlace?.instagram != ''"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-1 icon-size"
          >
            <img src="assets/images/ins-logo.svg" class="icon-default" />
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-11 title-address"
            *ngIf="showPlace?.instagram != '' && bandPlace"
          >
            {{ info?.result?.instagram }}
          </div>

          <div
            *ngIf="showPlace?.twitter != ''"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-1 icon-size"
          >
            <img src="assets/images/tw-logo.svg" class="icon-default" />
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-11 title-address"
            *ngIf="showPlace?.twitter != '' && bandPlace"
          >
            {{ info?.result?.twitter }}
          </div>

          <div
            *ngIf="showPlace?.facebook != ''"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-1 icon-size"
          >
            <img src="assets/images/fb-logo.svg" class="icon-default" />
          </div>
          <div
            class="col-xs-12 col-sm-12 col-md-12 col-lg-11 title-address"
            *ngIf="showPlace?.facebook !== '' && bandPlace"
          >
            {{ info?.result?.facebook }}
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <img
          *ngIf="bandPlace"
          src="{{ info?.result?.main_image }}"
          alt=""
          style="width: 100%"
        />
        <!-- ojo	<app-video-youtube *ngIf="showPlace.youtube_url!=null && bandPlace" [SendUrl]="showPlace.youtube_url"></app-video-youtube> -->
      </div>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <mat-tab-group>
          <mat-tab label="Descripcion">
            <div style="height: 500px">
              <app-description
                style="height: 500px"
                *ngIf="bandPlace"
                [text]="showPlace.description"
              ></app-description>
            </div>
          </mat-tab>
          <mat-tab label="Cursos">
            <div style="height: 500px" class="listacourses">
              <mat-card class="mat-card mat-focus-indicator ng-tns-c347-10" >
                <table class="tablecurso">
                  <thead>
                    <th>Nombre</th>
                    <th>Categoria</th>
                    <th>Fecha inicio</th>
                    <th>Fecha fin</th>
                    <th>Tags</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let cursos of info?.courses">
                      <td>
                        {{ cursos.course_name }}
                      </td>
                      <td>
                        {{ cursos.course_category }}
                      </td>
                      <td>{{ cursos.start_date }}</td>
                      <td>{{ cursos.end_date }}</td>
                      <td>{{ cursos.tags }}</td>
                    </tr>
                    <tr class="mensaje" *ngIf="info?.courses.length===0"><td colspan="5">No hay cursos para este lugar</td></tr>
                  </tbody>
                </table>
              </mat-card>

            </div>
          </mat-tab>
          <mat-tab label="Mapa">
            <mat-card fxFlex class="card" style="height: 500px">
              <app-google-maps
                fxFlex
                latitud="{{  info?.result?.latitude }}"
                longitud="{{ info?.result?.longitude }}"
                [coords]="{lat:info?.result?.latitude,lng:info?.result?.longitude}"
              ></app-google-maps>
            </mat-card>
          </mat-tab>
          <mat-tab label="Imagenes/Video">
            <div
              fxLayout="row"
              fxLayoutAlign="space-around stretch"
              fxLayoutGap="20px"
              style="padding: 18px"
            >
              <mat-card
                fxLayout="column"
                fxFlex="40"
                xLayoutAlign="space-between center"
                class="card"
              >
                <app-video-youtube [SendUrl]="url"></app-video-youtube>
                <div
                  fxLayout="column"
                  fxLayoutAlign="end stretch"
                  fxFlex
                  fxLayoutGap="18px"
                >
                  <p class="title-card">Video</p>
                  <mat-form-field>
                    <mat-label class="label-input">{{
                      "place.urlYoutube" | translate
                    }}</mat-label>
                    <textarea
                      (keyup)="onKey($event)"
                      name="url"
                      [(ngModel)]="url"
                      matInput
                      placeholder="https://www.youtube.com/watch?v=ozXIAg7zp9I"
                    ></textarea>
                  </mat-form-field>
                </div>
              </mat-card>
              <mat-card
                fxLayout="column"
                fxFlex="65"
                fxLayoutAlign="space-between stretch"
                fxFlex
                fxLayoutGap="20px"
                class="card"
              >
                <app-carousel *ngIf="images?.length>0"
                  [arrayImage]="images"
                  [edit]="false"
                ></app-carousel>
              </mat-card>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-card>
</div>
