import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/* ngx-translate */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

/* Modules */
import { MaterialModule } from 'src/app/material/material.module';
import { ComponentsModule } from 'src/app/dashboard/components/components.module';

/* Components */
import { DashboardComponent } from './dashboard.component';
import { DashboardCenterComponent } from 'src/app/dashboard/views/dashboard-center/dashboard-center.component';
import { HomeComponent } from '../dashboard/views/events/home/home.component';
import { CreateComponent } from '../dashboard/views/events/create/create.component';
import { DetailComponent } from './views/events/detail/detail.component';
import { AddEditComponent, DialogDataExampleDialog } from './views/places/add-edit/add-edit.component';
import { BasicDataComponent } from './views/places/add-edit/basic-data/basic-data.component';
import { DescriptionsComponent } from './views/places/add-edit/descriptions/descriptions.component';
import { GalleryComponent } from './views/places/add-edit/gallery/gallery.component';
import { ListsComponent } from './views/places/lists/lists.component';
import { ShowComponent } from './views/places/show/show.component';
import { DescriptionComponent } from './views/places/show/description/description.component';
import { VideoYoutubeComponent } from './components/video-youtube/video-youtube.component';
import { CoursesListComponent } from './views/courses/courses-list/courses-list.component';
import { CoursesAddComponent } from './views/courses/courses-add/courses-add.component';
import { EditComponent } from './views/events/edit/edit.component';
import { StepPlacesComponent } from './views/events/create/step-places/step-places.component';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { PaymentComponent } from './views/payment/payment.component';
import { MethodPaymentComponent } from './views/payment/method-payment/method-payment.component';
import { MyPaymentsComponent } from './views/payment/my-payments/my-payments.component';
import { TableMethodPaymentComponent } from './views/payment/method-payment/table-method-payment/table-method-payment.component';
import { FormPaymentComponent } from './views/payment/method-payment/form-payment/form-payment.component';
import { AddressComponent } from './views/payment/address/address.component';
import { BasicComponent } from './views/profile/basic/basic.component';
import { ConfigComponent } from './views/profile/config/config.component';
import { CommentComponent } from './views/profile/comment/comment.component';
import { HelpComponent } from './views/profile/help/help.component';
import { ChatComponent } from './views/chat/chat.component';
import { BoxUserComponent } from './views/chat/box-user/box-user.component';
import { BoxChatComponent } from './views/chat/box-chat/box-chat.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DialogComponent } from './views/chat/box-chat/dialog/dialog.component';
import { TeacherComponent } from './views/events/create/step-teacher/teacher.component';
import { FormAddressComponent } from './views/payment/address/form-address/form-address.component';
import { ListAddressComponent } from './views/payment/address/list-address/list-address.component';
import { NotificationsComponent } from './views/notifications/notifications.component';
import { ShowPlaceComponent } from './views/events/create/step-places/show-place/show-place.component';
import { BudgetComponent } from './views/budget/budget.component';
import { BudgetSentReceivedComponent } from './views/budget-sent-received/budget-sent-received.component';
import { SentComponent } from './views/budget-sent-received/sent/sent.component';
import { ReceivedComponent } from './views/budget-sent-received/received/received.component';
import { OfertComponent } from './views/ofert/ofert.component';
import { OfertListComponent } from './components/ofert-list/ofert-list.component';
import { ExapmplesComponent } from './views/exapmples/exapmples.component';
import { PublishComponent } from './views/events/publish/publish.component';
import { StepProposalComponent } from './views/events/create/step-proposal/step-proposal.component';
import { CreateQuestionComponent } from './views/events/create/step-proposal/create-question/create-question.component';
import { SendProposalComponent } from './views/events/create/step-teacher/send-proposal/send-proposal.component';
import { ModalPublishNoquestionComponent } from './views/events/create/step-proposal/modal-publish-noquestion/modal-publish-noquestion.component';
import { DialogScheduleComponent } from './views/places/add-edit/descriptions/dialog-schedule/dialog-schedule.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import {ListTeacherComponent} from './views/teachers/list-teacher/list-teacher.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { ConfirmScheduleComponent } from './views/events/create/step-places/show-place/confirm-schedule/confirm-schedule.component';
import { ModalCancelContractComponent } from './views/events/home/modal-cancel-contract/modal-cancel-contract.component';
import { ModalCancelProposalComponent } from './views/events/home/modal-cancel-proposal/modal-cancel-proposal.component';
import { AffiliatesComponent } from './views/affiliates/affiliates.component';
import { ModalCancelEventComponent } from './views/events/home/modal-cancel-event/modal-cancel-event.component';
import { CreateCourseComponent } from './views/courses/create-course/create-course.component';
import { StepPlaceCourseComponent } from './views/courses/create-course/step-place-course/step-place-course.component';
import { StepProposalCourseComponent } from './views/courses/create-course/step-proposal-course/step-proposal-course.component';
import { ListTeacherCourseComponent } from './views/courses/list-teacher-course/list-teacher-course.component';
import { DetailCourseComponent } from './views/courses/detail-course/detail-course.component';
import { EditCourseComponent } from './views/courses/edit-course/edit-course.component';
import { HomeCourseComponent } from './views/courses/home-course/home-course.component';
import { PublishCourseComponent } from './views/courses/publish-course/publish-course.component';
import { BoxInputComponent } from './views/chat/box-input/box-input.component';
import { MyOfertListComponent } from './components/my-ofert-list/my-ofert-list.component';
import { ProposalDetailsComponent } from './views/proposal-details/proposal-details.component';
import { JobsComponent } from './views/jobs/jobs.component';
import { ModalRejectComponent } from './views/budget/modal-reject/modal-reject.component';

import { DashboardTeacherComponent} from './views/teachers/dashboard/dashboard.component'
import { DetailProposalComponent } from './views/teachers/detail-proposal/detail-proposal.component';

import {  SharedModule } from './shared/shared.module';
import {ProfileTeacherComponent} from './views/teachers/profile/profile.component'
import { PostulationTeacherComponent} from './views/teachers/postulacion-teacher/postulation-teacher.component';
import { ModaloffertComponent } from './components/my-ofert-list/modal-offer/modal-offer.component';
import { ModaldeclineComponent } from './components/my-ofert-list/modal-decline/modal-decline.component';
import {ModaldeleteComponent} from './components/my-ofert-list/modal-delete/modal-delete.component'
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])


  const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    observer: true,
    direction: 'horizontal',
    threshold: 50,
    spaceBetween: 5,
    slidesPerView: 1,
    centeredSlides: true
  };

@NgModule({
  declarations: [
  DashboardComponent,
	DashboardCenterComponent,
	HomeComponent,
	CreateComponent,
	DetailComponent,
  AddEditComponent,
  OfertListComponent,
  BasicDataComponent,
  DescriptionsComponent,
  GalleryComponent,
  ListsComponent,
  ShowComponent,
  DescriptionComponent,
  VideoYoutubeComponent,
	CoursesListComponent,
  CoursesAddComponent,
  EditComponent,
  StepPlacesComponent,
  PaymentComponent,
  MethodPaymentComponent,
  MyPaymentsComponent,
  TableMethodPaymentComponent,
  FormPaymentComponent,
  AddressComponent,
  BasicComponent,
  ConfigComponent,
  CommentComponent,
  HelpComponent,
  ChatComponent,
  BoxUserComponent,
  BoxChatComponent,
  DialogComponent,
  TeacherComponent,
  FormAddressComponent,
  ListAddressComponent,
  NotificationsComponent,
  ShowPlaceComponent,
  BudgetComponent,
  BudgetSentReceivedComponent,
  SentComponent,
  ReceivedComponent,
  OfertComponent,
  ExapmplesComponent,
  PublishComponent,
  StepProposalComponent,
  CreateQuestionComponent,
  SendProposalComponent,
  ModalPublishNoquestionComponent,
  DialogScheduleComponent,
  CalendarComponent,
  ConfirmScheduleComponent,
  ModalCancelContractComponent,
  ModalCancelProposalComponent,
  AffiliatesComponent,
  ModalCancelEventComponent,
  CreateCourseComponent,
  StepPlaceCourseComponent,
  StepProposalCourseComponent,
  ListTeacherCourseComponent,
  DetailCourseComponent,
  EditCourseComponent,
  HomeCourseComponent,
  PublishCourseComponent,
  BoxInputComponent,
 DialogDataExampleDialog,
 MyOfertListComponent,
 ProposalDetailsComponent,
 JobsComponent,
 ModalRejectComponent,
 ProfileTeacherComponent,
 ListTeacherComponent,
 DashboardTeacherComponent,
 DetailProposalComponent,
 PostulationTeacherComponent,
 ModaloffertComponent,
 ModaldeclineComponent,
 ModaldeleteComponent
],
  imports: [
    CommonModule,
    ComponentsModule,
    MaterialModule,
    PickerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SwiperModule,
    FullCalendarModule,
    SharedModule
  ],
  entryComponents: [],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class DashboardModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/language/', '.json');
}
