<mat-card >
    <div fxLayout="row" ngxSplit="row"  style="width: 100%; height: 500px; overflow-y: auto;">
        <div fxFlex="100%" ngxSplitArea>
            <div fxLayout="row" ngxSplit="row">
                <div fxFlex="10%" ngxSplitArea style="margin: 7px 1em 0px 9px">
                    <strong class="font-3 padding-left-message title" translate>notifications.title</strong>
                </div>
            </div>
        

            <div fxLayout="row" ngxSplit="row" *ngFor="let messagex of notifications">
            <div fxFlex="100%" ngxSplitArea style="margin: 0px 26px 0px 28px;" >	
                
                <div fxLayout="row" ngxSplit="row">
                    <div fxFlex="5%" ngxSplitArea fxLayoutAlign="center center"  >
                        <div fxLayout="row" ngxSplit="row">
                            <div fxFlex="100%" ngxSplitArea class="the-box-3" >
                                <span matBadge="." matBadgeOverlap="false" *ngIf="messagex.status==0"></span>
                                <mat-icon >{{messagex.image}}</mat-icon>
                            </div>
                        </div>
                        
                    </div>
                    <div fxFlex="85%" ngxSplitArea fxLayoutAlign="start center" style="padding: 0px 3px; margin: 12px 5px 21px 15px;">
                        <div fxLayout="row" ngxSplit="row">
                            <div fxFlex="100%" ngxSplitArea>
                                <!-- <strong class="font-3">{{message.name}}</strong> -->
                                <p class="font-3"  [innerHTML]="messagex.title"></p>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="10%" ngxSplitArea fxLayoutAlign="center end">
                        <div fxLayout="row" ngxSplit="row" class="font-4">
                            {{messagex.time}}
                            
                        </div>
                    </div>

                </div>
                <div fxLayout="row" ngxSplit="row">
                    <div fxFlex="100%" ngxSplitArea >
                        <hr>
                    </div>
                </div>
            
            </div>
            
            </div>
        </div>
    </div>
        <!-- <div class="row col-md-12 col-xs-12" >
            
                
                <div class="col-md-12" style="width: 100%; height: 500px; overflow-y: auto;">
                    <div class="col-md-12 col-xs-12 pointer" *ngFor="let message of notifications">
                        <div class="row" style="padding-left: 18px;">
                            
                            <div class="col-md-1 col-xs-1 the-box-3">
                                <span matBadge="." matBadgeOverlap="false" ></span>
                                <mat-icon>{{message.image}}</mat-icon>

                            </div>
                            <div class="col-md-9 col-xs-9">
                                <div class="row">
                                    <div class="col-md-12 col-xs-12" class="font-3" [innerHTML]="message.title"></div>
                                </div>
                            </div>
                            <div class="col-md-1 col-xs-1 font-4">
                                {{message.time}}
                            </div>
                            <div class="col-md-12 col-xs-12" style="padding-top: 10px"></div>
                            <div class="col-md-12 col-xs-12" style="padding-top: 10px"></div>
                            <div class="col-md-12 col-xs-12" style="padding-bottom: 10px">
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
        </div> -->
</mat-card>