<mat-drawer-container class="drawer-container" style="overflow: hidden;">
    <div class="content">
      <app-header></app-header>
      <app-sub-header [screen]="'Cesta'"></app-sub-header>
  
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" class="container-card">
        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
  
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
            <p fxFlex class="style-space">Resumen del pedido</p>
            <button routerLink="/auth/login" mat-button class="example-icon favorite-icon style-login ingresar btn-card">
              <span *ngIf="data.length > 0; else buySingle">Continuar Comprando</span>
              <ng-template #buySingle>Comprar</ng-template>
            </button>
          </div>
  
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="padding-top: 20px;">
            <p fxFlex class="style-space-1">{{ data.length }} cursos en la cesta de compra</p>
            <button routerLink="/confirm-buy" *ngIf="data.length > 0" mat-button class="example-icon favorite-icon style-login registrate btn-card" style="margin: 0px 20px;">
              Pagar
            </button>
          </div>
        </div>
      </div>
  
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" *ngFor="let list of data" class="item-cart">
        <div fxFlex="20%" fxLayout="column" fxLayoutAlign="start start stretch" class="dimension-img">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" class="picture">
            <img src="{{ list.img }}" width="200px" alt="{{ list.title }}">
          </div>
        </div>
  
        <div class="product-text" fxFlex="80%" fxLayout="row" fxLayoutAlign="start none">
          <div fxFlex="calc(100% - 225px)" fxLayout="column" fxLayoutAlign="start none" class="content-cart-1">
            <div fxLayout="row" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
              <p class="title" style="margin-top: -10px;">{{ list.title }}</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
              <p class="category"><b>{{ list.category }}</b> | {{ list.place.name }} | {{ list.teacher }}
                <ng-template *ngIf="list.place.own"> | {{ list.place.own }}</ng-template>
              </p>
            </div>
            <div fxLayout="row" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px">
              <p class="address style-space" fxFlex>{{ list.address }} &nbsp;&nbsp;&nbsp;&nbsp; Fecha de Inicio: {{ list.dateStart }}</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px">
              <p class="level style-space"><b>Nivel:</b> {{list.level.name}} | {{ list.level.target }} | {{ list.level.approved }}</p>
            </div>
            
          </div>
  
          <div fxFlex="225px" fxLayout="column" fxLayoutAlign="start none" class="content-cart-2">
            <div class="button-x" fxLayout="row" fxLayoutAlign="end start">
              <p class="title"><mat-icon (click)="list.type === 'event' ? deleteItem(list.id) : deleteItem(list.productId)">clear</mat-icon></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
              <p class="discount">{{ list.percentage }} Dto. <span style="text-decoration: line-through;">{{ list.price }}</span></p>
            </div>
           
            <div fxLayout="row" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
              <p class="prices">{{ list.pricePP }}</p>
            </div>
            <div *ngIf="list.type == 'event'" class="item-event"> 
              <p>Disponiblidad: {{ list.availability }}</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px" style="margin-top: 15px;">
              <div class="item-course" *ngIf="list.type == 'course'">
                <label><strong>Cursante (Afiliado)</strong></label><br />
                <input type="text" [(ngModel)]="list.affiliate" readonly>
              </div>
              <div *ngIf="list.type == 'event'" class="item-event">
                <button (click)="counterDe(list)" class="example-icon favorite-icon style-login registrate btn-counter">
                  <mat-icon aria-hidden="false" aria-label="Example home icon">remove</mat-icon>
              </button>
              <input type="text" [(value)]="list.counter" disabled="true">
              <button (click)="counterIn(list)" class="example-icon favorite-icon style-login registrate btn-counter">
                  <mat-icon aria-hidden="false" aria-label="Example home icon">add</mat-icon>
              </button>
            </div>
            </div>
           
          </div>
        </div>
      </div>
  
      <div *ngIf="data.length == 0" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="36px" class="item-cart message">
        No tienes productos agregados al carrito de compra...!
      </div>
  
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" class="container-card">
        <div *ngIf="data.length > 0" fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
  
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="padding-top: 33px;">
            <p fxFlex class="style-space-3"></p>
            <p class="prices">Total: {{ total().currency }} {{ total().priceTotal }}</p>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="padding-top: 54px; padding-bottom: 61px;">
            <p fxFlex class="style-space-1"></p>
            <button routerLink="/confirm-buy" (click)="saveTotal()" mat-button class="example-icon favorite-icon style-login registrate btn-card" style="margin: 0px 20px;">
              Pagar
            </button>
          </div>
        </div>
      </div>
  
      <app-footer></app-footer>
    </div>
  </mat-drawer-container>
  