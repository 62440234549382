import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventsService } from '../../../../services/events.service';
import { GeneralService } from '../../../../services/general.service';
import { CourseDashboardService } from '../../../../services/course-dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalPublishComponent } from 'src/app/dashboard/shared/components/modal-publish/modal-publish.component';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-publish-course',
  templateUrl: './publish-course.component.html',
  styleUrls: ['./publish-course.component.scss'],
})
export class PublishCourseComponent implements OnInit, OnDestroy {


  currency;
  event;
  levels;
  teachers;
  form: FormGroup;
  id;
  load = false;
  image;

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  files;
  flagImage = false;
  event_data;
  urls: string = '';


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private GeneralService: GeneralService,
    private _router: Router,
    private CourseDashboardService: CourseDashboardService,
    public dialog: MatDialog,
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
      console.log('---page:id', this.id);
    });

    this.msgSuscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });

    this.form = this.fb.group({
      visible: ['', Validators.required],
      currency: ['', Validators.required],
      level: ['', Validators.required],
      price: ['', Validators.required],
      age: ['', Validators.required],
      capacity: ['', Validators.required],
      evaluate: [false, Validators.required],
      content: ['', Validators.required],
      url: ['', Validators.required],
    });
    this.serviceLoadData();
  }

  serviceLoadData() {
    // this.EventsService.get_admin_PublishEvent(this.id).subscribe((res:any) => {
    this.CourseDashboardService.get_admin_publishCourse(this.id).subscribe(
      (res: any) => {
        console.log('get_admin_publishCourse', res);
        this.currency = res.currency.original.currency;
        this.event = res.course[0];
        this.levels = res.levels;
        this.teachers = res.course_teacher;
        this.image = res.course_images;
        this.event_data = res.course_data;
        this.load = true;

        this.loadInfo();
      },
      (err) => {
        console.log('ERRor get_admin_PublishEvent', err);
      }
    );
  }

  onSaveService() {
    if (this.form.status == 'VALID') {
      const data = {
        state: this.form.get('visible').value,
        currency_id: this.form.get('currency').value,
        price: this.form.get('price').value,
        level_id: this.form.get('level').value,
        age: this.form.get('age').value,
        enrollment: this.form.get('capacity').value,
        evaluated: this.form.get('evaluate').value == true ? 1 : 0,
        content: this.form.get('content').value,
        url: this.urls,
      };
      console.log('data a mariana servicio post_admin_publishEvent: ', data);
      if (this.flagImage == true) {
        this.serviceImage();
      }
      // this.EventsService.post_admin_publishEvent(data, this.id).subscribe((res:any) => {
      this.CourseDashboardService.post_admin_publishCourse(
        data,
        this.id
      ).subscribe(
        (res: any) => {
          console.log('post_admin_publishEvent', res);
          if (res.message_return.ERROR == false) {
            // this._router.navigateByUrl('/dashboard/courses');
            this.openDialogPublish(res);
          }
        },
        (err) => {
          console.log('ERRor post_admin_publishEvent', err);
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit('Error en servidor');
        }
      );
    } else {
      this.GeneralService.typeMsg$.emit('error');
      this.GeneralService.msg$.emit('Formulario incompleto');
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).markAsDirty();
        this.form.get(key).markAsTouched();
      });
    }
  }

  serviceImage() {
    this.files = this.GeneralService.get_carouselFile();
     this.GeneralService.uploadMultiImageCourses(this.files, this.id).pipe(retry(3)).subscribe(
       (res)=>{
         console.log("responde", res);
       }
     );
     console.log(this.image)
  }

  flagIma() {
    console.log('activa flagImage');
    this.flagImage = true;
  }

  loadInfo() {
    console.log(this.event_data)
    this.form.reset({
      visible: this.event_data.visibility,
      currency: this.currency.find((c)=>c.code===this.event_data?.currency)?.id,
      level: this.event.level_id,
      price: this.event_data.price,
      age: this.event_data.age,
      capacity: this.event_data.enrollment,
      evaluate: this.event_data.approved == 1 ? true : false,
      content: this.event_data.content,
      url: this.event_data.url,
    });
    this.urls = this.event_data.url;
  }

  @Input() SendUrl: string = '';
  onKey($event) {
    this.urls = $event.target.value;
    console.log('onKey', this.urls);
  }
  getImages(images) {
    console.log(images);
    this.image = images;
  }

  openDialogPublish(element) {
    const dialogRef = this.dialog.open(ModalPublishComponent, {
      height: 'auto',
      width: 'auto',
      data: { element },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log("result", result);
      if (result != undefined) {
          this._router.navigateByUrl('/dashboard/courses');

      }
    });
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSuscription;
    this.typeMsgSuscription;
  }
}
