import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  baseUrl:string;
  temp:string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.url_server;
  }

  Create_order(data, quantity): Observable<any>{
    this.temp = `${this.baseUrl}paymentRedirect/${quantity}`
    return this.http.post<any>(this.temp, data, quantity)
  }
  checkPayment(id, orderId): Observable<any>{
    const temp = `${this.baseUrl}checkPayment/${id}/${orderId}`
    return this.http.get(temp)
  }
}
