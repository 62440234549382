// shared-modal.service.ts
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogCourseComponent, DialogData } from '../website/course-details/dialog-course/dialog-course.component';

@Injectable({
  providedIn: 'root',
})
export class SharedModalService {
  constructor(private dialog: MatDialog) {}

  openDialog(data: DialogData): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '80%';

    dialogConfig.data = data;

    this.dialog.open(DialogCourseComponent, dialogConfig);
  }
}
