<div fxLayout="row" style="margin-top: 13px" fxLayoutAlign="end center">
  <mat-icon class="icon-grey" style="color: black">folder</mat-icon>
  <div>
    <a class="raleway-14-b" href="">Panel/</a>
    <a class="raleway-14-b" href=""> Cursos/</a>
    <a class="raleway-14-b" href=""> Detalles</a>
  </div>
</div>
<div
  fxLayout="row"
  fxLayoutAlign="center stretch"
  fxLayoutGap="150px"
  style="margin-top: 20px"
>
  <div fxLayout="column" fxLayoutAlign="start start">
    <p class="raleway-23-g-bold" *ngIf="iscourse">Detalles del Curso</p>
    <p class="raleway-23-g-bold" *ngIf="!iscourse">Detalles del Evento</p>
    <p
      *ngIf="iscourse"
      style="margin-top: 53px"
      class="raleway-18-g-bold"
      style="margin-top: 30px"
    >
      {{ info?.title }}
    </p>
    <p
      *ngIf="!iscourse"
      style="margin-top: 53px"
      class="raleway-18-g-bold"
      style="margin-top: 30px"
    >
      {{ info?.title }}
    </p>

    <div fxLayout="row" style="margin-top: 20px">
      <p class="raleway-18-g-bold"> Categoria:  <span > </span></p>
      <p class="raleway-18-g">{{ info?.extra?.category }}</p>
    </div>

    <div fxLayout="row" style="margin-top: 20px">
      <p class="raleway-18-g-bold"> Lugar: </p>
      <p class="raleway-18-g">{{ info?.extra?.place }}</p>
    </div>

    <div fxLayout="row" fxLayoutGap="100px" class="div-border-orange">
      <div fxLayout="column" fxLayoutAlign="center start">
        <p class="oxigen-16-g-bold">Precio:</p>

        <p class="oxigen-23-g-bold">
          {{info?.extra?.price}}
        </p>
      </div>
      <div fxLayout="column" fxLayoutAlign="center start">
        <p class="oxigen-16-g-bold">Matricula:</p>
        <p class="oxigen-23-g-bold">Cupos: {{info?.extra?.availability}}</p>
      </div>
    </div>

    <div style="margin-top: 35px">
      <mat-chip-list>
        <p class="tags">{{info?.extra?.tags}}</p>
        <div *ngFor="let item of chips">
          <mat-chip class="raleway-14-g"
            ><b>{{ item }}</b></mat-chip
          >
        </div>
      </mat-chip-list>
    </div>
  </div>

  <div>
    <img
      *ngIf="info?.extra?.main_image == null"
      style="margin-top: 20px"
      src="assets/images/image-default.png"
      alt=""
      class="img"
    />
    <img
      *ngIf="info?.extra?.main_image != null"
      style="margin-top: 20px"
      [src]="info?.extra?.main_image"
      alt=""
      class="img"
    />
  </div>
</div>

<mat-tab-group mat-align-tabs="center" style="margin-top: 40px">
  <mat-tab label="Datos basicos">
    <div fxLayout="column" class="m-tab">
      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p class="oxigen-14-b-bold">Fecha:</p>
        <p
          *ngIf="info?.extra?.start_date != null && info?.extra?.end_date != null"
          class="oxigen-14-b"
        >
        {{info?.extra?.start_date}}
        </p>
        <p
          *ngIf="info?.extra?.start_date == null || info?.extra?.end_date == null"
          class="oxigen-14-b"
        >
          Sin asignar
        </p>
      </div>
      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p class="oxigen-14-b-bold">Nivel:</p>
        <p *ngIf="info?.extra?.level != null" class="oxigen-14-b">{{info?.extra?.level}}</p>
        <p *ngIf="info?.extra?.level == null" class="oxigen-14-b">Sin asignar</p>
      </div>
      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p class="oxigen-14-b-bold">Edad:</p>
        <p *ngIf="info?.extra?.age != null" class="oxigen-14-b">{{info?.extra?.age}}</p>
        <p *ngIf="info?.extra?.age == null" class="oxigen-14-b">Sin asignar</p>
      </div>
      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p class="oxigen-14-b-bold">Visibilidad:</p>
        <p *ngIf="info?.extra?.visibility == 0" class="oxigen-14-b">Publico</p>
        <p *ngIf="info?.extra?.visibility == 1" class="oxigen-14-b">Privado</p>
        <p *ngIf="info?.extra?.visibility == 3" class="oxigen-14-b">Sin asignar</p>
      </div>
      <!-- <div fxLayout="row" style="margin-top: 20px;" fxLayoutGap="5px">
                <p class="oxigen-14-b-bold">Evaluado: </p>
                <p *ngIf="detail?.approved==0" class="oxigen-14-b">NO</p>
                <p *ngIf="detail?.approved==1" class="oxigen-14-b">SI</p>
            </div> -->

      <!-- <p class="oxigen-14-b-" style="margin-top: 20px;"><b>Quantitative:</b> {{(detail?.quantitative? detail?.quantitative:'Sin asignar')}}</p> -->
      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p class="oxigen-14-b-bold" style="margin-top: 20px">Descripcion:</p>
      <p class="oxigen-14-b" style="margin-top: 15px">{{info?.extra?.description}}</p></div>

    </div>
  </mat-tab>

  <mat-tab label="Contenido" class="mtab">
    <div class="m-tab">
      <p class="oxigen-14-b" style="margin-top: 15px">
        <strong>Contenido:</strong> {{info?.extra?.content}}
      </p>

      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p  *ngIf="iscourse" class="oxigen-14-b"><b>Requerimiento:</b> {{info?.extra?.requirements}}</p>
        <p  *ngIf="!iscourse" class="oxigen-14-b"><b>Requerimiento:</b> </p>
      </div>

      <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
        <p class="oxigen-14-b-bold" style="margin-top: 20px">Aprenderas:</p>
      <p *ngIf="iscourse" class="oxigen-14-b" style="margin-top: 15px">{{info?.extra?.learn}}</p>
      <p *ngIf="!iscourse" class="oxigen-14-b" style="margin-top: 15px"></p>
    </div>

    <div fxLayout="row" style="margin-top: 20px" fxLayoutGap="5px">
      <p class="oxigen-14-b-bold" style="margin-top: 20px">Dirigido a:</p>
    <p *ngIf="iscourse" class="oxigen-14-b" style="margin-top: 15px">{{info?.extra?.aimed_to}}</p>
    <p *ngIf="!iscourse" class="oxigen-14-b" style="margin-top: 15px"></p>
  </div>

    </div>
  </mat-tab>

  <mat-tab label="Horario" class="mtab">
    <div class="m-tab">
      <ng-template matTabContent>
        <app-calendar
        *ngIf="iscourse"
          [idCourse]="idcourse"
          [modify]="false"
        ></app-calendar>
        <app-calendar
        *ngIf="!iscourse"
          [idNum]="idevent"
          [modify]="false"
        ></app-calendar>

        <!-- <p *ngIf="!place_id">No posee horario</p> -->
      </ng-template>
    </div>
  </mat-tab>

  <mat-tab label="Alumnos" class="mtab">
    <div class="m-tab">
      <table mat-table [dataSource]="dataSource" style="width: 100%">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="h-table" translate>
            job.name
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            dfgsdfsdf
          </td>
        </ng-container>

        <ng-container matColumnDef="identity" class="text-center">
          <th mat-header-cell *matHeaderCellDef class="h-table" translate>
            job.dni
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            dfgdfgdfg
          </td>
        </ng-container>

        <ng-container matColumnDef="age" class="text-center">
          <th mat-header-cell *matHeaderCellDef class="h-table" translate>
            job.age
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            3333333
          </td>
        </ng-container>

        <ng-container matColumnDef="email" class="text-center">
          <th mat-header-cell *matHeaderCellDef class="h-table" translate>
            job.email
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            asdfdfdsf
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </mat-tab>

  <mat-tab label="Imagenes/Video" class="mtab">
    <ng-template matTabContent>
      <div
        fxLayout="row"
        fxLayoutAlign="space-around stretch"
        fxLayoutGap="20px"
        style="padding: 18px"
      >
       <mat-card fxLayout="column" fxFlex="35" style="height: 500px">
          <app-video-youtube [SendUrl]="urls"></app-video-youtube>
          <div
            fxLayout="column"
            fxLayoutAlign="end stretch"
            fxFlex
            fxLayoutGap="18px"
          >
            <p class="title-card">Video</p>
            <mat-form-field>
              <mat-label class="label-input">{{
                "place.urlYoutube" | translate
              }}</mat-label>
              <textarea
                [(ngModel)]="youtube"
                (keyup)="onKey($event)"
                matInput
                placeholder="https://www.youtube.com/watch?v=ozXIAg7zp9I"
              ></textarea>
            </mat-form-field>
          </div>
        </mat-card>

        <mat-card
          fxLayout="column"
          fxFlex="65"
          fxLayoutAlign="space-between stretch"
          fxFlex
          fxLayoutGap="20px"
          class="card"
        >
          <app-carousel [arrayImage]="images" [edit]="false"></app-carousel>
        </mat-card>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
