import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';
import { environment } from "../../../environments/environment";
import { DomSanitizer } from '@angular/platform-browser';
import  {FavoriteService } from 'src/app/services/favorite.service';
import { CartService } from 'src/app/services/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';





@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})

export class EventDetailsComponent implements OnInit {
  start=5
  storage=environment.storage;
  load=false
  id: string;
  slug: string;  
  imagesCarousel: string[]= [];
  urlApi:string ;
  courses: any;
  resp: [];
  eventName: string;
  event: any;
  prodId: number;
  tags: string[] = [];
  user: any;
  product_id : number;
  user_id : number;
  isLoggedIn: boolean = false;
  teachers: any;
  isFavorite: boolean = false;
  price: any;
  videoUrl: SafeResourceUrl;

  schedule: any;
  evento : any;
  teacherId: any;
  eventDetail: any;
  eventSlug: any;
  placeId: any;
  availability: number;

  safeUrl: SafeResourceUrl;
  url=[
    {
      url:"/home",
      urlName:"Home/"
    },
    {
      url:"",
      urlName:"Categoria/"
    },
    {
      url:"",
      urlName:""
    }
  ]
  star=3;
  videoId: string;
  afterViewInit: boolean = false;

  counter: number = 0;

  constructor(
    private EventsService:EventsService,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    public favorite: FavoriteService,
    public cartService: CartService,
    private snackBar: MatSnackBar,
    private router: Router,
    private sanitizer: DomSanitizer


  ) { }


  toSlug(text: string): string {
    return text.toLowerCase().replace(/\s+/g, '-');
  }

  ngOnInit(): void {
    this.checkLoginStatus();
    this.slug = this._route.snapshot.paramMap.get('id');
    const aux = this.slug.split("-");
    this.id = aux[0];
    this.EventsService.get_events(this.slug).subscribe(resp => {
      console.log("evento cargando");
      console.log(resp);
      const { Event, event_schedule, event_teacher } = resp;
      this.event = resp;
      this.eventDetail = resp.Event[0];
      console.log('hola este es el el fokin evento', this.event);

      if (this.event && this.event.Event && this.event.Event[0]) {
        this.imagesCarousel = resp.images.map(image => this.removeTrailingSlash(image));
        if (resp.Event[0].tags && typeof resp.Event[0].tags === 'string') {
          this.tags = resp.Event[0].tags.split(',').map(tag => tag.trim());
        } else {
          this.tags = [];
        } 
        this.schedule = event_schedule;
        this.teachers = event_teacher;
        this.evento = Event;

        for (let i = 0; i < this.teachers.length; i++) {
          this.teacherId = this.teachers[i].id;
        
          // Añade una coma al final del texto si no es el último elemento
          if (i < this.teachers.length - 1) {
            this.evento += this.teacherId + ', ';
          } else {
            // Si es el último elemento, no añade la coma
            this.evento += this.teacherId;
          }
        }

        console.log(this.event);

        Event.forEach(element => {
          this.eventSlug = this.toSlug(element.place);
          this.placeId = element.places_id;
          this.prodId = element.productId;
          this.price = element.price;
          this.availability = element.availability;
          if(element.urls === null) {
            this.videoId = null;
          }

          if (element.urls && typeof element.urls === 'string') {
            const url = element.urls.split("v=");
            this.videoId = url[0];
          }
          this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.videoId);
        });

        console.log(this.availability);

        console.log(this.videoId);

        this.event.age = this.format_age(this.event.age);
        this.videoUrl = this.safeURL();
        this.load = true;
      }
      
    });
}

  removeTrailingSlash(url: string): string {
    if (url && url.endsWith('/')) {
      return url.slice(0, -1); 
    }
    return url;
  }
  
  ngAfterViewInit(): void {
    //this.afterViewInit = true;
  }

  tagsArray(stringTags) {
    if (stringTags && typeof stringTags === 'string') {
      return stringTags.split(',');
    }
    return [];
  }
  

  format_date (date) {
    if(typeof date == 'string') {
      const arrayDate = date.split("-");
      return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
    }
    return date;
  }

  safeURL(){
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.videoId);
  }

  format_age (age) {
    if(Number.isInteger(age)) {
      return `Para mayores de ${age} años.`;
    }
    return age;
  }


  checkLoginStatus() {
    const userData = localStorage.getItem('userData');
    if (userData){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;

    }
  }
  
  storeOrDelete(id: number) {
    const userId = JSON.parse(localStorage.getItem('userData')).id;
    const data = {
      user_id: userId,
      product_id: this.prodId
    };
    console.log(this.event)
    console.log(this.event.Event);
    const Event = this.event.Event;
    this.favorite.Post_favorite(data).subscribe(
      () => {
        if (this.eventDetail.isFavorite === 0) {
          this.eventDetail.isFavorite = 1;
        } else {
          this.eventDetail.isFavorite = 0;
        }
        },
      error => {
        console.error(error);
      }
    );
  }

  counterIn() {

    if(this.availability > 0 ) {
      this.counter++;
    } else {
      alert('Entradas agotadas!');
    }if(this.counter > this.availability) {
      alert('Limite de entradas excedido');
      this.counter--;

    } 
    
  }

  counterDe() {
    if (this.counter > 1) {
     this.counter--;
    }else {
      alert('El minimo de entradas en 1!');
    }
  }


  addEvent() : void {

    const data = {
      product_id: this.prodId,
      quantity: this.counter,
      amount: this.price,
      
    }

    this.cartService.post_admin_products(data).subscribe(
      response => {
        this.showSnackbar('Producto añadido con éxito', 'success-snackbar');
        console.log(response);
  
        setTimeout(() => {
          this.router.navigate(['/cart']);
        }, 2000);
      },
      error => {
        this.showSnackbar('Error al agregar el producto', 'error-snackbar');
        console.error(error);
  
        // Si ocurre un error, también podrías decidir si quieres redirigir aquí.
        // this.router.navigate(['/cart']);
      }
    );
  }


  showSnackbar(message: string, panelClass: string): void {
    this.snackBar.open(message, 'Cerrar', {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: panelClass
    });
  }
  
  constructYoutubeUrl(videoId: string): string {
    return `https://www.youtube.com/embed/${videoId}`;
  }

}
