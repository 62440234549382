<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="teacher">
    <th mat-header-cell *matHeaderCellDef>  </th>
    <td mat-cell *matCellDef="let element"> <p class="title"><strong>{{element.teacher.name}}</strong></p>
      <p class="subtitle">
        {{element.teacher.courseName}}
      </p>
      
      <p class="subtitle">
        {{element.teacher.city}} {{element.teacher.country}}
      </p>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>  </th>
    <td mat-cell *matCellDef="let element"> 
      <div class="row" >
        <div class="col-md-10 col-xs-12" style="text-align: right; ">
          <p class="title"><strong>{{element.user.name}}</strong></p>
          <span class="subtitle" [ngSwitch]="element.user.star">
            <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon></span>
            <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
            <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
            <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
            <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
          </span> <br>
          <p class="subtitle">{{element.user.timer}}</p>
        </div>
        <div class="col-md-2 col-xs-12">
          <img [src]="element.user.image" width="60px" alt="">
        </div>
      </div>
      
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>  </th>
    <td mat-cell *matCellDef="let element"> <span class="star"><mat-icon>{{element.actions.show}}</mat-icon></span> <span class="star"><mat-icon>{{element.actions.trash}}</mat-icon></span> </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>