<mat-card style="padding: 20px 30px;">
    <p class="title">Configuracion</p>
    <mat-tab-group mat-align-tabs="start" mat-stretch-tabs style="margin-top: 65px;">
        <mat-tab label="Contraseña" class="m-tab-label">
            <form [formGroup]="form" style="overflow: hidden !important;">
                <p style="margin-top: 25px;" class="paragraph">{{'config.password_title' | translate}}</p>


                <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="300px" style="margin-top: 110px">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <mat-form-field>
                            <mat-label class="input-secret">{{'config.current_password' | translate}}</mat-label>
                            <input matInput formControlName="passOriginal" [type]="hide1 ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide1">
                                <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>
                        <small *ngIf="errorPassword()" class="error" style="margin-top: -15px;">*Contraseña incorrecta</small>
                    </div>

                    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="80px">
                        <div fxLayout="column" fxLayoutAlign="start start">
                            <mat-form-field>
                                <mat-label class="input-secret">{{'config.new_password' | translate}}</mat-label>
                                <input matInput formControlName="pass" [type]="hide2 ? 'password' : 'text'">
                                <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                    <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                            <small *ngIf="invalidPassFormat==false" class="error"
                                style="margin-top: -15px;">*{{'register.error' | translate }}</small>
                        </div>


                        <div fxLayout="column" fxLayoutAlign="start start">
                            <mat-form-field>
                                <mat-label class="input-secret">{{'config.confirm_password' | translate}}</mat-label>
                                <input matInput formControlName="passConfirm" [type]="hide3 ? 'password' : 'text'">
                                <button mat-icon-button matSuffix (click)="hide3 = !hide3"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3">
                                    <mat-icon>{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                            <small *ngIf="samePass" class="error" style="margin-top: -15px;">*Claves diferentes</small>
                        </div>
                    </div>
                </div>

                <div fxLayoutAlign="center center" style="margin-top: 60px;">
                    <mat-spinner *ngIf="load" strokeWidth="10" [diameter]="24"></mat-spinner>
                    <button *ngIf="!load" mat-button class="orange-btn"
                        (click)="changePassword()">{{'config.save' | translate}}</button>
                </div>
            </form>
        </mat-tab>





        <mat-tab label="Membresía">
            <p class="oxigen-14" style="margin-bottom: 27px; margin-top: 43px">Escoger tipo de membresia</p>
            <mat-card class="m-card" style="margin-bottom: 30px;">
                <div fxLayout="row" fxLayoutGap="60px">
                    <div fxLayout="column" fxLayoutAlign="center none" fxFlex="75">
                        <p class="oxigen-23-bold" style="margin-bottom: 33px">Membresia premium</p>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <img src="/assets/images/cheque.png" class="img-check">
                            <p class="oxigen-14" style="margin-bottom: 15px">
                                Acceso total. Puedes publicar 100 cursos y 10 lugares. Promocion toda la semana</p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <img src="/assets/images/cheque.png" class="img-check">
                            <p class="oxigen-14">Lorem ipsum dolor sit amet consectetur adipisicing
                                elit. Architecto aliquid optio voluptate eaque suscipit molestias sapiente voluptatum
                                laborum
                                neque deserunt id animi enim provident pariatur dolor, magni vitae, sint porro.</p>
                        </div>
                    </div>
                    <div fxLayoutAlign="center center" fxFlex>
                        <button mat-button class="orange-btn">Contratar</button>
                    </div>
                </div>
            </mat-card>


            <mat-card class="m-card" style="margin-bottom: 30px;">
                <div fxLayout="row" fxLayoutGap="60px">
                    <div fxLayout="column" fxLayoutAlign="center none" fxFlex="75">
                        <p class="oxigen-23-bold" style="margin-bottom: 33px">Membresia Plus</p>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <img src="/assets/images/cheque.png" class="img-check">
                            <p class="oxigen-14" style="margin-bottom: 15px">
                                Acceso Total. Puedes publicar 50 cursos y 5 lugares. Promocion toda la semana</p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <img src="/assets/images/cheque.png" class="img-check">
                            <p class="oxigen-14">Lorem ipsum dolor sit amet consectetur adipisicing
                                elit. Architecto aliquid optio voluptate eaque suscipit molestias sapiente voluptatum
                                laborum
                                neque deserunt id animi enim provident pariatur dolor, magni vitae, sint porro.</p>
                        </div>
                    </div>
                    <div fxLayoutAlign="center center" fxFlex>
                        <button mat-button class="orange-btn">Contratar</button>
                    </div>
                </div>
            </mat-card>


            <mat-card class="m-card" style="margin-bottom: 30px;">
                <div fxLayout="row" fxLayoutGap="60px">
                    <div fxLayout="column" fxLayoutAlign="center none" fxFlex="75">
                        <p class="oxigen-23-bold" style="margin-bottom: 33px">Membresia Básica</p>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <img src="/assets/images/cheque.png" class="img-check">
                            <p class="oxigen-14" style="margin-bottom: 15px">
                                Acceso Total. Puedes publicar 10 cursos y 1 lugares. Promocion toda la semana </p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <img src="/assets/images/cheque.png" class="img-check">
                            <p class="oxigen-14">Lorem ipsum dolor sit amet consectetur adipisicing
                                elit. Architecto aliquid optio voluptate eaque suscipit molestias sapiente voluptatum
                                laborum
                                neque deserunt id animi enim provident pariatur dolor, magni vitae, sint porro.</p>
                        </div>
                    </div>
                </div>
            </mat-card>
        </mat-tab>




        <mat-tab label="Servicios conectados">

            <div fxLayout="column" style="margin-top: 70px;" fxFlex>
                <div *ngIf="google_auth == 0; else googleSession">
                    <div fxLayout="row" fxLayoutAlign="start start" class="mark" fxFlex>
                        <div fxFlex="65">
                            <p class="oxigen-23-bold">Inicie sesion en sogcial con 1 click</p>
                            <p class="oxigen-16-marL20" style="margin-top: 15px;">No has iniciado sesion a travez de Google
                            </p>
                        </div>                        
                            <a class="btn-google" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px" href="/auth/google" target="_blank">
                                <img src="/assets/images/google-logo.png" class="logo-google">
                                <span class="text-google" translate>layout.btn-google</span>
                            </a>                                           
                    </div>
                </div>
                <ng-template #googleSession>
                     <div fxLayout="row" fxLayoutAlign="start start" class="mark" fxFlex>
                        <div fxFlex="65">
                            <p class="oxigen-23-bold">Inicie sesion en sogcial con 1 click</p>
                            <p class="oxigen-16-marL20" style="margin-top: 15px;">Ya has iniciado sesion a través de Google
                            </p>
                        </div>
                        
                            <a class="btn-google disabled" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px" >
                                <img src="/assets/images/google-logo.png" class="logo-google">
                                <span class="text-google" translate>Ya estas conectado</span>
                            </a>                         
                    </div>
                </ng-template>
            </div>

            <div fxLayout="column" style="margin-top: 70px;" fxFlex>
                <div  *ngIf="facebook_auth == 0; else facebookSession">
                    <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 45px; padding-bottom: 45px;" fxFlex>
                        <div fxFlex="65">
                            <p class="oxigen-23-bold">Inicie sesión en Sogcial con 1 clic</p>
                            <p class="oxigen-16-marL20" style="margin-top: 15px;">No has iniciado sesión a través de
                                Facebook
                            </p>
                        </div>
                        <a class="btn-facebook" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px" href="/auth/facebook" target="_blank">
                            <img src="/assets/images/fb-logo.png" class="logo-facebook">
                            <span class="text-facebook">Entrar con facebook</span>
                        </a>
                    </div>
                </div>
                <ng-template #facebookSession>
                     <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 45px; padding-bottom: 45px;" fxFlex>
                        <div fxFlex="65">
                            <p class="oxigen-23-bold">Inicie sesión en Sogcial con 1 clic</p>
                            <p class="oxigen-16-marL20" style="margin-top: 15px;">ya has iniciado sesión a través de Facebook
                            </p>
                        </div>
                        <a class="btn-facebook disabled" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px">
                            <img src="/assets/images/fb-logo.png" class="logo-facebook">
                            <span class="text-facebook">Ya estas conectado</span>
                        </a>
                    </div>                    
                </ng-template>
                
            </div>

            <ng-template #name>
                <div fxLayout="column" style="margin-top: 70px; margin-bottom: 100px;" fxFlex>
                    <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 45px; 
            padding-bottom: 45px;" fxFlex>
                        <div fxFlex="65">
                            <p class="oxigen-23-bold">Inicie sesión en Sogcial con 1 clic</p>
                            <p class="oxigen-16-marL20" style="margin-top: 15px;"><img src="/assets/images/cheque.png"
                                    class="img-check">Has iniciado sesión con UsuarioX</p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center">
                            <mat-icon class="btn-icon pointer">insert_drive_file</mat-icon>
                            <button class="btn btn-border">DESCONECTAR</button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </mat-tab>

    </mat-tab-group>
</mat-card>