import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proposal-details',
  templateUrl: './proposal-details.component.html',
  styleUrls: ['./proposal-details.component.scss']
})
export class ProposalDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
