import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../../services/profile.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  hide1 = true;
  hide2 = true;
  hide3 = true;
  invalidFormatPass;
  // centered = true;
  form: FormGroup;
  user;
  error:number;
  configPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%&*]{6,15}$/;
  load=false;
  google_auth;
  facebook_auth;


  constructor(private ProfileService: ProfileService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      passOriginal: ['', Validators.required],
      pass: ['', Validators.required],
      passConfirm: ['', Validators.required],
    });
    this.user = JSON.parse(localStorage.getItem("userData"));
    console.log("user:::", this.user);
    this.google_auth = this.user.google_auth;
    this.facebook_auth = this.user.facebook_auth;    
  }

  changePassword() {
    if ((this.form.status == "VALID") && (this.invalidFormatPass == true) && (this.samePass == false)) {
      this.load=true;
      let data = {};
      data = {
        "email": this.user.email,
        "password": this.form.get('passOriginal').value,
        "new_password": this.form.get('pass').value,
        "new_password_confirmation": this.form.get('passConfirm').value,
        "token": this.user.access_token
      }
      this.ProfileService.post_password_change(data).subscribe(res => {
        console.log("res post_password_change::", res);
      }, err => {
        console.log("ERRor post_password_change", err)
        this.error=err.status;
        this.load=false;
      });
    }
  }

  get invalidPassFormat() {
    if (!this.form.get('pass').value.match(this.configPattern)) {
      if (this.form.get('pass').touched) {
        this.invalidFormatPass = false;
      }
    } else {
      this.invalidFormatPass = true;
    }
    return this.invalidFormatPass;
  }

  get samePass() {
    return ((this.form.get('passConfirm').touched) && !(this.form.get('pass').value === this.form.get('passConfirm').value) ) ? true : false;
  }

  errorPassword(){
    return ( this.error===406)? true : false;
  }

  test() {
    console.log("this.form", this.form);
  }
}