<!-- <pre>
    <form [formGroup]="form" >
    
        <mat-form-field>
            <mat-label>Hora Fin</mat-label>
            <input type="time" matInput formControlName="end" step="3600">
        </mat-form-field>


        <mat-form-field>
            <mat-label>Hora Fin</mat-label>
            <input type="time" matInput formControlName="start" step="3600" list="listalimitestiempo">
        </mat-form-field>

        <mat-form-field fxLayout="row">
            <mat-label>Hora Fin</mat-label>
            <input type="number" matInput formControlName="num" min="0" max="23">
            <span *ngIf="form.value.num<12">a.m</span>
            <span *ngIf="form.value.num>=12">p.m</span>

        </mat-form-field>
    </form>
    {{form.value | json}}
</pre>

<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">

</div>

<datalist id="listalimitestiempo">
    <option value="01:00">
    <option value="02:00">
    <option value="03:00">
    <option value="04:00">
</datalist> -->

<!-- vista 1 -->
<div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="60" fxLayoutGap="30px" style="padding:30px 30px 60px 30px">
    <p class="raleway-23-tg"><b>Mis Afiliados</b></p>
    <p class="oxigen-16-g"><b>Agregar nuevo afiliado</b></p>
    <mat-form-field style="padding-top: 30px;" class="oxigen-14-g">
        <mat-label>Nombre</mat-label>
        <input matInput placeholder="Nombre">
    </mat-form-field>

    <mat-form-field class="oxigen-14-g">
        <mat-label>Apellido</mat-label>
        <input matInput placeholder="Apellido">
    </mat-form-field>

    <mat-form-field class="oxigen-14-g">
        <mat-label>Numero de documento</mat-label>
        <input matInput placeholder="Numero documento">
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="18px" style="width: 100%;">
        <mat-form-field fxFlex="50" class="oxigen-14-g">
            <mat-label>Fecha de nacimiento</mat-label>
            <input matInput [matDatepicker]="picker2">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex="50" class="oxigen-14-g">
            <mat-label>Parentesco</mat-label>
            <mat-select>
                <mat-option value="option1">Option 1</mat-option>
                <mat-option value="option2" disabled>Option 2 (disabled)</mat-option>
                <mat-option value="option3">Option 3</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayoutAlign="start start" style="padding-top: 90px;">
        <button class="btn-orange btn pointer">Guardar</button>
    </div>

</div>

<!-- pagina 2 -->
<!-- <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px" style="padding:30px 30px 60px 30px">
    <p class="raleway-23-tg">Mis afiliados</p>
    <p class="oxigen-16-g">Aún no has registrado ningun afiliado.</p>
    <div fxLayoutAlign="start start" style="padding-top: 20px;">
        <button class="btn-orange btn pointer">Agregar</button>
    </div>
</div> -->

<!-- Pagina 3 -->
