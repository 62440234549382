import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { OffetService } from "../../../services/offet.service";
@Component({
  selector: 'app-ofert',
  templateUrl: './ofert.component.html',
  styleUrls: ['./ofert.component.scss']
})
export class OfertComponent implements OnInit {
  userDetails: any;
  show: boolean=false;
  currencies
  currency: any;
  jobs: any;
  places
  job: any;
  title
  price_total: any;
  description: any;
  time_length: any;
  weekly_limit: any;
  time_unit: any;

  constructor(
    private router: ActivatedRoute,
    private OffetService:OffetService,
    private _router: Router,

  ) { }
  profileForm
  time
  listTime =[]
  contract = [
  ];
  contracts
  ngOnInit(): void {
    this.profileForm = new FormGroup({
      title: new FormControl(''),
      lastName: new FormControl(''),
    });
    let id = this.router.snapshot.params.id;
    console.log(id)
    this.OffetService.get_admingetOffer(id).subscribe(resp=>{
      console.log(resp)
      this.userDetails=resp.offer[0]
      this.time_unit=resp.time_unit;
      let type_contract=resp.type_contract;
      this.time_unit.filter(resp=>{
        this.listTime.push({
          time:resp
        })
      })
      type_contract.filter(resp=>{
        this.contract.push({
          type:resp
        })
      })
      this.places=resp.place[0]
      console.log(this.places)
      this.currency=resp.currencies.original.currency;
      this.jobs=resp.job_category
      this.show=true;
    })


  }
  save(){
    let dataUser=JSON.parse(localStorage.getItem('userData'))


    let data={
      "course_id": this.userDetails.course_id!=null?this.userDetails.course_id:null,
      "event_id": this.userDetails.event_id!=null?this.userDetails.event_id:null,
      "place_id": this.places.place_id,
      "sender_id": dataUser.id,
      "recipient_id": this.userDetails.user_recipient_id,
      "proposal_id": this.userDetails.proposal_id,
      "contract_title": this.title,
      "job_category_id": this.job,
      "type_contract": this.contracts,
      "price_per_hour": this.contracts=="Fixed price"?this.price_total:null,
      "price_total": this.contracts=="Hourly"?this.price_total:null,
      "work_description": this.description,
      "terms_conditions": 0,
      "currency_id": this.currencies,
      "time_length": this.time_length,
      "time_unit": this.time,
      "weekly_limit": this.weekly_limit,
      "attached_file": "string"
    }
    this.OffetService.post_adminoffers(data).subscribe(resp=>{
      console.log(resp)
      this._router.navigateByUrl("/");
    })
    console.log(data);
  }

}
interface Food {
  value: string;
  viewValue: string;
}