import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  public baseurl: string;
  temp: string;
  authToken;

  msg$ = new EventEmitter();
  typeMsg$ = new EventEmitter();
  showMsg$ = new EventEmitter();

  m_es = new Array(
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  );
  m_en = new Array(
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  );

  carouselFile = [];
  main_image=null;

  timeEvent;
  get_timeEvent() {
    return this.timeEvent;
  }
  set_timeEvent(element) {
    this.timeEvent = element;
  }

  calendarData;
  get_calendarData() {
    return this.calendarData;
  }
  set_calendarData(element) {
    this.calendarData = element;
  }

  constructor(private __http: HttpClient, private http: HttpClient) {
    this.baseurl = environment.url_server;
    const access_token = localStorage.getItem('access_token');
    this.authToken = (access_token)?JSON.parse(access_token):'';
  }

  formatDate(date) {
    let strDate = date.split(' ');
    let format = '';
    if (strDate[0] == 'un' || strDate[0] == 'a') {
      strDate[0] = '1';
      format = strDate[0] + strDate[1][0] + strDate[1][1];
      return format;
    }
    format = strDate[0] + strDate[1][0] + strDate[1][1];
    return format;
  }

  onUploadOneImage(file, id) {

    const requestFormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    requestFormData.append('file', file[0]);

    const apiURL = this.baseurl + 'admin/imagesEvents?id=' + id;
    return this.http.post<any>(apiURL, requestFormData, {
      headers: headers,
    });

  }

  uploadGalleryandMainImagesPlaces(files, id, mainImage) {
    const requestFormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    if (files) {
      for (let i = 0; i < files.length; i++) {
        requestFormData.append(`fileArray[]`, files[i]);
      }
    }
    if (mainImage) {
      requestFormData.append(`file`, mainImage);
    }

    const apiURL = this.baseurl + 'admin/imagesPlaces?id=' + id;
    return this.http.post<any>(apiURL, requestFormData, {
      headers: headers,
    });
  }

  onUploadMultiImageEvents(files, id) {
    const requestFormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    for (var i = 0; i < files.length; i++) {
      requestFormData.append('fileArray[]', files[i]);
    }

    const apiURL = this.baseurl + 'admin/imagesEvents?id=' + id;
    return this.http.post<any>(apiURL, requestFormData, {
      headers: headers,
    });

  }

  onUploadOneCourse(file, id) {

    const requestFormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    requestFormData.append('file', file[0]);

    const apiURL = this.baseurl + 'admin/imagesCourses?id=' + id;
    return this.http.post<any>(apiURL, requestFormData, {
      headers: headers,
    });

  }

  copuyonUploadMultiImageEvents(files, id) {
    let formData = new FormData();
    console.log('multimage files on service:', files);
    console.log('this.authToken: ', this.authToken);
    formData.append('file', null);
    for (var i = 0; i < files.length; i++) {
      formData.append('fileArray[]', files[i]);
    }
    this.temp = this.baseurl + 'admin/imagesEvents?id=' + id;
    return fetch(this.temp, {
      method: 'post',
      headers: new Headers({ Authorization: `Bearer ${this.authToken}` }),
      body: formData,
    })
      .then((res) => {
        console.log('res-images:: ', res);
        return true;
      })
      .catch((err) => {
        console.log('Err-images:: ', err);
        return false;
      });
  }

  uploadMultiImageCourses(files, id): Observable<any> {
    const requestFormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    if (files) {
      for (let i = 0; i < files.length; i++) {
        requestFormData.append(`fileArray[]`, files[i]);
      }
    }

    const apiURL = this.baseurl + 'admin/imagesCourses?id=' + id;
    return this.http.post<any>(apiURL, requestFormData, {
      headers: headers,
    });
  }

  uploadGalleryandMainImages(files, id, mainImage): Observable<any> {
    const requestFormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    if (files) {
      for (let i = 0; i < files.length; i++) {
        requestFormData.append(`fileArray[]`, files[i]);
      }
    }
    if (mainImage) {
      requestFormData.append(`file`, mainImage);
    }

    const apiURL = this.baseurl + 'admin/imagesCourses?id=' + id;
    return this.http.post<any>(apiURL, requestFormData, {
      headers: headers,
    });
  }

  clearCarouselFile() {
    this.carouselFile = [];
    this.main_image=null;
  }

  get_carouselFile() {
    return this.carouselFile;
  }
  uploadGalleryandMainImagesEvents(files, id, mainImage): Observable<any> {
    const requestFormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    if (files) {
      for (let i = 0; i < files.length; i++) {
        requestFormData.append(`fileArray[]`, files[i]);
      }
    }
    if (mainImage) {
      requestFormData.append(`file`, mainImage);
    }

    const apiURL = this.baseurl + 'admin/imagesEvents?id=' + id;
    return this.http.post<any>(apiURL, requestFormData, {
      headers: headers,
    });
  }
  deleteCarouselFile(pos: number) {
    if (this.carouselFile.length >= 0) {
      this.carouselFile.splice(pos, 1);
    } else {
      this.carouselFile = [];
    }
    let formData = new FormData();
    for (var i = 0; i < this.carouselFile.length; i++) {
      formData.append('fileArray[]', this.carouselFile[i]);
    }
    return formData;
  }

  prepareMainImages(file){
    this.main_image=file;
  }

  getMainImages(){
   return this.main_image;
  }

  prepareSaveCarousel(files): any {
    console.log('array service:: ', files);
    let formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      // formData.append('fileArray[]', files[i]);
      this.carouselFile.push(files[i]);
    }
    this.carouselFile.forEach((element) => {
      formData.append('fileArray[]', element);
    });
    return formData;
  }

  get_countries(): Observable<any> {
    this.temp = this.baseurl + 'countries';
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  get_getStatesByCountry(id): Observable<any> {
    this.temp = this.baseurl + `getStatesByCountry/${id}`;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  get_admincountries(id): Observable<any> {
    ///admin/countries/{id}
    this.temp = this.baseurl + 'admin/countries/' + id;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  ///admin/cities/{id}
  get_admincities(id): Observable<any> {
    this.temp = this.baseurl + 'admin/cities/' + id;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  get_admincounties(id): Observable<any> {
    ///admin/counties/{id}
    this.temp = this.baseurl + 'admin/counties/' + id;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  get_adminparishes(id): Observable<any> {
    ///admin/parishes/{id}
    this.temp = this.baseurl + 'admin/parishes/' + id;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  get_adminstates(id): Observable<any> {
    this.temp = this.baseurl + 'admin/states/' + id;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  ///admin/states/{id}
  /*get_admincountries(id): Observable<any>{
    ///admin/countries/{id}
    this.temp = this.baseurl + `admin/countries/${id}`;
    return this.__http.get( this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }*/

  get_getCitiesByState(id): Observable<any> {
    this.temp = this.baseurl + `getCitiesByState/${id}`;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  get_getCountiesByCity(id): Observable<any> {
    this.temp = this.baseurl + `getCountiesByCity/${id}`;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  ///getParishesByCounty/{id}
  get_getParishesByCounty(id): Observable<any> {
    this.temp = this.baseurl + `getParishesByCounty/${id}`;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  get_currencies(): Observable<any> {
    //este mismo endpoint lo utilice en crear eventos-stepPropuesta...
    this.temp = this.baseurl + 'currencies';
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
  get_numberCourseByCategory(): Observable<any> {
    // /numberCourseByCategory
    this.temp = this.baseurl + `numberCourseByCategory`;
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message; // Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

  public getIPAddress() {
    return this.http.get(environment.ipify_url);
  }

  public sendFileChat(file, id, meetting_id) {
    console.log('file on service = ', file);
    let formData = new FormData();

    formData.append('file', file[0]);
    formData.append('id', id);
    formData.append('meetting_id', meetting_id);

    this.temp = 'https://chat.newyorkcityloop.com/upload';
    return fetch(this.temp, {
      method: 'post',
      headers: new Headers({ Authorization: `Bearer ${this.authToken}` }),
      body: formData,
    })
      .then((res) => {
        console.log('res-images:: ', res);
      })
      .catch((err) => {
        console.log('Err-images:: ', err);
      });
  }
}
