<div fxLayout="column" fxflex fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="center stretch" *ngIf="msg!=null">
    <app-message  [msg]="msg" [typeMsg]="typeMsg"></app-message>
  </div>
  <div fxLayoutAlign="center center" *ngIf="!loadCalendar">
    <mat-spinner  strokeWidth="10" [diameter]="48"></mat-spinner>
  </div>
  <div fxLayout="column" fxflex fxLayoutGap="20px" *ngIf='loadCalendar '>
    <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="20px">
      <p>Horas: {{timeCount}}</p>
      <p *ngIf="rentPlace">Precio: {{code}}  {{amount*timeCount  | currency}}</p>
    </div>

    <div class="full-calendar">
      <full-calendar
      [options]='calendarOptions'>
      [height]="'100px'"
    </full-calendar>
    </div>
  </div>
</div>
