import { TeacherService } from './../../../../services/teacher.service';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { PlaceService } from '../../../../services/place.service';
import { GeneralService } from '../../../../services/general.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {Location} from '@angular/common';
@Component({
  selector: 'app-profile-teachers',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileTeacherComponent implements OnInit {
  constructor(

    private _location: Location,
    private router: ActivatedRoute,
    private teacherService: TeacherService
  ) {}

  id;
  perfil;

  ngOnInit(): void {
    this.id = this.router.snapshot.params.id;

    this.teacherService.getProfile(this.id).subscribe((data) => {
      console.log(data);
      this.perfil = data.teacher[0];
    });

    // console.log(id);
  }

  backClicked() {
    this._location.back();
  }
}

