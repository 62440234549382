<div fxLayout="column" fxLayoutGap="36px" fxFlex>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <p class="title-card">{{ "place.images" | translate }}</p>

    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="18px"
      *ngIf="edit == true"
    >
      <!-- <mat-icon (click)="addimage()" class="btn-sm pointer border-orange" style="font-weight: bold;">add</mat-icon> -->

      <app-upload
        [origin]="origin"
        #up1
        (fileEventGallery)="receiveFileAdd($event)"
        (srcImgEventGallery)="receiveSrcImgAdd($event)"
      ></app-upload>

      <mat-icon (click)="deleteimage()" class="btn-sm pointer border-orange"
        >delete</mat-icon
      >
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <img *ngIf="num < 0" [src]="slides[0]" alt="" class="bigImg" />
    <img *ngIf="num >= 0" [src]="slides[num]" alt="" class="bigImg" />
  </div>

  <div fxLayout="row">
    <button class="button pointer" (click)="back()">
      <span class="material-icons">keyboard_arrow_left</span>
    </button>
    <div
      class="content-container"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxFlex
    >
      <div
        *ngIf="type == 'directive' && show"
        class="swiper-container"
        [swiper]="config"
        (indexChange)="onIndexChange($event)"
        (swiperTransitionStart)="onSwiperEvent('transitionStart')"
        (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxFlex="100"
      >
        <div class="swiper-wrapper">
          <div *ngFor="let slide of slides" class="swiper-slide">
            <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
              <img [src]="slide" alt="" class="imgC" />
            </div>
          </div>
          <!-- <div fxLayoutAlign="center center">
              <img src="https://t3.ftcdn.net/jpg/01/80/31/10/240_F_180311099_Vlj8ufdHvec4onKSDLxxdrNiP6yX4PnP.jpg" class="imgC pointer"  (click)="addimage()">
              <img [src]="imgAdd" class="imgC pointer"  (click)="addimage()">
            </div> -->
        </div>
      </div>
    </div>
    <button class="button pointer" (click)="next()">
      <mat-icon class="material-icons">keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>

