import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-modal-delete-hour',
  templateUrl: './modal-delete-hour.component.html',
  styleUrls: ['./modal-delete-hour.component.scss']
})
export class ModalDeleteHourComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    console.log("data on cancel proposal:", this.data)
  }

}
