<div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">
	<mat-card fxLayout="column" fxFlex="40" xLayoutAlign="space-between center" class="card">
		<app-video-youtube [SendUrl]="url"></app-video-youtube>
		<div fxLayout="column" fxLayoutAlign="end stretch" fxFlex fxLayoutGap="18px">
			<p class="title-card">Video</p>
			<mat-form-field>
				<mat-label class="label-input">{{"place.urlYoutube" | translate }}</mat-label>
				<textarea (keyup)="onKey($event)" name="url" [(ngModel)]="url" matInput placeholder="https://www.youtube.com/watch?v=ozXIAg7zp9I"></textarea>
			</mat-form-field>
		</div>
	</mat-card>
	<mat-card fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex fxLayoutGap="20px" class="card">
    <div (click)="flagIma()">
      <!-- [arrayImage]='images' -->
      <app-carousel
        origin="gallery"
        #ca3
        *ngIf="image == null"
        [edit]="true"
      ></app-carousel>
      <app-carousel
        #ca4
        origin="gallery"
        *ngIf="image != null"
        [arrayImage]="image"
        [edit]="true"
      ></app-carousel>
    </div>
		<!-- <app-carousel></app-carousel> -->
	</mat-card>
</div>
