import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EventInput } from '@fullcalendar/angular';

import{ EventsService } from '../../../services/events.service'


@Component({
  selector: 'app-exapmples',
  templateUrl: './exapmples.component.html',
  styleUrls: ['./exapmples.component.scss']
})
export class ExapmplesComponent implements OnInit {

  msg = "mensaje de exito bua ha ha ha"
  form: FormGroup;
  



  constructor(private EventsService:EventsService,
    private fb: FormBuilder,) { }

  INITIAL_EVENTS: EventInput[]
  TODAY_STR = new Date().toISOString().replace(/T.*$/, '');
  dayOffice;
  events;


  ngOnInit(): void {
    // this.EventsService.get_admin_eventSchedules(44).subscribe((res:any) => {
    //   console.log("get_admin_eventSchedules", res);
    // }, err => {
    //   console.log("ERRor get_currencies", err);
    // });

    // this.EventsService.get_admin_dataToCancelReservation(3).subscribe((res:any) => {
    //   console.log("get_admin_dataToCancelReservation", res);
    // }, err => {
    //   console.log("ERRor get_admin_dataToCancelReservation", err);
    // });

    this.EventsService.get_admin_PublishEvent(3).subscribe((res:any) => {
      console.log("get_admin_PublishEvent", res);
    }, err => {
      console.log("ERRor get_admin_PublishEvent", err);
    });

    // this.EventsService.get_schedule(44).subscribe(res => {
    //   console.log("get_schedule", res);
    // }, err => {
    //   console.log("ERRor get_currencies", err);
    // });

    this.form = this.fb.group({
      end   : ['', ],
      start   : ['', ],
      num   : ['', ],
    });

    this.INITIAL_EVENTS = [
      {
        groupId: 'unselectable',
        id:'database',
        title: 'No disponible',
        start: this.TODAY_STR + 'T08:00:00',
        end: this.TODAY_STR + 'T10:00:00',
        backgroundColor: "green",
        borderColor: "pink",
        textColor:"black",
        overlap: false,
      }, 
      {
        groupId: 'unselectable',
        id:'database',
        title: 'No disponible',
        start: this.TODAY_STR + 'T13:00:00',
        end: this.TODAY_STR + 'T15:00:00',
        overlap: false,
      },
      // {
      //   groupId: 'unselectable',
      //   title: 'No disponible',
      //   start: '2020-09-17T11:00:00',
      //   end: '2020-09-17T13:00:00',
      //   overlap: false,
      //   eventOverlap: false,
      // },
      // {
      //   // groupId: 'now',
      //   title: 'BCH237',
      //   start: '2020-09-17T13:00:00',
      //   end: '2020-09-17T15:00:00',
      //   durationEditable: true,
      //   startEditable: true,
      //   // eventOverlap:true,
      //   overlap: false,
      //   constraint: 'businessHours',
      // }


      
    ];
  }
  

}


