<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
      <app-header></app-header>
        <app-sub-header [screen]="'Terminos y condiciones'" ></app-sub-header>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" class="p-term">
          <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
                <mat-tab-group mat-stretch-tabs [(selectedIndex)]="indexTab">
                  <mat-tab label="Condiciones de uso" >
                    <div fxLayout="column" fxLayoutAlign="center">
                        <div fxLayout="column" class="tab-content">
                          <p class="p">Los servicios son proporcionados por por Sogcial C.A. Estos Términos de uso ('Condiciones de uso') regulan el uso que puedes hacer del sitio Web. Lee estos Términos en detalle y ponte en contacto con nosotros si deseas realizar alguna pregunta. Al usar nuestros Servicios, aceptas regirte por estos Términos, que comprenden las pautas mencionadas en ellos.</p>
                          <p class="p">Al momento de ingresar al sitio queda sujeto a estas Condiciones, que, junto con nuestras Políticas de Privacidad, rigen nuestra relación con usted. Solo podrá ingresar después de haber leído y aceptado las Condiciones.</p>
                          <p class="p">Acepta que Sogcial utilice la información que comparta según lo establecido en nuestras Políticas de Privacidad y de cookies.</p>
                        </div>
                        <mat-accordion class="example-headers-align" multi>
                          <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                Descripción del Sitio
                              </mat-panel-title>
                              <mat-panel-description>
                                <mat-icon>control_point</mat-icon>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                          </mat-expansion-panel>
                          <br>
                          <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                Acceso al Sitio
                              </mat-panel-title>
                              <mat-panel-description>
                                <mat-icon>control_point</mat-icon>
                              </mat-panel-description>
                            </mat-expansion-panel-header>


                          </mat-expansion-panel>
                          <br>
                          <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                Registro
                              </mat-panel-title>
                              <mat-panel-description>
                                <mat-icon>control_point</mat-icon>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                          </mat-expansion-panel>
                          <br>
                          <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                Tarifas
                              </mat-panel-title>
                              <mat-panel-description>
                                <mat-icon>control_point</mat-icon>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                          </mat-expansion-panel>
                        </mat-accordion>
                        <div fxLayout="column" class="tab-content">
                          <p class="p">Sogcial se reserva el derecho de cambiar sus tarifas o cargos por los servicios ofrecidos en el Sitio (o de comenzar a cobrar por cualquier servicio gratuito), tomando en cuenta por las tarifas pagadas antes de dichos cambios no resultarán afectadas.</p>
                        </div>
                        <mat-accordion class="example-headers-align">
                          <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" hideToggle>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                Comportamiento del usuario
                              </mat-panel-title>
                              <mat-panel-description>
                                <mat-icon>control_point</mat-icon>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                          </mat-expansion-panel>
                          <br>
                          <mat-expansion-panel [expanded]="step ===6" (opened)="setStep(6)" hideToggle>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                Responsabilidades de los visitantes al Sitio
                              </mat-panel-title>
                              <mat-panel-description>
                                <mat-icon>control_point</mat-icon>
                              </mat-panel-description>
                            </mat-expansion-panel-header>


                          </mat-expansion-panel>
                          <br>
                          <mat-expansion-panel [expanded]="step === 7" (opened)="setStep(7)" hideToggle>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                Reglas especiales para profesores
                              </mat-panel-title>
                              <mat-panel-description>
                                <mat-icon>control_point</mat-icon>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                          </mat-expansion-panel>
                          <br>
                          <mat-expansion-panel [expanded]="step === 8" (opened)="setStep(8)" hideToggle>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                Pagos y honorarios
                              </mat-panel-title>
                              <mat-panel-description>
                                <mat-icon>control_point</mat-icon>
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                          </mat-expansion-panel>
                        </mat-accordion>
                        <br>
                    </div>
                  </mat-tab>
                  <mat-tab label="Politicas de privacidad y Cookies">
                    <div fxLayout="column" fxLayoutAlign="center">
                      <div fxLayout="column" class="tab-content">
                        <p class="p">Los servicios son proporcionados por por Sogcial C.A. Estos Términos de uso ('Condiciones de uso') regulan el uso que puedes hacer del sitio Web. Lee estos Términos en detalle y ponte en contacto con nosotros si deseas realizar alguna pregunta. Al usar nuestros Servicios, aceptas regirte por estos Términos, que comprenden las pautas mencionadas en ellos.</p>
                        <p class="p">Al momento de ingresar al sitio queda sujeto a estas Condiciones, que, junto con nuestras Políticas de Privacidad, rigen nuestra relación con usted. Solo podrá ingresar después de haber leído y aceptado las Condiciones.</p>
                        <p class="p">Acepta que Sogcial utilice la información que comparta según lo establecido en nuestras Políticas de Privacidad y de cookies.</p>
                      </div>
                      <mat-accordion class="example-headers-align">
                        <mat-expansion-panel [expanded]="step === 9" (opened)="setStep(9)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Base legal para el procesamiento de los datos personales
                            </mat-panel-title>
                            <mat-panel-description>
                              <mat-icon>control_point</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                        </mat-expansion-panel>
                        <br>
                        <mat-expansion-panel [expanded]="step === 10" (opened)="setStep(10)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Información personal que recopilamos
                            </mat-panel-title>
                            <mat-panel-description>
                              <mat-icon>control_point</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>


                        </mat-expansion-panel>
                        <br>
                        <mat-expansion-panel [expanded]="step === 11" (opened)="setStep(11)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Objetivo del procesamiento
                            </mat-panel-title>
                            <mat-panel-description>
                              <mat-icon>control_point</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                        </mat-expansion-panel>
                        <br>
                        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(12)" hideToggle>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              Derechos de protección de datos
                            </mat-panel-title>
                            <mat-panel-description>
                              <mat-icon>control_point</mat-icon>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                        </mat-expansion-panel>
                      </mat-accordion>
                      <div fxLayout="column" class="tab-content">
                        <p class="p">Como Controlador de datos, nos comprometemos a respetar la naturaleza confidencial de tus datos personales y garantizamos el completo ejercicio de estos derechos. En todo momento, podrás ejercer los siguientes derechos:</p>
                        <p class="p"><strong>1. Derecho al acceso.</strong> Tienes derecho a confirmar si estamos procesando tus datos personales y, si lo estamos, tienes derecho a obtener una copia de los datos y de la información relacionada con el procesamiento.</p>
                        <p class="p"><strong>2. Derecho de modificación.</strong> Tienes derecho a corregir errores, a modificar datos incompletos o erróneos y a garantizar la autenticidad de la información procesada.</p>
                        <p class="p"><strong>3. Derecho a eliminar.</strong> Tienes derecho a solicitar la eliminación de tus datos personales sin un retraso injustificado, en el caso de que hayan sido procesados de manera errónea o si el fin para el que han sido procesados o recopilados ha dejado de existir.</p>
                        <p class="p"><strong>4. Derecho a limitar el procesamiento.</strong> Tienes derecho a solicitar la suspensión del procesamiento de tu información si es ilegal o si la exactitud de los datos es cuestionada.</p>
                        <p class="p"><strong>5. Derecho de oposición.</strong> Tienes derecho a oponerte al procesamiento de tus datos cuando han sido utilizados con fines de marketing directo o cuando el procesamiento deba finalizar debido a un problema personal, excepto cuando haya legítimo interés o sea necesario para el ejercicio o la defensa de acciones.</p>
                        <p class="p"><strong>6. Derecho a no ser sometido a decisiones personalizadas.</strong> Tienes derecho a no ser sometido a una decisión basada únicamente en el procesamiento automático de tus datos, incluida la caracterización del perfil, con efectos legales vinculantes o que te afecten, excepto cuando sea necesario para la ejecución de un contrato, de acuerdo con la ley, o si lo has autorizado de forma expresa.</p>
                      </div>
                    </div>

                  </mat-tab>
                </mat-tab-group>
              </div>
          </div>
      </div>


      <app-footer></app-footer>
    </div>
</mat-drawer-container>
