import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss']
})
export class ForgotPassComponent implements OnInit {
  email: string = '';
  error: boolean = false;
  isMobile: boolean = false;
  msg:string ='';
  typeMsg:string;
  load = false;
  disabled = true;

  constructor(
    private AuthService:AuthService,
    private _router: Router,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,) {}

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => this.isMobile = result.matches);
  } 

  onInputChange(){
    if(this.email != '' && this.email.match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")){
      this.disabled = false;
    }else{
      this.disabled = true;
    }
  }

  onEmailVerify(){
    this.load = true;
    this.msg='';
    if(this.email != ''){
      if(!this.email.match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")){
        this.error=true;
        this.typeMsg="error";
        this.translate.get('forgot.error').subscribe( (text: string) => {
          this.msg = text;
        });
      }else{
        this.error=false;
        let data = {
          "email": this.email,
        };
        
        this.AuthService.post_password_create(data).subscribe(
          res => {
            if(res.ERROR == false ){
              this.typeMsg="success";
              this.msg = res.ERROR_MENSAGGE;
              this.disabled = true;
            }
            this.load = false;
          },err => {
            this.error=true;
            this.typeMsg="error";
            this.msg = err.error.ERROR_MENSAGGE;
            this.load = false;
        });
      }
    }
    

  }
}