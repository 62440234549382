import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CourseWebsiteService } from 'src/app/services/course-website.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { environment } from "../../../environments/environment";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WindowScrollController } from '@fullcalendar/core';
import { Router } from '@angular/router';
import { DialogCourseComponent } from './dialog-course/dialog-course.component';
import { SharedModalService } from 'src/app/services/shared-modal.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';



export interface DialogData {
  courseName: string;
  price: any;
  category: string;
  image: any;
  courseAffliates: string[];
  productId: number;
  amount: any;
  userRegistered: any;
  registeredAffiliates: any;
}

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit {
  start = 5;
  storage = environment.storage;
  load = false;
  id: string;
  isFavorite: boolean = false;
  courses: any;
  imagesCarousel: string[] = [];
  urlApi: string;
  logged: string;
  tags: any;
  user: any;
  course_teachers: any;
  product_id: number;
  user_id: number;
  isLoggedIn: boolean = false;
  schedule: any;
  image: any;
  affiliates: any;
  productId : number;
  amount: any;
  allIn: boolean;
  userRegistered : any;
  registeredAffiliates: any;
  videoUrl: SafeResourceUrl;
  safeUrl: SafeResourceUrl;


  url = [
    {
      url: "/home",
      urlName: "Home/"
    },
    {
      url: "",
      urlName: "Categoria/"
    },
    {
      url: "",
      urlName: ""
    }
  ];
  star = 3;
  afterViewInit: boolean = false;
  videoId: string;

  constructor(
    private courseWebsiteService: CourseWebsiteService,
    private _route: ActivatedRoute,
    public dialog: MatDialog,
    public favorite: FavoriteService,
    private router: Router,
    private sharedModalService: SharedModalService,
    private sanitizer: DomSanitizer

  ) {
    const userData = localStorage.getItem('userData');
    this.isLoggedIn = userData !== null;
    this.checkLoginStatus();
  }

  ngOnInit(): void {
    this.id = this._route.snapshot.paramMap.get('id');
    if (this.id) {
      let aux = this.id.split("-");
      this.id = aux[0];
      this.courseWebsiteService.get_courseDetail(this.id).subscribe(resp => {
        this.courses = resp.courses[0];
        this.image = resp.course_images[0];
        this.course_teachers = resp.course_teachers[0];
        this.tags = this.courses.tags;
        this.user = resp.user[0];
        this.schedule = resp.schedule[0];
        this.affiliates = resp.affiliates;
        this.allIn = resp.allIn;
        this.userRegistered = resp.userRegistered;
        this.registeredAffiliates = resp.registeredAffiliates;
        if (this.tags) {
          var aux = this.tags.split(",");
          this.tags = aux;
        }
        this.load = true;
        this.url[2].urlName = this.courses.learn;
        this.urlApi = environment.storageServe;
        this.imagesCarousel = JSON.parse(this.courses.images);
        this.imagesCarousel = this.imagesCarousel.map(image => this.urlApi + '/' + image);
        console.log(this.courses);

        if(this.courses.url === null) {
          this.videoId = null;
        }


        if (this.courses.url && typeof this.courses.url === 'string') {
          const url =this.courses.url.split("v=");
          this.videoId = url[0];
        }
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.videoId);
        this.videoUrl = this.safeURL();

      });
    }
  }

  checkLoginStatus() {
    const userData = localStorage.getItem('userData');
    if (userData) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

  redirectToLogin() {
    const routeRedirection = '/auth/login';
    this.router.navigate([routeRedirection]);
  }

  storeOrDelete(id: number) {
    const userId = JSON.parse(localStorage.getItem('userData')).id;
    const data = {
      user_id: userId,
      product_id: id
    };

    this.favorite.Post_favorite(data).subscribe(
      () => {
        if (this.courses.isFavorite === 0) {
          this.courses.isFavorite = 1;
        } else {
          this.courses.isFavorite = 0;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  tagsArray(stringTags) {
    if (stringTags) {
      return stringTags.split(',');
    }
    return [];
  }

  format_data(date) {
    if (typeof date == 'string') {
      const arrayDate = date.split("-");
      return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
    }
    return date;
  }

  onTabChanged($event) {
    const { tab: { textLabel } } = $event;
    if (textLabel == 'Video') {
      this.afterViewInit = true;
    }
  }

  openDialog() {
    const data: DialogData = {
      courseName: this.courses.courseName,
      price: this.courses.price ? this.courses.price + ' ' + '$' + this.courses.currency : 'No Disponible',
      category: this.courses.category,
      image: this.image,
      courseAffliates: this.affiliates,
      productId: this.courses.productId,
      amount: this.courses.price,
      userRegistered: this.userRegistered,
      registeredAffiliates: this.registeredAffiliates,

    };
  
    this.sharedModalService.openDialog(data);
  }

  
  safeURL(){
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.videoId);
  }

  constructYoutubeUrl(videoId: string): string {
    return `https://www.youtube.com/embed/${videoId}`;
  }

}
