import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LatLngLiteral, MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  public searchControl: FormControl;
  @ViewChild('search', { static: false }) searchElementRef: ElementRef;
  lat = 0;
  lng = 0;
  map_zoom = 0;
  centerLatitude = 0;
  centerLongitude = 0;
  loader = false;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {}

  ngOnInit(): void {
    this.searchControl = new FormControl();
    this.searchControl.setValue('Venezuela');
    //set current position
    this.setCurrentPosition();

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ['address'],
        }
      );
      autocomplete.addListener('place_changed', (t) => {
        console.log('TTTT', t);
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log('RES', place);
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.map_zoom = 13;
        });
      });
    });
  }

  mapClicked($event: MouseEvent) {
    /* this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    }); */
  }

  centerChange(coords: LatLngLiteral) {
    //console.log(event);
    this.centerLatitude = coords.lat;
    this.centerLongitude = coords.lng;
  }

  markerDragEnd(m: any, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }
  mapReady(map) {
    map.addListener('dragend', () => {
      this.loader = true;
      //console.log(this.centerLatitude, this.centerLongitude)
      // do something with centerLatitude/centerLongitude
    });
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.map_zoom = 12;
      });
    }
  }

  country(e) {
    console.log(e.target.value);
    this.getGeoLocation(e.target.value);
  }

  getGeoLocation(address: string){
    let geocoder = new google.maps.Geocoder();
            if (geocoder) {
                geocoder.geocode({ 'address': address }, function (results, status) {
                  console.log("nada", results[0].geometry.viewport);
                    if (status == google.maps.GeocoderStatus.OK) {
                      console.log("LAT0", results[0].geometry.location);
                      const latitud = results[0].geometry.location.lat();
                      const longitud = results[0].geometry.location.lng(); 
                      console.log("LAT", results[0].geometry.location.lat())

                      this.ngZone.run(() => {
                        this.lat = latitud;
                        this.lng = longitud;
                      });
                      
                    }
                    else {
                        console.log('geocoding failed');
                    }
                });
            }
  }
}
