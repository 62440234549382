import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface,SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-front-sliders',
  templateUrl: './front-sliders.component.html',
  styleUrls: ['./front-sliders.component.scss']
})
export class FrontSlidersComponent implements OnInit {
  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    keyboard: true,
    autoplay:false,
    
    spaceBetween:4,
    navigation: true,
    speed:1000,
    setWrapperSize:true,
     breakpoints: {
            // when window width is <= 1024px
            1024: {
                slidesPerView: 1,
                initialSlide:0,

            }
        }
    // mousewheel: true,
    // scrollbar: false,
    // navigation: true,
  };
  sliders=[
    
  ]
  @Input() images
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.sliders=changes.images.currentValue;
  }
}
