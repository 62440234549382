


<table mat-table [dataSource]="dataSource" *ngIf="true">
    <!-- Position Column -->
    <ng-container matColumnDef="action_1">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="w-30">
        <p *ngIf="receive == 'submitted'" class="title"><strong>{{element.teacher.name}}</strong></p>
        <p *ngIf="receive == 'submitted'" class="subtitle">{{element.teacher.courseName}}</p>
        <p *ngIf="receive == 'submitted'" class="subtitle">{{element.teacher.city}} {{element.teacher.country}}</p>

        <p *ngIf="type=='0'||type=='1'||type=='2'" class="text-bold"><strong>Place: {{element.place}}</strong></p>
        <p *ngIf="(type=='0'||type=='1'||type=='2') && element.course!=null" class="text-normal">Curso: {{element.course}}</p>
        <p *ngIf="(type=='0'||type=='1'||type=='2') && element.event!=null" class="text-normal">Evento: {{element.event}}</p>
        <p *ngIf="type=='0'||type=='1'||type=='2'" class="text-normal">
          <span *ngIf="element.price_per_hour != null">{{element.time_length}} {{ element.time_unit }} / {{ element.price_per_hour }} {{ element.currency }}</span>
          <span *ngIf="element.price_total != null">{{element.time_length}} {{ element.time_unit }} / {{ element.price_total }} {{ element.currency }}</span>
        </p>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="action_2">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="w-40">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center" fxLayoutGap="36px" style="padding: 15px ; box-sizing: border-box ; ">
          <div fxFlex="80%" fxLayout="column" fxLayoutAlign="end end" class="unmargin">
            <div fxLayout="row" fxLayout.lt-md="column"  fxLayoutAlign="end end" fxLayoutGap.gt-sm="20px" >
              <p class="title" *ngIf="receive == 'submitted'"><strong>{{element.user.name}}</strong></p>
              <p class="text-bold" *ngIf="type=='0'||type=='1'||type=='2'">{{element.user_name}}</p>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column"  fxLayoutAlign="end end" fxLayoutGap.gt-sm="20px" >
              <span class="" [ngSwitch]="element.average_stars">
                <span class="star" *ngSwitchCase="0"><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon></span>
                <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
              </span>
            </div>
            <div  fxLayout="row" fxLayout.lt-md="column"  fxLayoutAlign="end end" fxLayoutGap.gt-sm="20px">
              <p class="text-normal" *ngIf="element.user_creation!='' && (type=='0'||type=='1'||type=='2')">Miembro desde {{ element.user_creation }}</p>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center" fxLayoutGap.gt-sm="20px">
              <p *ngIf="receive == 'submitted'" class="subtitle">{{element.user.timer}}</p>
            </div>
          </div>
          <div fxFlex="20%" fxLayout="column" fxLayoutAlign="start center" >
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap.gt-sm="20px">
              <img *ngIf="receive == 'submitted'" [src]="element.user.image" width="60px" alt="">
              <span *ngIf="type=='0'||type=='1'||type=='2'">
                <img *ngIf="element.avatar != null" src="{{storage}}{{element.avatar}}" width="60px" alt="">
                <img *ngIf="element.avatar == null" src="/assets/images/user.svg" width="60px" alt="">
              </span>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element" class="w-30" style="text-align: right;">
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="mat-menu-panel-3">
          <div fxLayout="column" fxLayoutAlign="start start" >
            <a *ngIf="tdetail=='proposal'" routerLink="/dashboard/proposal-details/{{element.proposal_id}}" class="padding">Ver</a>
            <a *ngIf="tdetail=='ofert'" routerLink="/dashboard/ofert-details/{{element.offer_id}}" class="padding">Ver</a>

            <a *ngIf="tdetail=='ofert'" class="padding">Mensajes</a>
            <a *ngIf="element.status_type=='accepted' && tdetail=='proposal'" class="padding">Mensajes</a>

            <a *ngIf="element.status_type=='accepted' && (type==0 || type==1)" class="padding">Crear Oferta</a>
            <a *ngIf="(element.status_type=='rejected' || element.status_type=='standby') && type==0" class="padding">Editar</a>
            <a *ngIf="(element.status_type=='rejected' || element.status_type=='standby') && (type==0 || type==1)" class="padding">Eliminar</a>
          </div>
        </mat-menu>
        <p class="state" *ngIf="type=='0'||type=='1'">{{ element.status }}</p>
        <p class="state" *ngIf="type=='2'">Oferta {{ element.status }}</p>
        <p class="time">Enviado hace {{ element.time_created }}</p>
    </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
