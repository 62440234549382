<div fxLayout="row" fxLayoutAlign="space-between center">
    <div style="padding-left: 20px;">
        <p class="title">Servicio de ayuda</p>
    </div>
    <p class="justifyRight" style="padding-right: 20px;">¿Quieres Contactarnos? <a class="link-chat" href="">¡Escríbenos!</a></p>
</div>

<mat-accordion>
    <mat-expansion-panel style="margin-top: 50px;">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="mat-panel-title">Primeros pasos</p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div  style="overflow: auto; height: 600px; padding: 20px;">
            <p class="paragraph-bold">titulo</p><br>
            <p class="paragraph"> Lorem ipsum dolor sit amet consectetur adipisicing elit. A placeat eum facilis reprehenderit officia voluptate quod officiis laudantium. Esse dicta non veniam. Ipsa aspernatur culpa aut. Cum eligendi vel provident?</p><br>
            <p class="paragraph-bold">titulo</p><br>
            <p class="paragraph"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque aliquid nemo nulla eaque totam inventore ipsa quisquam, atque perspiciatis quam molestias dolorum dignissimos veritatis modi doloribus iste veniam consectetur! Deserunt?</p><br>
            <p class="paragraph">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consectetur fugiat, fugit unde ratione expedita ex? Quis odit molestiae debitis, deleniti sint praesentium, illum beatae nulla sequi alias, numquam distinctio ut!</p>
            <p class="paragraph">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae earum, accusantium tenetur temporibus eaque ipsam voluptates consequuntur sint autem animi, dolor perferendis porro provident repellendus ipsa at sapiente fugit illum? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut asperiores repellendus sint rerum, distinctio ab laborum aut doloremque cupiditate adipisci eos repellat hic. Deleniti itaque recusandae mollitia. Non, eius a!</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et placeat odit iusto corrupti magnam quidem eum, ad temporibus quia deleniti ullam obcaecati dignissimos accusamus blanditiis quod labore magni voluptatum consectetur.</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et placeat odit iusto corrupti magnam quidem eum, ad temporibus quia deleniti ullam obcaecati dignissimos accusamus blanditiis quod labore magni voluptatum consectetur.</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et placeat odit iusto corrupti magnam quidem eum, ad temporibus quia deleniti ullam obcaecati dignissimos accusamus blanditiis quod labore magni voluptatum consectetur.</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta veritatis nisi ab quo, culpa incidunt facilis deleniti beatae enim, dolor ea, esse corporis optio ipsam eos. Iure consequuntur id repellat?</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores aut maiores blanditiis quos unde magni ea ad vero harum expedita vitae, cupiditate assumenda exercitationem deserunt odit, voluptas voluptatem eius reiciendis!</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus veniam fugiat quia, deserunt ad quidem laudantium, illum ducimus nulla, ipsa ab exercitationem cupiditate praesentium similique possimus reprehenderit eius ipsam nihil.</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. In iste, eaque ea porro repellendus doloribus unde, consequatur vero voluptatum assumenda neque odit harum sit! Facilis ab sint deleniti natus aut.</p>
            <p class="paragraph">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque provident recusandae a fugiat inventore, deleniti similique nisi omnis vero autem fugit fuga dicta, perferendis iure molestias odit voluptatibus quas quam.</p>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel style="margin-top: 10px;">
        <mat-expansion-panel-header>
            <mat-panel-title >
                <p class="mat-panel-title">Perfil</p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div  style="overflow: auto; height: 600px; padding: 20px;">
            <p class="paragraph-bold">titulo</p><br>
            <p class="paragraph"> Lorem ipsum dolor sit amet consectetur adipisicing elit. A placeat eum facilis reprehenderit officia voluptate quod officiis laudantium. Esse dicta non veniam. Ipsa aspernatur culpa aut. Cum eligendi vel provident?</p><br>
            <p class="paragraph-bold">titulo</p><br>
            <p class="paragraph"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque aliquid nemo nulla eaque totam inventore ipsa quisquam, atque perspiciatis quam molestias dolorum dignissimos veritatis modi doloribus iste veniam consectetur! Deserunt?</p><br>
            <p class="paragraph">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consectetur fugiat, fugit unde ratione expedita ex? Quis odit molestiae debitis, deleniti sint praesentium, illum beatae nulla sequi alias, numquam distinctio ut!</p>
            <p class="paragraph">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae earum, accusantium tenetur temporibus eaque ipsam voluptates consequuntur sint autem animi, dolor perferendis porro provident repellendus ipsa at sapiente fugit illum? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut asperiores repellendus sint rerum, distinctio ab laborum aut doloremque cupiditate adipisci eos repellat hic. Deleniti itaque recusandae mollitia. Non, eius a!</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et placeat odit iusto corrupti magnam quidem eum, ad temporibus quia deleniti ullam obcaecati dignissimos accusamus blanditiis quod labore magni voluptatum consectetur.</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et placeat odit iusto corrupti magnam quidem eum, ad temporibus quia deleniti ullam obcaecati dignissimos accusamus blanditiis quod labore magni voluptatum consectetur.</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et placeat odit iusto corrupti magnam quidem eum, ad temporibus quia deleniti ullam obcaecati dignissimos accusamus blanditiis quod labore magni voluptatum consectetur.</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta veritatis nisi ab quo, culpa incidunt facilis deleniti beatae enim, dolor ea, esse corporis optio ipsam eos. Iure consequuntur id repellat?</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores aut maiores blanditiis quos unde magni ea ad vero harum expedita vitae, cupiditate assumenda exercitationem deserunt odit, voluptas voluptatem eius reiciendis!</p>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel style="margin-top: 10px;">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <p class="mat-panel-title">Compra/Reembolsos</p>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div  style="overflow: auto; height: 600px; padding: 20px;">
            <p class="paragraph-bold">titulo</p><br>
            <p class="paragraph"> Lorem ipsum dolor sit amet consectetur adipisicing elit. A placeat eum facilis reprehenderit officia voluptate quod officiis laudantium. Esse dicta non veniam. Ipsa aspernatur culpa aut. Cum eligendi vel provident?</p><br>
            <p class="paragraph-bold">titulo</p><br>
            <p class="paragraph"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque aliquid nemo nulla eaque totam inventore ipsa quisquam, atque perspiciatis quam molestias dolorum dignissimos veritatis modi doloribus iste veniam consectetur! Deserunt?</p><br>
            <p class="paragraph">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consectetur fugiat, fugit unde ratione expedita ex? Quis odit molestiae debitis, deleniti sint praesentium, illum beatae nulla sequi alias, numquam distinctio ut!</p>
            <p class="paragraph">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae earum, accusantium tenetur temporibus eaque ipsam voluptates consequuntur sint autem animi, dolor perferendis porro provident repellendus ipsa at sapiente fugit illum? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut asperiores repellendus sint rerum, distinctio ab laborum aut doloremque cupiditate adipisci eos repellat hic. Deleniti itaque recusandae mollitia. Non, eius a!</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et placeat odit iusto corrupti magnam quidem eum, ad temporibus quia deleniti ullam obcaecati dignissimos accusamus blanditiis quod labore magni voluptatum consectetur.</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et placeat odit iusto corrupti magnam quidem eum, ad temporibus quia deleniti ullam obcaecati dignissimos accusamus blanditiis quod labore magni voluptatum consectetur.</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Et placeat odit iusto corrupti magnam quidem eum, ad temporibus quia deleniti ullam obcaecati dignissimos accusamus blanditiis quod labore magni voluptatum consectetur.</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta veritatis nisi ab quo, culpa incidunt facilis deleniti beatae enim, dolor ea, esse corporis optio ipsam eos. Iure consequuntur id repellat?</p>
            <p class="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores aut maiores blanditiis quos unde magni ea ad vero harum expedita vitae, cupiditate assumenda exercitationem deserunt odit, voluptas voluptatem eius reiciendis!</p>
        </div>
    </mat-expansion-panel>
</mat-accordion>




<!-- <ng-template matExpansionPanelContent>
    Some deferred content
  </ng-template> -->