import { Component, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { ModalCancelContractComponent } from '../../events/home/modal-cancel-contract/modal-cancel-contract.component';
import { ModalCancelEventComponent } from '../../events/home/modal-cancel-event/modal-cancel-event.component';
import { ModalCancelProposalComponent } from '../../events/home/modal-cancel-proposal/modal-cancel-proposal.component';
import { EventsService } from '../../../../services/events.service';
import { GeneralService } from '../../../../services/general.service';
import { CourseDashboardService } from '../../../../services/course-dashboard.service';
import { MatTableDataSource } from '@angular/material/table';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-home-course',
  templateUrl: './home-course.component.html',
  styleUrls: ['./home-course.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class HomeCourseComponent implements OnInit {
  dataSource;
  dataSourceAux;
  columnsToDisplay: string[];
  id;
  load = true;
  hide = true; //true
  expandedElement: PeriodicElement | null;

  filterValues = {
    category: '',
    place: '',
    status: '',
  };

  category = new FormControl();
  categoryList = [];
  place = new FormControl();
  placesList = [];
  status = new FormControl();
  statusList = [];

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  user;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private eventsService: EventsService,
    public dialog: MatDialog,
    private _router: Router,
    private GeneralService: GeneralService,
    private CourseDashboardService: CourseDashboardService
  ) {}

  ngOnInit(): void {
    this.msgSuscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });
    this.user = JSON.parse(localStorage.getItem('userData'));

    this.serviceGetEvents();
  }

  serviceGetEvents() {
    let ELEMENT_DATA = [];
    this.CourseDashboardService.get_course(this.user.id)
      .pipe(
        switchMap((response) => {
          const coursesFilter = response.courses.filter(
            (curso) => curso.status !== 'Eliminado'
          );
          const newList = {
            ...response,
            courses: coursesFilter,
          };
          return of(newList);
        })
      )
      .subscribe(
        (res: any) => {
          console.log('get_course', res);
          let countStatus = 0;
          res.courses.forEach((element) => {
            if (element.place != null && element != 0 && element.place != '0') {
              countStatus++;
            }
            if (
              element.teachers != 0 &&
              element.teachers != '0' &&
              element.teachers != null &&
              element.proposal_id != null
            ) {
              countStatus++;
            }
            if (element.status == 'Publicado') {
              countStatus++;
            }

            ELEMENT_DATA.push({
              name: element.courseName,
              category: element.category,
              place: element.place,
              place_id: element.place_id,
              date: element.start_date ? element.start_date : '0000/00/00',
              status: element.status,
              edit: 'create',
              copy: 'visibility',
              description: element.description,
              main_image: element.main_image,
              availability: element.availability,
              capacity: element.capacity,
              end_date: element.end_date,
              end_hour: element.end_hour,
              start_date: element.start_date,
              start_hour: element.start_hour,
              id_course: element.idcourse,
              price: element.price,
              tags: element.tags != null ? element.tags.split(',') : '',
              state: element.state,
              countStatus: countStatus,
              teacher: element.teachers,
              proposal_id: element.proposal_id,
              age: element.age,
              enrollment: element.enrollment,
              learn: element.learn,
              aimed_to: element.aimed_to,
              requirements: element.requirements,
              boolTeacher: element.teachers != 0 ? false : true,
              boolPlace: element.place != null ? false : true,
              boolPublish: element.status == 'Publicado' ? true : false,
              skip_proposal: element.skip_proposal,
            });
            countStatus = 0;
          });

          let datatable=ELEMENT_DATA;
          if(this.user.rol_id === 2){
           datatable=ELEMENT_DATA.filter((f)=>f.place!==null && f.proposal_id!==null)
          }
          this.dataSource = new MatTableDataSource(datatable);
          this.dataSourceAux = new MatTableDataSource(datatable);

          this.columnsToDisplay = [
            'name',
            'category',
            'date',
            'status',
            'menu',
          ];
          if (this.user.rol_id === 2) {
            const indice = this.columnsToDisplay.indexOf('status');
            this.columnsToDisplay.splice(indice, 1);
          }

          this.category.valueChanges.subscribe((category) => {
            this.filterValues.category = category;
            console.log(
              'this.filterValues.category:: ',
              this.filterValues.category
            );

            this.dataSource.data = this.dataSourceAux.data.filter((dat) => {
              let ok = false;
              for (
                let i = 0;
                i < this.filterValues.category.length && !ok;
                i++
              ) {
                let category = this.filterValues.category[i];
                if (category == dat.category) ok = true;
              }
              return ok;
            });
          });

          this.place.valueChanges.subscribe((place) => {
            this.filterValues.place = place;
            console.log('this.filterValues.place:: ', this.filterValues.place);

            this.dataSource.data = this.dataSourceAux.data.filter((dat) => {
              let ok = false;
              for (let i = 0; i < this.filterValues.place.length && !ok; i++) {
                let place = this.filterValues.place[i];
                if (place == dat.place) ok = true;
              }
              return ok;
            });
          });

          this.status.valueChanges.subscribe((status) => {
            this.filterValues.status = status;
            console.log(
              'this.filterValues.status:: ',
              this.filterValues.status
            );

            this.dataSource.data = this.dataSourceAux.data.filter((dat) => {
              let ok = false;
              for (let i = 0; i < this.filterValues.status.length && !ok; i++) {
                let status = this.filterValues.status[i];
                if (status == dat.status) ok = true;
              }
              return ok;
            });
          });

          this.dataSource.paginator = this.paginator;

          this.CourseDashboardService.get_coursesCategories().subscribe(
            (res: any) => {
              console.log('get_coursesCategories', res);
              res.categories.forEach((element) => {
                this.categoryList.push(element.name);
              });
            },
            (err) => {
              console.log('ERRor get_coursesCategories', err);
            }
          );

          this.CourseDashboardService.get_placesLimit().subscribe(
            (res: any) => {
              console.log('get_placesLimit', res);
              res.result.forEach((element) => {
                this.placesList.push(element.Place);
              });
            },
            (err) => {
              console.log('ERRor get_placesLimit', err);
            }
          );

          this.statusList.push('Publicado');
          this.statusList.push('Pendiente');
          this.statusList.push('Borrador');
          this.statusList.push('Eliminado');
          this.load = false;
        },
        (err) => {
          console.log('ERRor ', err);
        }
      );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialogCancelProposal(element, reservation: any, schedule: any) {
    const dialogRef = this.dialog.open(ModalCancelProposalComponent, {
      height: 'auto',
      width: 'auto',
      data: { element, reservation, schedule },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log('result', result);
      if (result != undefined) {
        console.log('borrar reservation');
        this.eventsService.get_admin_CancelReservation(result).subscribe(
          (res: any) => {
            console.log('get_admin_CancelReservation', res);
            // window.location.reload();
            this.serviceGetEvents();

            this.GeneralService.typeMsg$.emit('success');
            this.GeneralService.msg$.emit('Operacion exitosa');
          },
          (err) => {
            console.log('ERRor get_admin_CancelReservation', err);
          }
        );
      }
    });
  }

  openDialogCancelContract(element, results: any) {
    const dialogRef = this.dialog.open(ModalCancelContractComponent, {
      height: 'auto',
      width: 'auto',
      data: { element, results },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log('result', result);
      if (result != null) {
        let offers = [];
        for (let i = 0; i < result.length; i++) {
          offers.push({ offer_id: result[i] });
        }
        let data = { offers: offers };
        console.log('data a mariana: ', data);
        console.log('acepto borrrarrrrrrrrrrrrr');
        this.eventsService.post_admin_cancelOffer(data).subscribe(
          (res: any) => {
            console.log('post_admin_cancelOffer', res);
            // window.location.reload();
            this.GeneralService.typeMsg$.emit('success');
            this.GeneralService.msg$.emit('Operacion exitosa');
            this.serviceGetEvents();
          },
          (err) => {
            console.log('ERRor post_admin_cancelOffer', err);
          }
        );
      }
    });
  }

  openDialogRemoveEvent(remove: string, element) {
    const dialogRef = this.dialog.open(ModalCancelEventComponent, {
      height: 'auto',
      width: 'auto',
      data: { remove, element },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      let temp = result.split(',');
      if (result == 'pause') {
        console.log('pausar evento');
        this.serviceChangeStatus(element.id_course, 'Pendiente');
      }
      if (result == 'remove') {
        console.log('Borrar evento');
        this.serviceChangeStatus(element.id_course, 'Eliminado');
      }
    });
  }
  sendToProposalTeacher(idProposal: number) {
    this._router.navigateByUrl(
      '/dashboard/courses/list-teachers/' + idProposal
    );
  }

  sendToContractPlace(idCourse: number) {
    this.CourseDashboardService.set_courseId(idCourse);
    this._router.navigateByUrl('/dashboard/proposalPlaceCourse');
  }

  sendToPublish(idCourse: number) {
    this._router.navigateByUrl('/dashboard/courses/publish/' + idCourse);
  }

  serviceDataToCancelOffer(element: any) {
    if (this.msg == null) {
      let results;
      this.CourseDashboardService.get_admin_dataToCancelOffer(
        element.id_course
      ).subscribe(
        (res: any) => {
          console.log('get_admin_dataToCancelOffer', res);
          results = res.results;
          console.log('result: ', results);
          this.openDialogCancelContract(element, results);
        },
        (err) => {
          console.log('ERRor get_admin_dataToCancelOffer', err);
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit(err.statusText);
        }
      );
    }
  }

  serviceDataToCancelReservation(element: any) {
    if (this.msg == null) {
      let reservation;
      let schedule;
      this.CourseDashboardService.get_admin_dataToCancelReservation(
        element.id_course
      ).subscribe(
        (res: any) => {
          console.log('get_admin_dataToCancelReservation', res);
          reservation = res.results[0].reservation;
          schedule = res.results[0].schedules;
          this.openDialogCancelProposal(element, reservation, schedule);
        },
        (err) => {
          console.log('ERRor get_admin_dataToCancelReservation', err);
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit(err.statusText);
        }
      );
    }
  }

  serviceChangeStatus(id_course: number, type: string) {
    let data = { status: type };
    this.CourseDashboardService.post_admin_statusCourse(
      id_course,
      data
    ).subscribe(
      (res: any) => {
        console.log('post_admin_statusEvent', res);
        this.GeneralService.typeMsg$.emit('success');
        this.GeneralService.msg$.emit('Operacion exitosa');
        this.serviceGetEvents();
      },
      (err) => {
        console.log('ERRor post_admin_statusEvent', err);
      }
    );
  }

  atDashboar() {}

  goEdit(element) {
    console.log(element);
    localStorage.setItem('courseSelected', JSON.stringify(element));
    this._router.navigateByUrl('/dashboard/courses/edit/' + element.id_course);
  }

  create() {
    localStorage.removeItem('courseincomplete');
    localStorage.removeItem('idcourse');
    this._router.navigateByUrl('/dashboard/courses/create');
  }

  continue(element) {
    console.log(element);
    if (element.place_id === null) {
      console.log('vamos al paso 2');
      localStorage.setItem('courseincomplete', 'step2');
      localStorage.setItem('idcourse', element.id_course);
      this._router.navigateByUrl('/dashboard/courses/create');
    } else if (element.proposal_id === null) {
      console.log('vamos al paso 3');
      localStorage.setItem('idcourse', element.id_course);
      localStorage.setItem('courseincomplete', 'step3');
      this._router.navigateByUrl('/dashboard/courses/create');
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSuscription;
    this.typeMsgSuscription;
  }

  clearcategory() {
    this.category.setValue(null, { emitEvent: false });
    this.dataSource.data = this.dataSourceAux.data;
  }

  clearplace() {
    this.place.setValue(null, { emitEvent: false });
    this.dataSource.data = this.dataSourceAux.data;
  }

  clearstatus() {
    this.status.setValue(null, { emitEvent: false });
    this.dataSource.data = this.dataSourceAux.data;
  }
}

export interface PeriodicElement {
  name: string;
  category: string;
  place: String;
  date: string;
  status: string;
  edit: string;
  copy: string;
}

interface Filter {
  value: string;
  viewValue: string;
}
