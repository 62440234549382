import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class EventsService {
  public baseurl: string;
  temp: string;
  headers = new Headers();

  public onHeaderReload = new EventEmitter<void>();

  triggerHeaderReload() {
    this.onHeaderReload.emit();
  }


  constructor(private __http: HttpClient) {
    this.baseurl = environment.url_server;
    this.headers.set("Content-Type", "multipart/form-data");
    const accesstoken = localStorage.getItem('access_token');
    if(accesstoken){
      this.headers.set("Authorization", 'Bearer ' + JSON.parse(accesstoken));

    }
  }


  get_adminEvents(id: number): Observable<any> {
    this.temp = this.baseurl + 'admin/events/' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_events(id=null): Observable<any> {
    const userDataString = localStorage.getItem('userData');
  
    if (userDataString) {
      const userId = JSON.parse(userDataString).id;
      if (userId) {
        this.temp = this.baseurl + 'events/' + id + '?user_id=' + userId;
      }
    } else {
      this.temp = this.baseurl + 'events/' + id;
    }
  
    console.log(this.temp);
  
    return this.__http.get(this.temp)
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
  events(id): Observable<any> {
    // this.temp = this.baseurl + 'events';
    this.temp =this.baseurl+"events?user_id="+id
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_event_pagination(limit=null,id=null, text = null, category = null, country = null): Observable<any> {
    
    if(id==null&&limit!=null)
      this.temp = this.baseurl + `getEventPagination?limit=${limit}`;
    else
      if(id!=null&&limit!=null)
        this.temp = this.baseurl + `getEventPagination?limit=${limit}&lastId=${id}`;
      else
        this.temp = this.baseurl + `getEventPagination`;

    if(text && text !=""){
      this.temp = this.temp + `&text=${text}`;
    }
    if(country && country !=""){
      this.temp = this.temp + `&country=${country}`;
    }
    if(category && category !=""){
      this.temp = this.temp + `&category=${category}`;
    }

    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_event_create(): Observable<any> {
    this.temp = this.baseurl + 'admin/events/create';
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  put_admin_events(id, data): Observable<any> {
    this.temp = this.baseurl + 'admin/updateEvent/' + id;
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }


  post_events(data): Observable<any> {
    this.temp = this.baseurl + 'admin/events';
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  post_teacher_search(data): Observable<any> {
    this.temp = this.baseurl + 'teachers/search';
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_places_availables(id: number): Observable<any> {
    this.temp = this.baseurl + 'places/availables?user_id=' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_tags(): Observable<any> {
    this.temp = this.baseurl + 'tags';
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  post_places_availables(data, id: Number): Observable<any> {
    this.temp = this.baseurl + 'places/availables?user_id=' + id;
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  post_admin_proposal(data): Observable<any> {
    this.temp = this.baseurl + 'admin/proposals';
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  // eventId=44;
  eventId;
  get_eventId() {
    return this.eventId;
  }
  set_eventId(element) {
    this.eventId = element;
  }

  dataBasic$ = new EventEmitter();
  place$ = new EventEmitter();

  get_currencies(): Observable<any> {
    this.temp = this.baseurl + 'currencies';
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_userstars_id(id): Observable<any> {
    this.temp = this.baseurl + 'user-stars/' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(()=>{
          return of(false)
        })
      );
  }

  get_schedule(id): Observable<any>{
    this.temp = this.baseurl + 'schedule/' + id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  post_admin_reservation(data): Observable<any> {
    this.temp = this.baseurl + 'admin/reservation';
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_admin_eventSchedules(id){
    this.temp = this.baseurl + 'admin/eventSchedules/'+ id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_admin_dataToCancelReservation(id){
    this.temp = this.baseurl + 'admin/dataToCancelReservation?event_id='+ id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_admin_CancelReservation(id){
    this.temp = this.baseurl + 'admin/cancelReservation/'+id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_admin_PublishEvent(id){
    this.temp = this.baseurl + 'admin/publishEvent/'+id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  post_admin_publishEvent(data:any ,id:number): Observable<any> {
    this.temp = this.baseurl + 'admin/publishEvent/'+id;
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  get_admin_dataToCancelOffer(id){
    this.temp = this.baseurl + 'admin/dataToCancelOffer?event_id='+id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  post_admin_cancelOffer(data:any){
    this.temp = this.baseurl + 'admin/cancelOffer';
    return this.__http.post(this.temp, data )
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }
  post_admin_statusEvent(id:number, data:any){
    this.temp = this.baseurl + 'admin/statusEvent/'+id;
    return this.__http.post(this.temp, data )
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_admin_courseSchedule(id:number){
    this.temp = this.baseurl + 'admin/courseSchedules/'+id;
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }
}
