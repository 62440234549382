import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventsService } from '../../../../services/events.service'
import { ActivatedRoute, Params, Router } from '@angular/router';//preguntar
import { Subscription } from 'rxjs';
import { GeneralService } from '../../../../services/general.service';
import { ModalPublishComponent } from 'src/app/dashboard/shared/components/modal-publish/modal-publish.component';
import { MatDialog } from '@angular/material/dialog';
import { retry } from 'rxjs/operators';




@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.scss']
})
export class PublishComponent implements OnInit, OnDestroy {

  constructor(private EventsService:EventsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private GeneralService:GeneralService,
    private _router: Router,
    public dialog: MatDialog,

    ) { }

  currency;
  event;
  levels;
  teachers;
  form: FormGroup;
  id;
  load=false;
  image;


  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  files;
  flagImage=false;
  event_data;
  urls:string = '';


  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
      console.log("---page:id", this.id);
    });

    this.msgSuscription = this.GeneralService.msg$.subscribe(data => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe(data => {
      this.typeMsg = data;
    });

    this.form = this.fb.group({
      visible:['', Validators.required],
      currency: ['', Validators.required],
      level: ['', Validators.required],
      price: ['', Validators.required],
      age: ['', Validators.required],
      capacity : ['', Validators.required],
      evaluate : [false , Validators.required],
      content: ['', Validators.required],
      url: ['', Validators.required],
    });
    this.serviceLoadData();
  }

  serviceLoadData(){
    // cambiar a this.id
    this.EventsService.get_admin_PublishEvent(this.id).subscribe((res:any) => {
      console.log("get_admin_PublishEvent", res);
      this.currency=res.currency.original.currency;
      this.event=res.event[0];
      this.levels=res.levels;
      this.teachers=res.event_teacher;
      this.image=res.event_images;
      this.event_data=res.event_data
      this.loadInfo();

      this.load=true;
    }, err => {
      console.log("ERRor get_admin_PublishEvent", err);
    });
  }
  getImages(images) {
    console.log(images);
    this.image = images;
  }

  onSaveService(){
    if (this.form.status == "VALID") {
      let data;
      data={
        "visibility": this.form.get("visible").value,
        "currency_id": this.form.get("currency").value,
        "price": this.form.get("price").value,
        "level_id": this.form.get("level").value,
        "age": this.form.get("age").value,
        "capacity": this.form.get("capacity").value,
        "evaluated": (this.form.get("evaluate").value==true)? 1 : 0,
        "content": this.form.get("content").value,
        "urls": this.urls
      }
      console.log("data a mariana servicio post_admin_publishEvent: ", data);

      this.EventsService.post_admin_publishEvent(data, this.id).subscribe((res:any) => {
        if(this.flagImage==true){
          this.serviceImage();
        }
        console.log("post_admin_publishEvent", res);
        if(res.message_return.ERROR==false){
          this.openDialogPublish(res);
          // this._router.navigateByUrl("/dashboard/events");
        }
      }, err => {
        console.log("ERRor post_admin_publishEvent", err);
        this.GeneralService.typeMsg$.emit("error");
        this.GeneralService.msg$.emit("Error en servidor");
      });
    }else{
      this.GeneralService.typeMsg$.emit("error");
      this.GeneralService.msg$.emit("Formulario incompleto");
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).markAsDirty();
        this.form.get(key).markAsTouched();
      });
    }
  }

  serviceImage(){
    this.files=this.GeneralService.get_carouselFile();
    this.GeneralService.onUploadMultiImageEvents(this.files, this.id).pipe(retry(3)).subscribe()
  }

  flagIma(){
    console.log("activa flagImage");
    this.flagImage=true;
  }

  loadInfo(){
    this.form.reset({
      visible:this.event_data.visibility,
      currency:this.event_data.currency_id ,
      level:this.event.level_id,
      price:this.event_data.price ,
      age:this.event_data.age ,
      capacity :this.event_data.capacity ,
      evaluate :(this.event_data.approved==1)? true:false,
      content:this.event_data.content ,
      url:this.event_data.urls,
    })
    this.urls=this.event_data.urls;
  }


  @Input() SendUrl: string = "";
  onKey($event){
    this.urls = $event.target.value;
    console.log("onKey", this.urls);
  }

  openDialogPublish(element) {
    const dialogRef = this.dialog.open(ModalPublishComponent, {
      height: 'auto',
      width: 'auto',
      data: { element },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log("result", result);
      if (result != undefined) {
          this._router.navigateByUrl('/dashboard/events');

      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSuscription;
    this.typeMsgSuscription
  }
}


// get invalidName() {
//   return this.firstFormGroup.get('nameEvent').invalid && this.firstFormGroup.get('nameEvent').touched
// }
