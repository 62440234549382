import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-payments',
  templateUrl: './my-payments.component.html',
  styleUrls: ['./my-payments.component.scss']
})
export class MyPaymentsComponent implements OnInit {
  displayedColumns: string[] = ['fecha', 'referencia', 'metodo', 'monto','categoria','detalle'];
  dataSource = ELEMENT_DATA;
  constructor() { }

  ngOnInit(): void {
  }

}
export interface PeriodicElement {
  fecha: string;
  referencia: string;
  metodo: string;
  monto: string;
  categoria: string;
  detalle:string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {fecha: "00/00/000", referencia: '00000', metodo: "/assets/images/paypal.png", monto: '$30',categoria:"lugar",detalle:"pago recibo alquiler #1234"},
  {fecha: "00/00/000", referencia: '00000', metodo: "/assets/images/paypal.png", monto: '$30',categoria:"lugar",detalle:"pago recibo alquiler #1234"},
  {fecha: "00/00/000", referencia: '00000', metodo: "/assets/images/paypal.png", monto: '$30',categoria:"lugar",detalle:"pago recibo alquiler #1234"},
  {fecha: "00/00/000", referencia: '00000', metodo: "/assets/images/paypal.png", monto: '$30',categoria:"lugar",detalle:"pago recibo alquiler #1234"},
  {fecha: "00/00/000", referencia: '00000', metodo: "/assets/images/paypal.png", monto: '$30',categoria:"lugar",detalle:"pago recibo alquiler #1234"},
  {fecha: "00/00/000", referencia: '00000', metodo: "/assets/images/paypal.png", monto: '$30',categoria:"lugar",detalle:"pago recibo alquiler #1234"},
  
];