import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EventsService } from '../../../../services/events.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  displayedColumns: string[] = ['day', 'start_time', 'end_time'];
  dataSource;

  detail = {
    main_image: '',
    title: '',
    category: '',
    place: '',
    capacity: 0,
    availability: 0,
    description: '',
    end_date: '2021-01-01',
    end_hour: '02:00:00',
    price: 0.01,
    start_date: '2021-01-01',
    start_hour: '02:00:00',
    tags: '',
    urls: '',
    id_event: -1,
    city: '',
    country: '',
    state: '',
    age: '',
    approved: -1,
    level: '',
    content: '',
    currency: '',
    visibility: 0,
  };
  baseurl = '';
  event_schedule = [];
  teacher = [];
  event_content = [];
  youtube = '';
  id;
  chips: string[];
  images;
  user;
  url: string = '';
  urls: string = '';
  @Input() SendUrl: string = '';
  @Input() data;

  constructor(
    private EventsService: EventsService,
    private route: ActivatedRoute
  ) {
    this.baseurl = environment.url_server;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
      console.log('---id', this.id);
      this.EventsService.get_adminEvents(this.id).subscribe(
        (res) => {
          console.log('-----res(detalle):', res);

          const images = JSON.parse(res.event.images);
          const nuevos = images?.map((img) => {
            img = this.baseurl.replace('api/', '') + img;
            console.log(img);
            return img;
          });

          this.user = JSON.parse(localStorage.getItem('userData'));

          this.images = nuevos;
          this.detail.main_image = res.event.main_image;
          this.detail.title = res.event.name;
          this.detail.category = res.event.category;
          this.detail.place = res.event.place;
          this.detail.capacity = res.event.capacity;
          this.detail.availability = res.event.availability;
          this.detail.description = res.event.description;
          this.detail.end_date = res.event.end_date;
          this.detail.end_hour = res.event.end_hour;
          this.detail.price = res.event.price;
          this.detail.start_date = res.event.start_date;
          this.detail.start_hour = res.event.start_hour;
          this.detail.tags = res.event.tags;
          this.detail.urls = res.event.urls;
          this.detail.id_event = res.event.id_event;
          this.detail.city = res.event.city;
          this.detail.country = res.event.country;
          this.detail.state = res.event.state;
          this.detail.age = res.event.age;
          this.detail.approved = res.event.approved;
          this.detail.level = res.event.level;
          this.detail.content = res.event.content;
          this.detail.currency = res.event.currency;
          this.detail.visibility = res.event.visibility;
          this.urls = this.detail.urls;
          this.youtube = this.detail.urls;

          // console.log('res(detalle) guardado:', this.detail);
          for (let i = 0; i < res.event_content.length; i++) {
            this.event_content.push({
              objetive: res.event_content[i].objective,
              content: res.event_content[i].content,
            });
          }
          console.log('event_content:', this.event_content);

          this.event_schedule = res.schedule;
          this.dataSource = this.event_schedule;
          console.log('event_schedule:', this.event_schedule);

          if (res.event_teacher != null) {
            res.event_teacher.forEach((element) => {
              this.teacher.push({
                avatar: '', //element.avatar,
                last_name: element.last_name,
                name: element.name,
                skill: element.tags?.split(','),
                title: element.title,
              });
            });
          }
          console.log('teacher', this.teacher);
          if (this.detail.tags != null) {
            this.chips = this.detail.tags.split(',');
          } else {
            this.chips = null;
          }
          console.log('chips', this.chips);
          console.log('title', this.detail);
        },
        (err) => {
          console.log('ERRor ', err);
        }
      );
    });
  }
  onKey($event) {
    this.urls = $event.target.value;
    console.log('onKey', this.urls);
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.data.currentValue, 'desc');
    if (changes.data.currentValue != null) {
      this.url = changes.data.currentValue.result.youtube_url;
    }
  }
  errorHandler(event) {
    console.debug(event);
    event.target.src = "./assets/images/person-default.svg";
 }

}

const week = [
  { num: 0, name: 'Domingo' },
  { num: 1, name: 'Lunes' },
  { num: 2, name: 'Martes' },
  { num: 3, name: 'Miercoles' },
  { num: 4, name: 'Jueves' },
  { num: 5, name: 'Viernes' },
  { num: 6, name: 'Sabado' },
];
