<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
        <app-header></app-header>
        <app-sub-header [screen]="'Proceso de Pago'"></app-sub-header>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" class="container-card">
            <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                    <p fxFlex class="style-space">Confirma tu compra</p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="header-subtitle-payment">
                    <p fxFlex class="style-space-1">Puedes pagar con:</p>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center" class="card-content mc-price">
                    <button mat-button class="example-icon favorite-icon style-login ingresar btn-card" (click)="openPayment(typePaymentCard)">
                        Crédito / Débito
                    </button>
                    <button mat-button class="example-icon favorite-icon style-login btn-paypal" (click)="openPayment(typePaymentPaypal)"></button>
                    <p fxFlex class="prices">Total: {{totalString}}</p>
                </div>
                <mat-radio-group *ngIf="typePayment == typePaymentCard;else paypal" [(ngModel)]="selectCard" aria-labelledby="example-radio-group-label" class="example-radio-group" (change)="radioChange($event)">
                    <div fxLayout="row" fxLayout.lt-md="column" *ngFor="let item of listCards" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="item-card">
                        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="25">
                            <mat-radio-button [value]="item.value"></mat-radio-button>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="25">
                            <img src="../../../assets/images/{{item.img}}" [alt]="item.value">
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center stretch" fxFlex="25">
                            <p class="description-card">
                                {{item.card}}<br />
                                <span class="name">{{item.name}}</span> <br />
                                {{item.date}} <span class="code-seg">{{item.codeSeg}}</span> <br />
                            </p>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center stretch" fxFlex="25">
                            <p class="country-card">
                                <span class="country">{{item.country}}</span> <br />
                                {{item.code}} <br />
                            </p>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutGap.gt-sm="20px" class="item-card">
                        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="25" class="check-add-card" style="margin-top: 55px !important">
                            <mat-radio-button [value]="typeAddCard"></mat-radio-button>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="25" class="img-add-card" style="margin-top: 26px !important">
                            <img src="../../../assets/images/card-mas.png" alt="Agregar Tarjeta" style="margin-left: -40px;">
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start none" fxFlex="50" class="form-add">
                            <div *ngIf="showAddCard" class="add-card">
                              <h3>Informacion de la Tarjeta</h3>
                              <p>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Número</mat-label>
                                    <input matInput placeholder="" value="" name="nro_card" [formControl]="nroCardFormControl" [errorStateMatcher]="matcher">
                                    <mat-error *ngIf="nroCardFormControl.hasError('required') || nroCardFormControl.hasError('pattern')">
                                        {{ nroCardFormControl.hasError('required') ? errors.nroCardFormControl.msg.required :  errors.nroCardFormControl.msg.pattern}}
                                    </mat-error>
                                </mat-form-field>
                              </p>
                              <p>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Nombre</mat-label>
                                    <input matInput placeholder="" value="" name="name_card" [formControl]="nameCardFormControl" [errorStateMatcher]="matcher" maxlength="19">
                                    <mat-error *ngIf="nameCardFormControl.hasError('required') || nameCardFormControl.hasError('pattern')">
                                        {{ nameCardFormControl.hasError('required') ? errors.nameCardFormControl.msg.required :  errors.nameCardFormControl.msg.pattern}}
                                    </mat-error>
                                </mat-form-field>
                              </p>
                              <div>
                                  <div fxLayout="row" fxFlex="50">
                                    <mat-form-field>
                                        <mat-label>Fecha de caducidad</mat-label>
                                        <input matInput placeholder="MM/AA" value="" name="date_exp" [formControl]="dateExpFormControl" [errorStateMatcher]="matcher" maxlength="5">
                                        <mat-error *ngIf="dateExpFormControl.hasError('required') || dateExpFormControl.hasError('pattern')">
                                            {{ dateExpFormControl.hasError('required') ? errors.dateExpFormControl.msg.required :  errors.dateExpFormControl.msg.pattern}}
                                        </mat-error>
                                    </mat-form-field>
                                  </div>
                                  <div fxLayout="row" fxFlex="50">
                                    <mat-form-field>
                                        <mat-label>Código de seguridad</mat-label>
                                        <input matInput placeholder="CVC" value="" name="cvc" [formControl]="cvcFormControl" [errorStateMatcher]="matcher" maxlength="3">
                                        <mat-error *ngIf="cvcFormControl.hasError('required') || cvcFormControl.hasError('pattern')">
                                            {{ cvcFormControl.hasError('required') ? errors.cvcFormControl.msg.required :  errors.cvcFormControl.msg.pattern}}
                                        </mat-error>
                                    </mat-form-field>
                                  </div>
                              </div>
                              <p style="margin-top: 1rem">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Dirección de facturación</mat-label>
                                  <mat-select name="address" [formControl]="addressFormControl" [errorStateMatcher]="matcher" [(value)]="selectAddress" (selectionChange)="selectChange($event)">
                                    <mat-option value="" disabled>Seleccione</mat-option>
                                    <mat-option *ngFor="let opt of listAddress" [value]="opt.value">{{opt.text}}</mat-option>
                                    <mat-option [value]="typeAddNewAddress">Nueva</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="addressFormControl.hasError('required') || addressFormControl.hasError('pattern')">
                                    {{ addressFormControl.hasError('required') ? errors.addressFormControl.msg.required :  errors.cvcFormControl.msg.pattern}}
                                  </mat-error>
                                </mat-form-field>
                              </p>
                              <div *ngIf="showAddAddress">
                                  <p>
                                    <mat-form-field class="example-full-width">
                                      <mat-label>País</mat-label>
                                      <mat-select name="country" [formControl]="countryFormControl" [errorStateMatcher]="matcher" [(value)]="selectCountry" (selectionChange)="selectedState()">
                                        <mat-option value="" disabled>Seleccione</mat-option>
                                        <mat-option *ngFor="let opt of listCountry" [value]="opt.value">{{opt.text}}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="countryFormControl.hasError('required')">
                                        {{ errors.countryFormControl.msg.required }}
                                      </mat-error>
                                    </mat-form-field> 
                                  </p>
                                  <div>
                                      <div fxLayout="row" fxFlex="50">
                                        <mat-form-field>
                                          <mat-label>Ciudad</mat-label>
                                          <mat-select name="city" [formControl]="cityFormControl" [errorStateMatcher]="matcher" [(value)]="selectCity">
                                            <mat-option value="" disabled>Seleccione</mat-option>
                                            <mat-option *ngFor="let opt of listCity" [value]="opt.value">{{opt.text}}</mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="cityFormControl.hasError('required')">
                                            {{ errors.cityFormControl.msg.required}}
                                          </mat-error>
                                        </mat-form-field> 
                                      </div>
                                      <div fxLayout="row" fxFlex="50">
                                        <mat-form-field>
                                          <mat-label>Estado</mat-label>
                                          <mat-select name="state" [formControl]="stateFormControl" [errorStateMatcher]="matcher" [(value)]="selectState" (selectionChange)="selectedCity()">
                                            <mat-option value="" disabled>Seleccione</mat-option>
                                            <mat-option *ngFor="let opt of listState" [value]="opt.value">{{opt.text}}</mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="stateFormControl.hasError('required')">
                                            {{ errors.stateFormControl.msg.required}}
                                          </mat-error>
                                        </mat-form-field> 
                                      </div>
                                  </div>
                                  <p>
                                    <mat-form-field>
                                        <mat-label>Código Postal</mat-label>
                                        <input matInput placeholder="0000" value="" name="code_postal" [formControl]="codeFormControl" [errorStateMatcher]="matcher">
                                        <mat-error *ngIf="codeFormControl.hasError('required') || codeFormControl.hasError('pattern')">
                                            {{ codeFormControl.hasError('required') ? errors.codeFormControl.msg.required :  errors.codeFormControl.msg.pattern}}
                                        </mat-error>
                                    </mat-form-field>
                                  </p>
                                  <p>
                                    <mat-form-field class="example-full-width">
                                      <mat-label>Dirección</mat-label>
                                      <textarea matInput name="address_lg" [formControl]="addressLgFormControl" [errorStateMatcher]="matcher"></textarea>
                                      <mat-error *ngIf="addressLgFormControl.hasError('required')">
                                        {{ errors.addressLgFormControl.msg.required }}
                                      </mat-error>
                                    </mat-form-field>
                                  </p>
                              </div>
                              <p style="margin-top: 1rem">
                                  <mat-checkbox [(ngModel)]="saveAccount" name="saveAccount" class="example-margin">Guardar esta tarjeta</mat-checkbox>
                              </p>
                            </div>
                        </div>
                    </div>
                </mat-radio-group>
                <ng-template #paypal>
                    <mat-radio-group [(ngModel)]="selectPaypal" aria-labelledby="example-radio-group-label" class="example-radio-group" (change)="radioChangePaypal($event)">
                        <div fxLayout="row" fxLayout.lt-md="column" *ngFor="let item of listPaypal" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="item-card">
                            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="25">
                                <mat-radio-button [value]="item.value"></mat-radio-button>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
                                <img src="../../../assets/images/{{item.img}}" alt="item.value">
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start stretch" fxFlex="55">
                                <p class="description-card" style="margin: 30px">
                                    Iniciaste sesión con<br />
                                    <span class="name">{{item.email}}</span>
                                </p>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between start" fxLayoutGap.gt-sm="20px" class="item-card">
                            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="25" class="check-add-card">
                                <mat-radio-button [value]="typeAddPaypal"></mat-radio-button>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start start" fxFlex="75">
                                <div class="cont-add-paypal">
                                    <div>
                                        <p class="description-card subtitle">
                                            Para pagar con otra cuenta inicia sesión en Paypal 
                                        </p>
                                    </div>
                                    <div>
                                        <a mat-button href="#" target="_blank" style="padding: 0;">
                                            <img src="../../../assets/images/btn_paypal.png" alt="Enlace Paypal">
                                        </a>
                                    </div>
                                    <div>
                                        <mat-checkbox [(ngModel)]="saveAccount" class="example-margin">Guardar esta cuenta.</mat-checkbox>
                                    </div>
                         
                                </div>
                            </div>
                        </div>
                    </mat-radio-group>
                </ng-template>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">
                    <button mat-button class="example-icon favorite-icon style-login registrate btn-card" style="margin: 56px auto 10px;" (click)="paymentCompleted()" id="completePaymentBtn">
                        Completar Pago
                    </button>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" style="margin: 45px 0;">
                    <mat-checkbox [(ngModel)]="termAndCond" class="example-margin" style="margin: 0 auto"><span class="st-cust">
                        Acepto los <span class="highlight">términos y condiciones de Sogcial</span></span>
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</mat-drawer-container>