<mat-card class="card">
  <div class="detalles">
    <div class="titulo"> <h2>Detalles del Teacher</h2></div>

    <div class="contenido">
      <p>
        {{ perfil?.bio }}
      </p>
    </div>
    <mat-tab-group>
      <mat-tab label="Cursos">
        <div style="height: 500px">
          <div *ngFor="let curso of perfil?.courses" class="listacurso">
            <div class="foto">
              <img src="{{curso.main_image}}" />
            </div>
            <div class="datos">
              <div> Nombre: {{ curso.courseName }}</div>
              <div> Categoria: {{ curso.category }}</div>
              <div> Nivel: {{ curso.level }} | Lugar: {{ curso.place }}</div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Eventos">
        <div style="height: 500px">
          <div *ngFor="let eventos of perfil?.events" class="listacurso">
            <div class="foto">
              <img src="{{eventos.main_image}}" />
            </div>
            <div class="datos">
              <div> Nombre: {{ eventos.name }}</div>
              <div> Categoria: {{ eventos.category }}</div>
              <div> Tags: {{ eventos.tags }} | Lugar: {{ eventos.place }}</div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="perfil">
    <div fxLayout="row" fxLayoutAlign="center" class="boton">
      <mat-icon class="btn-icon pointer"> chevron_left</mat-icon>
      <button  (click)=" backClicked()" class="btn btn-border">VOLVER</button>
    </div>

    <div class="imagen">
      <img src="http://placekitten.com/g/300/300" />
    </div>

    <div class="name">
      <h2> {{perfil?.name}} {{perfil?.last_name}} </h2>
      <h3>{{perfil?.title}}</h3>
    </div>

    <div *ngFor=" let tags of perfil?.skills">
      <ul class="skills">
       <li> {{tags.name}}</li>
      </ul>
    </div>
    <!-- <div class="contacto">
      <a href="mailto:{{perfil?.email}}" class="btn btn-orange-auto">CONTACTAR</a>
    </div> -->

    <div class="redes">
      <div class="sociales">
        <mat-icon>
          <img src="assets/images/tik-logo.svg" class="icon-default" />
        </mat-icon>
        <div>
          <span> {{perfil?.youtube_url}}</span>
        </div>
      </div>

      <div class="sociales">
        <mat-icon>
          <img src="assets/images/ins-logo.svg" class="icon-default" />
        </mat-icon>
        <div>
          <span>{{perfil?.instagram_url}}</span>
        </div>
      </div>

      <div class="sociales">
        <mat-icon>
          <img src="assets/images/tw-logo.svg" class="icon-default" />
        </mat-icon>
        <div>
          <span>{{perfil?.twitter_url}}</span>
        </div>
      </div>
      <div class="sociales">
        <mat-icon>
          <img src="assets/images/fb-logo.svg" class="icon-default" />
        </mat-icon>
        <div>
          <span>{{perfil?.facebook_url}}</span>
        </div>
      </div>
    </div>
  </div>
</mat-card>


