import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  Inject,
  OnDestroy,
} from '@angular/core';
import { GeneralService } from '../../../../services/general.service';
import { PlaceService } from '../../../../services/place.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BasicDataComponent } from './basic-data/basic-data.component';
import { DescriptionsComponent } from './descriptions/descriptions.component';
import { GalleryComponent } from './gallery/gallery.component';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';

export interface DialogData {
  city_id: string;
  country_id: string;
  county_id: string;
  email: string;
  name: string;
  parish_id: string;
  place_category: string;
  state_id: string;
  youtube_url: string;
}
interface Category {
  id: number;
  name: string;
  parent_id: number;
  created_at: string;
  updated_at: string;
}
interface Country {
  id: number;
  name: string;
  created_at: string;
  iso: string;
  iso3: string;
  nicename: string;
  numcode: number;
  phonecode: number;
  slug: string;
  updated_at: string;
}
interface State {
  id: number;
  state_code: string;
  state_name: string;
  country_id: number;
  slug: string;
}
interface City {
  id: number;
  city: string;
  county: string;
  latitude: string;
  longitude: string;
  state: string;
  country: string;
  created_at: string;
  updated_at: string;
}
interface County {
  id: number;
  municipality: string;
  slug: string;
  city: string;
  state: string;
  country: string;
}
interface Parish {
  id: number;
  municipality_id: number;
  parish: string;
  slug: string;
  created_at: string;
  updated_at: string;
}
interface Place {
  name: string;
  user_id: number;
  place_category: number;
  description: string;
  main_image: string;
  images: string;
  address: string;
  slug: string;
  country: string;
  phone: string;
  email: string;
  web: string;
  latitude: string;
  longitude: string;
  status: string;
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  start_time_week: string;
  end_time_week: string;
  start_time_weekend: string;
  end_time_weekend: string;
  wifi: string;
  phone_2: string;
  slogan: string;
  county_id: number;
  state_id: number;
  city_id: number;
  parish_id: number;
  youtube_url: string;
}
@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss'],
})
export class AddEditComponent implements AfterViewInit, OnDestroy {
  file;
  files;
  youtubeVideo: string = '';
  country_id: number;
  state_id: number;
  city_id: number;
  county_id: number;
  parish_id: number;
  category_id: number;
  subCategory_id: string;
  categories: Category[];
  subCategories: Category[];
  countries: Country[];
  states: State[];
  cities: City[];
  counties: County[];
  parishes: Parish[];
  place: Place;
  bandCountry: boolean = false;
  bandCategory: boolean = false;
  bandSubCategory: boolean = false;
  bandState: boolean = false;
  bandCity: boolean = false;
  bandCounty: boolean = false;
  bandParish: boolean = false;
  name: string;
  phone: string;
  email: string;
  zip_code: string;
  address: string;
  web: string;
  load: boolean = false;
  urlMainImage;
  formbuildSchedule;
showErrorMap= false;
  @Input() data;

  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  description: string;
  capacity: string;
  controlStepper = {
    step1: false,
    step2: false,
    step3: false,
  };

  userData;

  url: string;
  idUrl: number = null;
  showPlace = null;

  categorySelected: number;
  subCategorySelected: number;

  @ViewChild(BasicDataComponent) basicData;
  @ViewChild(DescriptionsComponent) descriptions;
  @ViewChild(GalleryComponent) gallery;
  @ViewChild('stepper') stepper: MatStepper;
  step1 = new FormControl('', [Validators.required]);
  step1Error: string;

  error: boolean = false;
  dataError;
  hiddenUpload = false;

  //=======
  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  constructor(
    private GeneralService: GeneralService,
    private PlaceService: PlaceService,
    private router: ActivatedRoute,
    private _router: Router,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}
  getErrorMessage() {
    const errorInput = 'No debe quedar en blanco';
    const errorEmail = 'Formato incorrecto de email: example@you.com';
    const errorSelect = 'No debe quedar sin seleccionar';

    if (this.step1.hasError('required')) {
      this.step1Error = errorSelect;
      return this.step1Error;
    }
  }
  openDialog(data) {
    this.dialog.open(DialogDataExampleDialog, {
      data: {
        city_id: data.city_id != null ? data.city_id[0] : '',
        country_id: data.country_id != null ? data.country_id : '',
        county_id: data.county_id != null ? data.county_id[0] : '',
        email: data.email != null ? data.email[0] : '',
        name: data.name != null ? data.name[0] : '',
        parish_id: data.parish_id != null ? data.parish_id[0] : '',
        place_category:
          data.place_category != null ? data.place_category[0] : '',
        state_id: data.state_id != null ? data.state_id[0] : '',
        youtube_url: data.youtube_url != null ? data.youtube_url[0] : '',
      },
    });
  }
  ngOnInit(): void {
    this.msgSuscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });

    this.userData = JSON.parse(localStorage.getItem('userData'));
    // console.log(this.userData.id)
    this.idUrl = this.router.snapshot.params.id;
    if (this.idUrl != null) {
      this.PlaceService.get_placesShow(this.idUrl).subscribe((res) => {
        res;
        if (res.message_return.ERROR == false) {
          this.showPlace = res;
        }
      });
    } else {
      this.showPlace = {};
    }
  }
  ngAfterViewInit(): void {}

  onGetStep1() {
    return new Promise((resolve, reject) => {
      this.category_id = this.basicData.category_id;
      this.subCategory_id = this.basicData.subCategory_id;
      this.name = this.basicData.names;
      this.phone = this.basicData.phone;
      this.email = this.basicData.email;
      this.country_id = this.basicData.country_id;
      this.state_id = this.basicData.state_id;
      this.city_id = this.basicData.city_id;
      this.county_id = this.basicData.county_id;
      this.parish_id = this.basicData.parish_id;
      this.zip_code = this.basicData.zip_code;
      this.address = this.basicData.address;
      this.web = this.basicData.web;

      this.hiddenUpload = true;
      resolve(true);
    });

    // console.log(this.basicData,this.basicData.subCategory_id)
  }

  goStep3(){
    this.controlStepper.step2 = true;
      setTimeout(() => {
        this.stepper.next();
      }, 0);
  }

  goStep2() {

if(this.basicData.arrayMarker===null){
this.showErrorMap=true;
}

    if (
      this.basicData.arrayMarker!==null&&
      this.basicData.country_id !== undefined &&
      this.basicData.state_id !== undefined &&
      this.basicData.city_id !== undefined &&
      this.basicData.county_id !== undefined &&
      this.basicData.category_id !== undefined &&
      this.basicData.names !== undefined &&
      this.basicData.address !== undefined
    ) {
      this.controlStepper.step1 = true;
      setTimeout(() => {
        this.stepper.next();
      }, 0);
    }
  }

  onGetStep2() {
    return new Promise((resolve, reject) => {
      this.description = this.descriptions.description;
      this.facebook = this.descriptions.facebook;
      this.twitter = this.descriptions.twitter;
      this.instagram = this.descriptions.instagram;
      this.youtube = this.descriptions.youtube;
      this.capacity = this.descriptions.capacity;
      //this. = this.descriptions.capacity;
      //this.capacity = this.descriptions.capacity;

      this.hiddenUpload = false;
      resolve(true);
    });

    // console.log(this.description)
  }
  only(step) {
    if (step == 1 || step == 3) {
      this.hiddenUpload = false;
    } else {
      this.hiddenUpload = true;
    }
  }
  onGetStep3() {
    Promise.all([this.onGetStep1(), this.onGetStep2()]).then((results) => {
      this.controlStepper.step3 = true;

      this.save();
    });
  }

  save() {
    // this.hiddenUpload=false;
    let place;
    this.url = this.gallery.url;

    this.load = true;
    // place = { este es el place que tenia zapi
    //   "name": this.name,
    //   "user_id": this.userData.id,
    //   "place_category": this.subCategory_id==null || this.subCategory_id==""?this.category_id:this.subCategory_id,
    //   "description": this.description,
    //   "main_image": "string",
    //   "images": "string",
    //   "address": this.address,
    //   "country_id": this.country_id,
    //   "phone": this.phone,
    //   "email": this.email,
    //   "web": this.web,
    //   "latitude": "0",
    //   "longitude": "0",
    //   "status": "1",
    //   "facebook": this.facebook,
    //   "twitter": this.twitter,
    //   "instagram": this.instagram,
    //   "youtube": this.youtube,
    //   "slug": ".",
    //   "county_id": this.county_id,
    //   "state_id": this.state_id,
    //   "city_id": this.city_id,
    //   "parish_id": this.parish_id,
    //   "youtube_url": this.url,
    //   "zip_code": this.zip_code
    // };

    // let stat=(this.descriptions.availability==true)? 1 : 0;
    this.hiddenUpload = true;

    this.formbuildSchedule =
      this.descriptions.schedule == null ? null : this.descriptions.schedule;
    let scheduleFomat = [];

    if (
      this.formbuildSchedule != null &&
      this.formbuildSchedule.value.typeSchedule == 3
    ) {
      console.log('semana:', this.descriptions.arraybool);
      for (let j = 0; j < 7; j++) {
        if (this.descriptions.arraybool[j] == true) {
          let time = [];
          for (
            let i = 0;
            i < this.formbuildSchedule.value.schedule[j].hour.length;
            i++
          ) {
            time.push({
              start_time:
                this.formbuildSchedule.value.schedule[j].hour[i].start,
              end_time: this.formbuildSchedule.value.schedule[j].hour[i].end,
            });
          }
          scheduleFomat.push({
            dayofweek: j,
            time: time,
          });
        }
      }

      console.log('scheduleFomat:', scheduleFomat);
    } else {
      scheduleFomat = null;
    }
    place = {
      name: this.name, //
      user_id: this.userData.id,
      place_category:
        this.subCategory_id == null || this.subCategory_id == ''
          ? this.category_id
          : this.subCategory_id, //
      description: this.description, //
      address: this.address, //
      slug: '.',
      country_id: this.country_id, //
      phone: this.phone,
      email: this.email,
      web: this.web,
      latitude: this.basicData.arrayMarker?.lat.toString(),
      longitude: this.basicData.arrayMarker?.lng.toString(),
      status:
        this.formbuildSchedule != null && this.descriptions.availability == true
          ? 1
          : 0,
      amount:
        this.formbuildSchedule != null && this.descriptions.availability == true
          ? this.descriptions.base_price
          : '',
      capacity: this.descriptions.capacity,
      // "min_hour":(this.formbuildSchedule!=null  &&  this.descriptions.availability==true)? this.descriptions.min_time : "",
      facebook: this.facebook,
      twitter: this.twitter,
      instagram: this.instagram,
      youtube: this.youtube,
      all_day:
        this.formbuildSchedule != null &&
        this.formbuildSchedule.value.typeSchedule == 1
          ? 1
          : 0,
      start_time_week:
        this.formbuildSchedule != null &&
        this.formbuildSchedule.value.typeSchedule == 2
          ? this.formbuildSchedule.value.allDayStart
          : '', //traer
      end_time_week:
        this.formbuildSchedule != null &&
        this.formbuildSchedule.value.typeSchedule == 2
          ? this.formbuildSchedule.value.allDayEnd
          : '', //traer
      wifi: this.descriptions.wifi == true ? 1 : 0,
      phone2: this.basicData.phone_2,
      slogan: 'string', //sin definir
      county_id: this.county_id,
      state_id: this.state_id, //
      city_id: this.city_id, //
      parish_id: this.parish_id,
      youtube_url: this.url,
      zip_code: this.zip_code,
      currency:
        this.formbuildSchedule != null && this.descriptions.availability == true
          ? this.descriptions.currency
          : '', //traer
      schedule: scheduleFomat, //pilas con shedule
    };
    console.log('step3 place_array:', place);

    if (this.idUrl == null) {
      this.PlaceService.post_adminplaces(place).subscribe(
        (res) => {
          this.urlMainImage = res.main_image;
          if (res.message_return.ERROR == false) {
            this.saveImages(res.place_id);
            this._router.navigateByUrl('dashboard/places');
            this.load = false;
          } else {
            // console.log("Error")
          }
        },
        (err) => {
          console.log(err);
          this.error = true;
          this.load = false;
          // this.openDialog(err.error.errors);
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit('Formulario incompleto');
          dataArray = err.error.errors;
        }
      );
    } else {
      this.PlaceService.post_adminplacesupdate(place, this.idUrl).subscribe(
        (res) => {
          console.log('holaaaaaaaaa', res);
          this.urlMainImage = res.main_image;
          this.saveImages(res.result.id);

          if (res.message_return.ERROR == false) {
            this._router.navigateByUrl('dashboard/places');
            this.load = false;
          } else {
            // console.log("Error")
          }
        },
        (err) => {
          // console.log(err.error.errors)
          this.error = true;
          this.load = false;
          // this.openDialog(err.error.errors);
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit('Formulario incompleto');
          dataArray = err.error.errors;
        }
      );
    }
  }

  saveImages(id) {
    this.files = this.GeneralService.get_carouselFile();
    this.file = this.GeneralService.getMainImages();
    this.GeneralService.uploadGalleryandMainImagesPlaces(
      this.files,
      id,
      this.file?.length > 0 ? this.file[0] : null
    ).subscribe((img) => {
      console.log(img);
      this.GeneralService.clearCarouselFile();
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSuscription;
    this.typeMsgSuscription;
  }
}
@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}

var dataArray: any;
