import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProposalService } from 'src/app/services/proposal.service';


@Component({
  selector: 'app-modal-decline',
  templateUrl: './modal-decline.component.html',
  styleUrls: ['./modal-decline.component.scss']
})
export class ModaldeclineComponent implements OnInit {
user;
idcourse;
idevent;
reason;
iscourse=false;
  constructor(
    private router: Router,
    private proposalService: ProposalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {

    this.router.routeReuseStrategy.shouldReuseRoute=()=>{
      return false;
    }
    console.log(this.data)
    this.user=JSON.parse(localStorage.getItem('userData'))
    this.idcourse=this.data.id_course
this.idevent=this.data.id_event


if(this.data.id_course){
  this.iscourse=true;
}

  }

  rejectproposal(){
    const request={
      reason:this.reason
    }

    if(this.iscourse){
      this.proposalService.post_rejectproposalcourse(this.idcourse,this.user.id, request).subscribe((data)=>{
        this.router.navigateByUrl(
                    '/dashboard/proposals'
                  );
      console.log("-----------",data)
this.dialog.closeAll();
          })
    }else{
      this.proposalService.post_rejectproposalevent(this.idevent,this.user.id, request).subscribe((data)=>{
        this.router.navigateByUrl(
                    '/dashboard/proposals'
                  );
      console.log("-----------",data)
this.dialog.closeAll();

          })
    }
  }
}
