import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EventsService } from '../../../../../services/events.service'


@Component({
  selector: 'app-modal-cancel-contract',
  templateUrl: './modal-cancel-contract.component.html',
  styleUrls: ['./modal-cancel-contract.component.scss']
})
export class ModalCancelContractComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private EventsService: EventsService) { }

  user=[];
  tags=[];

  ngOnInit(): void {
    this.data?.results?.forEach(element => {
      element?.user?.forEach(users => {
        this.user.push({
          'avatar': users.avatar,
          'tags': users.tags,
          'teacher_average_stars': users.teacher_average_stars,
          'title': users.title,
          'ubication': users.ubication,
          'user_id': users.user_id,
          'user_name': users.user_name,
          'check': false,
          'offer_id': element.offer.offer_id,
        });
      });
    });
    console.log("this.user", this.user);
    console.log("data on modal:1 ",this.data);
  }

  // serviceDataToCancelOffer(){
  //   console.log("proposal_id", this.data.element.proposal_id);
  //   let proposalId;
  //   proposalId=(this.data.element.proposal_id!=undefined)? this.data.element.proposal_id: -1;

  //   this.EventsService.get_admin_dataToCancelOffer(proposalId).subscribe((res:any) => {

  //     console.log("get_admin_dataToCancelOffer", res);
  //     this.offer=res.offer_data;
  //     this.user=res.user_data.original.user[0];
  //     console.log("offer: ", this.offer);
  //     console.log("user: ", this.user);
  //   }, err => {
  //     console.log("ERRor get_admin_dataToCancelOffer", err);
  //     if(err.status==404){
  //       console.log("error en servidor 1");
  //     }else{
  //       console.log("paso 1");
  //     }
  //   });
  // }

  onChangingCheck(item){
    // this.user.forEach(element => {
    //   if(element.user_id==item.user_id){
    //     element.check=!element.check;
    //     console.log("consiguio");
    //     return true;
    //   }
    // });
    // console.log("No consiguio")

    this.user[item].check=!(this.user[item].check);
    console.log(" consiguio",this.user);
  }
onSave=[];
  onSaving(){
    this.user.forEach(element => {
      (element.check==true)? this.onSave.push(element.offer_id) : null;
    });
    console.log("onSave", this.onSave);
  }
}
