
<form class="example-form">
    <mat-form-field class="example-full-width">
    <mat-label>Busca el usuario a agregar a esta conversacion</mat-label>
      <input type="text"
             placeholder=""
             aria-label="Number"
             matInput
             [formControl]="myControl"
             [matAutocomplete]="auto"
             class="example-full-width">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <mat-chip>hola</mat-chip>
