import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralService } from '../../../../../../services/general.service';
import { EventsService } from '../../../../../../services/events.service';
import { CourseDashboardService } from '../../../../../../services/course-dashboard.service';
import { ConfirmScheduleComponent } from './confirm-schedule/confirm-schedule.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-show-place',
  templateUrl: './show-place.component.html',
  styleUrls: ['./show-place.component.scss'],
})
export class ShowPlaceComponent implements OnInit, OnDestroy {
  rent;
  splitted;

  msg;
  typeMsg;
  showMsg;
  timeEvent;
  msgSubscription: Subscription;
  typeMsgSubscription: Subscription;
  showMsgSubscription: Subscription;
cupos:number | string=0;

  serviceButton = true;

  @Output() goToStep3: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private GeneralService: GeneralService,
    private EventsService: EventsService,
    private CourseDashboardService: CourseDashboardService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.msgSubscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSubscription = this.GeneralService.typeMsg$.subscribe(
      (data) => {
        this.typeMsg = data;
      }
    );

    this.showMsgSubscription = this.GeneralService.showMsg$.subscribe(
      (data) => {
        this.showMsg = data;
      }
    );

    console.log('data:::::::::::', this.data);
    this.splitted = this.data.element.Ubication.split(',');
  }

  setPlace(saltar) {
    this.serviceButton = false;
    let user;
    this.timeEvent = this.GeneralService.get_timeEvent();
    user = JSON.parse(localStorage.getItem('userData'));
    console.log('this.timeEvent:', this.timeEvent);
    let schedule = [];
    this.timeEvent.forEach((element) => {
      schedule.push({
        start_date: element.startStr.split('T')[0],
        end_date: element.endStr.split('T')[0],
        all_day: 0,
        description: '',
        start_time: element.startStr.split('T')[1].split(':')[0] + ':00',
        end_time: element.endStr.split('T')[1].split(':')[0] + ':00',
      });
    });
    let dataService;
    if (this.data.course === false) {
      console.log('es evento');
      dataService = {
        place_id: this.data.element.id,
        course_id: null,
        event_id: this.EventsService.get_eventId(),
        sender_id: user.id,
        schedule: schedule,
        availability:this.cupos,
        skip:saltar

      };
    } else {
      console.log('es curso');
      dataService = {
        place_id: this.data.element.id,
        course_id: this.CourseDashboardService.get_courseId(),
        event_id: null,
        sender_id: user.id,
        schedule: schedule,
        availability:this.cupos,
        skip:saltar

      };
    }
    // let dataService = {
    //   "place_id": this.data.element.id,
    //   "course_id": null,
    //   "event_id": this.EventsService.get_eventId(),
    //   "sender_id": user.id,
    //   "schedule": schedule
    // };
    if (schedule.length > 0) {
      console.log('data a mariana setPlaceOwn:', dataService);

      this.EventsService.post_admin_reservation(dataService).subscribe(
        (res) => {
          console.log('RES post_admin_reservation::', res);
          if (res.message_return.CODE == 201) {
            this.GeneralService.typeMsg$.emit('success');
            this.GeneralService.msg$.emit(
              'Creada la planificacion de horario exitosamente'
            );
            this.serviceButton = true;
            if (saltar) {
              this.dialog.closeAll();
              if(this.data.course===false){
                this.router.navigateByUrl('/dashboard/events');

              }else{
                this.router.navigateByUrl('/dashboard/courses');;
              }


            } else {
              this.goToStep3.emit(true);
              if (this.data.showRent == false) {
                this.EventsService.place$.emit(this.data.element);
              } else {
                this.EventsService.place$.emit(null);
              }
            }
          }
        },
        (err) => {
          console.log('ERRor post_admin_reservation incorrecto', err);
          this.serviceButton = true;
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit(err.name);
        }
      );
    } else {
      this.GeneralService.typeMsg$.emit('error');
      this.GeneralService.msg$.emit('Debes seleccionar un horario');
      this.serviceButton = true;
    }
  }

  accept;


  openDialogConfirm(element, saltar = false) {
    if(this.cupos <= this.data.element.capacity){
      this.timeEvent = this.GeneralService.get_timeEvent();
      if (this.timeEvent.length > 0) {
        const dialogRef = this.dialog.open(ConfirmScheduleComponent, {
          height: 'auto',
          width: 'auto',
          data: { element, accept: (this.accept = false) },
        });

        dialogRef.afterClosed().subscribe((result) => {
          console.log('The dialog was closed', result);
          if (result == true) {
            this.setPlace(saltar);
          }
        });
      } else {
        this.GeneralService.typeMsg$.emit('error');
        this.GeneralService.msg$.emit('Debes seleccionar un horario');
      }
    }

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSubscription;
    this.typeMsgSubscription;
    this.showMsgSubscription;
  }
}
