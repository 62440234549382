<div style="padding: 25px 65px">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <p class="raleway-23-g" style="padding-top: 18px"><b>Publicación</b></p>
    <!-- <button [mat-dialog-close]="null" class="btn-close">
            <mat-icon aria-hidden="false">clear</mat-icon>
        </button> -->
  </div>
  <p class="oxigen-16-b" style="padding-top: 20px">
    La publicación se hizo exitosamente
  </p>

  <div
    fxLayout="row"
    fxLayoutAlign="end none"
    fxLayoutGap="20px"
    style="padding-top: 45px"
  >
    <div fxLayoutAlign="center center" mat-dialog-close="remove">
      <button class="btn-orange btn pointer">
        {{ "send-proposal.accept" | translate }}
      </button>
    </div>
  </div>
</div>
