<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
        <app-header></app-header>
        <app-sub-header *ngIf="load" [screen]="1" ></app-sub-header>
            <div  fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px"
            style="padding: 50px 155px;">
                <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start start stretch" >
                    <h1>aqui va al biografia de teacher.</h1>
                </div>
            </div>
        <app-footer></app-footer>
    </div>
</mat-drawer-container>