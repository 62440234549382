import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bio-teacher',
  templateUrl: './bio-teacher.component.html',
  styleUrls: ['./bio-teacher.component.scss']
})
export class BioTeacherComponent implements OnInit {
  load=true
  constructor() { }

  ngOnInit(): void {
  }

}
