<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
      <app-header></app-header>
        <app-sub-header [screen]="'Primeros pasos'" ></app-sub-header>
          <div class="row">
            <div class="col-md-12" style="padding: 10em;">
              <app-front-sliders [images]="images"></app-front-sliders>
            </div>
          </div>
          <app-section-rigth></app-section-rigth>
          <app-section-left></app-section-left>
          <app-section-rigth></app-section-rigth>
        <app-footer></app-footer>  
    </div>
</mat-drawer-container>