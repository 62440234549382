<div fxLayout="row" fxLayout.lt-md="column" ngxSplit="row">
    <div fxFlex="50%" ngxSplitArea>
		<div fxLayout="row" ngxSplit="row">
            <div fxFlex="10%" ngxSplitArea  class="back-address pointer">
                <mat-icon (click)="onBack()">keyboard_backspace</mat-icon>
            </div>
            <div fxFlex="90%" ngxSplitArea class=" back-address">
                <h1>{{id!=''?'Editar Direccion':'Agregar direccion'}}</h1>
            </div>
        </div>
		<div fxLayout="row" ngxSplit="row">
            <div fxFlex="100%" ngxSplitArea >
                <mat-form-field class="example-full-width" *ngIf="bandCountry">
                    <mat-label translate>place.country</mat-label>
                    <mat-select  (selectionChange)="getStates()" name="country_id" [(ngModel)]="country_id" [formControl]="countr" required>
                    <mat-option *ngFor="let country of countries" [value]="country.id">
                        {{country.name}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="countr.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
                <div *ngIf="!bandCountry" fxLayout="row" fxLayoutAlign="center center" >
                    <mat-spinner strokeWidth="10" [diameter]="24"></mat-spinner>
                </div>
            </div>
        </div>
        <div fxLayout="row" ngxSplit="row">
            <div fxFlex="100%" ngxSplitArea >
                <mat-form-field class="example-full-width" *ngIf="bandState">
                    <mat-label translate>place.state</mat-label>
                    <mat-select (selectionChange)="getCities()" name="state_id" [(ngModel)]="state_id" [formControl]="stat" required>
                    <mat-option *ngFor="let state of states" [value]="state.id">
                        {{state.state_name}}
                    </mat-option>
                    </mat-select>
                    <mat-error *ngIf="stat.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
                <div *ngIf="!bandState && showS" fxLayout="row" fxLayoutAlign="center center" >
                    <mat-spinner strokeWidth="10" [diameter]="24"></mat-spinner>
                </div>
            </div>
        </div>
        <div fxLayout="row" ngxSplit="row">
            <mat-form-field class="example-full-width" *ngIf="bandCity">
                <mat-label translate>place.city</mat-label>
                <mat-select (selectionChange)="getCounties()" name="city_id" [(ngModel)]="city_id" [formControl]="cit" required>
                <mat-option *ngFor="let city of cities" [value]="city.id">
                    {{city.city}}
                </mat-option>
                </mat-select>
                <mat-error *ngIf="cit.invalid">{{getErrorMessage()}}</mat-error>

            </mat-form-field>
            <div *ngIf="!bandCity && showC" fxLayout="row" fxLayoutAlign="center center" >
                <mat-spinner strokeWidth="10" [diameter]="24"></mat-spinner>
            </div>
        </div>
        <div fxLayout="row" ngxSplit="row">
            <mat-form-field class="example-full-width" *ngIf="bandCounty">
                <mat-label translate>place.county</mat-label>
                <mat-select (selectionChange)="getParish()" name="county_id" [(ngModel)]="county_id" [formControl]="count" required>
                <mat-option *ngFor="let county of counties" [value]="county.id">
                    {{county.municipality}}
                </mat-option>
                </mat-select>
                <mat-error *ngIf="count.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
            <div *ngIf="!bandCounty && showCo" fxLayout="row" fxLayoutAlign="center center" >
                <mat-spinner strokeWidth="10" [diameter]="24"></mat-spinner>
            </div>
        </div>
        <div fxLayout="row" ngxSplit="row">
            <mat-form-field class="example-full-width" *ngIf="bandParish">
                <mat-label translate>place.parish</mat-label>
                <mat-select name="parish_id" [(ngModel)]="parish_id" [formControl]="pari" required>
                <mat-option *ngFor="let parish of parishes" [value]="parish.id">
                    {{parish.parish}}
                </mat-option>
                </mat-select>
                <mat-error *ngIf="pari.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
            <div *ngIf="!bandParish && showP" fxLayout="row" fxLayoutAlign="center center" >
                <mat-spinner strokeWidth="10" [diameter]="24"></mat-spinner>
            </div>
        </div>
        <div fxLayout="row" ngxSplit="row">
            <mat-form-field class="example-full-width">
                <mat-label translate>place.zip_code</mat-label>
                <input ame="zip_code" [(ngModel)]="zip_code" matInput placeholder="5001">
            </mat-form-field>

            
        </div>

        <div fxLayout="row" ngxSplit="row">

            <mat-form-field class="example-full-width">
                <mat-label translate>place.address</mat-label>
                <textarea name="address" [(ngModel)]="address" matInput placeholder="av 19 de abril..." [formControl]="addre" required></textarea>
                <mat-error *ngIf="addre.invalid">{{getErrorMessage()}}</mat-error>
                
              </mat-form-field>
        </div>
        <div fxLayout="row" ngxSplit="row">

            <button  mat-raised-button  *ngIf="id==''" style="margin-top:10px ;"   (click)="onSaveAddress()">Guardar</button>
            <button mat-raised-button  *ngIf="id!=''" style="margin-top:10px ;" mat-raised-button   (click)="onEdit(id)">Editar</button>
        </div>

    </div>
    <div fxFlex="50%" ngxSplitArea>

    </div>
</div>

