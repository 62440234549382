<div fxLayout="column" fxLayoutAlign="center center" style="padding: 15px 35px;">
    <p class="oxigen-14-g-bold">Vas a publicar sin preguntas</p>
    <p class="oxigen-14-g-bold">Estas seguro?</p>
    <!-- <div fxLayout="row">
        <button (click)="save()">Aceptar</button>
        <button mat-dialog-close >Cancelar</button>
    </div> -->
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end end" style="margin-top: 45px;">
        <div fxLayoutAlign="center center">
            <!-- <button class="btn-orange btn">ACEPTAR</button> -->
            <button cdkFocusInitial (click)="save()" mat-dialog-close
                class="btn-orange btn">{{"create-question.accept" | translate}}</button>
        </div>
        <div mat-dialog-close class="btn-border pointer" fxLayoutAlign="center center">
            <p class="font">CANCELAR</p>
        </div>
    </div>
</div>