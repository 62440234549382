import { Component, OnInit } from '@angular/core';
import { CardService } from '../../services/card.service';
import { AddressService } from '../../services/address.service';
import { GeneralService } from '../../services/general.service';
import { CartService } from '../../services/cart.service';
import { PaymentService } from '../../services/payment.service';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationPaymentComponent } from '../components/notification-payment/notification-payment.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';




export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-confirm-buy',
  templateUrl: './confirm-buy.component.html',
  styleUrls: ['./confirm-buy.component.scss']
})

export class ConfirmBuyComponent implements OnInit {

  listCards = [
    /*{
      value: 'visa',
      img: 'card-visa.png',
      card: '4242 **** **** **42',
      name: 'Jose Perez',
      date: 'MM/AA',
      codeSeg: '***',
      country: 'Venezuela',
      code: '5001'
    },
    {
      value: 'mastercard',
      img: 'card-mastercard.png',
      card: '4242 **** **** **42',
      name: 'Jose Perez',
      date: 'MM/AA',
      codeSeg: '***',
      country: 'Venezuela',
      code: '5001'
    }*/
  ];
  selectCard: string;
  typeAddCard: string = 'add-card';
  showAddCard: boolean = false;
  selectAddress: string = "";
  listAddress = [
    {
      value: "valid",
      text: "Venezuela, Tachira, San Cristobal. 5001. La Concordia Calle Carrera ..."
    }
  ];
  typeAddNewAddress: string = 'add-address';
  showAddAddress: boolean = false;
  listCountry = [];
  selectCountry: string = "";
  listCity = [];
  selectCity: string = "";
  listState = [];
  selectState: string = "";
  typePaymentCard: string = 'card';
  typePaymentPaypal: string = 'paypal';
  typePayment: string = this.typePaymentCard;
  selectPaypal: string;
  baseUrl: any;

  pdfPath: string = '';
  listPaypal = [
    {
      value: 'paypal-1',
      img: 'card-paypal.png',
      email: 'correo@web.com'
    }
  ];
  typeAddPaypal: string = 'add-paypal';
  bandCountry: boolean = false;
  saveAccount = false;
  termAndCond = false;
  totalObj: any;
  totalString: string;
  matcher = new MyErrorStateMatcher();
  nroCardFormControl = new FormControl('', [Validators.required, Validators.pattern('([0-9]*){16}')]);
  nameCardFormControl = new FormControl('', [Validators.required, Validators.pattern('([A-Za-z. ]*)+')]);
  dateExpFormControl = new FormControl('', [Validators.required, Validators.pattern('((0[1-9]{1}|1[0-2]{1})/[0-9]{2})')]);
  cvcFormControl = new FormControl('', [Validators.required, Validators.pattern('([0-9]{3})')]);
  addressFormControl = new FormControl('', [Validators.required]);
  countryFormControl = new FormControl('', []);
  stateFormControl = new FormControl('', []);
  cityFormControl = new FormControl('', []);
  codeFormControl = new FormControl('', []);
  addressLgFormControl = new FormControl('', []);
  errors = {
    nroCardFormControl: { msg: { required: "Nro. de Tarjeta es requerido.", pattern: "Nro. de Tarjeta solo debe contener números, y debe contener 16 caracteres."} },
    nameCardFormControl: { msg: { required: "Nombre del Titular de la tarjeta es requerido.", pattern: 'Nombre del Titular de la tarjeta debe contener solo letras,". ".'} },
    dateExpFormControl: { msg: { required: "Fecha de Expiración es requerido.", pattern: 'Fecha de Expiración debe tener el formato "MM/AA", ej. 12/20'} },
    cvcFormControl: { msg: { required: "CVC es requerido.", pattern: 'CVC solo debe contener números, y contener máximo 3 caracteres.'} },
    addressFormControl: { msg: { required: "Dirección es requerido."} },
    countryFormControl: { msg: { required: "País es requerido." } },
    stateFormControl: { msg: { required: "Estado es requerido." } },
    cityFormControl: { msg: { required: "Ciudad es requerido." } },
    codeFormControl: { msg: { required: "Código Postal es requerido.", pattern: 'Código Postal solo debe contener números, y contener máximo 5 caracteres.'} },
    addressLgFormControl: { msg: { required: "Dirección es requerido."} }
  };

  constructor(private CardService:CardService,private paymentService:PaymentService,    private dialog: MatDialog,private AddressService:AddressService, private GeneralService:GeneralService, private CartService:CartService, private http: HttpClient) {
    this.baseUrl = environment.storage;
   }

  ngOnInit(): void {

    this.loadCards();
    this.loadPaypal();
    this.loadAddress();
    this.loadTotal();
  }

  radioChange(e){
    if(this.selectCard === this.typeAddCard) {
      this.showAddCard = true;
    } else {
      this.showAddCard = false;
    }
  }

  selectChange(e){
    if(this.selectAddress === this.typeAddNewAddress) {
      if(!this.bandCountry)
        this.loadCountry();
      this.setValidatorRequired();
      this.showAddAddress = true;
    } else {
      this.clearValidator();
      this.showAddAddress = false;
    }
  }
  
  setValidatorRequired() {
    this.countryFormControl.setValidators([Validators.required]);
    this.countryFormControl.updateValueAndValidity();
    this.stateFormControl.setValidators([Validators.required]);
    this.stateFormControl.updateValueAndValidity();
    this.cityFormControl.setValidators([Validators.required]);
    this.cityFormControl.updateValueAndValidity();
    this.codeFormControl.setValidators([Validators.required, Validators.pattern('([0-9]{4,5})')]);
    this.codeFormControl.updateValueAndValidity();
    this.addressLgFormControl.setValidators([Validators.required]);
    this.addressLgFormControl.updateValueAndValidity();
  }

  clearValidator() {
    this.countryFormControl.clearValidators();
    this.countryFormControl.updateValueAndValidity();
    this.stateFormControl.clearValidators();
    this.stateFormControl.updateValueAndValidity();
    this.cityFormControl.clearValidators();
    this.cityFormControl.updateValueAndValidity();
    this.codeFormControl.clearValidators();
    this.codeFormControl.updateValueAndValidity();
    this.addressLgFormControl.clearValidators();
    this.addressLgFormControl.updateValueAndValidity();
  }

  activeErrors() {
    if(!this.nroCardFormControl.valid || !this.nameCardFormControl.valid || !this.dateExpFormControl.valid ||
       !this.cvcFormControl.valid ||  !this.addressFormControl.valid || !this.countryFormControl.valid || 
       !this.stateFormControl.valid || !this.cityFormControl.valid || !this.codeFormControl.valid ||
       !this.addressLgFormControl.valid ) {
      Object.keys(this.errors).forEach(key => {
        this[key].touched = true;
      });
      return true;
    }
    return false;
  }

  openPayment(type) {
    this.typePayment = type;
  }

  radioChangePaypal(e) {
  }

  loadCards(){
    const data = JSON.parse(localStorage.getItem('userData'));
    this.CardService.get_cards(data.id).subscribe( res => {
      const { cards } = res;
      this.listCards = cards.map( item => {
        return {
          value: item.id,
          img: item.provider == "visa" ? 'card-visa.png' : 'card-mastercard.png',
          card: `${item.card_number}************`,
          name: item.card_name,
          date: item.expiration_date,
          codeSeg: '***',//item.CVC,
          country: item.country,
          code: item.zip_code
        };
      });
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  loadPaypal() {
  }

  loadAddress(){
   const data = JSON.parse(localStorage.getItem('userData'));
    this.AddressService.get_addresses(data.id).subscribe( res => {
      const { address } = res;
      this.listAddress = address.map( item => {
        return {
          value: item.address_id,
          text: `${item.country}, ${item.state}, ${item.city}. ${item.zip_code}. ${item.address}...`
        };
      });
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    }); 
  }

  loadTotal() {
    const totalObj = JSON.parse(localStorage.getItem('totalCart'));
    const cartProducts = JSON.parse(localStorage.getItem('cartProducts'));
    const counters = JSON.parse(localStorage.getItem('counters'));
    // Recupera las cantidades y realiza cualquier lógica necesaria
    cartProducts.products.forEach(product => {
      if (counters && counters[product.id]) {
        console.log(`Cantidad para el producto ${product.id}: ${counters[product.id]}`);
      }
    });
  
  
    this.totalObj = totalObj;
    this.totalString = totalObj ? `${totalObj.currency} ${totalObj.value.toFixed(2)}` : "USD 0";
  }

  loadCountry(){
      this.GeneralService.get_countries().subscribe( res => {
        const { countries } = res;
        this.listCountry = countries.map( elem => {
          return { value: elem.id, text: elem.name }
        });
        this.bandCountry = true;
      }, err => {
        alert(err.error.message_return.ERROR_MENSAGGE);
      });
  }

  selectedState() {
    this.GeneralService.get_getStatesByCountry(this.selectCountry).subscribe( res => {
      const { states } = res;
      this.listState = states.map( elem => {
        return {
          value: elem.id,
          text: elem.state_name
        }
      });
      this.selectState = "";
      this.listCity = [];
      this.selectCity = "";
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  selectedCity() {
    this.GeneralService.get_getCitiesByState(this.selectState).subscribe( res => {
      const { City } = res;
      this.listCity = City.map( elem => {
        return {
          value: elem.id,
          text: elem.city
        }
      });
      this.selectCity = "";
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }
  openLink(link, id, orderId) {
    const url = link;
    const paymentWindow = window.open(url, 'Payment', 'width=800,height=800');
      
    // Verificar el estado del pago en intervalos regulares
    const paymentCheckInterval = setInterval(() => {
      if (paymentWindow && paymentWindow.closed) {
        clearInterval(paymentCheckInterval);
        // Lógica para manejar el pago completado
        this.handlePaymentCompletion(id, orderId);
      }
    }, 1000);
    const newWindow = window.open(url,"DescriptiveWindowName",
    "resizable,scrollbars,status",);
    window.open
    

      if (newWindow) {
        newWindow.addEventListener('unload', () => {
          this.handleWindowClose();
        });
      }
  
 }
 handlePaymentCompletion(id, orderId) {
  this.paymentService.checkPayment(id, orderId).subscribe(res => {
    if (res.status === 'CREATED') {
      const dialogRef = this.dialog.open(NotificationPaymentComponent, {
        data: {
          title: 'Estado del Pago',
          message: 'El pago no se completo ',
          status : false
        },
      });
    }
    if (res.status === 'APPROVED') {
      const dialogRef = this.dialog.open(NotificationPaymentComponent, {
        data: {
          title: 'Estado del Pago',
          message: 'El pago  se completo Exitosamente ',
          status : true
        },
      });
    }
  })

  // Lógica para manejar el pago completado, por ejemplo, cargar información adicional.
}
 handleWindowClose() {
 }

 downloadPDF(pdfPath: string | string[]): void {
  console.log('Descargando PDF:', pdfPath);

  const apiUrl = `${this.baseUrl}/uploads/`; // Reemplaza con la URL de tu backend

  // Verificar si pdfPaths es un array
  if (Array.isArray(pdfPath)) {
    pdfPath.forEach(singlePdfPath => {
      // Obtener el nombre del archivo de la ruta
      const fileName = singlePdfPath.substring(singlePdfPath.lastIndexOf('/') + 1);
      
      // Abrir cada enlace PDF en una nueva ventana o pestaña del navegador
      window.open(`${apiUrl}${fileName}`, '_blank');
    });
  } else if (typeof pdfPath === 'string') {
    // Si pdfPaths es una cadena, abrir el único enlace en una nueva ventana o pestaña
    const fileName = pdfPath.substring(pdfPath.lastIndexOf('/') + 1);
    window.open(`${apiUrl}${fileName}`, '_blank');
  } else {
    console.error('Las rutas de PDF no son ni una cadena ni un array válido:', pdfPath);
  }
}

  paymentCompleted() {
      let data = {};
      const user = JSON.parse(localStorage.getItem('userData'));
      const cart = JSON.parse(localStorage.getItem('cartData'));
      if(this.termAndCond) {
        if(this.selectPaypal)
        {
      const quantity = 1;

          const dataPayment = JSON.parse(localStorage.getItem('cartProducts'));
          this.paymentService.Create_order(dataPayment, quantity).subscribe((resp: any) => {
            if (resp.pdf_paths && resp.pdf_paths.length > 0) {
              resp.pdf_paths.forEach(pdfPath => {
                console.log('PDF path:', pdfPath);
                this.downloadPDF(pdfPath);
              });
            } else {
              console.error('No se encontraron rutas de PDF en la respuesta.');
            }
          });
    
        }
        let address = 0;

        if(this.selectAddress == this.typeAddNewAddress && !this.activeErrors()) { 
          data = {
            "address": this.addressLgFormControl.value,
            "main_address": 0,
            "country_id": this.countryFormControl.value,
            "state_id": this.stateFormControl.value,
            "city_id": this.cityFormControl.value,
            "county_id": 0,
            "parish_id": 0,
            "zip_code": this.codeFormControl.value,
            "user_id": user.id
          };
          this.AddressService.post_adminaddresses(data).subscribe( res => {
            if(this.saveAccount && !this.activeErrors()) {
              data = {
                card_name: this.nameCardFormControl.value,
                card_number: this.nroCardFormControl.value,
                expiration_date: this.dateExpFormControl.value,
                CVC: this.cvcFormControl.value,
                billing_address_id: 0,//No me retorna el id
                type: this.typePayment == this.typePaymentCard ? 1 : 0,
                provider: "visa",
                user_id: user.id
              }
              this.CardService.post_admincards(data).subscribe( res => {
              }, err => {
                alert(err.error.message_return.ERROR_MENSAGGE);
              });
            }
          }, err => {
            alert(err.error.message_return.ERROR_MENSAGGE);
          });
        } else {
          address = this.addressFormControl.value;
          if(this.saveAccount && !this.activeErrors()) {
            data = {
              card_name: this.nameCardFormControl.value,
              card_number: this.nroCardFormControl.value,
              expiration_date: this.dateExpFormControl.value,
              CVC: this.cvcFormControl.value,
              billing_address_id: address,
              type: this.typePayment == this.typePaymentCard ? 1 : 0,
              provider: "visa",
              user_id: user.id
            }
            this.CardService.post_admincards(data).subscribe( res => {
            }, err => {
              alert(err.error.message_return.ERROR_MENSAGGE);
            });
          }
        }
        if(this.selectCard) {
          const date = new Date();
          const month = date.getMonth() < 8 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1);
          data = {
            order_date: `${date.getFullYear()}-${month}-${date.getDate()}`,
            order_Name: "Nombre de la orden Test",
            order_CartID: cart[0].cartID,
            order_status_id: 1,
            email: user.email,
            cart: 0,
          };
          this.CartService.post_admin_orders(data).subscribe( res => {
            alert('Exito..!!!');
          }, err => {
            alert(err.error.message_return.ERROR_MENSAGGE);
          });
        }
      } else {
        alert('Debes aceptar los terminos y condiciones de Sogcial...!');
      }
    
  }


  
  
  
}
