<mat-drawer-container
  class="drawer-container"
  style="overflow: hidden !important"
>
<div class="content" ng>
    <app-header></app-header>
    <app-sub-header [screen]="1"></app-sub-header>

    <!-- Seccion central -->
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="space-between none"
      fxLayoutGap="40px"
      class="containerPlaceDetails"
      style="padding-right: 6rem"
     >

     <div class="perfil">
        
      <div
        fxLayout="row"
        fxLayout.lt-md="row"
        fxLayoutAlign="end end"
        fxLayoutGap.gt-sm="0.3em"
        fxLayoutGap.lt-md="0.3em"
        class="sectionSocial"
      >
      <p class="space" fxFlex>Compartir </p>
        <img  class="pointer" href="#" src="/assets/images/facebook_copia.png" width="25px" alt="Facebook">
        <img class="pointer" href="#" src="/assets/images/twitter_copia_2.png" width="25px" alt="Twitter">  
        <img class="pointer" href="#" src="/assets/images/link_copia.png" width="25px" alt="Enlace">  
     </div>

        <H1>Acerca de mi</H1>
    
        <div class="bioInfo">  
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque reiciendis ipsum nobis repudiandae. Inventore animi sint, totam debitis ea voluptates necessitatibus cupiditate omnis distinctio architecto neque officiis labore optio. Pariatur!     
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus, ullam ea! Beatae fugit laboriosam quos vitae dolorum sed earum distinctio ut, totam provident officiis doloremque aspernatur corporis, non, mollitia ducimus.
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between stretch"
          fxLayoutGap.gt-sm="20px"
          style="width: 100%"
        >

        <!-- Bloque de cursos y eventos -->
          <mat-tab-group style="width: 100%">
            <!-- Area de cursos  -->
            <mat-tab label="Cursos"> 
              <div *ngIf="courses.length === 0">Curso no disponible</div>
              <div class="long-tabs" >
                <div fxLayout.lt-md="column" fxLayout="row"  fxLayoutAlign.lt-md="space-between center" routerLink="/course/{{ curso.id }}-{{ curso.name }}" class="containerlist" *ngFor="let curso of courses" style="width: 90%;">
                <div class="image-cur" ><img [src]="comprobar(curso.main_image)"  alt="" class="imgcourse"></div>
                <div class="descripciones">
                <div class="title-cur">
                <div class="nombrecurso">{{curso.name}}</div>
                <div class="categorycur"><b>{{curso.category}}</b></div>
                <div> <b>Nivel:</b> {{curso.level}} | Para mayores de {{curso.age}} | <span *ngIf="curso.evaluate">Evaluado</span>  <span *ngIf="!curso.evaluate">No evaluado</span></div>
              </div>
              </div>
              </div>
              </div>           
            </mat-tab>
              <!-- Area de Lugares -->
            <mat-tab label="Lugares"  >
              <div *ngIf="places.length === 0">Lugares no disponibles</div>
                <div class="long-tabs">
                  <div fxLayout.lt-md="column" fxLayout="row"  fxLayoutAlign.lt-md="space-between center" class="containerlist " *ngFor="let lugar of places" style="width: 90%;">
                  <div class="image-cur " ><img [src]="comprobar(lugar.main_image)"  alt="" class="imgcourse"></div>
                  <div class="descripciones ">
                    <div class="title-cur">
                      <div class="nombrecurso">{{lugar.name}}</div>
                      <div class="descrip">{{lugar.description}}</div>
                      <div class="categorycur">{{lugar.name}}</div>
                  </div>
                 </div>
                </div>
              </div> 
            </mat-tab>

            <mat-tab label="Eventos">
              <div *ngIf="eventos.length === 0">Eventos no disponibles</div>
              <div class="long-tabs">
                <div fxLayout.lt-md="column" fxLayout="row"  fxLayoutAlign.lt-md="space-between center" class="containerlist " *ngFor="let event of eventos"  routerLink="/event{{ event.id }}-{{ event.name }}"  style="width: 90%;">
                <div class="image-cur" ><img [src]="comprobar(event.main_image)" alt="" class="imgcourse"></div>
                <div class="descripciones ">
                  <div class="title-cur">
                    <div class="nombrecurso">{{event.name}}</div>
                    <div class="categorycur">{{event.category}}</div>
                    <div>{{event.level}} | Para mayores de {{event.age}} | <span *ngIf="event.availability">Evaluado</span>  <span *ngIf="!event.availability">No Evaluado</span></div>
                  </div>
                </div>
                </div>
              </div>               
           </mat-tab>
            
          </mat-tab-group>

        </div>
      </div> 

      <!-- Sidebar derecho -->
      <div
      class="contacto"
      class="sidebarRight"
      >
      <div class="imagen">
        <img [src]="baseUrl+'/'+user.avatar" (error)="onImgError($event)"/>
      </div>

        <div class="nombre">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="0.8" stroke="currentColor" class="w-2 h-2 icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
          </svg>
          <span style="margin-right: .5rem;"> {{user.name}} {{user.last_name}} </span>
        </div>
        <div class="profesion">
          {{user.title}}
        </div>

        <div class="redes">
          <div
            fxLayout="row"
  
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/facebook.png"
              width="27"
              class="icon-c"
              alt="facebook"
            />
            <span style=" margin: 0 1em;">  
            {{user.facebook_url}}
            </span>
          </div>
          <div
            fxLayout="row"
    
            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/twitter.png"
              width="27"
              class="icon-c"
              alt="Twitter"
            />
            <span style=" margin: 0 1em;">  
                {{user.twitter_url}}
            </span>
          </div>
          <div
            fxLayout="row"

            fxLayoutGap.gt-sm="15px"
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/instagram.png"
              width="27"
              class="icon-c"
              alt="Instagram"

            />
            <span style=" margin: 0 1em;">  
              {{user.instagram_url}}
              </span>
          </div>
          <div
            fxLayout="row"

      
            class="itemSocialNetwork"
          >
            <img
              src="/assets/images/youtube_1.png"
              width="27"
              class="icon-c"
              alt="Youtube"
        
            />
            <span style=" margin: 0 1em;">         
                 {{user.youtube_url}}
            </span>

            
          </div>

          <div class="enviar">
            <button   (click)="rejectproposalreceived()">CONTACTAR</button>
            <!-- <button>ENVIAR PROPUESTA</button> -->
          </div>
        </div>
     
      </div>    
    </div>
 
  
    <app-footer></app-footer>
  </div>
</mat-drawer-container>
