import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  SwiperComponent,
  SwiperDirective,
  SwiperConfigInterface,
} from 'ngx-swiper-wrapper';

import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  //mySwiper = new Swiper('.swiper-container', { speed: 400, spaceBetween: 100 });

  show: boolean = true;
  num: number = 0;

  imgDef = '../../../../assets/images/image-carousel.png';
  slides = [this.imgDef, this.imgDef, this.imgDef];

  imgAdd = '../../../../assets/images/image-carousel-plus.png';
  imagen = 'https://ar.pinterest.com/pin/730075789582829722/';

  type: string = 'component';

  disabled: boolean = false;

  config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 2,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false,
    spaceBetween: 10,
  };
  src_img: string = '';
  formData;
  @Output() sendImages = new EventEmitter();
  @Input() arrayImage: any = null;
  @Input() edit = false;
  @Input() origin = null;

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  constructor(private GeneralService: GeneralService) {}

  ngOnInit(): void {
    this.type = 'directive';

    if (this.arrayImage != null) {
      this.slides = this.arrayImage;
      for (let i = this.slides.length; i < 3; i++) {
        this.slides.push(this.imgDef);
      }
    }
  }

  onIndexChange(index: number): void {
    this.num = index;
  }

  onSwiperEvent(event: string): void {
    console.log('Swiper event: ', event);
  }

  // addimage() {
  //   console.log('antes:', this.slides.length);
  //   this.slides.push('https://i.imgur.com/H2VH1z6.jpg');
  //   this.directiveRef.setIndex(this.slides.length);
  //   if (
  //     this.slides.length == 2 &&
  //     this.slides[0] == '../../../../assets/images/image-carousel.png'
  //   ) {
  //     this.slides.splice(0, 1);
  //   }
  //   console.log('addslides', this.slides);

  //   // console.log('config:', this.config);
  //   // console.log("this.mySwiper:", this.mySwiper);
  // }

  deleteimage() {
    this.slides.splice(this.num, 1);
    if (this.slides.length == 0) {
      this.slides = [this.imgDef];
    }
    this.formData = this.GeneralService.deleteCarouselFile(this.num);

    // this.sendImages.emit(this.slides);
  }

  next() {
    this.directiveRef.nextSlide();
  }

  back() {
    this.directiveRef.prevSlide();
  }

  receiveSrcImgAdd(event) {
    if (this.arrayImage !== null) {
      this.GeneralService.clearCarouselFile();

      this.arrayImage = null;
      this.slides = [];
      this.num = 0;
      for (let i = this.slides.length; i < 3; i++) {
        this.slides.push(this.imgDef);
      }
    this.directiveRef.setIndex(0);

    }



    this.src_img = event;
    this.slides[this.num] = this.src_img;

    this.next();

  }

  update() {
    this.directiveRef.update();
  }
  otro() {
    this.componentRef.directiveRef.nextSlide();
  }

  receiveFileAdd(files) {
    setTimeout(() => {
      this.formData = this.GeneralService.prepareSaveCarousel(files);
    }, 300);
  }
}
