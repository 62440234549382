<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" style="background: url('assets/images/searching.png') top center; background-size: cover;height: 550px;">
    <div class="welcome-sogcial" fxFlex="50%" fxLayout="column" fxLayoutAlign="center start stretch">
        <div  fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center start stretch" >
            <p class="title-orange">Bienvenidos a</p>
        </div>
        <div  fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center start stretch" >
            <p class="subtitle-orange">Sogcial</p>
        </div>
    </div>
    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="center center stretch">
        <div  fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start stretch" >
            <p class="title-rigth-orange">
                Te damos la bienvenida a nuestra plataforma de aprendizaje continuo. 
            </p>
        </div>
        <div  fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start " >
            <p *ngIf="!isMobile" class="subtitle-rigth-orange">
                Disfruta de la oportunidad de explorar una amplia variedad de cursos y eventos,  ofreciéndote una experiencia educativa única, donde podrás acceder a cursos de alta calidad, eventos emocionantes para tu desarrollo personal y profesional. No importa en qué etapa de tu vida te encuentres, siempre hay algo nuevo por descubrir en SOGCIAL.
            <br> <span style="font-weight: 900;">¡Explora nuestros cursos ahora y comienza tu viaje de aprendizaje continuo!</span>
                </p>
              
        </div>
    </div>
</div>
<div fxLayout="row" class="background-blue" fxLayout.lt-md="column" fxLayoutAlign="space-between none" >
    <div class="page-name" fxFlex="100%" fxLayout="column" fxLayoutAlign="center start stretch" style="">
        <div  fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center start stretch" >
            <p class="title-white text-center">{{searching==1?'':searching}}</p>
        </div>
        <div  fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center start stretch" >
            <p *ngIf="searching!=1" class="subtitle-white text-center"><span>Home</span> / <span>{{searching}}</span></p>
        </div>
    </div>
</div>