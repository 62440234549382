<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px"
    style="padding: 80px; background-color: #F8F8F8;">
    
    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="end end center">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px">
            <p class="title">Un sitio para ti</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px">

            <p class="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>

            
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px">
            <button routerLink="/auth/login" mat-button class="example-icon favorite-icon style-login ingresar" >
            Ingresar
            </button>
        </div>
    </div>

    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" style="background: #9E9E9E; height: 350px;">
            
        </div>
    </div>
</div>