import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError, observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OffetService {
  public baseurl: string;
  temp: string;

  constructor(private __http: HttpClient,  private http: HttpClient) {
    this.baseurl = environment.url_server;
  }


  get_admingetOffer(id): Observable<any>{
    this.temp = this.baseurl + `admin/getOffer/${id}`;
    return this.__http.get<any>(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }
  post_adminoffers(data):Observable<any>{
    this.temp = this.baseurl + `admin/offers`;
    return this.__http.post<any>(this.temp,data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  post_acceptOffer(id):Observable<any>{
    this.temp = this.baseurl + `admin/acceptOffer/${id}`;
    return this.__http.post<any>(this.temp, {})
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  get_offers_id(id): Observable<any>{
    this.temp = this.baseurl + 'admin/offers/'+ id
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }
  public getIPAddress() {
    return this.http.get(environment.ipify_url);
  }

}
