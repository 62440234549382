import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ProposalService } from "../../../services/proposal.service";
import { GeneralService } from "../../../services/general.service";

@Component({
  selector: 'app-ofert-list',
  templateUrl: './ofert-list.component.html',
  styleUrls: ['./ofert-list.component.scss']
})
export class OfertListComponent implements OnInit {
  @Input() submitted: any;
  @Input() type:any;

  constructor(private ProposalService:ProposalService,
    private GeneralService: GeneralService) { }
  displayedColumns: string[] = ['action_1',"action_2","actions"];//['teacher',"user","actions"];
  tdetail='proposal';
  dataSource
  storage:any = environment.storage;
  typeUser:any = null;
  

  ngOnInit(): void {
    this.typeUser = JSON.parse( localStorage.getItem("userData") )["rol_id"];
    let ELEMENT_DATA=[
      {
        id:1,
        teacher:{
          name:"Jose rojas",
          courseName:"Programacion Web",
          city:"San Cristobal",
          country:"Venezuela"
        },
        user:{
          name:"Jose Zapata",
          star:4,
          image:"/assets/images/default.png",
          timer:"Cocinero internacional"
        },
        actions:{
          show:"visibility",
          trash:"delete"
        }
      },
      {
        id:2,
        teacher:{
          name:"Jose rojas",
          courseName:"Programacion Web",
          city:"San Cristobal",
          country:"Venezuela"
        },
        user:{
          name:"Jose Zapata",
          star:5,
          image:"/assets/images/default.png",
          timer:"Cocinero internacional"
        },
        actions:{
          show:"visibility",
          trash:"delete"
        }
      },
      {
        id:2,
        teacher:{
          name:"Jose rojas",
          courseName:"Programacion Web",
          city:"San Cristobal",
          country:"Venezuela"
        },
        user:{
          name:"Jose Zapata",
          star:3,
          image:"/assets/images/default.png",
          timer:"Cocinero internacional"
        },
        actions:{
          show:"visibility",
          trash:"delete"
        }
      },
      
      
    ];

    this.dataSource=ELEMENT_DATA
  }
  
  receive=""
  summit=""
  flagReceived=false;
  flagSubmitted=false;
  ngOnChanges(changes: SimpleChanges): void {
    this.tdetail= this.type>=2 ? 'ofert': 'proposal';
    if(this.type >=0 || this.type <=4){
      this.ProposalService.get_my_Proposals(this.type).subscribe(res=>{
        if(this.type==0){
          res.submitted_proposals.original.result.map(data=>{
            if(data.user_creation!='' || data.user_creation!=null)
              return data.user_creation = this.GeneralService.m_es[(data.user_creation.split('-')[1]-1)] +' '+ data.user_creation.split('-')[0];    
          });
          this.dataSource=res.submitted_proposals.original.result;
          this.flagSubmitted= this.dataSource.length ==0 ? false: true;
        }else if(this.type==1){
          res.received_proposals.original.result.map(data=>{
            if(data.user_creation!='' || data.user_creation!=null)
              return data.user_creation = this.GeneralService.m_es[(data.user_creation.split('-')[1]-1)] +' '+ data.user_creation.split('-')[0];
          });
          this.dataSource=res.received_proposals.original.result;
          this.flagSubmitted= this.dataSource.length ==0 ? false: true;
        }else if(this.type==2){
          res.offers_received.original.result.map(data=>{
            if(data.user_creation!='' || data.user_creation!=null)
              return data.user_creation = this.GeneralService.m_es[(data.user_creation.split('-')[1]-1)] +' '+ data.user_creation.split('-')[0];
          });
          this.dataSource=res.offers_received.original.result;
          this.flagSubmitted= this.dataSource.length ==0 ? false: true;
        }else if(this.type==3){}
      });
    }else{
      this.flagSubmitted=false;
      
      if(changes.submitted.firstChange!=true){
        let ELEMENT_DATA=[];
        let submitted;

        console.log("NAME = ", this.receive )

        if(this.receive=="submitted"){
          submitted=changes.submitted.currentValue.result.submitted_proposals;
          console.log(submitted,"submitted")
          submitted.filter(sub=>{
            ELEMENT_DATA.push(
              {
                
                id:sub.proposal_users_id,
                teacher:{
                  name:sub.title,
                  courseName:sub.course?sub.course:sub.event,//title
                  city:sub.ubication,//Ubication
                  country:" "
                },
                user:{
                  name:sub.teacher.original.user[0].name+" "+sub.teacher.original.user[0].last_name,
                  star:sub.teacher.original.user[0].teacher_average_stars,
                  image:sub.teacher.original.user[0].avatar,
                  timer:sub.teacher.original.user[0].title
                },
                actions:{
                  show:"visibility",
                  trash:"delete"
                }
              }
            )
    
          })
        }
        else if(this.receive=="received"){
          submitted=changes.submitted.currentValue.result.received_proposals;//["submitted_proposals"]["original"]["result"]["submitted_proposals"];//changes.submitted.currentValue.result.received_proposals;
          submitted.filter(sub=>{
            ELEMENT_DATA.push(
              {
                
                id:sub.proposal_users_id,
                teacher:{
                  name:sub.title,
                  courseName:sub.course?sub.course:sub.event,//title
                  city:sub.ubication,//Ubication
                  country:" "
                },
                user:{
                  name:sub.user.original.user[0].name+" "+sub.user.original.user[0].last_name,
                  star:sub.user.original.user[0].teacher_average_stars,
                  image:sub.user.original.user[0].avatar==null?'/assets/images/default.png':sub.user.original.user[0].avatar,
                  timer:sub.user.original.user[0].title
                },
                actions:{
                  show:"visibility",
                  trash:"delete"
                }
              }
            )
    
          })
        }
        
        this.dataSource=ELEMENT_DATA
        console.log(this.dataSource);
        this.flagSubmitted=true;

      }else{
        this.receive=changes.type.currentValue;
      }
    }    
  }

}
