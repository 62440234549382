import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public baseurl: string;
  temp: string;
  public message;

  constructor(private __http: HttpClient,  private http: HttpClient) {
    this.baseurl = environment.url_server;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
  }

  getMessage(){
    return this.message;
  }

  setMessage(msg){
    this.message = msg;
  }

  post_auth_login(data): Observable<any> {
    this.temp = this.baseurl + "auth/login";
    return this.__http.post<any>(this.temp, data, this.httpOptions)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }
  auth_logout(): Observable<any> {
    this.temp = this.baseurl + "auth/logout";
  
    const response = this.__http.get<any>(this.temp, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
    return response;
  }
  

  post_auth_signup(data): Observable<any> {
    this.temp = this.baseurl + "auth/signup";
    return this.__http.post<any>(this.temp, data, this.httpOptions)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  post_password_create(data): Observable<any> {
    this.temp = this.baseurl + "password/create";
    return this.__http.post<any>(this.temp, data, this.httpOptions)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }
  
  get_password_find(token): Observable<any> {
    this.temp = this.baseurl + 'password/find/' + token;
    return this.__http.get( this.temp,token)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  get_login_provider(urlProvider, callback): Observable<any> {
    this.temp = this.baseurl + urlProvider + callback;
    return this.__http.get( this.temp,callback)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  post_password_reset(data): Observable<any> {
    this.temp = this.baseurl + "password/reset";
    return this.__http.post<any>(this.temp, data, this.httpOptions)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  post_auth_provider(url): Observable<any> {
    this.temp = this.baseurl + url;
    return this.__http.post<any>(this.temp, this.httpOptions)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }


}
