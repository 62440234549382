import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [BreakpointObserver],
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  id: string;

  constructor( public translate: TranslateService,
    private route: ActivatedRoute,
    private _router: Router,
    private breakpointObserver: BreakpointObserver

     ) {
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('es');
    this.id = this.route.snapshot.paramMap.get("")
    

  }
  onActivate($event){
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, pos - 20); 
      } else {
          window.clearInterval(scrollToTop);
      }
  }, 1);
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }
  @HostListener("window:scroll", [""])
  onWindowScroll() {
 
    if (this.breakpointObserver.isMatched([Breakpoints.Handset])) {
      return; 
    }


    var data = this._router.url.toString().split('/');
  
    if (data[1] != 'dashboard' && data[1] != 'auth') {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        document.getElementById('mat-toolbar').style.height = '52px';
        document.getElementById('logo').style.width = '130px';
      } else {
        document.getElementById('mat-toolbar').style.height = '52px';
        document.getElementById('logo').style.width = '130px';
      }
    }
  }
  
}
