import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, UrlTree } from '@angular/router';
import  {FavoriteService } from 'src/app/services/favorite.service';
import { PlaceService } from 'src/app/services/place.service';
import { BussinesOwnerServiceService } from '../../../../services/bussines-owner-service.service';
import { ProfileService } from '../../../../services/profile.service';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalContactMessageComponent } from 'src/app/website/searching/teachers/modal-contact-message/modal-contact-message.component';
import { environment } from 'src/environments/environment';
import { element } from 'protractor';

@Component({
  selector: 'app-bussines-owner',
  templateUrl: './bussines-owner.component.html',
  styleUrls: ['./bussines-owner.component.scss']
})
export class BussinesOwnerComponent implements OnInit {
  flag;
  baseUrl = environment.storage;

  constructor(private miServicio:BussinesOwnerServiceService,private router: ActivatedRoute ,private route: Router, 
    public dialog: MatDialog) {
      let userData = localStorage.getItem("userData");
      if (userData !== "" && userData != null) {
        this.flag = true;
      } else {
        this.flag = false;
      }
  }

  user: any;
  places: any;
  courses: any;
  eventos: any;
 
  ngOnInit(): void {

   
    const slugUrl = this.router.snapshot.paramMap.get("slug");
    const [ id ] = slugUrl.split("-");



    this.miServicio.getUser(id).subscribe(resp => {
        this.user = resp.user[0];   
        console.log(this.user)      

        if(this.user.facebook_url == null ){
          this.user.facebook_url = 'facebook/sogcial.com';
        }

        if(this.user.twitter_url == null ){
          this.user.twitter_url = 'twitter/sogcial.com';
        }

        if(this.user.instagram_url == null ){
          this.user.instagram_url = 'instagram/sogcial.com';
        }

        if(this.user.youtube_url == null ){
          this.user.youtube_url = 'youtube/sogcial.com';
        }
        
    });

    console.log('holaaaa')

    this.miServicio.getBusinessInfo(id).subscribe(resp=>{
      this.places = resp.places;
      
      this.courses = resp.courses;
      this.eventos = resp.events;

      console.log("places", this.places);
      console.log("Events", this.eventos);
      console.log("courses", this.courses)
    });
    
  }

  rejectproposalreceived() {
    if(  this.flag === false ){
      localStorage.setItem("previusRoute", this.route.url)
      this.route.navigate(['/auth/login']);
    }
    else{
      const dialogRef = this.dialog.open(ModalContactMessageComponent, {
        width: '1000px',
        data : this.user
  
      });
    }
  }
  
  onImgError(event){
    event.target.src = './../../../../assets/images/person-default.svg'
   //Do other stuff with the event.target
   }
   comprobar(img){
    console.log("esto",img);
    if(img === ' ' || img === null){
      return './../../../../assets/images/image-default.png'
    }
    else{
      return  this.baseUrl+'/'+img
    }
   }

}
