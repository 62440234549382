<div style="margin: 0 2rem">
    <div mat-dialog-title>
      <h2 class="m-title">Inscripción del Curso</h2>
    </div>
    <div mat-dialog-content>
      <div style="display: flex;">
        <img src="{{ data.image }}" class="m-image">
        <div class="m-content" style="width: 70%">
          <p>¿Estas seguro de que quieres Incribirte en este curso?</p>
          <div style="display: flex;">
            <div class="info-course">
              <h1>{{ data.courseName }}</h1>
              <h2>{{ data.category }}</h2>
            </div>
            <div class="price-course">
              <p class="p-space prices-big" fxFlex style="font-size: 28px;">
               {{ data.price }}
              </p>
            </div>
          </div>
          <p>¿Quien va a tomar este curso?</p>
          <select class="select-af" [(ngModel)]="selectedAffiliateId">
            <ng-container *ngIf="!data.userRegistered">
              <option value="0">Para mí</option>
            </ng-container>
            <ng-container *ngFor="let aff of data.courseAffliates">
              <ng-container *ngIf="!data.userRegistered || !isAffiliateRegistered(aff.id)">
                <option [value]="aff.id">
                  {{ aff.name }} {{ aff.last_name }}
                </option>
              </ng-container>
            </ng-container>
          </select>
          
          
          
        </div>
      </div>
    </div>
    <div mat-dialog-actions align="end" class="content-actions">
      <button mat-button mat-dialog-close class="ingresar" (click)="onNoClick()">Cancelar</button>
      <button mat-button mat-dialog-close cdkFocusInitial class="registrate" (click)="addProduct()">Aceptar</button>
    </div>
  </div>