import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  public baseurl: string;
  temp: string;

  constructor(private __http: HttpClient,  private http: HttpClient) {
    this.baseurl = environment.url_server
  }

  post_admincards(data):Observable<any> {
    this.temp = this.baseurl + 'admin/cards' ;
    console.log("--------------data:", data);
    return this.__http.post( this.temp, data )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  get_cards(id):Observable<any> {
    this.temp = this.baseurl + `cards/${id}` ;
    // console.log("--------------data:", data);
    return this.__http.get( this.temp )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }
}
