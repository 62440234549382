import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {
  public baseurl: string;
  temp: string;

  constructor(private __http: HttpClient,  private http: HttpClient) {
    this.baseurl = environment.url_server;
  }

  post_place(data): Observable<any> {
    this.temp = this.baseurl + "auth/login";
    return this.__http.post<any>(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  post_adminplacesupdate(data,id){
// /admin/places/update/{id}
    this.temp = this.baseurl + `admin/places/${id}`;
    return this.__http.put<any>(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  // get_places(id: string): Observable<any>{
  //   console.log("id_________", id)
  //   if(id != ""){
  //     this.temp = this.baseurl + "places?user_id="+id;
  //   }else{
  //     this.temp = this.baseurl + "places";
  //   }
  //   return this.__http.get( this.temp)
  //     .pipe(retry(1),
  //       catchError(this.errorHandl)
  //     )
  // }

  get_listPlace(limit=null,id=null, text = null, category = null, country = null):Observable<any>{
    if(id==null&&limit!=null)
      this.temp = this.baseurl + `places?limit=${limit}`;
    else
      if(id!=null&&limit!=null)
        this.temp = this.baseurl + `places?limit=${limit}&lastId=${id}`;
      else
        this.temp = this.baseurl + `places`;

    if(text && text !=""){
      this.temp = this.temp + `&text=${text}`;
    }
    if(country && country !=""){
      this.temp = this.temp + `&country=${country}`;
    }
    if(category && category !=""){
      this.temp = this.temp + `&category=${category}`;
    }

    return this.__http.get<any>(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }
  getreserva_places(user_id): Observable<any> {
    //http://localhost:8000/api/placesproposals?user_id=XX

    this.temp = this.baseurl + `placesproposals?user_id=${user_id}`;
    let params = new HttpParams();



    return this.__http.get<any>(this.temp).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }
  get_places(data): Observable<any> {
    this.temp = this.baseurl + "places";
    let params = new HttpParams();

    Object.keys(data).forEach(function (item) {
      params = params.set(item, data[item]);
    });

    return this.__http.get<any>(this.temp, { params }).pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

  get_getParentPlaceCategory(): Observable<any>{
    this.temp = this.baseurl + 'getParentPlaceCategory' ;
    return this.__http.get( this.temp)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  ///placeCategories
  get_placeCategories():Observable<any>{
    this.temp = this.baseurl + 'placeCategories' ;
    return this.__http.get( this.temp)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  get_adminplaceCategories(id): Observable<any>{
    this.temp = this.baseurl + 'admin/placeCategories/'+id ;
    return this.__http.get( this.temp)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }


  get_deleteplace(id): Observable<any>{
this.temp = this.baseurl + 'admin/places/'+id ;
    return this.__http.delete( this.temp)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  get_getChieldPlaceCategory(id: number): Observable<any>{
    this.temp = this.baseurl + 'getChieldPlaceCategory/'+id ;
    return this.__http.get( this.temp)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  get_placesShow(id: number): Observable<any>{
    const userDataString = localStorage.getItem('userData');
  
    if (userDataString) {
      const userId = JSON.parse(userDataString).id;
      if (userId) {
        this.temp = this.baseurl + 'places/show/' + id + '?user_id=' + userId;
      }
    } else {
      this.temp = this.baseurl + 'places/show/' + id;
    }
  
    return this.__http.get( this.temp)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }
  post_adminplaces(data): Observable<any> {
    this.temp = this.baseurl + "admin/places";
    return this.__http.post<any>(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  public getIPAddress() {
    return this.http.get(environment.ipify_url);
  }
}
