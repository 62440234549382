import { TeacherService } from './../../../services/teacher.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss']
})
export class TeachersComponent implements OnInit {
  lastId=null;
  limit=10;
  country = "";
  title="";
  text:string="";
  skill="";
habilidades=[];
pais=[];
profesion=[];
informacion=[];
load=false;
nomore=false;
keyword=""
  constructor( private teacherService: TeacherService ) { }

  ngOnInit(): void {



  this.callTeacher()


  }

  callTeacher(){
    this.load=true;
    this.teacherService.get_ListTeacher(this.keyword,this.limit,this.lastId,this.skill,this.country,this.title).subscribe((data)=>{
    this.load=false;
    this.habilidades= data.skills
    this.pais= data.countries
    this.profesion=data.titles
    data.data.forEach(element => {
      this.informacion.push(element)
      this.lastId=element.id
    });
    if(data.data.length===0){
      this.nomore=true;
    }else{
      this.nomore=false;
    }


        console.log(this.informacion);
        })
    }

    onImgError(event){
      event.target.src = './../../../../assets/images/person-default.svg'
     //Do other stuff with the event.target
     }

     cargarmas(){
      this.callTeacher()
     }

     buscarenlista(){
       this.informacion=[];
      this.callTeacher()
     }



}
