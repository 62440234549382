<div *ngIf="statusAffiliates==0" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px" class="mat-elevation-z8" style="padding:30px 30px 60px 30px">
    <p class="raleway-23-tg"><b>Mis afiliados</b></p>
    <p class="oxigen-16-g"><b>Aún no has registrado ningun afiliado.</b></p>
    <div fxLayoutAlign="start start" style="padding-top: 20px;" (click)="addStatusAffiliates()">
        <button class="btn-orange btn pointer">Agregar</button>
    </div>
</div>

<div *ngIf="statusAffiliates==1" class="mat-elevation-z8">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="60" fxLayoutGap="30px" style="padding:30px 30px 60px 30px">
      <p class="raleway-23-tg"><b>Mis Afiliados</b></p>
      <p class="oxigen-16-g"><b>{{ !editStatus ? 'Agregar nuevo afiliado'  : 'Editar afiliado'}}</b></p>
      <mat-form-field style="padding-top: 30px;" class="oxigen-14-g">
        <mat-label>Nombre</mat-label>
        <input matInput placeholder="Nombre" name="name" [formControl]="nameFormControl" [errorStateMatcher]="matcher">
        <mat-error *ngIf="nameFormControl.hasError('required') || nameFormControl.hasError('pattern')">
          {{ nameFormControl.hasError('required') ? errors.nameFormControl.msg.required :  errors.nameFormControl.msg.pattern}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="oxigen-14-g">
        <mat-label>Apellido</mat-label>
        <input matInput placeholder="Apellido" name="lastname" [formControl]="lastNameFormControl" [errorStateMatcher]="matcher">
        <mat-error *ngIf="lastNameFormControl.hasError('required') || lastNameFormControl.hasError('pattern')">
          {{ lastNameFormControl.hasError('required') ? errors.lastNameFormControl.msg.required : errors.lastNameFormControl.msg.pattern }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="oxigen-14-g">
        <mat-label>No. Documento</mat-label>
        <input matInput placeholder="No. Documento" name="dni" [formControl]="dniFormControl" [errorStateMatcher]="matcher">
        <mat-error *ngIf="dniFormControl.hasError('required') || dniFormControl.hasError('pattern') || dniFormControl.hasError('maxlength')">
          {{ dniFormControl.hasError('required') ? errors.dniFormControl.msg.required : (dniFormControl.hasError('pattern') ? errors.dniFormControl.msg.pattern : errors.dniFormControl.msg.maxlength) }}
        </mat-error>
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="18px" style="width: 100%;">
        <mat-form-field fxFlex="50" class="oxigen-14-g">
          <mat-label>Fecha de Nacimiento</mat-label>
          <input matInput [matDatepicker]="picker2" [formControl]="birthdayFormControl" [errorStateMatcher]="matcher">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-error *ngIf="birthdayFormControl.hasError('required')">
            {{errors.birthdayFormControl.msg.required}}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50" class="oxigen-14-g">
          <mat-label>Parentesco</mat-label>
          <mat-select [formControl]="relationShipFormControl" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let item of relationships; let i = index" [value]="item.id">{{item.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="relationShipFormControl.hasError('required')">
            {{errors.relationShipFormControl.msg.required}}
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field class="oxigen-14-g">
        <mat-label>Email</mat-label>
        <input matInput placeholder="correo@email.com" [formControl]="emailFormControl" [errorStateMatcher]="matcher">
        <mat-error *ngIf="emailFormControl.hasError('required') || emailFormControl.hasError('email')">
          {{emailFormControl.hasError('required') ? errors.emailFormControl.msg.required : errors.emailFormControl.msg.email }}
        </mat-error>
      </mat-form-field>

      <div fxLayoutAlign="start start" style="padding-top: 25px;">
        <button (click)="saveAffiliates()" [disabled]="disabledBtn" [ngClass]="{ 'btn-orange': true, 'btn': true, 'pointer': true, 'opacityBtn': disabledBtn }">Guardar</button>
      </div>
    </div>
</div>

<div *ngIf="statusAffiliates==2" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px" class="mat-elevation-z8" style="padding:30px 30px 60px 30px">
    <p class="raleway-23-tg"><b>Mis Afiliados</b></p>
    <div fxLayout="row" fxLayoutAlign="start stretch" style="padding-bottom: 18px;border-bottom:solid 2px #4C4C4C;">
        <p fxFlex="25"><b>Nombres y Apellidos</b></p>
        <p fxFlex="25"><b>Documento de identidad</b></p>
        <p fxFlex="25"><b>Fecha de Nacimiento</b></p>
        <p fxFlex="25"><b>Parentesco</b></p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" *ngFor="let af of affiliates;" style="padding-bottom: 10px;border-bottom:solid 1px black">
        <p fxFlex="25" class="pl-2r">{{af.name}} {{af.last_name}}</p>
        <p fxFlex="25" class="pl-2r">{{af.DNI}}</p>
        <p fxFlex="25" class="pl-2r">{{formatDate(af.birthdate)}}</p>
        <div fxLayout="row" fxFlex="25" fxLayoutAlign="start center">
            <p fxFlex="80" class="pl-2r">{{af.relationship}}</p>
            <button fxFlex="20" mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{id: af.affiliate_id}">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
    <br>
    <p style="padding-top: 30px;"><b>Agrega a un nuevo afiliado</b></p>
    <div fxLayoutAlign="start start" style="padding-top: 20px;" (click)="addStatusAffiliates()">
        <button class="btn-orange btn pointer">Registrar</button>
    </div>
</div>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-id="id">
    <button mat-menu-item (click)="editAffiliate(id)">
      <span>Editar</span>
    </button>
    <button mat-menu-item (click)="deleteAffiliate(id)">
      <span>Eliminar</span>
    </button>
  </ng-template>
</mat-menu>
