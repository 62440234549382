import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  public baseurl: string;
  temp: string;
  headers = new Headers();


  constructor(private __http: HttpClient) {
    this.baseurl = environment.url_server;
  }


  getAllActiveCoursesByTeacher(id): Observable<any>{
    this.temp = this.baseurl + 'admin/getAllActiveCoursesByTeacher/'+id ;
    return this.__http.get( this.temp)
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }
}
