<!-- 1ft section -->
<div fxLayout="row" fxLayout.lt-md="column" xLayoutAlign="start start"  fxLayoutGap="36px" id="ft-section">
    <div fxFlex="60%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutAlign.sm="space-between center">
        <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="Index-home">
            <i class="title-section-1" fxFlex>Disfrutas aprender?</i>
            <p class="subtitle-section-1" fxFlex>¡COMIENZA HOY MISMO!</p>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign.sm="center" style="padding-top: 20px;">
                <div class=" input-all">
                    <input [(ngModel)]="filter" class="text-input input-all"  placeholder="Todas las Categorias">
                </div>
                <select [(ngModel)]="selectSearch" class="text-input selected input-select-index" >
                  <option value="" selected disabled>Lugares, Cursos y Eventos</option>
                  <option [value]="typeCourses">Cursos</option>
                  <option [value]="typeEvents">Eventos</option>
                  <option [value]="typeSites">Lugares</option>
                </select>
                <button mat-raised-button class="registrate searchRegistrate" (click)="searchRedirect()" style="border-radius: 0 4px 4px 0; max-height: 53px;">
                  <p *ngIf="isMobile" style="text-align: center; font-size: 18px; font-family: Raleway-Regular; margin-bottom: 10px;"> <span style="position: relative;
                    bottom: 10px;" id="search-span">Search </span>
                    <span class="material-icons " style="font-size: 34px;">
                    search
                  </span></p>
                    <span  *ngIf="isMobile==false" class="material-icons input-all" style="font-size: 34px;">
                    search
                  </span>
                </button>
              </div>
        </div>
    </div>
    <div *ngIf="isMobile==false" fxFlex="40%" fxLayout="column" fxLayoutAlign="space-evenly center">
    </div>
</div>
<!-- section 3 block -->

<div fxLayout="row" fxLayout.xs="column" fxLayout.sm="row" fxLayoutAlign="space-between none" fxLayoutGap.xs="23.34px">

    <div fxFlex="34%" fxLayout="row" fxLayout.sm="column" fxLayoutAlign="space-between stretch" >
        <div class="box-square oranges icon" fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="start center stretch" id="lupa">
            <mat-icon>search</mat-icon>
        </div>
        <div class="box-square oranges" fxLayout="column" fxLayoutAlign.sm="center center" fxLayoutAlign="start start stretch" fxLayoutAling.xs="center" id="text-lupa">
            <div fxLayout="row" fxLayout.md="column" fxLayoutAlign="center start stretch" >
                <p class="title">Busca</p>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start stretch" >
                <p class="subtitle">lo que quieras aprender</p>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign.sm="center center" fxLayoutAlign="center start stretch" >
                <p class="subtitle-2">Selecciona de nuestras categorias el curso que te interese</p>
            </div>
        </div>
    </div>
    <div fxFlex="33%" fxLayout="row" fxLayout.sm="column" fxLayoutAlign="space-between stretch">
        <div class="box-square yellow" id="touch" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start center stretch">
            <mat-icon>touch_app</mat-icon>
        </div>
        <div class="box-square yellow" fxLayout="column" fxLayout.xs="column" fxLayoutAlign.sm="center center" fxLayoutAlign="start start stretch" id="text-touch">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start stretch" >
                <p class="title">Inscribete</p>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start stretch" >
                <p class="subtitle">en el curso seleccionado</p>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start stretch" >
                <p class="subtitle-2">Lee las caracteristicas y el contenido del curso y busca las opciones inscribirte</p>
            </div>
        </div>
    </div>
    <div fxFlex="33%"  fxLayout="row" fxLayout.sm="column">
        <div class="box-square blue"  fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start center stretch" id="check">
            <mat-icon>done_outline</mat-icon>
        </div>
        <div class="box-square blue" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start start stretch" id="text-check" fxLayoutAlign.sm="center center">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start stretch" >
                <p class="title">Disfruta</p>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start stretch" >
                <p class="subtitle">y sigue aprendiendo</p>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center start stretch" >
                <p class="subtitle-2">Acude a tu curso en el sitio seleccionado en el horario acordado</p>
            </div>
        </div>
    </div>
</div>
<!-- section white and orange -->

<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<!-- solo aplique flex box layout unicamente para las imagenes del slider, en ningun otro lado, usar flex box layout -->
<div class="row background-slider" class="title-slide-container">
    <div class="col-md-6" style="padding-bottom: 50px;" fxLayout.>
        <p class="title-slide">Explore todas las </p>
        <p class="subtitle-slide">Categorias</p>
    </div>
    <div class="col-md-6" style=" display: flex;align-items: center;justify-content: center; padding-bottom: 50px;">
        <button  routerLink="/search/cursos" mat-button class="example-icon favorite-icon style-login registrate" style="margin: 0px 20px;" >
            Ver Todas
        </button>
    </div>
    <div class="">
        <app-carousel-website></app-carousel-website>
    </div>
</div>
<!-- biembenidos de sogcial -->
<div class="welcome-sogcial" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between none" fxLayoutAlign.xs="center" style="background: url('assets/images/section-4.jpg') top center;" fxLayOutAlign.xs="center none">
    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="center start stretch" class="titulo-naranja-container" fxLayoutAlign.xs="center center">
        <div  fxLayout="column" fxLayoutAlign="center start stretch" fxLayout>
            <p class="title-orange">Bienvenidos a</p>
        </div>
        <div  fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center start stretch">
            <p class="subtitle-orange">Sogcial</p>
        </div>
    </div>
    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="center center stretch">
        <div  fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" >
            <p class="title-right-orange">Aqui econtraras cursos y actividades complementarias para los que disfrutan aprender continuamente.</p>
        </div>
        <div   fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" >

            <button id="about-us-button" mat-button class="example-icon favorite-icon style-login registrate" style="margin: 0px 20px;" routerLink="/about-us">
                APRENDE MÁS DE NOSOTROS
            </button>
        </div>
    </div>
</div>
<!-- 1ft steps of sogcial -->
<div fxLayout="row" fxLayout.lt-md="column-reverse" fxfxLayoutAlign="space-between none" style="background: #ffffff; height: 600px;">
    <div class="container-grey" fxFlex="50%" fxLayout="column" fxLayoutAlign="center start stretch">
    
                <img src="../../../assets/images/sogcialIndex.jfif" style="width: 100%;" alt="indexImageFirst">

    </div>
    <div class="title-orange-container" fxFlex="50%" fxLayout="column" fxLayoutAlign="center start stretch" fxLayoutAlign.sm="center center">
        <div  fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center start stretch" >
            <p class="title-orange">Primeros pasos en</p>
        </div>
        <div  fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center start stretch" >
            <p class="subtitle-orange">Sogcial</p>
        </div>
        <div  fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" >
            <button routerLink="/first-steps" mat-button class="example-icon favorite-icon style-login registrate" style="margin: 20px 0px;padding-left: 3em;padding-right: 3em;" >
                ENTERATE MAS
            </button>
        </div>
    </div>
</div>
<!-- instrutor or business -->
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between none" >
    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="center start stretch" >
        <div id="square-yellow-container" fxLayout="column" fxLayout.sm="column" fxLayoutAlign="center center stretch">

            <div class="square-yellow" fxLayout="row" fxLayoutAlign="center center stretch">
                <div fxFlex="70%" fxFlex.xs="60%"fxLayout="column" fxLayoutAlign="center end stretch" fxLayoutAlign.xs="center center">
                    <p class="title">Conviértete en</p>
                    <span class="span-instructor">instructor</span>
                    <p class="subtitle-2" id="subtitle-2-square-yellow">
                        Proporcionamos las herramientas y plataforma para que puedas pertenecer a una academia y dictar un curso</p>
                    <button  routerLink="/business-info" mat-button class="example-icon favorite-icon style-login registrate"
                    id="start-today-button">
                        Empieza Hoy mismo
                    </button>
                </div>
                <div fxFlex="30%" fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center start stretch" fxLayoutAlign.sm="end end">
                    <mat-icon id="mat-icon-business-center">business_center</mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div fxFlex="50%" fxLayout="column" fxLayoutAlign="center start stretch" >
        <div id="square-blue-container" fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center center stretch">

            <div class="square-blue" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch">
                <div fxFlex="30%" fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center start stretch">
                    <mat-icon id="store-icon" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="start start">storefront</mat-icon>
                </div>
                <div fxFlex="70%" fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center start stretch">
                    <p id="sogcial-business" class="title">Sogcial <br *ngIf="!isTablet">Business</p>
                    <p id="sogical-business-subtitle" class="subtitle-2">Publica, administra y configura los por nuestra plataforma. Todo online y accesible desde cualquier luegar.</p>
                    <button  id="get-it-now-button" routerLink="/business-info" mat-button class="example-icon favorite-icon style-login registrate">
                        Consiguelo Ahora
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-newsletter></app-newsletter>
