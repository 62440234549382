<mat-drawer-container class="drawer-container">
  <app-header ></app-header>
  <mat-drawer *ngIf="!flag"  #sidenav class="sidenav" mode="side" [opened]="opened">
    <app-sidebar ></app-sidebar>
  </mat-drawer>
  <div class="content" *ngIf="!flag">
    <router-outlet></router-outlet>
  </div>
  <div class="content-2" *ngIf="flag">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</mat-drawer-container>
