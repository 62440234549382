<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px"
    style="padding: 50px;">
    <div fxFlex="20%" fxLayout="column" fxLayoutAlign="start end stretch">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
            <mat-icon>remove_from_queue</mat-icon>
        </div>
    </div>
    <div fxFlex="80%" fxLayout="column" fxLayoutAlign="start start stretch">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
            <p class="p">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
        </div>
    </div>
</div>