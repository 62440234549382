<mat-card  fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="15px" *ngIf="proposal">
    <div fxFlex="70%" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="24px" style="padding: 20px 50px 20px 20px;">
        <div *ngIf="type=='proposal'; else viewOfert" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="24px">
            <h1 fxFlex class="title" translate>description</h1>
            <p class="text">{{ proposal.description }}</p>
            <h1 fxFlex class="title" translate>budget.send_proposal</h1>
            <p class="text"><b>{{"budget.interested_proposal"|translate}},</b> {{"budget.interested_proposal2"|translate}} </p>
            <div class="border-b"><textarea matInput rows="7"></textarea></div>
            <p class="text">{{"budget.additionality"| translate}}</p>
            <div *ngFor="let item of proposal.questions_and_responses; let i = index" style="margin-bottom: 20px;">
                <p class="text" >{{i+1}}. {{ item.question }}</p>
                <div class="border-b"><textarea matInput rows="7">{{item.response}}</textarea></div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px">
                <button routerLink="/dashboard/proposals" class="btn btn-orange" style="min-width: 140px;" translate>back</button>
            </div>
          </div>
          <ng-template #viewOfert >
            <div fxLayout="column" fxLayoutGap="24px">
               <h1 fxFlex class="title" translate>{{ proposal.contract_title }}</h1>
                <h2 fxFlex class="title">Especificaciones del trabajo</h2>
                <p class="text">{{ proposal.description }}</p>
                <img fxFlex="22px" [src]="proposal.image">
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px" *ngIf="proposal.type_user==1">
                <button (click)="rejectOffer(proposal.id)" class="btn btn-border-orange color-orange" style="min-width: 140px;">Rechazar</button>
                <button (click)="acceptOffer(proposal.id)" class="btn btn-orange" style="min-width: 140px;">Aceptar</button>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px" *ngIf="proposal.type_user==2">
                <button routerLink="/dashboard/proposals" class="btn btn-orange" style="min-width: 140px;" translate>back</button>
            </div>
          </ng-template>
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="start start stretch" fxLayoutGap="40px" style="border-left: 2px solid #ccc;
    padding: 20px;">
        <p class="title2"><strong translate>details</strong></p>
        <div fxLayout="column" fxLayoutGap="10px">
            <p *ngIf="proposal.course_id != null" class="subtitle" ><strong translate>course</strong></p>
            <p *ngIf="proposal.event_id != null" class="subtitle" ><strong translate>event</strong></p>

            <p class="text">{{ proposal.title }}</p>
            <p class="text">{{"title" | translate}}: {{ proposal.title }}</p>
            <p class="text">{{"level" | translate}}: {{ proposal.level }}</p>

            <p class="text" *ngIf="proposal.price_per_hour!=null"> {{"budget.price" | translate}}: {{"budget.price_hour" | translate}}</p>
            <p class="text" *ngIf="proposal.price_total!=null"> {{"budget.price" | translate}}: {{"budget.fixed" | translate}}</p>

            <p class="text ml-52" *ngIf="proposal.price_per_hour!=null"> {{ proposal.price_per_hour }} {{ proposal.currency }}</p>
            <p class="text ml-52" *ngIf="proposal.price_total!=null"> {{ proposal.price_total }} {{ proposal.currency }}</p>

            <p class="text" *ngIf="type=='ofert'"> {{"budget.weeklyHrs"|translate}}: {{proposal.weekly_hours}}</p>
            <p class="text">{{"budget.estimated_time"|translate}}: {{ proposal.time_length }} {{ proposal.time_unit }}</p>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" *ngIf="proposal.type_user==1">
            <p class="subtitle"><b>{{"budget.summary.Enterprise" | translate}} </b><span class="text" style="font-weight: 400;">(Usuario {{ proposal.type_user == 1 ? 'Business': 'Teacher'}})</span></p>
            <p class="text">{{ proposal.user_name }}</p>
            <div fxLayout="row" style="width: 290px">
                <span *ngFor="let item of [].constructor(contStar); let i = index" class="material-icons">star</span>
                <span *ngFor="let star of [].constructor(5-contStar); let j = index" class="material-icons">star_border</span>
            </div>
            <button (click)="acceptProposal(proposal.proposal.id)" class="btn-orange btn">{{'ev-create.acept' | translate}}</button>
        </div>

    </div>
    <div fxFlex="25%" fxLayout="column" fxLayoutAlign="start start stretch" class="padding-sidebar">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <p class="subtitle-sub"><strong>{{'budget.summary.title' | translate}}</strong></p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <p class="subtitle-instructor">instructor</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <p class="subtitle-course" ><strong>{{"budget.summary.course" | translate}}</strong></p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <p class="subtitle-instructor">instructor</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <p class="subtitle-course"><strong>{{"budget.summary.Enterprise" | translate}}</strong></p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start stretch" fxLayoutGap.gt-sm="20px">
            <p class="subtitle-business">Usuario Bussines </p>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" *ngIf="proposal.place.length>0">
            <p class="subtitle"><strong translate>places</strong></p>
            <p class="text">{{ proposal.place[0].place}} <br> {{ proposal.place[0].address}} {{ proposal.place[0].ubication}}</p>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" >
            <p class="subtitle"><strong>{{"budget.summary.contract_type" | translate}} </strong></p>
            <p class="text">{{ proposal.type_contract}}</p>
        </div>

        <!-- <div fxLayout="column" fxLayoutGap="10px">
            <p class="subtitle"><strong>Horario</strong></p>
            <p class="text">{{ proposal.course.start_date }} - {{ proposal.course.end_date }}</p>
        </div> -->
    </div>

</mat-card>
