import { CourseDashboardService } from './../../../services/course-dashboard.service';
import { HomeComponent } from './../events/home/home.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-center',
  templateUrl: './dashboard-center.component.html',
  styleUrls: ['./dashboard-center.component.scss']
})
export class DashboardCenterComponent implements OnInit {

dashCards;
date;
lang:string;
user
id
info
  constructor(
    private courseDashboardService: CourseDashboardService,)

    {}

  ngOnInit() {
    this.lang = (localStorage.getItem('lenguage')) ? localStorage.getItem('lenguage').toLowerCase(): 'es';
    this.date = new Date().toLocaleDateString(this.lang+'-GB', {
      day : 'numeric',
      month : 'long',
      year : 'numeric'
    });


  	this.dashCards= JSON.parse(localStorage.getItem('dashCards'));
    this.user = JSON.parse(localStorage.getItem('userData'));


    this.courseDashboardService.get_home(this.user.id).subscribe(
        (data) => {
         console.log(data)
this.info=data
        }
      );
    }

  }




