<div style="padding-left: 50px">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <p class="raleway-23-g" style="padding-top: 18px">
      <b>Aceptar propuesta</b>
    </p>
    <button [mat-dialog-close]="null" class="btn-close">
      <mat-icon aria-hidden="false">clear</mat-icon>
    </button>
  </div>

  <div class="detalles">
    <p class="raleway-14-bold" *ngIf="data.id_course">
      Curso: <span class="title">{{ data.title }}</span>
    </p>
    <p class="raleway-14-bold" *ngIf="data.id_event">
      Evento: <span class="title">{{ data.title }}</span>
    </p>
  </div>

  <div class="fila1">
    <div class="perfil">
      <img [src]="data?.extra?.main_image" />
    </div>
    <div class="usuario">
      <div class="margen">{{ data?.extra?.name_business }}</div>

      <div class="margen">
        {{ data.extra.place_name }}
      </div>
      <div class="margen">
        <mat-icon *ngFor="let s of [].constructor(data.starscont)"
          >star</mat-icon
        >
      </div>
    </div>
  </div>
  <div class="enviar">
    <span>Enviar un mensaje al cliente</span>
  </div>
  <textarea [(ngModel)]="reason" name="" id="" cols="99" rows="3"></textarea>

  <div class="botones">
    <button mat-dialog-close="remove" class="btn">CANCELAR</button>
    <button (click)="acceptproposal()" class="btn">ACEPTAR</button>
  </div>
</div>
