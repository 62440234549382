import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { AddressService } from '../../../../../services/address.service';

@Component({
  selector: 'app-list-address',
  templateUrl: './list-address.component.html',
  styleUrls: ['./list-address.component.scss']
})
export class ListAddressComponent implements OnInit {
  displayedColumns: string[] ;
  load:boolean=false
  dataSource;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  userData: any;
  id;
  @Output() savings = new EventEmitter<number>();

  constructor(
    private AddressService:AddressService,
  ) { }

  ngOnInit(): void {
    this.userData=JSON.parse(localStorage.getItem('userData'))
    let ELEMENT_DATA=[]
    this.AddressService.get_addresses(this.userData.id).subscribe(
      resz => {
        console.log(resz.address)
       if(resz.message_return.ERROR == false ){
        this.load=true;
        resz.address.forEach(element => {
          ELEMENT_DATA.push({
              name: element.country+" "+element.state+" "+element.city+" "+element.municipality+" "+element.parish+" "+element.address,
              category: element.main_address,
              address_id:element.address_id,
              edit: 'create',
              copy: 'cancel',
            });
        });

        
        this.dataSource = (ELEMENT_DATA);
        console.log(this.dataSource)
        this.displayedColumns = ['name', 'category', 'acciones'];
        this.dataSource.paginator = this.paginator;    
        }
     });

    

  }
  edit(id){
    console.log(id)
    this.id=id
    localStorage.setItem('address_id',id);
    this.savings.emit();  
  }
  deleted(id){
    this.load=false;
    this.AddressService.delete_adminaddresses(id).subscribe(resp=>{
      this.load=true;
      this.ngOnInit()
    });
  }

}
export interface PeriodicElement {
  name: string;
  category: string;
  courses_events: String;
  location: string;
  edit: string;
  copy: string;
}

// let ELEMENT_DATA: PeriodicElement[]