import { Component, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatAccordion } from '@angular/material/expansion';
import { PlaceService } from '../../../../services/place.service';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { ProposalService } from '../../../../services/proposal.service';
import { GeneralService } from '../../../../services/general.service';
import { EventsService } from '../../../../services/events.service';
import { CourseDashboardService } from '../../../../services/course-dashboard.service';
import { M } from '@angular/cdk/keycodes';
import { ModalCancelProposalComponent } from '../../events/home/modal-cancel-proposal/modal-cancel-proposal.component';
import { MatDialog } from '@angular/material/dialog';
import { computeSegEndResizable } from '@fullcalendar/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ListsComponent implements OnInit {
  dataSource;
  dataSourceAux;
  dataSourceBackUp;
  columnsToDisplay: string[];
  id;
  load = true;
  hide = true;
  hidePlace = true;
  expandedElement: PeriodicElement | null;
  userData;
  categoryListreserva = [];
  filterValues = {
    category: '',
    location: '',
  };
  category = new FormControl();
  categoryList = [];
  location = new FormControl();
  locationList = [];
  contenido = [];
  received: any;
  dataUser;
  ciudades = [];
  element;
  contenidoreserva = [];
  dataSourcer = [];
  columnsToDisplayr = ['name', 'weight', 'symbol', 'position'];
  expandedElementr: PeriodicElement | null;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('paginator') paginator: MatPaginator;

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  constructor(
    private PlaceService: PlaceService,
    private ProposalService: ProposalService,
    private generalService: GeneralService,
    private courseDashboardService: CourseDashboardService,
    private eventsService: EventsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.dataUser = JSON.parse(localStorage.getItem('userData'));

    this.msgSuscription = this.generalService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.generalService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });

    console.log(this.dataUser);
    this.ProposalService.post_adminuserProposals(this.dataUser.id).subscribe(
      (resp) => {
        console.log(resp);
      }
    );

    // this.ProposalService.get_adminreceivedproposals(this.dataUser.id).subscribe(received=>{
    //   console.log("RECIVE = ",received);

    //   this.received=received;
    // })

    this.serviceGetPlaces();

    this.getreservation();
  }

  getreservation() {
    this.contenido = [];
    this.PlaceService.getreserva_places(this.dataUser.id).subscribe((data) => {
      console.log('================================================', data);
      const cursos = data.courses?.map((c) => {
        return { ...c, iscourse: true };
      });

      const eventos = data.events?.map((e) => {
        return { ...e, iscourse: false };
      });
      this.contenido = cursos.concat(eventos);
      this.contenido = this.contenido.map((m) => {
        return {
          ...m,
          expand: false,
          id_event: m.id_course_event,
          id_course: m.id_course_event,
        };
      });
      this.contenidoreserva = this.contenido;
      this.categoryListreserva = this.contenidoreserva.map((c) => c.category);
      this.categoryListreserva = [...new Set(this.categoryListreserva)];
      this.ciudades = this.contenidoreserva.map((c) => c.city);
      this.ciudades = [...new Set(this.ciudades)];
    });
  }

  categoryreserva(e) {
    console.log(e.value);
    this.contenido = this.contenido.filter((data) =>
      e.value.includes(data.category)
    );
    if (e.value.length === 0) {
      this.contenido = this.contenidoreserva;
    }
  }

  ciudadfiltro(c) {
    console.log(c.value);
    this.contenido = this.contenido.filter((data) =>
      c.value.includes(data.city)
    );
    if (c.value.length === 0) {
      this.contenido = this.contenidoreserva;
    }
  }
  // ============

  serviceGetPlaces() {
    let ELEMENT_DATA = [];
    let data = {
      user_id: this.userData.id,
    };

    this.PlaceService.get_places(data).subscribe(
      (res) => {
        if (res.message_return.ERROR != true) {
          res.result.forEach((element) => {
            ELEMENT_DATA.push({
              name: element.Name,
              category: element.Category,
              courses_events: element.courses + element.events,
              location: element.Ubication,
              edit: 'create',
              copy: 'visibility',
              delete: 'delete',
              description: element.description,
              id_place: element.id,
              main_image: element.main_image ? element.main_image : '',
            });

            if (this.categoryList.indexOf(element.Category) == -1)
              this.categoryList.push(element.Category);

            if (this.locationList.indexOf(element.Ubication) == -1)
              this.locationList.push(element.Ubication);
          });
          this.dataSource = new MatTableDataSource(ELEMENT_DATA);
          this.dataSourceBackUp = this.dataSource;
          this.dataSourceAux = new MatTableDataSource(ELEMENT_DATA);
          this.columnsToDisplay = [
            'name',
            'category',
            'courses_events',
            'location',
            'edit',
            'copy',
            'delete',
          ];
        }

        this.category.valueChanges.subscribe((category) => {
          this.filterValues.category = category;
          console.log(
            'this.filterValues.category:: ',
            this.filterValues.category
          );

          this.dataSource.data = this.dataSourceAux.data.filter((dat) => {
            let ok = false;
            for (let i = 0; i < this.filterValues.category.length && !ok; i++) {
              let category = this.filterValues.category[i];
              if (category == dat.category) ok = true;
            }
            return ok;
          });
        });

        this.location.valueChanges.subscribe((location) => {
          this.filterValues.location = location;
          console.log(
            'this.filterValues.location:: ',
            this.filterValues.location
          );

          this.dataSource.data = this.dataSourceAux.data.filter((dat) => {
            let ok = false;
            for (let i = 0; i < this.filterValues.location.length && !ok; i++) {
              let location = this.filterValues.location[i];
              if (location == dat.location) ok = true;
            }
            return ok;
          });
        });
        if (this.columnsToDisplay && this.dataSource) {
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
          }, 0);
        }
      },
      (err) => {
        console.log('ERRor ', err);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterReseva(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.contenido = this.contenidoreserva.filter(
      (data) =>
        data.name_place?.toLowerCase().includes(filterValue) ||
        data.course_category?.toLowerCase().includes(filterValue) ||
        data.category?.toLowerCase().includes(filterValue) ||
        data.country?.toLowerCase().includes(filterValue) ||
        data.state?.toLowerCase().includes(filterValue) ||
        data.city?.toLowerCase().includes(filterValue) ||
        data.name_place?.toLowerCase().includes(filterValue)
    );
  }

  reset() {
    // console.log(" dataSourceBackUp",this.dataSourceBackUp)
    // this.dataSource=this.dataSourceBackUp;
    // this.category.setValue(null);
    // this.location.setValue(null);
  }

  onImgError(event) {
    event.target.src = './assets/images/image-carousel.png';
  }
  serviceDataToCancelReservation(element: any) {
    if (this.msg == null) {
      let reservation;
      let schedule;
      this.courseDashboardService
        .get_admin_dataToCancelReservation(element.id_course)
        .subscribe(
          (res: any) => {
            console.log('get_admin_dataToCancelReservation', res);
            reservation = res.results[0].reservation;
            schedule = res.results[0].schedules;
            this.openDialogCancelProposal(element, reservation, schedule);
            this.getreservation();
          },
          (err) => {
            console.log('ERRor get_admin_dataToCancelReservation', err);
            this.generalService.typeMsg$.emit('error');
            this.generalService.msg$.emit(err.statusText);
          }
        );
    }
  }

  deleteplace(id) {
    console.log('ssssssssss', id);
    this.PlaceService.get_deleteplace(id).subscribe((data) => {
      console.log('gggggggg', data);
      this.dataSource = null;
      this.serviceGetPlaces();
      this.generalService.typeMsg$.emit('success');
      this.generalService.msg$.emit('Operacion exitosa');
    });
  }

  serviceDataToCancelReservationevento(element: any) {
    if (this.msg == null) {
      let reservation;
      let schedule;
      this.eventsService
        .get_admin_dataToCancelReservation(element.id_event)
        .subscribe(
          (res: any) => {
            console.log('get_admin_dataToCancelReservation', res);
            reservation = res.results[0].reservation;
            schedule = res.results[0].schedules;
            this.openDialogCancelProposal(element, reservation, schedule);
          },
          (err) => {
            console.log('ERRor get_admin_dataToCancelReservation', err);
            this.generalService.typeMsg$.emit('error');
            this.generalService.msg$.emit(err.statusText);
          }
        );
    }
  }

  openDialogCancelProposal(element, reservation: any, schedule: any) {
    const dialogRef = this.dialog.open(ModalCancelProposalComponent, {
      height: 'auto',
      width: 'auto',
      data: { element, reservation, schedule },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      console.log('result', result);
      if (result != undefined) {
        console.log('borrar reservation');
        this.eventsService.get_admin_CancelReservation(result).subscribe(
          (res: any) => {
            console.log('get_admin_CancelReservation', res);
            // window.location.reload();

            this.generalService.typeMsg$.emit('success');
            this.generalService.msg$.emit('Operacion exitosa');
            this.getreservation();
          },
          (err) => {
            console.log('ERRor get_admin_CancelReservation', err);
          }
        );
      }
    });
  }

  clearcategory(){
    this.category.setValue(null, {emitEvent:false});
    this.dataSource.data = this.dataSourceAux.data
  }

  clearlocation(){
    this.location.setValue(null, {emitEvent:false});
    this.dataSource.data = this.dataSourceAux.data
  }

  clearcateg(selectcategory){
    selectcategory.value='';
    this.contenido= this.contenidoreserva;

  }

  clearubication(selectlocation){
    selectlocation.value='';
    this.contenido= this.contenidoreserva;
  }
}

export interface PeriodicElement {
  name: string;
  category: string;
  courses_events: String;
  location: string;
  edit: string;
  copy: string;
  delete: string;
}

interface Filter {
  value: string;
  viewValue: string;
}
