
<div class="row">
    <div class="col-md-12 col-xs-12">
        <mat-card class="cards-chat">
            <div class="row">
                <div class="col-md-3 col-xs-12">
                    <mat-card class="cards-chat-hidden">
                        <app-box-input></app-box-input>
                        <app-box-user (NotifacationNew)="NotifacationNew$"  (messageEvent)="receiveMessage($event)" (name)="functionName($event)"></app-box-user>
                    </mat-card>
                </div>
                <div class="col-md-9 col-xs-12">
                    <mat-card style="box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);">
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <mat-card class="margins" style="box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);">
                                    <app-box-chat [childMessage]="parentMessage" [user]="user" [chatText]="chatText" [chatTexty]="chatTexty" [names]="names"></app-box-chat>
                                </mat-card>
                                <!-- <i *ngIf="band">esta escribiendo un mensaje</i> -->
                            </div>
                            <div class="col-md-12 col-xs-12"  *ngIf="inputChat">
                                <mat-card style="box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);">
                                <div class="row" style="height: 70px;">
                                    
                                    <div class="col-md-10 col-xs-9">
                                          <mat-form-field class="example-full-width">
                                            <mat-label>Escribe aqui para chatear</mat-label>
                                            <input matInput placeholder="" value="" [(ngModel)]="message" (keyup.enter)="addInput(message)" (blur)="onBlur()" (focus)="onFocus()">
                                        </mat-form-field>
                                        <emoji-mart  class="emoji-mart" set="{{set}}" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)" title="Pick your emoji…"></emoji-mart>
                                    </div>
                                    <div class="col-md-1 col-xs-1" style="display: flex;align-items: flex-end;justify-content: flex-end; height: 50px;">
                                        <button  (click) ="toggleEmojiPicker()" mat-fab color="accent" aria-label="Example icon button with a bookmark icon" style="box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);">
                                            <span class="material-icons" style="font-size:40px">sentiment_satisfied_alt</span>
                                        </button>
                                    </div>
                                    <div class="col-md-1 col-xs-2 ">
                                        <!-- <button mat-fab  aria-label="Example icon button with a bookmark icon" style="box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);">
                                            <label for="fileUpload" class="material-icons" style="font-size:40px">attach_file</label>
                                        </button> -->
                                        <input (change)="onFileChange($event)" type="file" id="fileUpload" #fileUpload style="position: absolute; width: 0px; height: 0px ;">
                                    </div>
                                </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </mat-card>
    </div>
</div>