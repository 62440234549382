<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
  <div class="content">
      <app-header></app-header>
      <app-sub-header *ngIf="load" [screen]="1"></app-sub-header>

      <div class="container-fluid">
          <div class="row">
              <div class="col col-md-6 col-lg-8">
                  <div class="containerCourseDetails">
                      <div class="row">
                          <div class="col-md-12">
                              <h2 class="title">Nombre del lugar: {{title}}</h2>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-12">
                              <p *ngIf="load" class="subtitle">Categoria: {{ categoryM }} > {{ subCategoryM }}</p>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-12">
                              <p *ngIf="load" class="description">{{description}}</p>
                          </div>
                      </div>
    
                      <div class="row">
                          <div class="col-md-12">
                              <div class="row" style="width: 100%;">
                                  <div class="col-md-12 col-xs-12">
                                      <app-slide-course-details *ngIf="load" [images]="carousel"></app-slide-course-details>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-12">
                              <div class="sectionSocial">
                                  <p class="space">Compartir <span style="margin-left: 10px;"> <img class="pointer" src="/assets/images/facebook_copia.png" width="25px" alt="Facebook">
                                    <img class="pointer" src="/assets/images/twitter_copia_2.png" width="25px" alt="Twitter">
                                    <img class="pointer" src="/assets/images/link_copia.png" width="25px" alt="Enlace"></span></p>
                                  
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-12">
                            <mat-tab-group *ngIf="load" style="width: 100%">
                              <mat-tab label="Descripcion">
                                <div class="long-tabs">
                                  <p *ngIf="load" class="itemTab">
                                    <mat-icon class="icon-s">fiber_manual_record</mat-icon>
                                    Capacidad: {{ place?.capacity }}
                                  </p>
                                  <p *ngIf="load" class="itemTab">
                                    <mat-icon class="icon-s">fiber_manual_record</mat-icon>
                                    Wifi: {{ wifi ? "Disponible" : "No Disponible" }}
                                  </p>
                                </div>
                              </mat-tab>
                            </mat-tab-group>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col col-md-6 col-lg-4">
                  <div class="sidebarRight">
                      <div class="item-event mt-5  row g-0">
                          <div class="col-8 col-sm-9 col-lg-9">
                              <!-- Botón para usuarios no registrados (caso de no estar loggeado) -->
                              <button mat-raised-button fxFlex
                              class="example-icon favorite-icon style-login registrate asd"
                              style="margin: 0px 20px; font-size: 12px;">
                              Reservar
                              </button>
                          </div>
                          <div class="col-2 ">
                            <a *ngIf="isLoggedIn" (click)="storeOrDelete(placeDetail.productId)" mat-button class="example-icon favorite-icon style-login registrate2" [ngClass]="{'favorite': placeDetail.isFavorite === 1, 'no-favorite': placeDetail.isFavorite === 0}">
                              <mat-icon>{{ placeDetail.isFavorite === 1 ? 'favorite' : 'favorite_border' }}</mat-icon>
                            </a>
                          </div>
                      </div>
                      <div style="padding-bottom: 30px;">
                        <div class="sideUser">
                          <div class="row ms-3">
                              <div class="col-3">
                                  <img *ngIf="load" src="/assets/images/default.png" width="100%" alt="">
                              </div>
                              <div class="col-9">
                                  <div class="row">
                                      <p *ngIf="load" class="address-user ">Responsable:</p>
                                      <p style="display: block;" class="name-user">{{ nameUser }} </p>
                                      <p *ngIf="load" class="address-user">{{ city }}, estado {{ state }}</p>
                                  </div>
                                  <div class="item-event">
                                      <div class="row">
                                          <span *ngIf="load" fxFlex [ngSwitch]="star" class="start-rigth">
                                              <span class="star" *ngSwitchCase="0"><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                  <span class="star" *ngSwitchCase="1"><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                  <span class="star" *ngSwitchCase="2"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                  <span class="star" *ngSwitchCase="3"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon><mat-icon>star_border</mat-icon></span>
                                                  <span class="star" *ngSwitchCase="4"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_border</mat-icon></span>
                                                  <span class="star" *ngSwitchCase="5"><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon><mat-icon>star_rate</mat-icon></span>
                                          </span>
                                          <a class="start-rigth width-full-side link-start" [routerLink]="'/owner-business/' + slug ">Ver Calificaciones</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                         
                      </div>
                      <div class="row">
                          <div class="col-md-12">
                              <div class="gray-box">
                                  <p *ngIf="load" class="p-space academy">Ubicación</p>
                              </div>

                              <div
                              fxLayout="row"
                              fxLayout.lt-md="column"
                              fxLayoutAlign="space-between stretch"
                              fxLayoutGap.gt-sm="20px"
                            >
                              <p *ngIf="load" class="sideAddress">
                                {{ city }} - {{ zone }} - {{ zipCode }}<br />
                                {{ address }}
                              </p>
                            </div>
                            <div
                              fxLayout="row"
                              fxLayout.lt-md="column"
                              fxLayoutAlign="space-between stretch"
                              fxLayoutGap.gt-sm="20px"
                              class="sideMap"
                            >
                              <agm-map [latitude]="lat" [zoom]="15" [longitude]="lng">
                                <agm-marker
                                  *ngFor="let m of markers; let i = index"
                                  [latitude]="m.lat"
                                  [longitude]="m.lng"
                                  [markerDraggable]="draggable"
                                ></agm-marker>
                              </agm-map>
                            </div>

                              
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-12">
                              <div class="gray-box">
                                  <p *ngIf="load" class="p-space academy">Contacto</p>
                              </div>
                              <div class="pt-teachers pb-teachers">
                                  <div class="row ms-3"  routerLink="/bio-teacher/1" title="Ver bio de Jose Rojas">
                                      <div class="col-3">
                                      </div>
                                      
                                  </div>
                                  <div class="row">
                                      <p  class="itemTeacher">
                                          <mat-icon class="icon-s">fiber_manual_record</mat-icon> Telefono: {{ phone }} <span *ngIf="phone_2">- {{ phone_2 }}</span>

                                        </p>

                                        <p  class="itemTeacher">
                                          <mat-icon class="icon-s">fiber_manual_record</mat-icon> Correo: {{email}}

                                        </p>

                                        <p  class="itemTeacher">

                                          <mat-icon class="icon-s">fiber_manual_record</mat-icon> Web: {{web}}
                                        </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-12">
                              <div class="gray-box">
                                  <p *ngIf="load" class="p-space academy">Horario</p>
                              </div>
                              <div class="pt-schedule pb-schedule">
                                  <div class="row">
                                      <p *ngIf="load && schedule.length > 0" class="itemSchedule">
                                          <mat-icon class="icon-s">fiber_manual_record</mat-icon> Fecha de Inicio 10/10/2020: 10:00 - 15:00</p>
                                  </div>
                                  <div class="row">
                                      <p *ngIf="load && schedule.length > 0" class="itemSchedule">
                                          <mat-icon class="icon-s">fiber_manual_record</mat-icon> Fecha de Fin 10/10/2020: 10:00 - 15:00</p>
                                  </div>
                                  <div class="row">
                                      <p *ngIf="load && schedule.length == 0" class="itemSchedule">
                                          <mat-icon class="icon-s">fiber_manual_record</mat-icon> No Disponible</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      
  <app-footer></app-footer>
  </div>
</mat-drawer-container>