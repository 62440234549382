<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between none"
  fxLayoutGap="36px"
  style="background: #e2e2e2; padding: 5em"
>
  <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap.gt-sm="20px"
    >
      <input  (keyup.enter)="buscarenlista()" [(ngModel)]="keyword" class="input-searching" fxFlex/>
      <button   (click)=" buscarenlista()" mat-raised-button fxFlex class="registrate">Buscar</button>
      <button mat-raised-button fxFlex class="registrate">
        <mat-icon>filter_list</mat-icon> Filtar
      </button>
    </div>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap.gt-sm="20px"
      class="styled-select"
    >
      <select   (change)="buscarenlista()" fxFlex class="select-searching" [(ngModel)]="skill">
        <option value="" disabled>Seleccione Habilidad</option>
        <option *ngFor="let item of habilidades" value="{{ item.name }}">
          {{ item.name }}
        </option>
      </select>

        <select (change)="buscarenlista()" fxFlex class="select-searching" [(ngModel)]="country">
        <option value="" disabled>Seleccione Pais</option>
        <option *ngFor="let item of pais" value="{{ item.name }}">
          {{ item.name }}
        </option>
      </select>
      <select (change)="buscarenlista()" fxFlex class="select-searching" [(ngModel)]="title">
        <option value="" disabled>Seleccione Profesion</option>
        <option *ngFor="let item of profesion" value="{{ item.title }}">
          {{ item.title }}
        </option>
      </select>
    </div>
  </div>
</div>

<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between none"
  fxLayoutGap="36px"
  class="padding-teachers"
>
  <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap.gt-sm="20px"
    >
      <p class="search-return">Mostrando {{ limit }} resultados</p>
    </div>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap.gt-sm="50px"
      style="padding: 3em; margin-top: 2rem; border-bottom: 1px solid #000000"
      *ngFor="let info of informacion"
    >
      <div fxFlex="15%" class="img">
        <img src="{{ info.avatar }}" (error)="onImgError($event)" alt="" />
      </div>
      <div fxFlex="85%" fxLayout="column" fxLayoutAlign.gt-sm="space-between center" fxLayoutAlign="space-between start"  fxLayoutGap.lt-md="1em">
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between start"
          fxLayoutAlign.lt-md="space-between center"
          fxLayoutGap.lt-md="1em"
          style="width: 100%; padding-top: 2.5em"
        >
          <p fxFlex class="title">{{ info.name }} {{ info.last_name }}</p>
          <p class="space carrer" fxFlex>{{ info.title }}</p>
          <button
            [routerLink]="'/detail/teacher-public/'+info.id"
            mat-raised-button
            fxFlex
            class="show-course"
          >
            Ver Perfil
          </button>
        </div>
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between start"
          fxLayoutAlign.lt-md="space-between center"
          fxLayoutGap.lt-md="1em"
          style="width: 100%; padding-bottom: 2em"
        >
          <p class="space-2 subtitle" fxFlex>
            {{ info.country }},{{ info.statename }}-{{ info.cityname }}
          </p>
          <div fxFlex class="estrella">
            <span *ngFor="let s of [].constructor(5); let i = index">
              <ng-container *ngIf="i < info.teacher_average_stars; then rateIcon else borderIcon"></ng-container>
              <ng-template #rateIcon><mat-icon class="star-icon">star_rate</mat-icon></ng-template>
              <ng-template #borderIcon><mat-icon class="star-icon">star_border</mat-icon></ng-template>
            </span>
            
            
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="start"
          style="width: 100%; padding-bottom: 2em"
        >
          <mat-chip-list aria-label="Fish selection" *ngFor="let item of info.skills">
            <mat-chip >{{item.name}}</mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between none"
  fxLayoutGap="36px"
  style="padding: 50px 155px"
>
  <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center center stretch"
      fxLayoutGap.gt-sm="20px"
    >
      <mat-spinner *ngIf="load"></mat-spinner>
    </div>
    <div

      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center center stretch"
      fxLayoutGap.gt-sm="20px"
    >
      <p *ngIf="nomore">No hay mas  Profesores </p>
    </div>
    <div
    *ngIf="!nomore"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center center stretch"
      fxLayoutGap.gt-sm="20px"
    >
      <button  (click)=" cargarmas()" mat-raised-button fxFlex class="show-course-load">cargar mas</button>
    </div>
  </div>
</div>
