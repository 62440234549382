<div style="padding-left: 50px">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <p class="raleway-23-g" style="padding-top: 18px">
      <b *ngIf="data.course_id">Eliminar Curso</b>
      <b *ngIf="data.event_id">Eliminar Evento</b>
    </p>
    <button [mat-dialog-close]="null" class="btn-close">
      <mat-icon aria-hidden="false">clear</mat-icon>
    </button>
  </div>

  <div class="question">
    <span>¿Estas seguro de que quieres eliminar?</span>
  </div>

  <div class="detalles">
    <p class="raleway-14-bold" *ngIf="data.course_id">
      Curso: <span class="title">{{ data.title }}</span>
    </p>
    <p class="raleway-14-bold" *ngIf="data.event_id">
      Evento: <span class="title">{{ data.title }}</span>
    </p>
  </div>

  <span class="datos">* Perderas todos los datos</span>

  <div class="botones">
    <button mat-dialog-close="remove" class="btn">CANCELAR</button>
    <button (click)="deleteproposal()" class="btn">ACEPTAR</button>
  </div>
</div>
