<div fxLayout="column" fxLayoutGap="21px" style="height: 430px; width: 808px; padding: 30px 40px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <p class="raleway-23-g"><b>Cancelar contrato</b></p>
        <button [mat-dialog-close]="null" class="btn-close">
            <mat-icon aria-hidden="false">clear</mat-icon>
        </button>
    </div>

    <p class="oxigen-16-b" style="padding-top: 8px;">¿Estas seguro de que quieres cancelar este contrato?</p>
    <p class="oxigen-16-b"><b>Instructor:</b></p>
    <p class="raleway-16-g"><b>Curso/Evento:</b> {{data.element.name}}</p>
    <div style="overflow-y: scroll;">
        <div *ngFor="let user of user; let i=index" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="20px"
            style="width: 100%;">
            <mat-checkbox  [checked]="user.check" (change)="onChangingCheck(i)"></mat-checkbox>
            <img src="assets/images/image-default.png" alt="" class="imgTeacher" style="padding-right: 30px;">
            <div fxLayout="column" fxLayoutAlign="space-between stretch">
                <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="100px" style="width: 450px;" fxFlex>
                    <p fxFlex="50"><b>{{user.user_name}}</b></p>
                    <p fxFlex="50"><b>{{user.title}}</b></p>
                </div>
                <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="100px" style="width: 100%;" fxFlex>
                    <p fxFlex="50">{{user.ubication}}</p>
                    <p fxFlex="50">
                        <mat-icon *ngFor="let item2 of [].constructor(user.teacher_average_stars)">star</mat-icon>
                    </p>
                </div>
                <mat-chip-list>
                    <mat-chip *ngFor="let item of tags[i]" class="oxigen-14-b">{{user}}</mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>
    <p>*Ciertas condiciones aplican</p>

    <div fxLayout="row" fxLayoutAlign="end none" fxLayoutGap="20px">
        <div class="btn-border-orange pointer" [mat-dialog-close]="null" fxLayoutAlign="center center">
            <p class="font">{{'send-proposal.cancel' | translate}}</p>
        </div>
        <div fxLayoutAlign="center center" [mat-dialog-close]="onSave" (click)="onSaving()">
            <button class="btn-orange btn">{{'send-proposal.accept' | translate}}</button>
        </div>
    </div>
</div>
