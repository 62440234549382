import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from "../../../services/configuration.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    facebook = "";
    instagram = "";
    twitter = "";
    linkedin = "";
    ticktok = "";
  constructor(private ConfigurationService:ConfigurationService) { }

  ngOnInit(): void {
  
      this.ConfigurationService.get_configurations().subscribe(res => {
        console.log("res get_configurations:", res);
        this.facebook = res.configuration.facebook;
        this.instagram = res.configuration.instagram;
        this.twitter = res.configuration.twitter;
        this.linkedin = res.configuration.linkedin;
        this.ticktok = res.configuration.ticktok;
      }, err => {
        console.log("ERRor ", err)
      });
  }

}
