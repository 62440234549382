<div fxLayout="column" fxFlex fxLayoutGap="40px" style="padding: 20px;">
    <app-message *ngIf="msg!=null" [msg]="msg" [typeMsg]="typeMsg"></app-message>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <button mat-button (click)="show = 1" class="btn-type" [ngClass]="{'selected': show==1}">
        <img src="./assets/images/home.png" class="png">&#160; &#160;&#160;{{"create-place.own" | translate}}</button>
      <button mat-button (click)="show = 2" class="btn-type" [ngClass]="{'selected': show==2}">
        <img src="./assets/images/home-rent.png" class="png">&#160;
        &#160;&#160;{{"create-place.rented" | translate}}</button>
      <button mat-button (click)="show = 3" class="btn-type" disabled [ngClass]="{'selected': show==3}">
        <img src="./assets/images/home.png" class="png">&#160; &#160;&#160;{{"create-place.virtual" | translate}}</button>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <mat-form-field fxFlex="40%" *ngIf="show==1">
        <mat-label>{{'events.search' | translate }}</mat-label>
        <input matInput (keyup)="applyFilterOwn($event)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <mat-form-field fxFlex="40%" *ngIf="show==2">
        <mat-label>{{'events.search' | translate }}</mat-label>
        <input matInput (keyup)="applyFilterRent($event)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>

    <div [ngClass]="{'showTable': show==1, 'hideTable': show!=1}">
      <mat-table [dataSource]="dataSourceOwn">

        <ng-container matColumnDef="name">
          <th mat-header-cell fxFlex="25" class="table-td relawey-14-bold" *matHeaderCellDef>
            {{'create-place.name' | translate }}</th>
          <td mat-cell fxFlex="25" class="table-td oxigen-14" *matCellDef="let element">
            <p>{{element.Name}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell fxFlex="18" class="table-td relawey-14-bold" *matHeaderCellDef>
            {{'create-place.category' | translate }}</th>
          <td mat-cell fxFlex="18" class="table-td oxigen-14" *matCellDef="let element">
            <p>{{element.Category}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="capacity">
          <th mat-header-cell fxFlex="10" class="table-td relawey-14-bold" *matHeaderCellDef>
            {{'create-place.capacity' | translate }}</th>
          <td mat-cell fxFlex="10" class="table-td oxigen-14" *matCellDef="let element">
            <p>{{element.capacity}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="ubication">
          <th mat-header-cell fxFlex="40" class="table-td relawey-14-bold" *matHeaderCellDef>
            {{'create-place.location' | translate }}</th>
          <td mat-cell fxFlex="40" class="table-td oxigen-14" *matCellDef="let element">
            <p>{{element.Ubication}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="visibility">
          <th mat-header-cell class="radio-but" *matHeaderCellDef></th>
          <td mat-cell class="radio-but" *matCellDef="let element">
            <mat-icon class="pointer" (click)="openDialogOwn(element)">visibility</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsOwn"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsOwn;"></tr>

      </mat-table>

      <mat-paginator #paginatorOwn="matPaginator" [pageIndex]="0" [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons>
      </mat-paginator>

      <!-- <div *ngIf="createPage==true; else otherButton" fxLayoutAlign="center center">
        <button (click)="nextStepper()" matStepperNext class="btn-orange-auto btn">SIGUIENTE</button>
      </div> -->
    </div>

    <div [ngClass]="{'showTable': show==2, 'hideTable': show!=2}">
      <mat-table [dataSource]="dataSourceRent">

        <ng-container matColumnDef="name">
          <th mat-header-cell fxFlex="25" class="table-td relawey-14-bold" *matHeaderCellDef>
            {{'create-place.name' | translate }}</th>
          <td mat-cell fxFlex="25" class="table-td oxigen-14" *matCellDef="let element">
            <p>{{element.Name}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell fxFlex="18" class="table-td relawey-14-bold" *matHeaderCellDef>
            {{'create-place.category' | translate }}</th>
          <td mat-cell fxFlex="18" class="table-td oxigen-14" *matCellDef="let element">
            <p>{{element.Category}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="capacity">
          <th mat-header-cell fxFlex="10" class="table-td relawey-14-bold" *matHeaderCellDef>
            {{'create-place.capacity' | translate }}</th>
          <td mat-cell fxFlex="10" class="table-td oxigen-14" *matCellDef="let element">
            <p>{{element.capacity}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="ubication">
          <th mat-header-cell fxFlex="40" class="table-td relawey-14-bold" *matHeaderCellDef>
            {{'create-place.location' | translate }}</th>
          <td mat-cell fxFlex="40" class="table-td oxigen-14" *matCellDef="let element">
            <p>{{element.Ubication}}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="visibility">
          <th mat-header-cell class="radio-but" *matHeaderCellDef></th>
          <td mat-cell class="radio-but" *matCellDef="let element">
            <mat-icon class="pointer" (click)="openDialogRent(element)">visibility</mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsRent"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsRent;"></tr>

      </mat-table>

      <mat-paginator #paginatorRent="matPaginator" [pageIndex]="0" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
      </mat-paginator>
<!--
      <div *ngIf="createPage==true; else otherButton" fxLayoutAlign="center center">
        <button (click)="nextStepper()" matStepperNext class="btn-orange-auto btn">SIGUIENTE</button>
      </div> -->
    </div>

  </div>

  <ng-template #otherButton>
    <div fxLayoutAlign="center center" *ngIf="createPage==true">
      <button class="btn-orange-auto btn" routerLink="/dashboard/events">Volver</button>
    </div>
  </ng-template>
