import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute} from '@angular/router';
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.scss']
})
export class TermConditionComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  step = 0;
  type = 'term-condition';
  indexTab = 0;

  setStep(index: number) {
    this.step = index;
  }

  setIndex(type: string) {
    this.indexTab = type === 'term-condition' ? 0 : 1;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.type = activatedRoute.snapshot.url[0].path;
    this.setIndex(this.type);
  }

  ngOnInit(): void {
  }

}
