import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { SocketIoService } from './socket-io.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private eventSubject = new Subject<any>();
  private noti =new BehaviorSubject<any[]>([])
  noti$= this.noti.asObservable();
  baseurl: any;
  temp: string;
  user ;
  testNotification: any[] = [];
  meetingUser : any[] = [];
  MessagesNotifications : number;
  private messages: any[] = [];

  constructor(private __http: HttpClient,  private http: HttpClient, private socket: SocketIoService) {
    this.MessagesNotifications = 0;
    this.baseurl = environment.url_server;
    if(localStorage.getItem("userData")){
    this.socket.listenToServerEvents('sendMessagesPrivate').subscribe((data: any) => {

     });
  
      this.user = JSON.parse(localStorage.getItem("userData"));
      
     this.socket.getMeeting(this.user.id);
     this.socket.listenForChatsData().subscribe((privateChats) => {
      this.meetingUser = privateChats;
     });
     }

  }


  AddLastMessage(id){
      
    this.socket.getMessages(id);

    this.socket.listenForLastChats().subscribe(
      (data: any[]) => {
          this.messages.push(data[data.length-1])
      });
  }
  getNotificationsTest() {
      return  this.messages;
  }
  ListenEventTest(data): void{

      this.meetingUser.forEach(element => {
        if(element.meet.id === data.meeting_id){
          this.messages.push(data);
        }
        
      });

  }
  deleteNotification(id){
    const AllMessages = this.messages.filter((element) => element.meeting_id == id);
    AllMessages.forEach(message =>{
      this.messages.splice(message.index, 1);
    });
    this.noti.next(this.messages)

  }
  getMessages(){
    return this.messages;
    
  }
  AddMessage(data){
    this.messages.push(data)
  }
  SeenMessage(id){
    const AllMessages = this.messages.filter((element) => element.meeting_id == id);
    AllMessages.forEach(message =>{
      this.messages.splice(message.index, 1);
    })

  }
  CleanNotifications(){
    this.messages=[];
  }

  post_notificationsgetNotificationsByUserID(data):Observable<any> {
    this.temp = this.baseurl + 'notifications/getNotificationsByUserID' ;

    return this.__http.post( this.temp, data )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  post_getActiveNotificationsByUserID(data):Observable<any> {
    this.temp = this.baseurl + 'notifications/getActiveNotificationsByUserID' ;
    return this.__http.post( this.temp, data )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  put_notifications_status(data):Observable<any> {
    this.temp = this.baseurl + 'notifications/status' ;
    return this.__http.put( this.temp, data )
    .pipe( retry(1),
      catchError(this.errorHandl)
    );
  }

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }

}
