import { Component, OnInit } from '@angular/core';
import { PlaceService } from '../../services/place.service';
import { ActivatedRoute, Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import  {FavoriteService } from 'src/app/services/favorite.service';


@Component({
  selector: 'app-place-details',
  templateUrl: './place-details.component.html',
  styleUrls: ['./place-details.component.scss']
})
export class PlaceDetailsComponent implements OnInit {
  load:boolean = false;
  title:string = "Academia OneCode";
  category:string = "Educación";
  categoryM:string;
  isLoggedIn: boolean = false;

  description:string = "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.";
  carousel:any = [];
  userSlug: string;
  slug: any;
  userId: any;
  placeId: number;
  nameUser = "Nombre de Usuario";
  city:string = "San cristobal";
  state:string = "Tachira";
  zone:string = "la concordia";
  address:string = "calle unica calle unica calle unica";
  zipCode:string = "5001";
  phone:string = "04245525555";
  phone_2:string;
  fullSlug:any;
  email:string = "correo@midominio.com";
  web:string = "www.midominio.com";
  facebook:string = "@Facebook";
  twitter:string = "@Twitter";
  instagram:string = "@Instagram";
  youtube:string = "@Youtube";
  star=3;
  placeDetail: any;
  place:any;
  subCategory:any;
  subCategoryM:string;
  categoryAux:any;
  isFavorite: boolean = false;

  schedule:any;
  wifi:number;
  week = [ 'Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado' ];
  lat:any;
  zoom:any = 10;
  lng:any;
  markers = [];
  draggable:boolean = true;

    constructor(private PlaceService:PlaceService, private _router: Router, private route: ActivatedRoute,  public favorite: FavoriteService, ) {}

  ngOnInit(): void {

    const userData = localStorage.getItem('userData');

    this.isLoggedIn = userData !== null;

    this.checkLoginStatus();

    this.loadPlace();
    
  }

  toSlug(text: string): string {
    return text.toLowerCase().replace(/\s+/g, '-');
  }

  loadPlace() {
    const seo = this.route.snapshot.paramMap.get("id");
    const seoArray = seo.split("-");
    const id = parseInt(seoArray[0]);
    this.PlaceService.get_placesShow(id).subscribe( resp => {
      const { Sub_category, category, result, schedule } = resp;
      this.subCategory = Sub_category;
      this.categoryAux = category;
      this.subCategoryM = Sub_category ? this.subCategory[0]['Sub Category'].toLowerCase() : null;
      this.categoryM = category[0].Category.toLowerCase();
      this.place = result;
      this.schedule = schedule.map((elem) => {
        let text = this.week[elem.dayofweek] + ' ';
        for(let i = 0; i < elem.start_time.length; i++) {
          const start_time = elem.start_time[i].split(':');
          const hour_start = parseInt(start_time[0]);
          const type_hour = hour_start < 12 ? 'am' : 'pm';
          const hour_start_num = hour_start < 13 ? (hour_start == 0 ? 12 : hour_start) : (hour_start - 12);
          const hour_start_st = hour_start_num.toString();
          const end_time = elem.end_time[i].split(':');
          const hour_end = parseInt(end_time[0]);
          const type_hour_end = hour_end < 12 ? 'am' : 'pm';
          const hour_end_num = hour_end < 13 ? (hour_end == 0 ? 12 : hour_end) : (hour_end - 12);
          const hour_end_st = hour_end_num.toString();

          text += hour_start_st + ':' + start_time[1] + type_hour + ' a ' + hour_end_st + ':' + end_time[1] + type_hour_end;
          if(i+1 < elem.start_time.length) {
            text += ', ';
          }
        }
        return {
          ...elem,
          text: text
        };
      });
      console.log("resultzoso", result);
      this.title = result.Place;
      this.userId = result.user_id;
      this.category = category[0].Category;
      this.nameUser = result.User;
      this.userSlug = this.toSlug(this.nameUser);
      this.slug = this.userId + '-' + this.userSlug;
      this.state = result.State;
      this.description = result.description;
      this.city = result.city;
      this.zone = result.municipality;
      this.zipCode = result.zip_code;
      this.address = result.address;
      this.phone = result.phone;
      this.phone_2 = result.phone_2;
      this.email = result.email;
      this.web = result.web;
      this.facebook = result.facebook;
      this.twitter = result.twitter;
      this.instagram = result.instagram;
      this.youtube = result.youtube;
      this.wifi = parseInt(result.wifi);
      this.carousel = resp.images;

      console.log("////////////////",this.carousel);
      this.lat = parseFloat(result.latitude);
      this.lng = parseFloat(result.longitude);
      this.markers.push({
        lat: result.latitude,
        lng: result.longitude
      });
      this.load=true;
      this.placeDetail = result;
    }, err => {
      alert(err.error.message_return.ERROR_MENSAGGE);
    });
  }

  checkLoginStatus() {
    const userData = localStorage.getItem('userData');
    if (userData){
      this.isLoggedIn = true;
    }else{
      this.isLoggedIn = false;

    }
  }

  storeOrDelete(id: number) {
    const userId = JSON.parse(localStorage.getItem('userData')).id;
    const data = {
      user_id: userId,
      product_id: id
    };
  
    this.favorite.Post_favorite(data).subscribe(
      () => {
        if (this.placeDetail.isFavorite === 0) {
          this.placeDetail.isFavorite = 1;
        } else {
          this.placeDetail.isFavorite = 0;
        }
        },
      error => {
        console.error(error);
      }
    );
  }
  

  

}
