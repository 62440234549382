import { Component, OnInit,Inject,SimpleChanges, Input, ViewChild, ElementRef  } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from "./dialog/dialog.component";
import { SocketIoService } from '../../../../services/socket-io.service';
import { NotificationsService } from 'src/app/services/notifications.service';

declare var $: any;
import * as moment from 'moment';
export interface DialogData {
  animal: string;
  name: string;
}

export interface Message{
  id:number,
  name:string,
  image:string,
  message:string,
  message_id:number,
  hidden:boolean
}
@Component({
  selector: 'app-box-chat',
  templateUrl: './box-chat.component.html',
  styleUrls: ['./box-chat.component.scss']
})
export class BoxChatComponent implements OnInit {
  @ViewChild('contentBox') contentBox: ElementRef;
  animal: string;
  container: any;
  contentMsg: string;
  name: string;
  meeting_id;
  content;
  clear
  test:Message[]
  @Input() names=""
  username

  @Input() childMessage
  @Input() user; 
  @Input() chatText
  @Input() chatTexty
  datas = []
  tempChat ;
  text=[];
  band=false;
  userData = JSON.parse( localStorage.getItem("userData") )
  moment = moment;

  constructor(
    public dialog: MatDialog, private socket: SocketIoService ,
    private NotificationsService: NotificationsService,
    private elementRef: ElementRef
  ) { }
   
    status=false;
    SearchChat(search: string): void {
      if (search) {
        const searchLower = search.toLowerCase();
        this.childMessage = this.tempChat.filter(objeto => objeto.message.toLowerCase().includes(searchLower));

      } else {
        this.childMessage =  this.tempChat 
      }
  
    }
    Search(e){

      this.SearchChat(e)
    }
  ngOnInit(): void {
      

  }
  
  testing(data){
  
  }

  ngAfterViewInit(): void{
    this.testing("xx");

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.tempChat = this.childMessage 

    if(typeof changes.childMessage!="undefined" && changes.childMessage.firstChange!=true){
      this.test=this.datas.filter(o => o.id === changes.childMessage.currentValue);
      this.status=true;
      
    }
    if(typeof changes.chatText!="undefined" && changes.chatText.firstChange!=true){
      this.band=true;
    }
      if(typeof changes.chatTexty!="undefined" && changes.chatTexty.firstChange!=true){
      this.test.push({
        id:this.user.id,
        name:this.user.name+" "+this.user.last_name,
        image:"/assets/images/default.png",
        message:changes.chatTexty.currentValue,
        message_id:100000000,
        hidden:true
      })
      this.band=false;
    }
        
    setTimeout(() => {
      this.container = document.getElementById('content-box-chat');
      this.container.scrollTop = this.container.scrollHeight;
    });
    
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      height: '500px',
      data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.animal = result;
    });
  }
  edit(message_id){
    document.getElementById("text-box-" + message_id).style.display = "block";

  }

  delete(id){
    this.socket.deleteMessage(id);
    this.renderAgain()


    // this.socket.emit("delete_message",  { meetting_id : localStorage.getItem("meetting_id_current") , id : id });
  }
  renderAgain(){
   this.meeting_id = this.childMessage[0].meeting_id
   this.socket.getMessages(this.meeting_id);
   this.childMessage = [];
   const menssagesLast = this.socket.listenForLastChats().subscribe(
    (data: any[]) => {
      this.childMessage = data;
    },
    error => {
      console.error('Error en la solicitud HTTP:', error);
    }
  );
  }

  sendEdit(id){
    this.meeting_id = id;
    this.content= $(`#text-input-${id}`).val();
    this.socket.editMessage(this.meeting_id , this.content)
    this.renderAgain()
 }

  scroll(e){
    this.NotificationsService.deleteNotification(this.childMessage[0].meeting_id)
  }

  onFocus() {

  }

  onBlur() {

  }

}