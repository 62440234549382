import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
   name = 'Angular 6';
  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: '400px',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'Raleway', name: 'Raleway'},
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
  [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'subscript',
    'superscript',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'justifyFull',
    'indent',
    'outdent',
    'insertOrderedList',
    'heading',
  ],
  [
    'fontSize',
    'textColor',
    'backgroundColor',
    'link',
    'unlink',
    'insertImage',
    'insertVideo',
    'toggleEditorMode'
  ]
]
   
};

  constructor() { }

  ngOnInit(): void {
  }

}


// toolbarHiddenButtons: [
//   [
//     'bold',
//     'italic',
//     'underline',
//     'strikeThrough',
//     'subscript',
//     'superscript',
//     'justifyLeft',
//     'justifyCenter',
//     'justifyRight',
//     'justifyFull',
//     'indent',
//     'outdent',
//     'insertOrderedList',
//     'heading',
//     'fontName'
//   ],
//   [
//     'fontSize',
//     'textColor',
//     'backgroundColor',
//     'link',
//     'unlink',
//     'insertImage',
//     'insertVideo',
//     'insertHorizontalRule',
//     'removeFormat',
//     'toggleEditorMode'
//   ]
// ]