<div *ngIf="origin === 'one'" fxLayout="row" fxLayoutAlign="end center">
  <div (click)="inputFile.click()" fxLayout="row" class="subirimg">
    <mat-icon class="btn-icon pointer">add_photo_alternate</mat-icon>
    <label  mat-button class="btn btn-border" style="height: 33px;">
      <ng-container *ngIf="title !== null">{{ title }}</ng-container>
      <ng-container *ngIf="title === null">
        {{ "upload" | translate }}</ng-container
      >
    </label>
  </div>
  <input
    type="file"
    #inputFile
    class="btn-file"
    accept="image/*"
    multiple
    (change)="onFileChange($event)"
  />
</div>

<div *ngIf="origin === 'gallery'" fxLayout="row" fxLayoutAlign="end center">
  <div (click)="inputFileGallery.click()" fxLayout="row">
    <mat-icon class="btn-icon pointer">add_photo_alternate</mat-icon>
    <label mat-button class="btn btn-border" style="height: 33px">
      <ng-container *ngIf="title !== null">{{ title }}</ng-container>
      <ng-container *ngIf="title === null">
        {{ "upload" | translate }}</ng-container
      >
    </label>
  </div>

  <input
    type="file"
    #inputFileGallery
    class="btn-file"
    accept="image/*"
    multiple
    (change)="onFileChangeGallery($event)"
  />
</div>


