<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
        <app-header></app-header>
            <app-sub-header [screen]="'Businness'" ></app-sub-header>
            <app-boxes [image]="data"></app-boxes>

            <app-mini-section-left></app-mini-section-left>
            <app-mini-section-rigth></app-mini-section-rigth>
            <app-mini-section-left></app-mini-section-left>
            <app-mini-section-rigth></app-mini-section-rigth>
            
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px"
    style="padding: 5em;background: #F8F8F8;" >
                <div fxFlex="33%" fxLayout="column" fxLayoutAlign="space-evenly center" style="padding-top:17em;padding-bottom:14em;padding-left:5em;padding-right:5em;height: 200px; border: 1px solid #FE8426; border-radius: 5px;">
                    
                        <app-promotions></app-promotions>
                    
                    
                </div>
                <div fxFlex="33%" fxLayout="column" fxLayoutAlign="space-evenly center" style="padding-top:17em;padding-bottom:14em;padding-left:5em;padding-right:5em;height: 200px; border: 1px solid #FE8426; border-radius: 5px;">
                    
                        <app-promotions></app-promotions>
                    
                </div>
                <div fxFlex="34%" fxLayout="column" fxLayoutAlign="space-evenly center" style="padding-top:17em;padding-bottom:14em;padding-left:5em;padding-right:5em;height: 200px; border: 1px solid #FE8426; border-radius: 5px;">
                    
                        <app-promotions></app-promotions>
                    
                </div>
            </div>
        <app-footer></app-footer>
    </div>
</mat-drawer-container>