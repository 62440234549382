import { Component, OnInit } from '@angular/core';
import { NewsletterService } from "../../../services/newsletter.service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  email;
  error;
  showError:boolean = false;
  data;
  isMobile:boolean = false;
  isTablet:boolean = false;

  constructor(
    private NewsletterService: NewsletterService,
    private breakpointObserver: BreakpointObserver
  ) { }


  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => this.isMobile = result.matches);

    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => this.isMobile = result.matches);
    this.isTablet = !this.isMobile && window.innerWidth >= 600 && window.innerWidth <= 1023;

  }
  save() {
    let data = {
      email: this.email
    }
    this.showError = false
    this.NewsletterService.post_newsletterregister(data).subscribe(resp => {
      //console.log(resp);
      if (resp.message_return.ERROR == false) {
        this.data = resp.message_return.ERROR;
        console.log(data)
        this.error = resp.message_return.ERROR_MESSAGE;
        this.showError = true
      } else {
        this.data = resp.message_return.ERROR;
        console.log(data)
        this.error = resp.message_return.ERROR_MESSAGE;
        this.showError = true
      }

    })
  }
}
