import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  public baseurl: string;
  temp: string;
  errorHandl;

  constructor(private __http: HttpClient, private http: HttpClient) {
    this.baseurl = environment.url_server;
  }

  get_configurations(): Observable<any> {
    this.temp = this.baseurl + 'configurations';
    return this.__http
      .get(this.temp)
      .pipe(retry(1), catchError(this.errorHandl));
  }
}
