import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  TRANSLATIONS,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { GeneralService } from '../../../../../services/general.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogScheduleComponent } from '../descriptions/dialog-schedule/dialog-schedule.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-descriptions',
  templateUrl: './descriptions.component.html',
  styleUrls: ['./descriptions.component.scss'],
})
export class DescriptionsComponent implements OnInit {
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  description: string;
  src_img: string = '';
  availability = false;
  min_time = 1;
  base_price;
  currency;
  currencyList;
  formData;
  schedule = null;
  wifi = false;
  arraybool = [];
  hiddenUpload;
  capacity;
  week = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  file;
  files;

  descr = new FormControl('');
  // descrError:string;
  @Input() data;
  @Input() hiddenx;
  @Input() url;
  getErrorMessage() {
    const errorInput = 'No debe quedar en blanco';
    const errorEmail = 'Formato incorrecto de email: example@you.com';
    const errorSelect = 'No debe quedar sin seleccionar';

    //  if (this.descr.hasError('required')) {
    //    this.descrError=errorSelect;
    //    return this.descrError
    //  }
  }
  constructor(
    private GeneralService: GeneralService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.GeneralService.get_currencies().subscribe(
      (res) => {
        console.log('res  get_currencies on descriptions:)', res);
        this.currencyList = res.currency;
      },
      (err) => {
        console.log('ERRor en get_currencies', err);
      }
    );
    this.src_img = this.data?.result?.main_image;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('desc', changes, changes.data == null);
    if (changes.data != null) {
      this.description = changes.data?.currentValue?.result?.description;
      this.facebook = changes.data?.currentValue?.result?.facebook;
      this.twitter = changes.data?.currentValue?.result?.twitter;
      this.instagram = changes.data?.currentValue?.result?.instagram;
      this.youtube = changes.data?.currentValue?.result?.youtube;
      this.capacity = changes.data?.currentValue?.result?.capacity;
      this.availability = changes.data?.currentValue?.result?.status === '1';
      this.wifi = changes.data?.currentValue?.result?.wifi === '1';
      if (this.availability) {
        this.base_price = changes.data?.currentValue?.result?.amount;
        this.currency = parseInt(changes.data?.currentValue?.result?.currency) ;
      }

      if (!this.router.url.includes('create')) {
        if (changes.data?.currentValue?.result?.all_day) {
          console.log('hola========');
          this.schedule = {
            value: {
              typeSchedule: 1,
            },
          };
        } else if (
          changes.data?.currentValue?.result?.start_time_week !== null &&
          changes.data?.currentValue?.result?.end_time_week !== null
        ) {
          this.schedule = {
            value: {
              typeSchedule: 2,
              allDayStart: changes.data?.currentValue?.result?.start_time_week,
              allDayEnd: changes.data?.currentValue?.result?.end_time_week,
            },
          };
        } else if (changes.data?.currentValue?.schedule?.length > 0) {
          this.schedule = {
            value: {
              typeSchedule: 3,
              schedule: [],
            },
          };
          changes.data?.currentValue?.schedule?.forEach((element) => {
            const day = this.week[element.dayofweek];
            this.arraybool[element.dayofweek] = true;
            this.schedule['value'][day] = true;

            const item = {
              day: element.dayofweek,
              hour: [],
            };
            for (let index = 0; index < element.start_time.length; index++) {
              const hora = {
                start: element.start_time[index],
                end: element.end_time[index],
              };
              item.hour.push(hora);
            }
            this.schedule.value.schedule.push(item);
          });

          for (let index = 0; index < this.week.length; index++) {
            const dia = {
              day: index,
              hour: [
                {
                  start: '',
                  end: '',
                },
              ],
            };

            const buscardia = this.schedule.value.schedule?.find(
              (s) => s.day === index
            );
            if (!buscardia) {
              this.arraybool[index] = false;

              this.schedule.value.schedule.push(dia);
            }
          }
          const diaorden = this.schedule.value.schedule.sort((a, b) => {
            return a.day - b.day;
          });
          console.log(diaorden);

          console.log(this.schedule);
        }
      }
    }

    this.hiddenUpload = changes.hiddenx?.currentValue;
  }

  receiveSrcImg(event) {
    this.src_img = event;
  }

  receiveFile(file) {
    this.file = file;
    this.GeneralService.prepareMainImages(this.file);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogScheduleComponent, {
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      this.schedule = result == undefined || result == null ? null : result;
      console.log('this.schedule', this.schedule);
      if (this.schedule != null && this.schedule.value.typeSchedule == 3) {
        this.arraybool = [
          this.schedule.value.sun,
          this.schedule.value.mon,
          this.schedule.value.tue,
          this.schedule.value.wed,
          this.schedule.value.thu,
          this.schedule.value.fri,
          this.schedule.value.sat,
        ];
      }

      console.log('result de dialog:', this.schedule);
    });
  }

  validateUrl(input){

    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const regex = new RegExp(expression);
    if(input?.value.length>3){
      input['error']=!input.value.match(regex)
    }else{
      input['error']=false
    }
  }
}
