<div style="padding-left: 50px">
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <p class="raleway-23-g" style="padding-top: 18px">
        <b>Contacto</b><br>
        <span style="color: #888; font-size: 13px;"> Utiliza el formulario para ponerte en contacto</span>
      </p>
    

      <button [mat-dialog-close]="null" class="btn-close">
        <mat-icon aria-hidden="false">clear</mat-icon>
      </button>
    </div>
  
    <div class="question">
      <span>Asunto</span>
    </div>
  
    <div>
      <textarea (keyup.enter)="sendMessage()" [(ngModel)]="affair" class="custom-border" placeholder="Agrega un titulo o asunto para la conversacion"  name="" id="" cols="99" rows="3"></textarea>
    </div>
    <div class="question">
        <span>Mensaje</span>
      </div>
    
      <div>
        <textarea (keyup.enter)="sendMessage()" [(ngModel)]="textMessage" class="custom-border" placeholder="Proporciona detalles de la conversacion" name="" id="" cols="99" rows="3"></textarea>
      </div>
  
    <!-- <div class="enviar">
      <span>Enviar un mensaje al cliente</span>
    </div> -->
    <!-- <textarea name="" id="" cols="99" rows="3"></textarea> -->
  
    <div class="botones">
      <button mat-dialog-close="remove" class="btn">CANCELAR</button>
      <button (click)="sendMessage()">Enviar Mensaje</button>

    </div>
  </div>
  