import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { GeneralService } from '../../../../services/general.service';
import { CourseDashboardService } from '../../../../services/course-dashboard.service';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatStepper } from '@angular/material/stepper';
import { map, startWith, last, retry } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { EventsService } from './../../../../services/events.service';

@Component({
  selector: 'app-create-course',
  templateUrl: './create-course.component.html',
  styleUrls: ['./create-course.component.scss'],
})
export class CreateCourseComponent implements OnInit, OnDestroy {
  user;

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  firstFormGroup: FormGroup;

  load = false;
  showErrorImg = false;
  src_img: string = '';
  file;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('chipList') chipList;

  categories;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl(null, Validators.required);
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[] = [];
  searchTags = [];
  levels = [];
  controlStepper = {
    step1: false,
    step2: false,
    step3: false,
  };
  selectedStepIndex = 0;
  constructor(
    private _formBuilder: FormBuilder,
    private GeneralService: GeneralService,
    private CourseDashboardService: CourseDashboardService,
    private dialogRef: MatDialog,
    private EventsService: EventsService
  ) {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags.slice()
      )
    );
  }

  ngOnInit(): void {
    console.log('prueba curso');
    this.user = JSON.parse(localStorage.getItem('userData'));

    this.firstFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      category: ['', Validators.required],
      description: ['', Validators.required],
      level: ['', Validators.required],
      state: ['', Validators.required],
    });

    this.msgSuscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });
    this.serviceGetInfo();
  }

  serviceGetInfo() {
    this.CourseDashboardService.get_courses_create().subscribe(
      (res) => {
        console.log('respuesta endpoint', res);
        if (!res?.message_return?.ERROR) {
          this.categories = res.course_categories;
          this.levels = res.levels;
          res.tags.forEach((element) => {
            this.allTags.push(element.name);
            this.searchTags.push(element);
          });
          this.load = false;
        } else {
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit(res.message_return.ERROR_MENSAGGE);
        }
        this.checkStep();
      },
      (err) => {
        console.log('ERRor get_courses_create-get_event_create', err);
        this.GeneralService.typeMsg$.emit('error');
        this.GeneralService.msg$.emit(err.statusText);
      }
    );
  }

  checkStep() {
    const courseincomplete = localStorage.getItem('courseincomplete');
    if (
      courseincomplete &&
      courseincomplete !== '' &&
      courseincomplete === 'step2'
    ) {
      const idcourse = localStorage.getItem('idcourse');
      this.CourseDashboardService.set_courseId(idcourse);
      this.EventsService.set_eventId(-1);

      this.firstFormGroup.get('name').setValue(' ');
      this.firstFormGroup.get('category').setValue(' ');
      this.firstFormGroup.get('description').setValue(' ');
      this.firstFormGroup.get('level').setValue(' ');
      this.firstFormGroup.get('state').setValue(' ');

      this.controlStepper.step1 = true;
      this.stepper.next();

      console.log('vamos al paso 2');
    } else if (
      courseincomplete &&
      courseincomplete !== '' &&
      courseincomplete === 'step3'
    ) {
      console.log('vamos al paso 3');
      const idcourse = localStorage.getItem('idcourse');
      this.CourseDashboardService.set_courseId(idcourse);
      this.EventsService.set_eventId(-1);
      this.firstFormGroup.get('name').setValue(' ');
      this.firstFormGroup.get('category').setValue(' ');
      this.firstFormGroup.get('description').setValue(' ');
      this.firstFormGroup.get('level').setValue(' ');
      this.firstFormGroup.get('state').setValue(' ');

      this.controlStepper.step1 = true;
      this.stepper.next();
      this.controlStepper.step2 = true;

      setTimeout(() => {
        this.stepper.next();
      }, 0);
    }
  }

  serviceSave() {
    let postImage;
    let array_tags = [];
    if (
      this.firstFormGroup.status == 'VALID' &&
      this.tags.length > 0 &&
      this.src_img !== ''
    ) {
      // && this.file != undefined
      this.tags.forEach((element) => {
        for (let i = 0; i < this.searchTags.length; i++) {
          if (element == this.searchTags[i].name) {
            // item += this.searchTags.length ? `{ ${i} : ${this.searchTags[i].id}}`: `{ ${i} : ${this.searchTags[i].id}},`;
            array_tags.push({ tag_id: this.searchTags[i].id });
          }
        }
      });
      this.load = true;
      let data = {
        name: this.firstFormGroup.get('name').value,
        course_category_id: this.firstFormGroup.get('category').value,
        state: this.firstFormGroup.get('state').value,
        level_id: this.firstFormGroup.get('level').value,
        user_id: this.user.id,
        tags: array_tags,
        description: this.firstFormGroup.get('description').value,
      };
      console.log('data para servicio:', data);
      this.CourseDashboardService.post_admin_courses(data).subscribe(
        (res) => {
          console.log('RES create-post_admin_courses::', res);
          if (res.message_return.ERROR == false) {
            this.CourseDashboardService.dataBasic$.emit(
              this.firstFormGroup.get('name').value
            );
            this.CourseDashboardService.set_courseId(res.courseId);
            this.EventsService.set_eventId(-1);
            console.log(
              'this.CourseDashboardService.get_courseId():',
              this.CourseDashboardService.get_courseId()
            );
            this.GeneralService.onUploadOneCourse(
              this.file,
              res.courseId
            ).pipe(retry(3)).subscribe();

            this.controlStepper.step1 = true;
            this.stepper.next();

          }
        },
        (err) => {
          console.log('ERRor create-post_admin_courses incorrecto', err);
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit(err.name);
          this.load = false;
        }
      );
    } else {
      this.GeneralService.typeMsg$.emit('error');
      this.GeneralService.msg$.emit('Formulario incompleto');
      this.load = false;
      Object.keys(this.firstFormGroup.controls).forEach((key) => {
        this.firstFormGroup.get(key).markAsDirty();
        this.firstFormGroup.get(key).markAsTouched();
      });
      if (this.tags.length === 0) {
        this.chipList.errorState = true;
      }
      if (this.src_img === '') {
        this.showErrorImg = true;
      }
    }
  }

  get_invalidField(field: string) {
    return (
      this.firstFormGroup.get(field).invalid &&
      this.firstFormGroup.get(field).touched
    );
  }

  receiveSrcImg(event) {
    this.src_img = event;
    this.showErrorImg = false;
  }

  receiveFile(file) {
    this.file = file;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    console.log('add -----------> ', event.value);

    if ((value || '').trim()) {
      this.tags.push(value.trim());
    } // Add our tag

    if (input) {
      input.value = '';
    } // Reset the input value

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log('event.option.viewValue', event.option.viewValue);
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.chipList.errorState = false;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(
      (tag) => tag.toLowerCase().indexOf(filterValue) === 0
    );
  }
  getStep(step) {
    console.log(step);
    console.log(this.controlStepper);
    if (!(step.selectedIndex === 1 && this.controlStepper.step1)) {
      console.log('no pasa');
      this.selectedStepIndex = 0;
    }
  }

  nextStep2(valid) {
    this.controlStepper.step2 = true;

    setTimeout(() => {
      this.stepper.next();
      this.dialogRef.closeAll();
    }, 100);

    // this.stepper.next();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSuscription;
    this.typeMsgSuscription;
  }
}
