import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-budget-sent-received',
  templateUrl: './budget-sent-received.component.html',
  styleUrls: ['./budget-sent-received.component.scss']
})
export class BudgetSentReceivedComponent implements OnInit {
  sent={
    sent:[
      {
        list:1
      }
    ],
    receive:[
      {
        list:1
      }
    ]
  }
  constructor() { }

  ngOnInit(): void {
  }

}
