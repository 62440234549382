import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, retryWhen, delay, take } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const maxRetries = 0; 
    const delayMs = 10000; 

    return next.handle(request).pipe(
      retryWhen(errors =>
        errors.pipe(
          delay(delayMs),
          take(maxRetries),
          catchError((error) => {
            if (error.status === 429) {
              return timer(delayMs);
            }
            return throwError(error);
          })
        )
      )
    );
  }
}
