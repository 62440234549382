import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProposalService } from 'src/app/services/proposal.service';
import { User } from '../../../../auth/reset-password/reset-password.component';

@Component({
  selector: 'app-modal-offer',
  templateUrl: './modal-offer.component.html',
  styleUrls: ['./modal-offer.component.scss'],
})
export class ModaloffertComponent implements OnInit {
  idcourse;
  user;
  reason;
  idevent;
  iscourse = false;
  constructor(
    private router: Router,
    private proposalService: ProposalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog

  ) {}

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute=()=>{
      return false;
    }
    this.user = JSON.parse(localStorage.getItem('userData'));

    console.log(this.data);
    this.idcourse = this.data.id_course;
    this.idevent = this.data.id_event;

    if (this.data.id_course) {
      this.iscourse = true;
    }
  }

  acceptproposal() {
    const request = {
      reason: this.reason,
    };

    if (this.iscourse) {
      this.proposalService
        .post_acceptproposalcourse(this.idcourse, this.user.id, request)
        .subscribe((data) => {
          this.router.navigateByUrl('/dashboard/proposals');
          console.log('-----------', data);
this.dialog.closeAll();

        });
    } else {
      this.proposalService
        .post_accepteventproposal(this.idevent, this.user.id, request)
        .subscribe((data) => {
          this.router.navigateByUrl('/dashboard/proposals');
          console.log('-----------', data);
this.dialog.closeAll();

        });
    }
  }
}
