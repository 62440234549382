import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventsService } from '../../../../../../services/events.service';
import { GeneralService } from '../../../../../../services/general.service';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-publish-noquestion',
  templateUrl: './modal-publish-noquestion.component.html',
  styleUrls: ['./modal-publish-noquestion.component.scss'],
})
export class ModalPublishNoquestionComponent implements OnInit {
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;
  msg;
  typeMsg;
  fromCourse = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private EventsService: EventsService,
    private _router: Router,
    private GeneralService: GeneralService
  ) {}

  ngOnInit(): void {
    this.msgSuscription = this.GeneralService.msg$.subscribe((data) => {
      this.msg = data;
    });
    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe((data) => {
      this.typeMsg = data;
    });
  }

  save() {
    console.log('data para mariana:', this.data);
    this.EventsService.post_admin_proposal(this.data.data).subscribe(
      (res) => {
        console.log('Propuesta se creo correcto: res', res);
        if (!res?.message_return?.ERROR) {
          localStorage.removeItem('courseincomplete');
          localStorage.removeItem('idcourse');
          localStorage.removeItem('eventincomplete');
          localStorage.removeItem('idevent');
          if (this.fromCourse) {
            this._router.navigateByUrl(
              '/dashboard/courses/list-teachers/' + res.proposal_id
            );
          } else {
            this._router.navigateByUrl(
              '/dashboard/events/list-teachers/' + res.proposal_id
            );
          }
        } else {
          this.GeneralService.typeMsg$.emit('error');
          this.GeneralService.msg$.emit(
            res.message_return.ERROR_MENSAGGE + 'get_currencies'
          );
        }
      },
      (err) => {
        console.log('ERRor post_admin_proposal on no-question', err);
        this.GeneralService.typeMsg$.emit('error');
        this.GeneralService.msg$.emit(err.statusText);
      }
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.msgSuscription;
    this.typeMsgSuscription;
  }
}
