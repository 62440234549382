<div *ngIf="type == 'success'" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px" class="bg-success">
    <span class="material-icons icon">check_circle_outline</span>
    <span class="text">{{message}}</span>
</div>

<div *ngIf="type == 'error'" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px" class="bg-error">
    <span class="material-icons icon">highlight_off</span>
    <span class="text"><b>Error: </b>{{message}}</span>
</div>


<!-- <div *ngIf="msgAddress" fxLayout="row" fxLayoutAlign="space-between center"
    [ngClass]="{'msg-success':typeMsg == 'success', 'msg-err':typeMsg == 'error'}" class="msg">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <i *ngIf="typeMsg == 'success'" class="fas fa-check icon"></i>
        <i *ngIf="typeMsg == 'error'" class="fas fa-times"></i>
        <p style="font-size: 15px;">{{msgDir}}</p>
    </div>
    <button [ngClass]="{'msg-success':typeMsg == 'success', 'msg-err':typeMsg == 'error'}" class="btn-close">
        <mat-icon aria-hidden="false" (click)="closeMsg()">clear</mat-icon>
    </button>
</div> -->

<div *ngIf="msg!=null" fxLayout="row" fxLayoutAlign="space-between center" style="margin-bottom: 20px"
    [ngClass]="{'success':typeMsg == 'success', 'error':typeMsg == 'error', 'warning':typeMsg == 'warning'}" fxFlex>
    <div fxLayout="row" fxLayoutGap="3%" fxFlex>
        <mat-icon *ngIf="typeMsg=='success'" class="icon-success">check_circle_outline</mat-icon>
        <mat-icon *ngIf="typeMsg=='warning'" class="icon-warning">error_outline</mat-icon>
        <mat-icon *ngIf="typeMsg=='error'" class="icon-error">highlight_off</mat-icon>

        <p
            [ngClass]="{'text-success':typeMsg == 'success', 'text-error':typeMsg == 'error', 'text-warning':typeMsg == 'warning'}">
            {{msg}}
        </p>
    </div>
    <button
        [ngClass]="{'btn-close-success':typeMsg == 'success', 'btn-close-error':typeMsg == 'error', 'btn-close-warning':typeMsg == 'warning'}"
        (click)="close()">
        <mat-icon>clear</mat-icon>
    </button>
</div>