import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactSupportService } from '../../services/contact-support.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  form: FormGroup;
  selected = '';
  description;
  problem_type_id;
  email;
  name;
  message;
  show = false;
  success = false;

  errorForm = false;

  constructor(
    private ContactSupportService: ContactSupportService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, Validators.required],
      problem_type_id: [null, Validators.required],
      description: [null, Validators.required],
      email: [null, Validators.required],
    });
    this.form.valueChanges.subscribe((adrian) => {



      this.errorForm = false;
    });
  }
  save() {


    if (this.form.valid) {
      let data = {
        name: this.form.value.name,
        problem_type_id: this.form.value.problem_type_id,
        description: this.form.value.description,
        email: this.form.value.email,
      };


      this.show = true;
      this.success = false;
      this.ContactSupportService.post_contactSupport(data).subscribe(
        (resp) => {

          if (resp.message_return.ERROR == false) {
            this.show = true;
            this.success = true;
            this.message = resp.message_return.ERROR_MENSAGGE;
          }
        },
        (err) => {

        }
      );
    } else {
      this.errorForm = true;
      Object.keys(this.form.controls).forEach((key) => {
        this.form.get(key).markAsDirty();
        this.form.get(key).markAsTouched();
      });
    }
  }
}
