import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject,Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketIoService {
  private newMessagesSubject = new Subject<any[]>();


  public message$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private socket: Socket) {

    this.setupEventListeners();
   }

  // sendMessage(msg: string) {
  //   this.socket.emit('message', msg);
  // }
  // withCredentials() {
  //   this.socket.withCredentials = true;
  // }
  private setupEventListeners() {
    this.socket.fromEvent('sendMessagesPrivate').subscribe((data: any) => {
      // Cuando se recibe un nuevo evento, emite los datos a través del Subject
      this.newMessagesSubject.next(data);
    });
  }
  getMessages(meeting_id) {
    this.socket.emit("getLastMessage", meeting_id);
  }
  listenForLastChats(): Observable<any[]> {
    return this.socket.fromEvent('LastMessages');
  }
  listenToServerEvents(eventName: string): Observable<any> {
    return new Observable((observer) => {
      this.socket.on(eventName, (data: any) => {
        observer.next(data);
        console.log("listener: "+data)
      });
    });
  }
  public getNewMessage = () => {
    this.socket.on('sendMessagesPrivate', (message) =>{
      this.message$.next(message);
      console.log('socket mensaje: ' + message)
    });

    return this.message$.asObservable();
  };
  
  on(event: string, callback: Function) {
    this.socket.on(event, callback);
  }
  register(register){
    this.socket.emit("register", register);
  }
  seenMessage(message_id){
    this.socket.emit("seenMessage", message_id)
  }
  
  editMessage(message_id, content){
    this.socket.emit("editMessage", message_id, content)
  }
  deleteMessage(message_id){
    this.socket.emit("deleteMessage",message_id);
  }
  newMeeting(UserId1:string , UserId2: string, affair:string
    ){
    this.socket.emit("create_meeting", UserId1,UserId2,affair
    );
  }
  getMeeting(UserId){
    this.socket.emit("get_private_chats", UserId);
  }
  listenForChatsData(): Observable<any[]> {
    return this.socket.fromEvent('private_chats_data');
  }
  listenNotifications(): Observable<any> {
    return this.socket.fromEvent('newsMessages');
  }
  listenIdMeet():  Observable<any[]> {
    return this.socket.fromEvent('meetIdChat')

  }
  JoinMeeting(data){
    this.socket.emit('joinMeeting', data);
  }
  SenderMessage(data){
    this.socket.emit('sendMessagesPrivate' , data);
  }

  // getMessage() {
  //   this.socket.on('message', (message: string) => {
  //     this.message$.next(message)
  //   });

  //   return this.message$.asObservable();
  // }
}
