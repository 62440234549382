<mat-drawer-container class="drawer-container" style="overflow: hidden !important;">
    <div class="content">
        <app-header></app-header>
            <app-sub-header [screen]="'contacto'" ></app-sub-header>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" style="padding: 50px 155px;">
                    <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                            <p class="title">¿Necesitas ayuda?</p>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                            <p class="subtitle">Si usas la Sogcial y deseas hacer alguna pregunta o necesitas ayuda técnica, visita nuestro Centro de Ayuda para ver los tutoriales de la plataforma, o revisa nuesta seccion de preguntas frecuentes.</p>

                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                            <p class="subtitle">También, puedes contactarte con nuestro equipo de Atención al cliente a través de <strong>soporte@sogcial.com</strong> o por el siguiente formulario:</p>
                        </div>
                    </div>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" style="padding: 50px 155px;">
                    <div [formGroup]="form" fxFlex="60%" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                            <mat-form-field fxFlex>
                                <mat-label>Nombre</mat-label>
                                <input matInput formControlName="name">
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                            <mat-form-field fxFlex>
                                <mat-label>Correo</mat-label>
                                <input matInput formControlName="email" >
                            </mat-form-field>
                        </div>
                         <mat-form-field>
                            <mat-label>Tipo de problema</mat-label>
                            <mat-select [(value)]="selected" formControlName="problem_type_id" >
                              <mat-option>None</mat-option>
                              <mat-option value="1">Opcion 1</mat-option>
                              <mat-option value="2">Opcion 2</mat-option>
                              <mat-option value="3">Opcion 3</mat-option>
                            </mat-select>
                          </mat-form-field>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px">
                            <mat-form-field class="example-full-width">
                                <mat-label>Deja un comentario</mat-label>
                                <textarea matInput placeholder="" formControlName="description" ></textarea>
                              </mat-form-field>
                        </div>
                        <div *ngIf="errorForm === true" class="error">
                          Faltan campos por llenar
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" *ngIf="!show">
                            <button (click)="save()"  mat-button class="example-icon favorite-icon style-login registrate" style="margin: 0px 20px;" >
                                Enviar
                            </button>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" *ngIf="show&&!success">
                            <mat-spinner></mat-spinner>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center stretch" fxLayoutGap.gt-sm="20px" *ngIf="success">
                            <p>El mensaje ha sido enviado</p>
                        </div>

                    </div>
                </div>
        <app-footer></app-footer>
    </div>
</mat-drawer-container>
