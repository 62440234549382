import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sent',
  templateUrl: './sent.component.html',
  styleUrls: ['./sent.component.scss']
})
export class SentComponent implements OnInit {
  displayedColumns: string[] = ['teacher',"user","actions"];
  
  dataSource 
  constructor() { }

  ngOnInit(): void {
    let ELEMENT_DATA=[
      {
        id:1,
        teacher:{
          name:"Jose rojas",
          courseName:"Programacion Web",
          city:"San Cristobal",
          country:"Venezuela"
        },
        user:{
          name:"Jose Zapata",
          star:4,
          image:"/assets/images/default.png",
          timer:"Publicado hace un mes"
        },
        actions:{
          show:"visibility",
          trash:"delete"
        }
      },
      {
        id:2,
        teacher:{
          name:"Jose rojas",
          courseName:"Programacion Web",
          city:"San Cristobal",
          country:"Venezuela"
        },
        user:{
          name:"Jose Zapata",
          star:5,
          image:"/assets/images/default.png",
          timer:"Publicado hace un mes"
        },
        actions:{
          show:"visibility",
          trash:"delete"
        }
      },
      {
        id:2,
        teacher:{
          name:"Jose rojas",
          courseName:"Programacion Web",
          city:"San Cristobal",
          country:"Venezuela"
        },
        user:{
          name:"Jose Zapata",
          star:3,
          image:"/assets/images/default.png",
          timer:"Publicado hace un mes"
        },
        actions:{
          show:"visibility",
          trash:"delete"
        }
      },
      
      
    ];

    this.dataSource=ELEMENT_DATA
  }

}
