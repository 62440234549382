<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" class="p-term" style="padding-left: 11em;padding-right: 11em; padding-top: 3em;">
    <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
            <p class="title">Confirma tu compra</p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
            <p>Puedes pagar con: </p>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond" style="height: 2em; border-bottom: 1px solid #000000; margin-bottom: 28px;">
            
        </div>
    </div>
</div>   

<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between none" fxLayoutGap="36px" class="p-term" style="padding-left: 11em;padding-right: 11em;">
    <div fxFlex="25%" fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
            <button  routerLink="/auth/login" mat-button class="example-icon favorite-icon style-login ingresar" style="padding-left: 3em; padding-right: 10em;" >
                Credito / Debito
            </button>
            <button  routerLink="/auth/register" mat-button class="example-icon favorite-icon style-login ingresar" style="margin: 0px 20px;" >
                <img src="/assets/images/paypal.png" width="100%" alt="">
            </button>
        </div>
    </div>

    <div fxFlex="75%" fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end end stretch" fxLayoutGap.gt-sm="20px" class="tab-term-cond">
            <p class="total">Total: CO$ 210.000</p>
        </div>
    </div>
</div>   

