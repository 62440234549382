import { Injectable } from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, retry} from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public baseurl: string;
  temp: string;
  public message;
  headers = new Headers();

  constructor(private __http: HttpClient,  private http: HttpClient) {
    this.baseurl = environment.url_server;
    this.headers.set("Content-Type", "multipart/form-data");const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      this.headers.set("Authorization", 'Bearer ' + JSON.parse(accessToken));
    } else {
      console.error("'access_token' not found in localStorage");
    }
  }


  getMessage(){
    return this.message;
  }

  setMessage(msg){
    this.message = msg;
  }

  get_getProducts(): Observable<any> {
    this.temp = this.baseurl + "getProducts";
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  post_admin_products(data): Observable<any> {
    this.temp = this.baseurl + 'admin/addProduct';
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  get_admin_getCartList(id): Observable<any> {
    this.temp = this.baseurl + "admin/carts";
    console.log(this.temp);
    return this.__http.get(this.temp)
      .pipe(retry(1),
        catchError(this.errorHandl)
      )
  }

  put_admin_products(id, data): Observable<any> {
    this.temp = this.baseurl + 'admin/products/' + id;

    return this.__http.put(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }

  delete_admin_products(id, data): Observable<any>{
    this.temp = this.baseurl + `admin/products/${id}`;
    data['_method'] = 'DELETE';
    return this.__http.post( this.temp, data)
      .pipe( retry(1),
        catchError(this.errorHandl)
      );
  }

  post_admin_orders(data): Observable<any> {
    this.temp = this.baseurl + 'admin/orders';
    return this.__http.post(this.temp, data)
      .pipe(retry(1),
        catchError(this.errorHandl)
      );
  }


  generateQrCodesPDF(data,quantity: number): Observable<any> {
    return this.http.get(`${this.baseurl}generate-qr-codes-pdf/${quantity}`, { responseType: 'json' });
}

  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;// Get client-side error
    } else {
      errorMessage = error; // Get server-side error
    }
    return throwError(errorMessage);
  }

  incrementQuantity(id): Observable<any> {
    return this.http.post(`${this.baseurl}increment-quantity/${id}`, { responseType: 'json' });
}

decrementQuantity(id): Observable<any> {
  return this.http.post(`${this.baseurl}decrement-quantity/${id}`, { responseType: 'json' });
}

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }
}
