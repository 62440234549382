import { Component, Input, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { GeneralService } from '../../../../../services/general.service';
import { EventsService } from '../../../../../services/events.service';
import { MatDialog } from '@angular/material/dialog';
import { ShowPlaceComponent } from '../../../events/create/step-places/show-place/show-place.component';


@Component({
  selector: 'app-step-place-course',
  templateUrl: './step-place-course.component.html',
  styleUrls: ['./step-place-course.component.scss']
})
export class StepPlaceCourseComponent implements OnInit, OnDestroy {

  @Input() createPage=false;
  @Output() nextStep: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("paginatorOwn", {static: true}) paginatorOwn: MatPaginator;
  @ViewChild("paginatorRent", {static: true}) paginatorRent: MatPaginator;

  msg;
  typeMsg;
  msgSuscription: Subscription;
  typeMsgSuscription: Subscription;

  user;

  show:number=0;

  own=[];
  rent=[];
  dataSourceOwn;
  dataSourceRent;
  displayedColumnsOwn: string[] = ['name', 'category', 'capacity','ubication','visibility'];
  displayedColumnsRent: string[] = ['name', 'category', 'capacity','ubication','visibility'];

  constructor(private GeneralService: GeneralService,
    private EventsService:EventsService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("userData"));
    console.log("places :this.user on step-place:", this.user);
    this.msgSuscription = this.GeneralService.msg$.subscribe(data => {
      this.msg = data;
    });

    this.typeMsgSuscription = this.GeneralService.typeMsg$.subscribe(data => {
      this.typeMsg = data;
    });

    this.EventsService.get_places_availables(this.user.id).subscribe(res => {
      console.log("res:places get_places_availables:", res);
      if(!res.message_return.ERROR){
        this.own=res.owns; this.rent=res.rents;
        this.dataSourceOwn = new MatTableDataSource(res.owns);
        this.dataSourceOwn.paginator = this.paginatorOwn;
        this.dataSourceRent = new MatTableDataSource(res.rents);
        this.dataSourceRent.paginator = this.paginatorRent;
      }else{
        this.GeneralService.typeMsg$.emit("error");
        this.GeneralService.msg$.emit(res.message_return.ERROR_MENSAGGE+"get_places_availables");
      }
    }, err => {
      console.log("ERRor get_places_availables", err);
      this.GeneralService.typeMsg$.emit("error");
      this.GeneralService.msg$.emit(err.statusText);
    });
  }


  openDialogOwn(element) {
    const dialogRef = this.dialog.open(ShowPlaceComponent, {
      height: '650px',
      width: '1000px',
      // width: 'auto',
      data: {element, showRent: false, course:true}
    });

    const sub = dialogRef.componentInstance.goToStep3.subscribe(() => {
      console.log("probando ir al paos 3");
      this.nextStepper();
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openDialogRent(element) {
    const dialogRef = this.dialog.open(ShowPlaceComponent, {
      height: '650px',
      width: '1000px',
      // width: 'auto',
      data: {element, showRent: true, course:true}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  nextStepper(){
    this.nextStep.emit(true);
  }


  applyFilterOwn(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceOwn.filter = filterValue.trim().toLowerCase();
  }

  applyFilterRent(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceRent.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(){
    this.msgSuscription;
    this.typeMsgSuscription
  }

}
